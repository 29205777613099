import * as settingsService from '../../services/settings';
import * as actionTypes from './actionTypes';

export const getSettingsLoading = () => { return { type: actionTypes.GET_SETTINGS_LOADING }; };

export const getSettingsSuccess = (data) => {
	return {
		type: actionTypes.GET_SETTINGS_SUCCESS,
		payload: data
	};
};

export const getSettingsError = (error) => {
	return {
		type: actionTypes.GET_SETTINGS_ERROR,
		payload: error
	};
};

export const getSettings = () => {
	return (dispatch) => {
		dispatch(getSettingsLoading());

		return settingsService.getSettings()
			.then((result) => { return dispatch(getSettingsSuccess(result.data.settings)); })
			.catch((error) => { return dispatch(getSettingsError(error)); });
	};
};
