import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import SupportNotesAddModal from './supportCustomersSupportNotesAddModal';
import SupportNotesUpdateModal from './supportCustomersSupportNotesUpdateModal';
import SupportNotesDeleteModal from './supportCustomersSupportNotesDeleteModal';
import { deleteFile, getNoteDetails, uploadFile, uploadingFile } from '../../data/actions/actions';
import { Badge, Icon, Modal } from '@syneto/compass-react';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

function SupportCustomersSupportNotes(props) {
	let { maintenanceNotes, productNotes, productId, noteForPendingProduct, uploadMessageSuccess, uploadMessageError } = props;

	const [addModal, toggleAddModal] = useState(false);
	const [updateModal, toggleUpdateModal] = useState(false);
	const [deleteModal, toggleDeleteModal] = useState(false);
	const [note, setNote] = useState(null);
	const [attachmentId, setAttachmentId] = useState(null);
	const [attachmentName, setAttachmentName] = useState('');
	const [deleteWarningModal, toggleDeleteWarningModal] = useState(false);

	useEffect(() => {
		uploadMessageSuccess && props.pushSuccessfulNotification(uploadMessageSuccess);
		uploadMessageError && props.pushDangerNotification(uploadMessageError);
	}, [uploadMessageSuccess, uploadMessageError]);

	function uploadFile(event, noteId) {
		props.uploadingFile();
		props.uploadFile(noteId, event.target.files[0], productId);
	}

	function openDeleteWarningModal(note, attachment) {
		toggleDeleteWarningModal(true);
		setNote(note);
		setAttachmentId(attachment.id);
		setAttachmentName(attachment.originalName);
	}

	function deleteFile(noteId, attachmentId) {
		props.deleteFile(noteId, attachmentId, productId);
		toggleDeleteWarningModal(false);
	}

	function openAddSupportNoteModal() {
		toggleAddModal(true);
	}

	function openUpdateSupportNoteModal(note) {
		toggleUpdateModal(true);
		props.getNoteDetails(note.id);
		setNote(note);
	}

	function openDeleteSupportNoteModal(note) {
		toggleDeleteModal(true);
		props.getNoteDetails(note.id);
		setNote(note);
	}

	function convertUrl(content) {
		let replacedText = content + '\n';
		const replacePattern = /(?:http(s)?:\/\/)?(?:ftp?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+\s/gim;
		const regExpMatches = replacedText.match(replacePattern);
		regExpMatches && regExpMatches.forEach((regExpMatch) => {
			regExpMatch.trim();
		});
		const hyperLinkElements = [...new Set(regExpMatches)];
		hyperLinkElements.forEach((hyperLinkElement) => {
			replacedText = replacedText.replace(new RegExp(String(hyperLinkElement), 'g'), `<a target=${'_blank'} href=${hyperLinkElement}>${hyperLinkElement}</a>`);
		});
		return parse(replacedText);
	}

	function renderProductNotes(notes) {
		if (!notes.length) {
			return <div className="row m-0">
				<label className="col-3 pt-2" style={{ fontSize: '14px' }}>Support notes</label>
				<div className="col-9 pt-2 add-note-btn-support" style={{ fontSize: '14px' }}
					onClick={openAddSupportNoteModal}>
					<i className="fa fa-plus pf-2" />Add note
				</div>
			</div>;
		}

		return <div className="row m-0">
			<label className="col-3 pt-2" style={{ fontSize: '14px' }}>Support notes</label>
			<div className="col-9">
				{notes.map((note) => {
					return (
						<div className="row pt-2 pb-1 bottom-border container-product-note" key={note.id}>
							<div className="col-8">
								<div className="note-title">{note.title}</div>
								<div>{convertUrl(note.content)}</div>
								{note.product_note_attachments && note.product_note_attachments.map((attachment) => {
									return <Badge key={attachment.id} className="my-1 me-2" role="outline">
										<a target="_blank" href={attachment.url} rel="noreferrer">{attachment.originalName}</a>
										<a onClick={() => { return openDeleteWarningModal(note, attachment); }}>
											<Icon name="fas fa-times ms-2" size="xs" color="#9C353E" />
										</a>
									</Badge>;
								})
								}
							</div>

							<div className="col-4">
								<span className="me-2" onClick={() => { return openUpdateSupportNoteModal(note); }}>
									<i className="fa fa-pencil" />
								</span>
								<span className="me-2" onClick={() => { return openDeleteSupportNoteModal(note); }}>
									<i className="fa fa-trash" />
								</span>

								<span>
									<label style={{ color: '#7a6be8', cursor: 'pointer' }} htmlFor={`attachment${note.id}`}>
										<i className="fa fa-plus" />
									</label>
									<input hidden type="file" id={`attachment${note.id}`} name={`attachment${note.id}`}
										onChange={(e) => { return uploadFile(e, note.id); }} />
								</span>
							</div>
						</div>
					);
				})}
			</div>

			<div className="col-3" />
			<div className="col-9 add-note-btn-support" onClick={openAddSupportNoteModal}>
				<i className="fa fa-plus pf-2" />Add note
			</div>
		</div>;
	}

	return (
		<div>
			<div className="row m-0 border-bottom">
				<label className="col-3 pt-2" style={{ fontSize: '14px' }}>Operations note</label>
				<div className="col-9 pt-2">
					<span>{maintenanceNotes ? maintenanceNotes : 'There is no note for this product.'}</span>
				</div>
			</div>
			{renderProductNotes(productNotes)}

			<SupportNotesAddModal isOpen={addModal} toggleModal={toggleAddModal} productId={productId}
				noteForPendingProduct={!!noteForPendingProduct} />
			<SupportNotesUpdateModal isOpen={updateModal} toggleModal={toggleUpdateModal} productId={productId}
				note={note} noteForPendingProduct={!!noteForPendingProduct} />
			<SupportNotesDeleteModal isOpen={deleteModal} toggleModal={toggleDeleteModal} productId={productId}
				note={note} noteForPendingProduct={!!noteForPendingProduct} />
			<Modal show={deleteWarningModal}
				onHide={() => { return toggleDeleteWarningModal(false); }}
				content={`Are you sure you want to delete ${attachmentName}`}
				title="Warning"
				actions={{
					primary: {
						label: 'Delete',
						onClick: () => { return deleteFile(note.id, attachmentId); }
					},
					secondary: {
						label: 'Cancel'
					}
				}}
			/>
		</div>
	);
}

const mapStateToProps = ({ productNotes }) => {
	return {
		uploadMessageSuccess: productNotes.uploadMessageSuccess,
		uploadMessageError: productNotes.uploadMessageError,
		productNotes: productNotes.notes
	};
};

SupportCustomersSupportNotes.propTypes = {
	maintenanceNotes:      PropTypes.string,
	productNotes:          PropTypes.array.isRequired,
	userId:                PropTypes.string,
	maintenanceServiceId:  PropTypes.string,
	productId:             PropTypes.string,
	noteForPendingProduct: PropTypes.bool
};

export default connect(mapStateToProps, {
	uploadingFile,
	uploadFile,
	deleteFile,
	getNoteDetails,
	pushSuccessfulNotification,
	pushDangerNotification
})(SupportCustomersSupportNotes);
