export const GET_PRODUCTS_DEFAULT_QUERY_PARAMS = {
	start: 0,
	limit: 15,
	searchFor: ''
};

export const GET_PRODUCTS_INACTIVE_QUERY_PARAMS = {
	start: 0,
	limit: 15,
	searchFor: '',
	status: 'inactive'
};
