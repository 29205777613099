import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Alert } from '@syneto/compass-react';

const EnforcementDisclamer = ({ close }) => {
	return (
		<Card
			className="z-depth-1"
			title={'Setup multiple factor authentication'}
			content={
				<>
					<Alert variant='warning'>
						Your administrator has enabled multifactor authentication for your account. Please configure MFA.
					</Alert>
					<div className='d-flex justify-content-end'>
						<Button onClick={close}>Configure multi-factor</Button>
					</div>
				</>
			}
		/>
	);
};

export default EnforcementDisclamer;

EnforcementDisclamer.propTypes = {
	close: PropTypes.func.isRequired,
};
