import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Badge, Button, Table, Tooltip } from '@syneto/compass-react';
import Utils from '../../../../../helpers/Utils';
import { attachProductsToEvents, extractSerialNumbersFromSerenityEvents } from '../../../../../helpers/normalize-data';
import { getCurrentStatusForSerenityEvents } from '../../../../../helpers/normalize-data';
import { SerenityEventsStatusFormatter } from '../../../../../helpers/SerenityEventsStatusFormatter';
import { getProductsBySerialNumber } from '../../../../../data/product/actions';
import { deleteSerenityEvent, loadSerenityEvents, updateSerenityEvent } from '../../../../../data/serenity-events/actions';
import SupportSerenityEventsTableFilters from './supportSerenityEventsTableFilters';
import { SupportSerenityEventsEditModal } from './supportSerenityEventsEditModal';
import { SupportSerenityEventsDeleteModal } from './supportSerenityEventsDeleteModal';
import Preloader from '../../../../../components/preloader';



const SupportSerenityAllEventsTable = (props) => {
	const { events, products, pagination, minimal, loading, serialNumber, loadSerenityEvents, updateSerenityEvent, getProductsBySerialNumber, deleteSerenityEvent } = props;
	const [queryParams, setQueryParams] = useState({});
	const [isEditModalVisible, setIsEditModalVisible] = useState(false);
	const [eventBeingEdited, setEventBeingEdited] = useState(null);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [eventsToBeDeleted, setEventsToBeDeleted] = useState([]);
	const [eventsData, setEventsData] = useState([]);

	const defaultPagination = {
		limit: 15,
		start: 0
	};
	const defaultQueryParams = {
		...defaultPagination,
		...( serialNumber ? { serialNumber }: {}),
		...( !minimal ? { status: 'new' }: { showIgnoredEvents: true }),
	};

	useEffect(() => {
		if (!serialNumber) {
			fetchSerenityEvents(defaultQueryParams);
		}
	}, []);

	useEffect(() => {
		if (serialNumber) {
			fetchSerenityEvents(defaultQueryParams);
		}
	}, [serialNumber]);

	useEffect(() => {
		const listOfSerialNumbers = extractSerialNumbersFromSerenityEvents(events);

		if (listOfSerialNumbers.length > 0) {
			getProductsBySerialNumber(listOfSerialNumbers);
		}
	}, [events]);

	useEffect(() => {
		if (products.length > 0 && events.length > 0) {
			setEventsData(attachProductsToEvents(products, events));
		} else {
			setEventsData([]);
		}
	}, [events, products]);


	useEffect(() => {
		if (eventBeingEdited) {
			const eventFound = events.find((event) => {
				return eventBeingEdited.id === event.id;
			});

			eventFound && setEventBeingEdited(eventFound);
		}
	}, [events]);

	const fetchSerenityEvents = (newQueryParams) => {
		const fetchQueryParams = { ...queryParams, ...newQueryParams };
		let url = '/serenity/events';

		if (!_.isEmpty(fetchQueryParams)) {
			setQueryParams(fetchQueryParams);
			url = url + `?${new URLSearchParams(fetchQueryParams)}`;
		}

		loadSerenityEvents(url);
	};

	const handleTableFiltersChange = (newQueryParams) => {
		fetchSerenityEvents({ ...newQueryParams, ...defaultPagination });
	};

	const renderStatusBadge = (status) => {
		switch (status) {
			case 'closed':
				return (
					<Badge appearance="success" role="outline">
						{Utils.toUppercase(status)}
					</Badge>
				);
			case 'ignored':
				return (
					<Badge role="outline">
						{Utils.toUppercase(status)}
					</Badge>
				);
			default:
				return (
					<Badge appearance={`${SerenityEventsStatusFormatter(status)}`}>
						{Utils.toUppercase(status)}
					</Badge>
				);
		}
	};

	const renderVisibilityBullet = (currentStatus) => {
		if (currentStatus !== 'new' && currentStatus !== 'ignored') {
			return (
				<Tooltip placement="top" text="Visible to the end user">
					<a href="javascript:" className="bullet" />
				</Tooltip>
			);
		}

		return null;
	};

	const renderStatus = (cell, row) => {
		const currentStatus = getCurrentStatusForSerenityEvents(row.state).value;
		const userStateAcknowledged = row.userState.find((userState) => {
			return userState.value === 'acknowledged';
		});

		return (
			<>
				{renderStatusBadge(currentStatus)}
				{userStateAcknowledged === undefined && renderVisibilityBullet(currentStatus)}
			</>
		);
	};

	const renderDateTime = (cell, row) => {
		return (
			moment(row.recordTimestamp, 'x').format('DD/MM/YYYY - HH:mm')
		);
	};

	const renderEventTitle = (cell, row) => {
		return (
			<Tooltip key={row.id} placement="bottom" text={<><p><strong>Title:</strong> {row.content.subject}</p>
				<p><strong>Description:</strong>
					<span className="text-monospace">
						{row.content.body.length > 255 ? row.content.body.substring(0, 255) + ' ...' : row.content.body}
					</span>
				</p>
			</>}>
				<div>{row.content.subject}</div>
			</Tooltip>
		);
	};

	const renderCellActions = (cell, row) => {
		return <Button role="tertiary" onClick={() => { return openEditModal(row); }}>Edit</Button>;
	};

	const getTableActions = () => {
		const changeStatusActions = ['New', 'Pending', 'Resolved', 'Closed', 'Ignored'].map((status) => {
			return {
				label: status,
				onClick: (events) => { 
					return handleEventsStatusChange(events, status.toLowerCase()); 
				}
			};
		});
		const deleteAction = {
			label: 'Delete',
			onClick: (events) => { return openDeleteModal(events); }
		};

		return [...changeStatusActions, deleteAction];
	};

	const getPaginationOptions = () => {
		return {
			sizePerPageList: [15, 30, 60, 100, 200],
			sizePerPage: pagination.itemsPerPage,
			showTotal: true,
			page: pagination.currentPage,
			totalSize: pagination.totalItems
		};
	};

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
			case 'search': {
				handleSearch(searchText);
				return;
			}
		}
	};

	const handlePagination = (page, sizePerPage) => {
		const start = String((page - 1) * sizePerPage);

		fetchSerenityEvents({
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleSearch = (value) => {
		fetchSerenityEvents({
			searchFor: value,
			start: 0
		});
	};

	const handleEventsStatusChange = (events, status) => {
		return Promise.all(events.map((event) => {
			event.state.push({
				value: status,
				timeStamp: moment().valueOf().toString()
			});

			return updateSerenityEvent({
				event
			});
		})).then(() => {
			fetchSerenityEvents();
		});
	};

	const handleEventStatusChange = (event, status) => {
		handleEventsStatusChange([event], status.toLowerCase());
	};

	const openEditModal = (event) =>{
		setEventBeingEdited({ ...event });
		setIsEditModalVisible(true);
	};

	const closeEditModal = () => {
		setIsEditModalVisible(false);
		setEventBeingEdited(null);
	};

	const handleEventsDelete = (events) => {
		Promise.all(events.map((event) => {
			return deleteSerenityEvent(event);
		})).then(() => {
			fetchSerenityEvents();
		});
	};

	const openDeleteModal = (events) => {
		setEventsToBeDeleted(events);
		setIsDeleteModalVisible(true);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setEventsToBeDeleted([]);
		closeEditModal();
	};

	const emptyTableState = () => {
		return loading 
			? <Preloader dataIsLoading={true} />
			: 'No events found';
	};

	const getTable = () => {
		return <Table
			classes="serenityAllEventsTable"
			data={!loading && eventsData.length > 0 ? eventsData : [] }
			keyField="id"
			remote
			pagination={getPaginationOptions()}
			selectable="multiple"
			search={{ defaultSearch: pagination.searchFor }}
			noDataIndication={emptyTableState()}
			extra={
				<SupportSerenityEventsTableFilters
					minimal={minimal}
					fetchSerenityEvents={handleTableFiltersChange}
					queryParams={queryParams}
				/>
			}
			actions={getTableActions()}
			onTableChange={handleTableChange}
		>
			<Table.Col field="emitter.serialNumber" align="left">Machine S/N</Table.Col>
			<Table.Col field="status" align="left" renderCell={renderStatus}>Status</Table.Col>
			<Table.Col field="date" align="left" renderCell={renderDateTime}>Date - Time</Table.Col>
			<Table.Col field="title" align="left" renderCell={renderEventTitle}>Event Title</Table.Col>
			<Table.Col field="emitter.companyName" align="left">End User</Table.Col>
			<Table.Col field="emitter.email" align="left">Email</Table.Col>
			<Table.Col field="emitter.productModel" align="left">Machine Model</Table.Col>
			<Table.Col field="emitter.maintenanceType" align="left">Maintenance type</Table.Col>
			<Table.Col field="actions" align="center" renderCell={renderCellActions}>Actions</Table.Col>
		</Table>;
	};

	const getMinimalTable = () => {
		return (
			<Table
				data={!loading && eventsData.length > 0 ? eventsData : [] }
				keyField="id"
				remote
				pagination={getPaginationOptions()}
				noDataIndication={emptyTableState()}
				extra={
					<SupportSerenityEventsTableFilters
						minimal={minimal}
						fetchSerenityEvents={fetchSerenityEvents}
						queryParams={queryParams}
					/>
				}
				onTableChange={handleTableChange}
				rowEvents={{
					onClick: (e, row) => {
						openEditModal(row);
					}
				}}
			>
				<Table.Col field="status" align="left" renderCell={renderStatus}>Status</Table.Col>
				<Table.Col field="date" align="left" renderCell={renderDateTime}>Date - Time</Table.Col>
				<Table.Col field="title" align="left" renderCell={renderEventTitle}>Event Title</Table.Col>
				<Table.Col field="emitter.maintenanceType" align="left">Maintenance type</Table.Col>
			</Table>
		);
	};

	return (
		<>
			<div className="p-4 bg-white">
				{minimal
					? getMinimalTable()
					: getTable()
				}
			</div>
			<SupportSerenityEventsEditModal
				isVisible={isEditModalVisible}
				close={closeEditModal}
				event={eventBeingEdited}
				renderStatus={renderStatus}
				handleEventStatusChange={handleEventStatusChange}
				openDeleteModal={openDeleteModal}
				dataIsLoading={loading}
			/>
			<SupportSerenityEventsDeleteModal
				isVisible={isDeleteModalVisible}
				close={closeDeleteModal}
				events={eventsToBeDeleted}
				handleEventsDelete={handleEventsDelete}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const { serenityEvents: { serenityEvents, loading: serenityEventsLoading, pagination }, products: { products, loading: productsLoading } } = state;

	return {
		events: serenityEvents,
		products,
		loading: serenityEventsLoading || productsLoading,
		pagination
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadSerenityEvents: (url) => { return dispatch(loadSerenityEvents(url)); },
		getProductsBySerialNumber: (listOfSerialNumbers) => { return dispatch(getProductsBySerialNumber(listOfSerialNumbers)); },
		updateSerenityEvent: (event) => { return dispatch(updateSerenityEvent(event)); },
		deleteSerenityEvent: (event) => { return dispatch(deleteSerenityEvent(event.id)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportSerenityAllEventsTable);
