const formFields = {
	passwordResetToken: 'passwordResetToken',
	account: {
		email: 'account.email',
		password: 'account.password',
		passwordCheck: 'account.passwordCheck',
		rememberMe: 'account.rememberMe',
		businessRelationship: 'account.businessRelationship',
		data_collection_consent: 'account.data_collection_consent',
		subscribe_news: 'account.subscribe_news',
		subscribe_product: 'account.subscribe_product',
		subscribe_satisfaction_surveys: 'account.subscribe_satisfaction_surveys',
		customerSince: 'account.customerSince'
	},
	authentification: {
		email: 'authentification.email',
		password: 'authentification.password'
	},
	contact: {
		firstName: 'contact.firstName',
		lastName: 'contact.lastName',
		roleInCompany: 'contact.roleInCompany',
		phoneNumber: 'contact.phoneNumber'
	},
	mfa: {
		isEnabled: 'mfa.isEnabled',
		isEnforced: 'mfa.isEnforceds',
	}
};

export default formFields;
