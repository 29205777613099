import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Validator from 'validatorjs';
import { unflatten } from 'flat';
import { Button, Modal, Row, Col } from '@syneto/compass-react';
import Preloader from '../../../../components/preloader';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';
import UserRoles from '../../../../constants/user-roles';
import Form from '../../../../components/forms/form';
import { getCompanyServices, putCompanyServices } from '../../../../services/companies';

const FORM_NAME = 'RASService';

const validate = (values) => {
	Validator.register('typeDate', (originalValue) => {
		const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
		const value = moment(originalValue).format('YYYY-MM-DD');

		if (dateRegex.test(originalValue) && moment(value).toDate()) { 
			return moment(value).toDate(); 
		}

		return new Date('');
	}, 'Must be a date');

	const validator = new Validator(values,
		{
			'rasStartingPeriod': ['required', 'typeDate'],
			'rasEndingPeriod': ['required', 'typeDate'],
			'rasAvailableSeats': ['required', 'integer', 'min:0']
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const OperationsCompaniesViewModalClass = (props) => {
	const { companyDetails, closeModal, handleSubmit, submitting, isOperations } = props;
	const { pushDangerNotification, pushSuccessfulNotification } = props;

	const [rasServiceDetails, setRasServiceDetails] = useState({});
	const [loadingCompanyServices, setLoadingCompanyServices] = useState(false);

	useEffect(() => {
		if ( !isOperations) {
			loadCompanyServices();
		}
	}, []);

	const loadCompanyServices = async () => {
		try {
			setLoadingCompanyServices(true);

			const companyServices = await getCompanyServices(companyDetails && companyDetails.id);

			if (companyServices?.length) {
				companyServices.map((s) => {
					if (s.name === 'Remote Access Service') {
						setRasServiceDetails({
							rasAvailableSeats: _.find(s.service_properties, { 'name': 'availableSeats' })?.value,
							rasStartingPeriod: _.find(s.service_properties, { 'name': 'startingPeriod' })?.value,
							rasEndingPeriod: _.find(s.service_properties, { 'name': 'endingPeriod' })?.value
						});
					}
				});
			} else {
				setRasServiceDetails({});
			}

			setLoadingCompanyServices(false);
		} catch (e) {
			pushDangerNotification('Company services cannot be loaded');
		}
	};

	const submit = (values) => {
		const rasServiceDetails = {
			name: 'Remote Access Service',
			companyId: companyDetails.id,
			properties: [
				{
					name: 'availableSeats',
					value: values.rasAvailableSeats
				},
				{
					name: 'startingPeriod',
					value: moment(values.rasStartingPeriod, 'DD/MM/YYYY').format('YYYY-MM-DD')
				},
				{
					name: 'endingPeriod',
					value: moment(values.rasEndingPeriod, 'DD/MM/YYYY').format('YYYY-MM-DD')
				}
			]
		};

		return putCompanyServices(companyDetails.id, rasServiceDetails)
			.then(() => {
				pushSuccessfulNotification('Company services updated successfully');
				closeModal();
			})
			.catch((error) => {
				pushDangerNotification('Company services cannot be updated');
			});
	};

	const renderUserDetails = (profile, is_master_account) => {
		return <div key={profile.id} style={{ marginBottom: '15px' }}>
			<h5
				className="mb-2">{is_master_account && profile.is_support_contact ? 'Master Account & Support Contact' : profile.is_support_contact ? 'Support Contact' : 'Company Account Manager'}
			</h5>

			<Row className="mt-2">
				<Col><b>Contact name</b></Col>
				<Col>{profile.user.first_name} {profile.user.last_name}</Col>
			</Row>

			<Row className="mt-2">
				<Col><b>Email</b></Col>
				<Col>{profile.user.email ? profile.user.email : 'N/A'}</Col>
			</Row>

			<Row className="mt-2">
				<Col><b>Phone</b></Col>
				<Col>{profile.phone_number ? profile.phone_number : 'N/A'}</Col>
			</Row>

			<Row className="mt-2">
				<Col><b>Role</b></Col>
				<Col>{profile.role_in_the_company ||profile.role_in_the_company !== '' ? profile.role_in_the_company : 'N/A'}</Col>
			</Row>
		</div>;
	};

	const renderCompanyDetails = (company) => {
		return (
			<>
				<Row>
					<Col>
						<Row>
							<Col><b>Company name</b></Col>
							<Col>{company.name}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>VAT number</b></Col>
							<Col>{company.vat ? company.vat : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Market sector</b></Col>
							<Col>{company.marketSector ? company.marketSector : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Number of employees</b></Col>
							<Col>{company.numberOfEmployees ? company.numberOfEmployees : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Country</b></Col>
							<Col>{company.country ? company.country : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Province</b></Col>
							<Col>{company.province ? company.province : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>City</b></Col>
							<Col>{company.city ? company.city : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Address</b></Col>
							<Col>{company.address ? company.address : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Postal code</b></Col>
							<Col>{company.postalCode ? company.postalCode : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Company email</b></Col>
							<Col>{company.email ? company.email : 'N/A'}</Col>
						</Row>

						<Row className="mt-2">
							<Col><b>Company website</b></Col>
							<Col>{company.website ? company.website : 'N/A'}</Col>
						</Row>
					</Col>
					<Col>
						{
							company.profiles.filter((profile) => { 
								return profile.id === company.profiles[0].id || profile.isSupportContact; 
							}).map((profile) => {
								return renderUserDetails(profile, profile.id === company.profiles[0].id);
							})
						}
					</Col>
				</Row>

				<hr />

				<Row>
					<Col>
						<h5>Additional Services</h5>
					</Col>
				</Row>

				<Row>
					<Col xs={3}>
						&nbsp;
					</Col>

					<Col xs={3}>
						<b>Starting period</b>
					</Col>

					<Col xs={1} />

					<Col xs={3}>
						<b>Ending period</b>
					</Col>

					<Col xs={2}>
						<b>Available seats</b>
					</Col>
				</Row>

				<Row className="mt-2">
					{
						loadingCompanyServices
							? <Preloader dataIsLoading={loadingCompanyServices} />
							: (
								<>
									<Col className="pt-2" xs={3}>
										<b>Remote Access Services</b>
									</Col>

									<Col xs={3}>
										<Form.datepicker
											name="rasStartingPeriod"
											placeholder="eg. 15/06/2020"
											formName={FORM_NAME}
											initValue={rasServiceDetails.rasStartingPeriod}
										/>
									</Col>

									<Col className="pt-2" xs={1}>to</Col>

									<Col xs={3}>
										<Form.datepicker
											name="rasEndingPeriod"
											placeholder="eg. 15/06/2020"
											formName={FORM_NAME}
											initValue={rasServiceDetails.rasEndingPeriod}
										/>
									</Col>

									<Col xs={2}>
										<Form.text
											name='rasAvailableSeats'
											placeholder="eg. 10"
											formName={FORM_NAME}
											initValue={rasServiceDetails.rasAvailableSeats}
										/>
									</Col>
								</>
							)
					}
				</Row>
			</>
		);
	};

	function renderDetails() {
		if (props.loggedInUser?.user?.account?.role === 'marketing') {
			return props.renderCompanyDetails(companyDetails);
		}

		return renderCompanyDetails(companyDetails);
	}

	return (
		<Modal
			enforceFocus={false}
			show={true}
			size="lg"
			onHide={closeModal}
		>
			<form noValidate onSubmit={(e) => { return e.preventDefault(); }}>
				<Modal.Header>
					<Modal.Title>Company Details</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{renderDetails(companyDetails)}
				</Modal.Body>

				<Modal.Footer>
					<div className="float-end">
						<Button className="me-2" role="secondary" onClick={closeModal}>Close</Button>
						{
							props.loggedInUser?.user?.account?.role !== UserRoles.MARKETING ? (
								<Button disabled={submitting} onClick={handleSubmit(submit)} type="submit">
									Update
								</Button>
							) : null
						}
					</div>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		putCompanyServices: (companyId, servicesData) => { return dispatch(putCompanyServices(companyId, servicesData)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); },
	};
};

const OperationsCompaniesViewModalForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	initialValues: {
		rasStartingPeriod: null,
		rasEndingPeriod: null,
		rasAvailableSeats: null
	},
	validate: validate,
	shouldValidate: () => { return true; }
})(OperationsCompaniesViewModalClass);

export const OperationsCompaniesViewModal = connect(mapStateToProps, mapDispatchToProps)(OperationsCompaniesViewModalForm);

OperationsCompaniesViewModal.propTypes = {
	isOperations: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
	companyDetails: PropTypes.object.isRequired,
	renderCompanyDetails: PropTypes.func,
	className: PropTypes.string,
	hasDeleteButton: PropTypes.bool
};
