import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status:  'loading',
	error:   null,
	loading: true,
	updateEvents:  []
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.LOADING_PRODUCT_UPDATE_EVENTS_SUCCESS:
			return {
				...state,
				status:  action.payload.message,
				error:   null,
				loading: false,
				updateEvents:  action.payload
			};
		case actionTypes.LOADING_PRODUCT_UPDATE_EVENTS_ERROR:
			error = action.payload.data || { message: action.payload.message };
			return {
				...state,
				status: null,
				error:  error.message,
				lading: false,
				updateEvents: []
			};
		default:
			return state;
	}
};
