import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';

export const OperationsProductsWarningModal = ({ showWarningModal, closeWarningModal, onSubmit }) => {
	return (
		<Modal
			show={ showWarningModal }
			onHide={ closeWarningModal }
			title="Warning"
			content="That doesn't look like a correct serial number. Continue?"
			actions={ {
				primary:   {
					label:   'Continue',
					onClick: onSubmit
				},
				secondary: {
					label: 'Cancel'
				}
			} }
		/>
	);
};

OperationsProductsWarningModal.propTypes = {
	showWarningModal:  PropTypes.bool.isRequired,
	closeWarningModal: PropTypes.func.isRequired,
	onSubmit:          PropTypes.func.isRequired
};
