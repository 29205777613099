import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@syneto/compass-react';
import { getCustomerMonitoringDetails } from '../../../../../../data/selected-customer/actions';

const MonitoringInfo = (props) => {
	const { monitoringDetails, products, copyToClipboard, getCustomerMonitoring } = props;

	useEffect(() => {
		if (products.length > 0) {
			const companyHasMonitoring = products.find((product) => { return product.monitoringEnabled; });

			
			if (companyHasMonitoring?.companyId) {
				getCustomerMonitoring(companyHasMonitoring.companyId);
			}
		}
	}, [products]);

	return monitoringDetails
		? (
			<div className="container mt-3 ps-0 pf-0">
				<div className="row">
					<div className="col-6">
						<dl className="row monitoring-information-support">
							<dt className="col-3">URL</dt>
							<dd className="col-9">
								<Link to={`https://${monitoringDetails.endpoint_url}`} target="_blank">{`https://${monitoringDetails.endpoint_url}`}</Link>
							</dd>

							<dt className="col-3">Username</dt>
							<dd className="col-9">
								<span role="button" onClick={() => { return copyToClipboard(monitoringDetails.username); }}>
									<b>{monitoringDetails.username}&nbsp;
										<Icon name="fa fa-clipboard" />
									</b>
								</span>
							</dd>

							<dt className="col-3">Password</dt>
							<dd className="col-9">
								<span role="button" onClick={() => { return copyToClipboard(monitoringDetails.password); }}>
									<b>{monitoringDetails.password}&nbsp;
										<Icon name="fa fa-clipboard" />
									</b>
								</span>
							</dd>
						</dl>
					</div>
				</div>
			</div>
		) 
		: (<p className="text-muted">Currently, the monitoring services for this account are not active.</p>);
};

const mapStateToProps = (state) => {
	return {
		products: state.selectedCustomer.products,
		monitoringDetails: state.selectedCustomer.monitoringDetails
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCustomerMonitoring: (companyId) => { return dispatch(getCustomerMonitoringDetails(companyId)); },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringInfo);
