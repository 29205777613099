import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@syneto/compass-react';
import { bulkDeleteMaintenanceServices, deleteMaintenanceServiceData } from '../../../../../data/productMaintenanceService/actions';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../../data/notifications/actions';
import { deleteProducts } from '../../../../../services/products';
class OperationsProductsDeleteModalClass extends React.Component {
	render() {
		const { maintenanceServices, onHide } = this.props;

		return (
			<Modal
				show={true}
				onHide={onHide}
				title={this.title(maintenanceServices)}
				content={this.content(maintenanceServices)}
				actions={maintenanceServices.length ?
					{
						primary: {
							label: 'Delete',
							onClick: this.onSubmit
						},
						secondary: {
							label: 'Cancel',
							onClick: onHide
						}
					} :
					{
						primary: {
							label: 'OK',
							onClick: onHide
						}
					}
				}
			/>
		);
	}

	title = (maintenanceServices) => {
		if (maintenanceServices.length > 1) {
			return 'Delete maintenance plans';
		}

		return 'Delete maintenance';
	};

	content = (maintenanceServices) => {
		if (maintenanceServices.length === 1) {
			let { quoteNumber } = maintenanceServices[0] || '';

			return <p className="text-value">You are going to delete a maintenance plan from the quote {quoteNumber}. Are
				you sure?</p>;
		}

		return maintenanceServices.length > 1 ?
			'You are going to delete several maintenance plans. Are you sure?' :
			'You have not selected any maintenance plan for deletion.';
	};

	onSubmit = () => {
		const { maintenanceServices, onHide, afterUpdateCallback } = this.props;

		let payload = maintenanceServices.map((obj) => { return obj.id; } );

		deleteProducts(payload)
			.then((response) => {
				this.props.pushSuccessfulNotification('Maintenance service deleted successfully');
				onHide();
				afterUpdateCallback();
			})
			.catch((error) => {
				this.props.pushDangerNotification('Could not delete maintenance service');
			});
	};
}

const mapStateToProps = ({ productMaintenanceService }) => {
	return {
		success: productMaintenanceService.success,
		error: productMaintenanceService.error
	};
};

export const OperationsProductsDeleteModal = connect(mapStateToProps, {
	deleteMaintenanceServiceData,
	bulkDeleteMaintenanceServices,
	pushSuccessfulNotification,
	pushDangerNotification

})(OperationsProductsDeleteModalClass);

OperationsProductsDeleteModal.propTypes = {
	onHide: PropTypes.func.isRequired,
	maintenanceServices: PropTypes.array,
	afterUpdateCallback: PropTypes.func.isRequired
};
