import { API_DEFAULT_VERSION, API_V2, axios, backEndAxios } from './httpClient';

export const getCompanies = () => {
	return backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/get-companies`);
};

export const getCompanyServices = async (companyId) => {
	const res = await axios.get('/services', { params: { companyId } });
	return res?.data?.services ?? [];
};

export const putCompanyServices = async (companyId, servicesData) => {
	return await axios.put('/services', servicesData);
};

export const getCompaniesWithEndUsers = () => {
	return backEndAxios.get(`/centralBackend/${API_V2}/iam/getCompaniesWithEndUsers`);
};
