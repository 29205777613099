import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { onClickSidebarMenu } from '../../data/actions/sidebar';
import Preloader from '../../components/preloader';
import Sidebar from '../../components/Sidebar';

const Marketing = (props) => {
	const { loadUserData, userRole, onClickSidebarMenu } = props;
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const MARKETINGT_ROUTES = {
		END_USER_ACCOUNTS: {
			LABEL: 'End-User Accounts',
			PATH: 'end-user-accounts',
			IDENTIFIER: 'endUserAccounts',
			ITEMS: []
		},
		REPORTS: {
			LABEL: 'Reports',
			PATH: 'reports',
			IDENTIFIER: 'reports',
			ITEMS: []
		}
	};

	useEffect(() => {
		switch (location.pathname.split('/')[2]) {
			case MARKETINGT_ROUTES.REPORTS.PATH:
				onClickSidebarMenu(MARKETINGT_ROUTES.REPORTS.IDENTIFIER);
				break;
			case MARKETINGT_ROUTES.END_USER_ACCOUNTS.PATH:
			default:
				onClickSidebarMenu(MARKETINGT_ROUTES.END_USER_ACCOUNTS.IDENTIFIER);
				break;
		}
	}, []);

	useEffect(() => {
		if (userRole && userRole !== 'marketing') {
			navigate('/');
		}

		if (!loadUserData && userRole) {
			setLoading(false);
		}
	}, [loadUserData, userRole]);

	return (
		<div id="marketing" className="w-100 mt-4">
			<div className="container-fluid">
				<div className="row">
					<div className="side-menu">
						<Sidebar items={MARKETINGT_ROUTES} />
					</div>
					<div className="page-content row">
						<div className="pt-4 ps-4 pf-4 bg-white">
							{
								loading
									? <Preloader dataIsLoading={true} />
									: <Outlet />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loadUserData: state?.user?.loading,
		userRole: state?.user?.user?.account?.role
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClickSidebarMenu: (path) => { dispatch(onClickSidebarMenu(path)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
