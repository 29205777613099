import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { maintenanceExpirationDefaultFilter } from '../../../../constants/maintenance-durations';
import { fetchMarketingReports } from '../../../../data/marketingReport/actions';
import { getCustomers } from '../../../../data/customers/action';
import { pushDangerNotification } from '../../../../data/notifications/actions';
import MarketingReportsFilter from './marketingReportsFilter';
import MarketingReportsTable from './marketingReportsTable';


const MarketingReports = ({ maintenanceServices, loadingExpiredMaintenancePlans, customers, loadingEndUsers, getMarketingReports, getCustomers }) => {
	const [marketingReports, setMarketingReports] = useState([]);
	const [maintenanceExpirationFilter, setMaintenanceExpirationFilter] = useState(maintenanceExpirationDefaultFilter);

	useEffect(() => {
		fetchMarketingReports(maintenanceExpirationFilter);
	}, []);

	useEffect(() => {
		if (maintenanceServices.length > 0 && customers.length > 0) {
			setMarketingReports(addUserToMarketingReport());
		}
		
	}, [maintenanceServices, customers]);

	const fetchMarketingReports = (maintenanceExpirationFilter) => {
		try {
			getMarketingReports(maintenanceExpirationFilter || '6-months-fromNow'),
			getCustomers();
		} catch (error) {
			pushDangerNotification(`Could not retrieve maintenance plans. Reason: ${error.message}`);
		}
	};

	const updateMaintenanceExpiration = (newMaintenanceExpiration) => {
		setMaintenanceExpirationFilter(newMaintenanceExpiration);
	};

	const addUserToMarketingReport = () => {
		return maintenanceServices.map((report) => {
			const user = customers.find((user) => { return user.id === report.product.user_id; });
			return {
				...report,
				companyName: user?.company?.name ?? '',
				user: {
					firstName: user?.firstName ?? '',
					lastName: user?.lastName ?? '',
					email: user?.email ?? ''
				}
			};
		});
	};

	return (
		<>
			<h6 className="font-weight-bold">Maintenance expiration</h6>
			<MarketingReportsFilter
				updateMaintenanceExpiration={updateMaintenanceExpiration}
				fetchMarketingReports={fetchMarketingReports}
			/>
			<MarketingReportsTable
				maintenanceServices={marketingReports}
				maintenanceExpirationFilter={maintenanceExpirationFilter}
				loading={loadingExpiredMaintenancePlans || loadingEndUsers}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loadingEndUsers: state.customers.loading,
		customers: state.customers.data ?? [],
		loadingExpiredMaintenancePlans: state.marketingReport.loading,
		maintenanceServices: state.marketingReport.maintenanceServices
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMarketingReports: (expirationFilter) => {
			return dispatch(fetchMarketingReports(expirationFilter));
		},
		getCustomers: () => {
			return dispatch(getCustomers(true));
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingReports);
