module.exports = {
	parseOSVersion: (osVersion) => {
		const tempOSVersion = osVersion || 'N/A';
		const osVersionNumbers = [];
		for (let number of tempOSVersion.split('.')) {
			osVersionNumbers.push(number);
		}

		return osVersionNumbers.slice(0, 3).join('.');
	}
};
