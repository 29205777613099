import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	status: null,
	error: null,
	loading: false,
	serenityEvents: [],
	marketingSite: null,
	pagination: {
		currentPage: 1,
		firstItem: 1,
		itemsPerPage: 15,
		lastItem: 1,
		links: '',
		numberOfPages: 1,
		searchFor: '',
		totalItems: 1
	}
};

export default (state = INITIAL_STATE, action) => {
	let error;

	switch (action.type) {
		case actionTypes.START_LOADING_SERENITY_EVENTS:
			return {
				...state,
				loading: true,
				status: 'pending',
				pagination: INITIAL_STATE.pagination
			};
		case actionTypes.LOADING_SERENITY_EVENTS_SUCCESS:
			return {
				...state,
				status: 'success',
				error: null,
				loading: false,
				serenityEvents: action.payload.data,
				pagination: {
					...state.pagination,
					...action.payload.pagination
				}
			};
		case actionTypes.LOADING_SERENITY_EVENTS_FAILURE:
			error = action.payload.data || { message: action.payload.message };
			return {
				...state,
				...INITIAL_STATE,
				status: 'failed',
				error: error.message
			};
		case actionTypes.GETTING_SERENITY_MARKETING_SITE_SUCCESS:
			return {
				...state,
				status: 'success',
				error: null,
				loading: false,
				marketingSite: action.payload.serenityMarketingSite
			};
		case actionTypes.GETTING_SERENITY_MARKETING_SITE_FAILURE:
			error = action.payload.data || { message: action.payload.message };
			return {
				...state,
				status: 'failed',
				error: error.message,
				loading: false,
				marketingSite: null
			};
		default:
			return state;
	}
};
