import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { Button, Modal } from '@syneto/compass-react';
import ValidationService from '../../../validation/ValidationService';
import { OperationsProductsEditModalHeader } from './OperationsProductsEditModalHeader';
import { OperationsProductsEditModalBody } from './OperationsProductsEditModalBody';
import { loadMaintenanceDetailsOperations } from '../../../../../data/productMaintenanceService/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../../data/notifications/actions';
import DateFormatterService from '../../../../../helpers/DateFormatter';
import Currency from '../../../../../helpers/money';
import Preloader from '../../../../../components/preloader';
import { getSettingValue } from '../../../../../helpers/settings';
import { getActivationCode } from '../../../../../data/product/actions';
import { updateProduct } from '../../../../../services/products';
import Utils from '../../../../../helpers/Utils';

const FORM_NAME = 'maintenanceUpdate';

const OperationsProductsEditModal = (props) => {
	const {
		user,
		productModels,
		selectedProduct,
		allMaintenancePlansOptions,
		onHide,
		reset,
		handleSubmit,
		submitting,
		afterUpdateCallback,
		pushSuccessfulNotification,
		pushDangerNotification,
		change,
		fields,
		getActivationCode,
		activationKey,
		maxMaintenanceExtension,
		totalDuration,
		companiesWithEndUsers,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [validationMessage, setValidationMessage] = useState(null);
	const [allMaintenancePlansOptionsOrdered, setAllMaintenancePlansOptionsOrdered] = useState([]);

	useEffect(() => {
		const orderedAllMaintenancePlansOptions = _.orderBy(
			allMaintenancePlansOptions,
			[
				(maintenanceTypeOption) => {
					return maintenanceTypeOption.synetoInternalName.toUpperCase().trim();
				},
			],
			['asc']
		);
		setAllMaintenancePlansOptionsOrdered(orderedAllMaintenancePlansOptions);
	}, [allMaintenancePlansOptions]);

	const cancelMaintenanceServiceUpdate = () => {
		onHide();
		reset();
	};

	const updateMaintenanceService = async (values) => {
		const currency = Currency.EUR();
		values.saleValue = parseFloat((Utils.normalizeSaleValue(values.saleValue) * currency.getCentFactor()).toFixed(2));
		values.maintenanceService.saleValue = parseFloat(
			(Utils.normalizeSaleValue(values.maintenanceService.saleValue) * currency.getCentFactor()).toFixed(2)
		);
		if (values && values.maintenanceService && values.maintenanceService.renewals && values.maintenanceService.renewals.length > 0
		) {
			values.maintenanceService.renewals.forEach((renewal) => {
				renewal.saleValue = parseFloat(
					(Utils.normalizeSaleValue(renewal.saleValue) * currency.getCentFactor()).toFixed(2)
				);
			});
		}
		updateProduct(values)
			.then((response) => {
				onHide();
				afterUpdateCallback();
				pushSuccessfulNotification(response.data.message);
			})
			.catch((error) => {
				pushDangerNotification(error.response.data.message);
			});
	};

	const isFormValid = (values) => {
		if (values.maintenanceService.renewals.length) {
			if (!ValidationService.isSerialNumberValid(values.serialNumber) && !ValidationService.isDurationValid(maxMaintenanceExtension, totalDuration)
			) {
				setValidationMessage(
					`That doesn't look like a correct serial number and the maximum number of years has been exceeded. Continue?`
				);
				return false;
			}
			if (!ValidationService.isDurationValid(maxMaintenanceExtension, totalDuration)) {
				setValidationMessage('Maximum number of years has been reached. Continue?');
				return false;
			}
		} else if (!ValidationService.isSerialNumberValid(values.serialNumber) && !values.virtualAppliance) {
			setValidationMessage(`That doesn't look like a correct serial number. Continue?`);
			return false;
		}

		return true;
	};

	const submit = (values) => {
		if (isFormValid(values)) {
			updateMaintenanceService(values);
		}
	};

	const submitWarningModal = (values) => {
		updateMaintenanceService(values);
		setValidationMessage(null);
	};

	return (
		selectedProduct && (
			<>
				<Modal enforceFocus={false} show={true} onHide={cancelMaintenanceServiceUpdate} size="lg">
					<Modal.Header>
						<OperationsProductsEditModalHeader selectedProduct={selectedProduct} />
					</Modal.Header>

					<Modal.Body>
						{isLoading ? (
							<Preloader dataIsLoading={true} />
						) : (
							<OperationsProductsEditModalBody
								formName={FORM_NAME}
								change={change}
								fields={fields}
								companiesWithEndUsers={companiesWithEndUsers}
								productModels={productModels}
								maintenancePlansOptions={allMaintenancePlansOptionsOrdered}
								selectedProduct={selectedProduct}
								loadMaintenanceDetailsOperations={loadMaintenanceDetailsOperations}
								getActivationCode={getActivationCode}
								user={user}
							/>
						)}
					</Modal.Body>

					<Modal.Footer>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button role="secondary" onClick={cancelMaintenanceServiceUpdate} className="me-2">
								Cancel
							</Button>

							<Button onClick={handleSubmit(submit)}>{!submitting ? 'Save' : 'Saving...'}</Button>
						</div>
					</Modal.Footer>
				</Modal>

				{validationMessage && (
					<Modal
						show={true}
						onHide={() => {
							setValidationMessage(null);
						}}
						title="Warning"
						content={validationMessage}
						actions={{
							primary: {
								label: 'Continue',
								onClick: handleSubmit(submitWarningModal),
							},
							secondary: {
								label: 'Cancel',
							},
						}}
					/>
				)}
			</>
		)
	);
};

const mapStateToProps = (state, { selectedProduct }) => {
	const currency = Currency.EUR();
	const maintenanceTotalDuration = selectedProduct?.maintenanceService?.renewals?.reduce(
		(acc, maintenanceExtension) => {
			acc += parseInt(maintenanceExtension.duration);
			return acc;
		},
		parseInt(selectedProduct.maintenanceService.duration)
	);

	const getSelectedEndUser = () => {
		let endUser;
		state.companies.companiesWithEndUsers.forEach((company) => {
			company.users.forEach((user) => {
				if (user.id === selectedProduct.userId) {
					endUser = selectedProduct.userId;
				}
			});
		});

		return endUser;
	};

	return {
		initialValues: {
			id: selectedProduct.id,
			modelId: selectedProduct.modelId,
			serialNumber: selectedProduct.serialNumber,
			activationDate: DateFormatterService.formatDateToSpecificFormat(selectedProduct, 'activationDate', 'DD/MM/YYYY') ?? null,
			activationKey: selectedProduct?.activationKey?.productKey ?? null,
			saleValue: selectedProduct.saleValue ? selectedProduct.saleValue / currency.getCentFactor() : '',
			hasChronos: selectedProduct.hasChronos ?? false,
			postInstallChecks: selectedProduct.assemblyTests ?? [],
			hasFailoverAndFailback: selectedProduct.hasFailoverAndFailback ?? false,
			userId: getSelectedEndUser() ?? null,
			hasContinuousAuthentication: selectedProduct.hasContinuousAuthentication ?? false,
			hasMultiFactorAuthentication: selectedProduct.hasMultiFactorAuthentication ?? false,
			hasLegalHold: selectedProduct.hasLegalHold ?? false,
			hasCloudGate: selectedProduct.hasCloudGate ?? false,
			isUpgradableToOs5: selectedProduct.isUpgradableToOs5 ?? false,
			maintenanceService: {
				id: selectedProduct.maintenanceService.id ?? null,
				maintenancePlanId: selectedProduct?.maintenanceService?.maintenancePlanId ?? null,
				hasSerenity: selectedProduct?.maintenanceService?.hasSerenity ?? false,
				duration: selectedProduct?.maintenanceService?.duration ?? 0,
				quoteNumber: selectedProduct?.maintenanceService?.quoteNumber ?? null,
				saleValue: selectedProduct?.maintenanceService?.saleValue
					? selectedProduct.maintenanceService.saleValue / currency.getCentFactor()
					: '',
				notes: selectedProduct?.maintenanceService?.notes ?? null,
				renewals: selectedProduct?.maintenanceService?.renewals
					? selectedProduct.maintenanceService.renewals.map((renewal) => {
						return {
							serenity: renewal.plan.serenity ?? null,
							id: renewal.id ?? null,
							maintenanceId: renewal?.maintenanceId ?? null,
							maintenancePlanId: renewal?.maintenancePlanId ?? null,
							productId: renewal.productId ?? null,
							// maintenanceType: renewal?.plan?.synetoInternalName ?? '',
							hasSerenity: renewal?.hasSerenity ?? false,
							duration: renewal.duration ?? 0,
							quoteNumber: renewal.quoteNumber ?? '',
							saleValue: renewal.saleValue && renewal.saleValue !== '' ? renewal.saleValue / currency.getCentFactor() : '',
						};
					})
					: [],
			},
		},
		activationKey: state.products.activationKey,
		customers: state.customers.data,
		productModels: state.productModels.models,
		allMaintenancePlansOptions: state.maintenancePlansOptions.maintenancePlansOptions.allMaintenancePlansOptions,
		user: state.user.user,
		totalDuration: maintenanceTotalDuration,
		maxMaintenanceExtension: getSettingValue(state.settings.data, 'maxMaintenanceExtension') ?? 36,
		maintenanceServiceUpdated: state.productMaintenanceService?.maintenanceService ?? null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadMaintenanceDetailsOperations: () => {
			return dispatch(loadMaintenanceDetailsOperations());
		},
		getActivationCode: () => {
			return dispatch(getActivationCode());
		},
		pushSuccessfulNotification: (message) => {
			return dispatch(pushSuccessfulNotification(message));
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		},
	};
};

const OperationsProductsEditModalForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: ValidationService.validate,
	warn: ValidationService.warn,
	shouldValidate: () => {
		return true;
	},
})(OperationsProductsEditModal);

export default connect(mapStateToProps, mapDispatchToProps)(OperationsProductsEditModalForm);

OperationsProductsEditModal.propTypes = {
	onHide: PropTypes.func.isRequired,
	maintenancePlansOptions: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	selectedProduct: PropTypes.object.isRequired,
	openPostInstallLogsModal: PropTypes.func.isRequired,
	afterUpdateCallback: PropTypes.func.isRequired,
	companiesWithEndUsers: PropTypes.array.isRequired,
};
