import React from 'react';
import { Field } from 'redux-form';
import { Form } from '@syneto/compass-react';

export const Radio = (props) => {
	return (
		<Field
			type="radio"
			component={ renderField }
			{ ...props }
		/>
	); 
};

const renderField = (props) => {
	const { label, input, id, name, icon, required, disabled, feedback, inline, style, checked } = props;
	checked && (input.checked = checked);

	return (
		<Form.Check
			type="radio"
			label={ <span>{ label } { icon ? icon : '' }</span> }
			id={ id }
			name={ name }
			feedback={ feedback }
			disabled={ disabled }
			required={ required }
			inline={ inline }
			style={ style }
			{ ...input }
		/>
	);
};
