import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getVMActivationKeys, increaseActivations } from '../../data/actions/actions';
import SupportVMActivationCodesTable from './supportVMActivationCodesTable';
import SupportVMActivationCodesIncrementModal from './supportVMActivationCodesIncrementModal';
import ActivationHistoryModal from './supportVMActivationCodesHistoryModal';

const SupportVMActivationCodes = ({ loading, activationKeys, getVMActivationKeys, increaseActivations }) => {
	const location = useLocation();
	const [showIncrementModal, toggleIncrementModal] = useState(false);
	const [productKey, setProductKey] = useState(null);
	const [showHistoryModal, toggleHistoryModal] = useState(false);
	const [activationHistory, setActivationHistory] = useState(null);
	const [extraActivations, setExtraActivations] = useState(null);
	const ActivationCodeQueryString = location.search.split('=')[1];
		
	useEffect(() => {
		getVMActivationKeys();
	}, []);

	const openIncrementModal = (productKey) => {
		setProductKey(productKey);
		toggleIncrementModal(true);
	};

	const openActivationsHistoryModal = (activations, extraActivations) => {
		setActivationHistory(activations);
		setExtraActivations(extraActivations.extra_activations);
		toggleHistoryModal(true);
	};

	return (
		<>
			<SupportVMActivationCodesTable
				loading={ loading }
				activationKeys={ activationKeys }
				increaseActivations={ increaseActivations }
				ActivationCodeQueryString={ ActivationCodeQueryString }
				openIncrementModal={openIncrementModal}
				openActivationsHistoryModal={openActivationsHistoryModal}
			/>
			<SupportVMActivationCodesIncrementModal 
				isOpen={showIncrementModal} 
				toggleModal={toggleIncrementModal}
				productKey={productKey}
			/>
			<ActivationHistoryModal 
				isOpen={showHistoryModal}
				toggleModal={toggleHistoryModal}
				activationHistory={activationHistory}
				extraActivations={extraActivations}
				className="modal-dialog modal-lg"
				classNameModalHeader="modal-header" 
			/>
		</>

	);
};

const mapStateToProps = (state) => {
	const { activationKeys, loading } = state.activationKeys;

	return {
		activationKeys,
		loading
	};
};

export default connect(mapStateToProps, {
	getVMActivationKeys,
	increaseActivations
})(SupportVMActivationCodes);
