import React from 'react';
import { Modal, Tooltip } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Form from '../../forms/form';
import formFields from '../../../constants/formFields';
import {
	hideNotificationPreferencesModal,
	updateUser
} from '../../../data/profile/action';
import {
	pushSuccessfulNotification
} from '../../../data/notifications/actions';

const NavNotificationPreferencesModalClass = ({ handleSubmit, submitting, showNotificationPreferencesModal, userData, updateUserData, hideNotificationPreferencesModal, reset }) => {
	const submit = (values) => {
		return updateUserData({ ...userData, ...values })
			.then(() => {
				hideNotificationPreferencesModal();
				pushSuccessfulNotification('Your notification preferences have been updated.');
			});
	};

	const cancelNotificationPreferencesUpdate = () => {
		reset();
		hideNotificationPreferencesModal();
	};

	return (
		<div>
			{showNotificationPreferencesModal ?
				<form id="notificationPreferences" noValidate>
					<Modal
						show={showNotificationPreferencesModal}
						onHide={cancelNotificationPreferencesUpdate}
						title="Notification preferences"
						actions={{
							primary: {
								label: !submitting ? 'Update' : 'Updating...',
								onClick: handleSubmit(submit),
								disabled: submitting
							},
							secondary: {
								label: 'Cancel',
								onClick: () => { return cancelNotificationPreferencesUpdate(); },
								disabled: submitting
							}
						}}
						content={
							<fieldset>
								<div className="row">
									<div className="col-12">
										<Form.checkbox
											id={formFields.account.subscribe_news}
											name={formFields.account.subscribe_news}
											label="Send me emails about technical news"
											icon={
												<Tooltip text="We will send you infrequent newsletters regarding our products and services"
													placement="bottom">
													<i
														className="fa fa-question"
													/>
												</Tooltip>
											}
											className="p-3"
										/>
										<Form.checkbox
											id={formFields.account.subscribe_product}
											name={formFields.account.subscribe_product}
											label="Send me emails about issues, updates and patches"
											icon={
												<Tooltip
													text="We will notify you about issues affecting the functionality or security of your account"
													placement="bottom">
													<i
														className="fa fa-question"
													/>
												</Tooltip>
											}
											className="p-3"
										/>
										<Form.checkbox
											id={formFields.account.subscribe_satisfaction_surveys}
											name={formFields.account.subscribe_satisfaction_surveys}
											label="Send me customer satisfaction surveys"
											icon={
												<Tooltip text="We will send you infrequent customer satisfaction surveys" placement="bottom">
													<i className="fa fa-question" />
												</Tooltip>
											}
											className="p-3"
										/>
									</div>
								</div>
							</fieldset>
						}
					>
					</Modal>
				</form>
				: null}
		</div>
	);
};

const mapStateToProps = ((state) => {
	return {
		initialValues: {
			userId: state.user.user ? state.user.user.userId : null,
			account: {
				subscribe_news: state.user.user && state.user.user.account ? state.user.user.account.subscribe_news : false,
				subscribe_product: state.user.user && state.user.user.account ? state.user.user.account.subscribe_product : false,
				subscribe_satisfaction_surveys: state.user.user && state.user.user.account ? state.user.user.account.subscribe_satisfaction_surveys : false
			}
		},
		userData: state.user.user,
		showNotificationPreferencesModal: state.user.showNotificationPreferencesModal
	};
});

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserData: (userData) => { return dispatch(updateUser(userData)); },
		hideNotificationPreferencesModal: () => { return dispatch(hideNotificationPreferencesModal()); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); }
	};
};

const NavNotificationPreferencesModalForm = reduxForm({
	form: 'notificationPreferences',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true
})(NavNotificationPreferencesModalClass);

export const NavProfileNotificationsModal = connect(mapStateToProps, mapDispatchToProps)(NavNotificationPreferencesModalForm);

NavProfileNotificationsModal.propTypes = {
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	submitting: PropTypes.bool,
	dispatch: PropTypes.func,
	initialize: PropTypes.func
};
