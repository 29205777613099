import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	createdSuccessMessage:    null,
	createdErrorMessage:      null,
	updateSuccessMessage:     null,
	updateErrorMessage:       null,
	deleteSuccessMessage:     null,
	deleteErrorMessage:       null,
	showModalAddProductModel: false,
	status:                   '',
	error:                    null,
	loading:                  false,
	models:                   []
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.LOADING_PRODUCT_MODELS:
			return {
				...state,
				loading: true
			};
		case actionTypes.LOADING_PRODUCT_MODELS_SUCCESS:
			return {
				...state,
				loading: false,
				models:  action.payload
			};
		case actionTypes.LOADING_PRODUCT_MODELS_ERROR:
			error = action?.payload?.data || { message: action.payload?.message };
			return {
				...state,
				lading: false,
				models: []
			};
		case actionTypes.CREATING_PRODUCT_MODEL:
			return {
				...state,
				createdSuccessMessage: null,
				createdErrorMessage:   null
			};
		case actionTypes.CREATE_PRODUCT_MODEL_SUCCESS:
			return {
				...state,
				createdSuccessMessage: action.payload.message,
				createdErrorMessage:   null
			};
		case actionTypes.CREATE_PRODUCT_MODEL_ERROR:
			return {
				...state,
				createdSuccessMessage: null,
				createdErrorMessage: action.payload
			};
		case actionTypes.UPDATING_PRODUCT_MODEL:
			return {
				...state,
				updateSuccessMessage: null,
				updateErrorMessage:   null
			};
		case actionTypes.UPDATE_PRODUCT_MODEL_SUCCESS:
			return {
				...state,
				updateSuccessMessage: action.payload.message,
				updateErrorMessage:   null
			};
		case actionTypes.UPDATE_PRODUCT_MODEL_ERROR:
			error = action.payload.data || { message: action.payload.message };
			return {
				...state,
				updateSuccessMessage: null,
				updateErrorMessage:   error.message
			};
		case actionTypes.DELETING_PRODUCT_MODEL:
			return {
				...state,
				deleteSuccessMessage: null,
				deleteErrorMessage:   null
			};
		case actionTypes.DELETE_PRODUCT_MODEL_SUCCESS:
			return {
				...state,
				deleteSuccessMessage: action.payload.message,
				deleteErrorMessage:   null
			};
		case actionTypes.DELETE_PRODUCT_MODEL_ERROR:
			error = action.payload.data || { message: action.payload.message };
			return {
				...state,
				deleteSuccessMessage: null,
				deleteErrorMessage:   error.message
			};
		case actionTypes.SHOW_MODAL_ADD_PRODUCT_MODEL:
			return {
				...state,
				showModalAddProductModel: true
			};
		case actionTypes.HIDE_MODAL_ADD_PRODUCT_MODEL:
			return {
				...state,
				showModalAddProductModel: false
			};
		default:
			return state;
	}
};
