import { actionTypes } from '../actionTypes';

const initialState = {
	activeMenu: null
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.navigation.admin.clicked: 
			return {
				activeMenu: payload.menuIdentifier
			};
		case actionTypes.sidebar.activeMenu:
			return {
				activeMenu: payload
			};
		default:
			return state;
	}
};
