import * as actionTypes from './actionTypes';
import { axios } from '../../services/httpClient';
import base64 from 'base-64';

export const exportToCSV = (fields, dataForExport, marketingUserEmail) => {
	return (dispatch) => {
		dispatch(exportingToCSV());
		return axios.post('/marketing/export-csv', { fields, dataForExport })
			.then((result) => {
				if (result.status == 200) {
					const encodedEmail = base64.encode(marketingUserEmail);
					window.open(`${result.request.responseURL}?security_param=${encodedEmail}`);
					dispatch(exportToCSVSuccess(result.request.responseURL));
				}
			})
			.catch((error) => {
				dispatch(exportToCSVError(error));
			});
	};
};

const exportingToCSV = () => {
	return {
		type: actionTypes.EXPORTING_TO_CSV
	};
};

const exportToCSVSuccess = (data) => {
	return {
		type: actionTypes.EXPORT_TO_CSV_SUCCESS,
		payload: data
	};
};

const exportToCSVError = (error) => {
	return {
		type: actionTypes.EXPORT_TO_CSV_ERROR,
		payload: error
	};
};
