import { API_DEFAULT_VERSION, backEndAxios } from './httpClient';

export const validateJwtToken = (authenticationToken) => {
	return backEndAxios.post(`/iam-auth/authentication/validate-jwt-token`, { 'token': authenticationToken });
};

export const authenticateInternalUser = (credentials) => {
	return backEndAxios.post(`/iam-auth/${API_DEFAULT_VERSION}/internal-authentication/authenticate-internal-user`, credentials);
};

export const configureMfa = (data) => {
	return backEndAxios.post(`/iam-auth/${API_DEFAULT_VERSION}/internal-authentication/configure-mfa`, data);
};

export const verifyTotpCode = (data) => {
	return backEndAxios.post(`/iam-auth/${API_DEFAULT_VERSION}/internal-authentication/verify-totp-code`, data);
};

export const verifyRecoveryCode = (data) => {
	return backEndAxios.post(`/iam-auth/${API_DEFAULT_VERSION}/internal-authentication/verify-recovery-code`, data);
};

export const toggleMfaStatus = (data) => {
	return backEndAxios.post(`/iam-auth/${API_DEFAULT_VERSION}/internal-authentication/toggle-mfa-status`, data);
};
