import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	status: null,
	success: null,
	error: null,
	loading: true,
	maintenanceServices: [],
	maintenanceService: null,
	maxMaintenanceExtension: null,
	activationKey: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.CREATE_MAINTENANCE_SERVICE_DATA_SUCCESS:
			return {
				...state,
				status: 'create_maintenance_service_success',
				success: action.payload,
				error: null,
				loading: false
			};
		case actionTypes.CREATE_MAINTENANCE_SERVICE_DATA_ERROR:
			return {
				...state,
				status: 'create_maintenance_service_error',
				success: null,
				error: action.payload,
				loading: false
			};
		case actionTypes.UPDATE_MAINTENANCE_SERVICE_DATA_SUCCESS:
			return {
				...state,
				status: 'update_maintenance_service_success',
				success: action.payload,
				error: null,
				loading: false
			};
		case actionTypes.UPDATE_MAINTENANCE_SERVICE_DATA_ERROR:
			return {
				...state,
				status: 'update_maintenance_service_error',
				success: null,
				error: action.payload,
				loading: false
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICE_DATA_SUCCESS:
			return {
				...state,
				status: 'delete_maintenance_service_success',
				success: action.payload,
				error: null,
				loading: false
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICE_DATA_ERROR:
			return {
				...state,
				status: 'delete_maintenance_service_error',
				success: null,
				error: action.payload,
				loading: false
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICES_DATA_SUCCESS:
			return {
				...state,
				status: 'delete_maintenance_services_success',
				success: action.payload,
				error: null,
				loading: false
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICES_DATA_ERROR:
			return {
				...state,
				status: 'delete_maintenance_services_error',
				success: null,
				error: action.payload,
				loading: false
			};
		case actionTypes.LOADING_MAINTENANCE_SERVICES:
			return {
				...state,
				loading: true
			};
		case actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS:
			return {
				...state,
				loading: false,
				maintenanceServices: action.payload.maintenanceServices,
				maxMaintenanceExtension: action.payload.maxMaintenanceExtension
			};
		case actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS:
			return {
				...state,
				loading: true
			};
		case actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				maintenanceService: action.payload
			};
		case actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case actionTypes.RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS:
			return INITIAL_STATE;
		case actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				maintenanceService: action.payload
			};
		case actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};
