import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Card } from '@syneto/compass-react';
import { unflatten } from 'flat';
import Form from '../../components/forms/form';
import Validator from '../../components/forms/validate';
import formFields from '../../constants/formFields';
import { recoverPassword } from './data/actions/actions';
import { pushDangerNotification } from '../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'account.email': ['required', 'email']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let PasswordRecovery = (props) => {
	const { handleSubmit, submitting, passwordRecovery, recoverPassword, pushDangerNotification } = props;

	useEffect(() => {
		if (passwordRecovery.status == 'failed') {
			pushDangerNotification(props.passwordRecovery.error.message);
		}
	}, [passwordRecovery.status]);

	const submit = (values) => {
		return recoverPassword(values);
	};

	return (
		<div id="password-recovery" className="w-100 h-100">
			<div className="container h-100">
				<div className="row h-100 d-flex justify-content-center align-items-center">
					<div className="col log-in">
						<Card
							title={'Password recovery'}
							content={
								<>
									<form noValidate
										className={props.passwordRecovery.status == 'instructionsSent' ? 'd-none' : ''}>
										<Form.hidden name="passwordResetUrl" />
										<Form.email name={formFields.account.email} label="Email address" autoFocus />

										<Button disabled={submitting} onClick={handleSubmit(submit)} type="submit">
											{!submitting ? 'Send' : 'Sending...'}
										</Button>
									</form>
									<p className={props.passwordRecovery.status != 'instructionsSent' ? 'd-none' : ''}>Please
										check your email address for instructions on how to reset your password.</p>
								</>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PasswordRecovery = reduxForm({
	form: 'password-recovery',
	validate
})(PasswordRecovery);

const mapStateToProps = (state) => {
	let passwordResetUrl = `${location.protocol}//${location.host}/password-reset`;

	return {
		initialValues: { passwordResetUrl: passwordResetUrl, account: { 'email': '' } },
		passwordRecovery: state.passwordRecovery
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		recoverPassword: (values) => { return dispatch(recoverPassword(values)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); } 
	};
};

PasswordRecovery.propTypes = {
	initialValues: PropTypes.object.isRequired,
	passwordRecovery: PropTypes.object.isRequired
};

PasswordRecovery = connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);

export default PasswordRecovery;
