import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';

export const fetchMarketingReports = (maintenanceExpirationFilter) => {
	let filterValues = maintenanceExpirationFilter.split('-');
	if (filterValues.length > 3) {
		let [max, valueForMax, min, valueForMin, unitOfTime, tense] = filterValues;
		return (dispatch) => {
			dispatch(fetchingMarketingReports());
			return axios.get(`/marketing/maintenance-reports`, {
				params: {
					valueForMax,
					valueForMin,
					unitOfTime,
					tense
				}
			}).then((response) => {
				dispatch(fetchMarketingReportsSuccess(response.data));
			}).catch((error) => {
				dispatch(fetchMarketingReportsError(error.response.data));
			});
		};
	} else {
		let [quantifierOfTime, unitOfTime, tense] = filterValues; //quantifierOfTime = 3, unitOfTime=months, tense=ago or
		// fromNow(future)
		return (dispatch) => {
			return axios.get(`/marketing/maintenance-reports`, {
				params: {
					quantifierOfTime,
					unitOfTime,
					tense
				}
			}).then((response) => {
				dispatch(fetchMarketingReportsSuccess(response.data));
			}).catch((error) => {
				dispatch(fetchMarketingReportsError(error.response.data));
			});
		};
	}
};

export const fetchingMarketingReports = () => {
	return {
		type: actionTypes.FETCHING_MARKETING_REPORTS,
		payload: null
	};
};

export const fetchMarketingReportsSuccess = (data) => {
	return {
		type: actionTypes.FETCH_MARKETING_REPORTS_SUCCESS,
		payload: data
	};
};

export const fetchMarketingReportsError = (error) => {
	return {
		type: actionTypes.FETCH_MARKETING_REPORTS_ERROR,
		payload: error.message
	};
};
