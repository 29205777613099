import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status: 'loading',
	error: null,
	loading: true,
	supportTunnels: []
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.LOADING_SUPPORT_TUNNELS_SUCCESS:
			return {
				...state,
				status: 'loaded',
				error: null,
				loading: false,
				supportTunnels: action.payload
			};
		case actionTypes.LOADING_SUPPORT_TUNNELS_FAILURE:// return error and make loading = false
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false,
				supportTunnels: []
			};
		default:
			return state;
	}
};
