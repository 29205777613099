import React from 'react';
import { Icon, Tooltip } from '@syneto/compass-react';
class GenerateDownloadLinkForOSManual {
	static generateDownloadLink(osVersion) {

		let osVersionParam = osVersion || '4.0.0';
		return (
			<a href={ `https://storage.googleapis.com/central-manuals/user-guide-syneto-os-${ osVersionParam }.pdf` }
				target="_blank" rel="noreferrer">{ osVersionParam }</a>
		);
	}

	static generateDownloadLinkWithTooltipAndIcon(osVersion) {
		let osVersionParam = osVersion || '4.0.0';
		return (
			<Tooltip placement="left" text="Download manual">
				<a href={ `https://storage.googleapis.com/central-manuals/user-guide-syneto-os-${ osVersionParam }.pdf` }
					target="_blank"
					className="btn btn-fab btn-dark btn-sm" rel="noreferrer">
					<Icon name="fa fa-book fa-fw"/>
				</a>
			</Tooltip>
		);
	}
}

export default GenerateDownloadLinkForOSManual;
