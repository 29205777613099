export const ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_SUCCESS = 'ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_SUCCESS';
export const ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_ERROR = 'ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_ERROR';

export const GET_RENEWALS_SETTINGS_SUCCESS = 'GET_RENEWALS_SETTINGS_SUCCESS';
export const GET_RENEWALS_SETTINGS_ERROR = 'GET_RENEWALS_SETTINGS_ERROR';

export const EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS = 'EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS';
export const EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR = 'EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR';

export const GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS = 'GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS';
export const GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR = 'GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR';

export const UPDATE_RAS_SETTINGS_SUCCESS = 'UPDATE_RAS_SETTINGS_SUCCESS';
export const UPDATE_RAS_SETTINGS_ERROR = 'UPDATE_RAS_SETTINGS_ERROR';

export const GET_RAS_SETTINGS_SUCCESS = 'GET_RAS_SETTINGS_SUCCESS';
export const GET_RAS_SETTINGS_ERROR = 'GET_RAS_SETTINGS_ERROR';

export const GET_NPS_SETTINGS_SUCCESS = 'GET_NPS_SETTINGS_SUCCESS';
export const GET_NPS_SETTINGS_ERROR = 'GET_NPS_SETTINGS_ERROR';

export const EDIT_NPS_SETTINGS_SUCCESS = 'EDIT_NPS_SETTINGS_SUCCESS';
export const EDIT_NPS_SETTINGS_ERROR = 'EDIT_NPS_SETTINGS_ERROR';

export const DELETE_MAINTENANCE_SERVICE_RENEWAL_SUCCESS = 'DELETE_MAINTENANCE_SERVICE_RENEWAL_SUCCESS';
export const DELETE_MAINTENANCE_SERVICE_RENEWAL_ERROR = 'DELETE_MAINTENANCE_SERVICE_RENEWAL_ERROR';

export const GET_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS = 'GET_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS';
export const GET_VIRTUAL_APPLIANCE_SETTINGS_ERROR = 'GET_VIRTUAL_APPLIANCE_SETTINGS_ERROR';

export const UPDATE_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS = 'UPDATE_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS';
export const UPDATE_VIRTUAL_APPLIANCE_SETTINGS_ERROR = 'UPDATE_VIRTUAL_APPLIANCE_SETTINGS_ERROR';
