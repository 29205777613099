import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionModal from '../../../../components/ActionModal';
import { usePrevious } from '../../../../hooks/usePrevious';

const AdminInternalUsersDeleteModal = ({ closeModal, onDelete, userDetails, actionStatus, handleModalActionSuccess, handleModalActioError }) => {
	const userUpdatePrevStatus = usePrevious(actionStatus);

	useEffect(() => {
		if (userUpdatePrevStatus === 'panding' && actionStatus === 'success') {
			handleModalActionSuccess();
			closeModal();
		}
		if (actionStatus === 'failed') {
			handleModalActioError();
		}
	}, [actionStatus, userUpdatePrevStatus]);

	const renderUserProductDetails = (product) => {
		return (
			<div className="row pb-2">
				<label className="col-3">Product</label>
				<div className="text-value col-9">{product.name}</div>

				<label className="col-3">OS Version</label>
				<div className="text-value col-9">{product.osVersion}</div>

				<label className="col-3">Serial #</label>
				<div className="text-value col-9">{product.serialNumber}</div>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div>
				{userDetails?.products && (
					<ul className="list-group">
						{
							userDetails?.products.map((product, key) => {
								return <li key={key} className="list-group-item">{renderUserProductDetails(product)}</li>;
							})
						}
					</ul>
				)}
				<span>Deleting the user will permanently remove all its data from the database, with no possibility of recovery. Are you sure that you want to proceed?</span>
			</div>
		);
	};

	return (
		<ActionModal
			showModal={true}
			onHide={closeModal}
			title={`Delete User ${userDetails && userDetails.first_name} ${userDetails && userDetails.last_name}`}
			content={renderContent()}
			primaryLabel='Delete'
			callback={() => { return onDelete(userDetails); }}
		/>
	);
};

export default AdminInternalUsersDeleteModal;

AdminInternalUsersDeleteModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	handleModalActionSuccess: PropTypes.func.isRequired,
	handleModalActioError: PropTypes.func.isRequired,
	actionStatus: PropTypes.string.isRequired
};
