import * as companyService from '../../services/companies';
import * as actionTypes from './actionTypes';

export const getCompaniesLoading = () => { return { type: actionTypes.GET_COMPANIES_LOADING }; };

export const getCompaniesSuccess = (data) => {
	return {
		type: actionTypes.GET_COMPANIES_SUCCESS,
		payload: data
	};
};

export const getCompaniesError = (error) => {
	return {
		type: actionTypes.GET_COMPANIES_ERROR,
		payload: error
	};
};

export const getCompanies = () => {
	return (dispatch) => {
		dispatch(getCompaniesLoading());

		return companyService.getCompanies()
			.then((result) => { return dispatch(getCompaniesSuccess(result.data.companies)); })
			.catch((error) => { return dispatch(getCompaniesError(error)); });
	};
};

export const getCompaniesWithEndUsers = () => {
	return (dispatch) => {
		dispatch(startFetchingCompaniesWithEndUsers());
		return companyService.getCompaniesWithEndUsers()
			.then((result) => {
				dispatch(getCompaniesWithEndUsersSuccess(
					result.data.companies ?? []
				));

				return result.data.message;
			})
			.catch((error) => {
				dispatch(getCompaniesWithEndUsersError(error));
			});
	};
};

const startFetchingCompaniesWithEndUsers = () => {
	return {
		type: actionTypes.START_FETCHING_COMPANIES_WITH_USERS
	};
};

const getCompaniesWithEndUsersSuccess = (payload) => {
	return {
		type: actionTypes.GET_COMPANIES_WITH_USERS_SUCCESS,
		payload
	};
};

const getCompaniesWithEndUsersError = (error) => {
	return {
		type: actionTypes.GET_COMPANIES_WITH_USERS_ERROR,
		payload: error
	};
};
