import { axios } from '../../../../services/httpClient';
import { SubmissionError } from 'redux-form';
import * as actionTypes from '../actionTypes';

export const recoverPassword = (formFields) => {
	return (dispatch) => {
		return axios.post('/users/password-recovery', formFields)
			.then((response) => {
				dispatch(recoverPasswordSuccess(response.data));
			})
			.catch((result) => {
				if ( result.response.data.validationErrors ) {
					let validationErrors = {};
					result.response.data.validationErrors.forEach((error) => {
						if ( error.path == 'email' ) {
							validationErrors.account = { email: error.message };
						}
					});
					throw new SubmissionError(validationErrors);
				} else {
					dispatch(recoverPasswordFailure(result.response.data));
				}
			});
	};
};

export const recoverPasswordSuccess = (data) => {
	return {
		type:    actionTypes.PASSWORD_RECOVER_SUCCESS,
		payload: data
	};
};

export const recoverPasswordFailure = (error) => {
	return {
		type:    actionTypes.PASSWORD_RECOVER_FAILURE,
		payload: error
	};
};
