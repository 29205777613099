module.exports = {
	collection: [
		{ employees: '1-20', label: '1-20 employees' },
		{ employees: '21-50', label: '21-50 employees' },
		{ employees: '51-100', label: '51-100 employees' },
		{ employees: '101-500', label: '101-500 employees' },
		{ employees: '501-1000', label: '501-1000 employees' },
		{ employees: '1000+', label: '1000+ employees' }
	]
};
