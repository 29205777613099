import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DatePicker, Icon, Tooltip } from '@syneto/compass-react';
import Form from '../../../../../components/forms/form';
import SelectOptionsRenderer from '../../../../../helpers/SelectOptionsRenderer';
import OperationsProductsModalMaintenancePlans from '../OperationsProductsMaintenancePlansModal';
import ServiceField from '../../../../../components/forms/common/service-field';

export const OperationsProductsAddModalBody = (props) => {
	const {
		initialChronos,
		initialFailoverAndFailback,
		productModels,
		users,
		formName,
		maintenancePlansOptions,
		change,
		initialMaintenanceType,
		getActivationCode,
		activationKey,
	} = props;

	const [modelId, setModelId] = useState(null);
	const [virtualAppliance, toggleVirtualAppliance] = useState(null);
	const [chronos, setChronos] = useState(initialChronos);
	const [failoverAndFailback, setFailoverAndFailback] = useState(initialFailoverAndFailback);

	useEffect(() => {
		if (virtualAppliance) {
			change('serialNumber', null);
			change('virtualAppliance', true);
			getActivationCode();
		} else {
			change('activationKey', '');
			change('virtualAppliance', false);
		}
	}, [virtualAppliance]);

	useEffect(() => {
		if (modelId && modelId.title === 'Cloud Gate Appliance') {
			change('hasCloudGate', false);
		}
	}, [modelId]);

	useEffect(() => {
		change('activationKey', activationKey);
	}, [activationKey]);

	const changeProductModel = (value) => {
		const selectedProductModel = productModels.find((productModel) => {
			return productModel.id === value;
		});

		if (selectedProductModel) {
			setChronos(selectedProductModel.chronos);
			setFailoverAndFailback(selectedProductModel.failoverAndFailback);
			toggleVirtualAppliance(selectedProductModel.virtualAppliance);
			setModelId(selectedProductModel);
		}
	};

	const copyToClipboard = (inputId) => {
		const copyText = document.getElementById(inputId);
		copyText.select();
		document.execCommand('copy');
	};

	return (
		<form
			onSubmit={(e) => {
				return e.preventDefault();
			}}
		>
			<fieldset className="border-0">
				<div className="row">
					<div className="col-8">
						<h4 className="with-border">Appliance</h4>
						<div className="row">
							<div className="col-5">
								<Form.select
									label="Model"
									name="modelId"
									options={SelectOptionsRenderer.renderProductModel(productModels)}
									onValueChange={changeProductModel}
									emptyOption="Please select"
									required
								/>
								<ServiceField
									name="hasChronos"
									label="Chronos"
									stateValue={chronos}
									changePropsInParent={change}
									value={false}
								/>
								<ServiceField
									name="hasFailoverAndFailback"
									label="Failover & Failback"
									stateValue={failoverAndFailback}
									changePropsInParent={change}
									value={false}
								/>
							</div>
							<div className="col-4">
								<Form.text
									name="serialNumber"
									label="Serial number"
									disabled={virtualAppliance}
									required={!virtualAppliance}
									placeholder={virtualAppliance ? 'No serial attached' : ''}
								/>
							</div>
							<div className="col-3">
								<Form.text name="saleValue" label="Sale value" />
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Continuous Authentication: </label>
								<Form.checkbox noMarginBottom name="hasContinuousAuthentication" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Multi-factor Authentication: </label>
								<Form.checkbox noMarginBottom name="hasMultiFactorAuthentication" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Has legal hold: </label>
								<Form.checkbox noMarginBottom name="hasLegalHold" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Is upgradable to OS5: </label>
								<Form.checkbox noMarginBottom name="isUpgradableToOs5" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Has Cloud Gate: </label>
								<Form.checkbox
									name="hasCloudGate"
									disabled={modelId && modelId.title === 'Cloud Gate Appliance' ? true : false}
									style={{ cursor: modelId && modelId.title === 'Cloud Gate Appliance' ? 'not-allowed' : 'default' }}
								/>
							</div>
							<div className="col-12">
								<Form.textarea
									name="maintenanceService.notes"
									label="Notes"
									style={{ height: 85 + 'px' }}
									required={virtualAppliance}
								/>
							</div>
						</div>
					</div>
					<div className="col-4">
						<h4 className="with-border border-0 p-0">Specify activation details</h4>
						<div className="activation-details border-radius-5">
							<div className="row">
								<div className="col-12">
									<Form.select
										label="End-user"
										name="userId"
										options={SelectOptionsRenderer.renderCompaniesWithUsers(users)}
										emptyOption="Not set"
										disabled={virtualAppliance}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="mb-3">
										<Form.datepicker
											formName={formName}
											calendarClassName="activation-details__date-picker"
											id="activationDate"
											name="activationDate"
											label="Activation date"
											placeholderText="Not set"
											placement="left"
											initValue={null}
											autoComplete="off"
										/>
									</div>
									<div className={virtualAppliance ? 'd-block' : 'd-none'}>
										<label className="font-weight-bold">Activation Code</label>
										<div className="float-end">
											<Tooltip text="Copy activation key" placement="top" trigger="click">
												<Button
													className="p-0 me-2"
													role="tertiary"
													onClick={() => {
														return copyToClipboard('activationKey');
													}}
												>
													<Icon name="fa fa-copy" color="black" />
												</Button>
											</Tooltip>
											<Button
												className="p-0 me-2"
												role="tertiary"
												onClick={() => {
													return getActivationCode();
												}}
											>
												<Icon name="fa fa-sync" color="black" />
											</Button>
										</div>
										<Form.text id="activationKey" name="activationKey" readOnly placeholder="Not set" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<h4 className="with-border">Maintenance</h4>

			<OperationsProductsModalMaintenancePlans
				maintenancePlansOptions={maintenancePlansOptions}
				initialMaintenanceType={initialMaintenanceType}
				serenityValue={false}
				change={change}
			/>
		</form>
	);
};

OperationsProductsAddModalBody.propTypes = {
	users: PropTypes.array,
	productModels: PropTypes.array.isRequired,
	maintenancePlansOptions: PropTypes.array.isRequired,
	initialMaintenanceType: PropTypes.string.isRequired,
	change: PropTypes.func.isRequired,
	getActivationCode: PropTypes.func.isRequired,
};
