import React from 'react';
import { Badge } from '@syneto/compass-react/lib';

const NotesIcon = (props) => {
	let { productNotes } = props;

	return (
		<div style={ { display: 'flex', justifyContent: 'space-evenly' } }>
			<div>Notes </div>
			{ productNotes > 0 && <Badge className="ms-2" appearance="danger">{ productNotes }</Badge> }
		</div>
	);
};

export default NotesIcon;
