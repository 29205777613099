import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Badge, Icon, Tooltip } from '@syneto/compass-react';
import moment from 'moment';
import MaintenanceStatusFormatter from '../../../../helpers/MaintenanceStatusFormatter';
import DateFormatterService from '../../../../helpers/DateFormatter';
import PostInstallCheckLogsModal from '../../../../components/PostInstallCheckLogsModal';
import { consumeToken, generateToken, loadSupportTunnels, readToken } from '../../data/actions/actions';

const MaintenanceDetails = (props) => {
	let {
		maintenanceService,
		serialNumber,
		supportTunnels,
		maintenancePlanInternalNotes,
		maintenanceServiceDetails,
		currentlyActiveMaintenanceService,
		accessToken,
		product,
		user,
		userData,
		consumeToken,
		generateToken,
		loadSupportTunnels,
		readTokenserialNumber,
	} = props;
	const [showPostInstallChecksModal, setShowPostInstallChecksModal] = useState(false);
	const supportPorts = Object.prototype.hasOwnProperty.call(supportTunnels, 'serialNumber') ? supportTunnels : null;
	const osVersion = product && product.osVersion && parseInt(product.osVersion.replace('.', ''));
	const currentlyActiveMaintenanceServicePlan =
    currentlyActiveMaintenanceService.maintenance_plan ?? currentlyActiveMaintenanceService.plan ?? {};
	const activationDate = product && product.activationDate;
	const userRole = user?.account?.role;

	useEffect(() => {
		const serialNumber = serialNumber || product?.serialNumber;

		loadSupportTunnels(serialNumber);
		readToken(serialNumber);
	}, [product, serialNumber]);

	const beautifyInternalNotes = (internalNotes) => {
		if (internalNotes) {
			return internalNotes.split(/[\r\n]+/);
		}
	};

	const hasPinValue = (accessToken) => {
		return accessToken && accessToken.pin && accessToken.pin.value;
	};

	const renderMaintenancePlanInternalNotes = (internalNotes) => {
		if (!internalNotes) {
			return <div>No internal notes found</div>;
		}

		return (() => {
			return beautifyInternalNotes(internalNotes).map((internalNote, key) => {
				return key + 1 !== beautifyInternalNotes(internalNotes).length ? (
					<div className="pb-2" key={key}>
						{internalNote}
					</div>
				) : (
					<div key={key}>{internalNote}</div>
				);
			});
		})();
	};

	const accumulatedDuration = (maintenanceServiceDetails) => {
		const renewals = maintenanceServiceDetails.maintenance_service_renewals ?? maintenanceServiceDetails.renewals;

		if (renewals) {
			return renewals.reduce((acc, maintenanceExtension) => {
				acc += maintenanceExtension.duration;
				return acc;
			}, maintenanceServiceDetails.duration);
		}

		return 0;
	};

	const arrowState = (state) => {
		if (state === 'lost') {
			return 'fa fa-arrow-down';
		}
		if (state === 'up') {
			return 'fa fa-arrow-up';
		}
	};

	const setStyle = (state) => {
		if (state === 'lost') {
			return '#E04C59';
		}
		if (state === 'up') {
			return '#00A865';
		}
	};

	const copyToClipboard = (inputId, tooltipId) => {
		const copyText = document.getElementById(inputId);
		copyText.select();
		document.execCommand('copy');

		// Commented because it raises some errors

		// const tooltip     = document.getElementById(tooltipId)
		// tooltip.innerHTML = 'Copied: ' + copyText.value
	};

	const handleGenerateToken = () => {
		if (accessToken?.pin?.value) {
			consumeToken(product.serialNumber);
		} else {
			generateToken(product.serialNumber);
		}
	};

	const changeGenerateButton = (accessToken) => {
		return hasPinValue(accessToken) ? 'Cancel' : 'Generate';
	};

	const toggleShowPostInstallChecksModal = (value) => {
		setShowPostInstallChecksModal(value);
	};

	const renderPostInstallChecksSectionStatusPill = (status) => {
		switch (true) {
			case status && status.hasFailed:
				return (
					<Tooltip text="Tests have failed" placement="top">
						<Badge appearance="danger">Failed</Badge>
					</Tooltip>
				);
			case status && status.isPending:
				return (
					<Tooltip text="Tests are pending" placement="top">
						<Badge appearance="warning">Pending</Badge>
					</Tooltip>
				);
			default:
				return (
					<Tooltip text="Tests are successful" placement="top">
						<Badge appearance="success">Passed</Badge>
					</Tooltip>
				);
		}
	};

	const renderAssemblyTestResults = (postInstallChecks) => {
		return postInstallChecks[0] ? (
			<>
				{renderPostInstallChecksSectionStatusPill(postInstallChecks[0].status)}
				{!postInstallChecks[0]?.status?.isPending && (
					<Button
						className="ms-2 py-0"
						role="tertiary"
						type="submit"
						onClick={() => {
							return toggleShowPostInstallChecksModal(true);
						}}
					>
						<Icon className="me-2" name="fa fa-eye" color="#4f5ed7" /> View Logs
					</Button>
				)}
			</>
		) : (
			<span>Not performed for this machine</span>
		);
	};

	return (
		<div>
			{supportPorts && (
				<div className="row m-0 mb-4 port-status-box align-items-center">
					<div className="col-7 align-self-center">
						{supportPorts.web.state === 'down' && supportPorts.cli.state === 'down' ? (
							<div className="text-value">Web and SSH ports are not opened</div>
						) : (
							<div>
								<div className="row">
									<div className="col-6">
										<label className="m-0"> Web port:</label>
										<div className="d-flex">
											<Icon name={`${arrowState(supportPorts.web.state)} pf-2`} />
											<input
												style={{ color: setStyle(supportPorts.web.state) }}
												className="portInputElement"
												id="webPort"
												type="text"
												readOnly
												value={supportPorts.web.port}
											/>

											<a
												style={{ color: '#70758B' }}
												className="btn btn-default p-0"
												href={`https://${supportPorts.host}:${supportPorts.web.port}${
													hasPinValue(accessToken) ? '/?pin=' + accessToken.pin.value : ''
												}`}
												target="_blank"
												rel="noreferrer"
											>
												<Icon name="fa fa-external-link ps-2" />
											</a>
										</div>
									</div>
									<div className="col-6">
										<label className="m-0">SSH port:</label>
										<div className="d-flex">
											<Icon name={`${arrowState(supportPorts.cli.state)} pf-2`} />
											<input
												id="inputSSHPort"
												className="portInputElement"
												style={{ color: setStyle(supportPorts.cli.state) }}
												type="text"
												readOnly
												value={supportPorts.cli.port}
											/>
											<Tooltip text="Copy port" id="tooltipSSHPortModal" placement="top">
												<button
													id="copySSHPort"
													className="btn btn-default p-0"
													style={{ color: '#70758B' }}
													onClick={() => {
														copyToClipboard('inputSSHPort', 'tooltipSSHPort');
													}}
												>
													<Icon name="fa fa-copy ps-2" />
												</button>
											</Tooltip>
										</div>
									</div>
								</div>
								<div className="text-value d-flex">
									<Tooltip text="Copy link" id="tooltipCopyWebLink" placement="top">
										<button
											id="copyWebPort"
											className="btn btn-default"
											style={{ color: '#70758B', paddingLeft: 0 }}
											onClick={() => {
												copyToClipboard('web-link', 'tooltipCopyWebLink');
											}}
										>
											<Icon name="fa fa-copy" />
										</button>
									</Tooltip>

									<input
										id="web-link"
										type="text"
										readOnly
										style={{ background: 'transparent', border: 'none', width: '100%' }}
										value={`https://${supportPorts.host}:${supportPorts.web.port}${
											hasPinValue(accessToken) ? '/?pin=' + accessToken.pin.value : ''
										}`}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="col-5 pb-3">
						{osVersion < 44 ? (
							<div className="text-value">Login tokens are not supported</div>
						) : (
							<div className="row">
								<div className="col-8 text-value">
									{hasPinValue(accessToken) ? (
										<div>
											<label className="m-0">Access token:</label>
											<div className="align-items-center">
												<Icon name="fa fa-check pf-2" />
												<input
													id="inputAccessToken"
													className="portInputElement"
													type="text"
													readOnly
													value={accessToken.pin.value}
												/>
												<Tooltip placement="top" text="Copy token">
													<button
														id="copyAccessToken"
														className="btn btn-default p-0"
														style={{ color: '#70758B' }}
														onClick={() => {
															copyToClipboard('inputAccessToken', 'tooltipAccessToken');
														}}
													>
														<Icon name="fa fa-copy ps-2" />
													</button>
												</Tooltip>
											</div>
										</div>
									) : (
										<div style={{ padding: '14px' }}>No access token</div>
									)}
								</div>
								<div className="col-4 pt-2">
									<Button role="tertiary" onClick={handleGenerateToken} type="submit">
										{changeGenerateButton(accessToken)}
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			<div className="row ps-3 pf-3" style={{ fontSize: '14px' }}>
				{userData ? (
					<>
						<label className="col-4 bottom-border">Activated by</label>
						<div className="col-8 bottom-border">{`${userData.firstName} ${userData.lastName}`}</div>
					</>
				) : null}
				<label className="col-4 pt-2">Status</label>

				<div className="col-8 pt-2">
					<Badge
						appearance={MaintenanceStatusFormatter.setStyle(
							MaintenanceStatusFormatter.maintenanceStatus(product.maintenanceService.expiresOn)
						)}
					>
						{MaintenanceStatusFormatter.maintenanceStatus(product.maintenanceService.expiresOn)}
					</Badge>
				</div>

				<label className="col-4 pt-2 top-border">Maintenance type</label>
				<div className="col-8 pt-2 top-border">{currentlyActiveMaintenanceServicePlan.synetoInternalName}</div>

				{product && (
					<>
						<label className="col-4 pt-2 top-border">Has Chronos</label>
						<div className="col-8 pt-2 top-border">{product.hasChronos ? 'Yes' : 'No'}</div>
					</>
				)}

				{product && (
					<>
						<label className="col-4 pt-2 top-border">Has Continuous Authentication</label>
						<div className="col-8 pt-2 top-border">{product.hasContinuousAuthentication ? 'Yes' : 'No'}</div>
					</>
				)}

				{product && (
					<>
						<label className="col-4 pt-2 top-border">Has Multi-factor Authentication</label>
						<div className="col-8 pt-2 top-border">{product.hasMultiFactorAuthentication ? 'Yes' : 'No'}</div>
					</>
				)}

				{product && (
					<>
						<label className="col-4 pt-2 top-border">Has legal hold</label>
						<div className="col-8 pt-2 top-border">{product.hasLegalHold ? 'Yes' : 'No'}</div>
					</>
				)}

				<label className="col-4 pt-2 top-border">Validity</label>
				<div className="col-8 pt-2 top-border">
					{DateFormatterService.humanizeMonths(accumulatedDuration(maintenanceServiceDetails))}
				</div>

				<label className="col-4 pt-2 top-border">Activation date</label>
				<div className="col-8 pt-2 top-border">
					{activationDate
						? DateFormatterService.formatDateToSpecificFormat(product, 'activationDate', 'DD/MM/YYYY')
						: 'N/A'}
				</div>

				<label className="col-4 pt-2 top-border">Expiration date</label>
				<div className="col-8 pt-2 top-border">
					{product.maintenanceService.expiresOn
						? DateFormatterService.formatDateToSpecificFormat(product.maintenanceService, 'expiresOn', 'DD/MM/YYYY')
						: 'N/A'}
					{product.maintenanceService.expiresOn ? (
						<span>&nbsp;({moment(product.maintenanceService.expiresOn).fromNow()})</span>
					) : null}
				</div>

				<label className="col-4 pt-2 top-border">Internal notes</label>
				<div className="col-8 pt-2 top-border">{renderMaintenancePlanInternalNotes(maintenancePlanInternalNotes)}</div>

				{maintenanceService?.product?.post_install_checks && (
					<>
						<label className="col-4 pt-2 top-border">Assembly test results</label>
						<div className="col-8 pt-2 top-border">
							{renderAssemblyTestResults(maintenanceService?.product.post_install_checks)}
						</div>
					</>
				)}

				<label className="col-4 pt-2 top-border">Service description</label>
				<div className="col-8 pt-2 top-border">
					<span>
						{currentlyActiveMaintenanceServicePlan.end_user_description ??
              currentlyActiveMaintenanceServicePlan.endUserDescription}
					</span>
				</div>
			</div>
			{maintenanceService?.product?.post_install_checks?.length > 0 && userRole && (
				<PostInstallCheckLogsModal
					show={showPostInstallChecksModal}
					onHide={() => {
						return toggleShowPostInstallChecksModal(false);
					}}
					postInstallData={maintenanceService.product.post_install_checks[0]}
					userRole={userRole}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		supportTunnels: state.supportTunnels.supportTunnels,
		accessToken: state.accessToken.accessToken,
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadSupportTunnels: (serialNumber) => {
			return dispatch(loadSupportTunnels(serialNumber));
		},
		readToken: (serialNumber) => {
			return dispatch(readToken(serialNumber));
		},
		generateToken: (serialNumber) => {
			return dispatch(generateToken(serialNumber));
		},
		consumeToken: (serialNumber) => {
			return dispatch(consumeToken(serialNumber));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceDetails);
