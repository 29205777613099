export const LOADING_PRODUCT_MODELS = 'LOADING_PRODUCT_MODELS';
export const LOADING_PRODUCT_MODELS_SUCCESS = 'LOADING_PRODUCT_MODELS_SUCCESS';
export const LOADING_PRODUCT_MODELS_ERROR = 'LOADING_PRODUCT_MODELS_ERROR';

export const CREATING_PRODUCT_MODEL = 'CREATING_PRODUCT_MODEL';
export const CREATE_PRODUCT_MODEL_SUCCESS = 'CREATE_PRODUCT_MODEL_SUCCESS';
export const CREATE_PRODUCT_MODEL_ERROR = 'CREATE_PRODUCT_MODEL_ERROR';

export const UPDATING_PRODUCT_MODEL = 'UPDATING_PRODUCT_MODEL';
export const UPDATE_PRODUCT_MODEL_SUCCESS = 'UPDATE_PRODUCT_MODEL_SUCCESS';
export const UPDATE_PRODUCT_MODEL_ERROR = 'UPDATE_PRODUCT_MODEL_ERROR';

export const DELETING_PRODUCT_MODEL = 'DELETING_PRODUCT_MODEL';
export const DELETE_PRODUCT_MODEL_SUCCESS = 'DELETE_PRODUCT_MODEL_SUCCESS';
export const DELETE_PRODUCT_MODEL_ERROR = 'DELETE_PRODUCT_MODEL_ERROR';

export const SHOW_MODAL_ADD_PRODUCT_MODEL = 'SHOW_MODAL_ADD_PRODUCT_MODEL';
export const HIDE_MODAL_ADD_PRODUCT_MODEL = 'HIDE_MODAL_ADD_PRODUCT_MODEL';
