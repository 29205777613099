import React, { Component } from 'react';
import { Icon } from '@syneto/compass-react';

class Preloader extends Component {
	renderPreloaderForProductStats() {
		return (
			<ul className="list-group list-group-flush">
				<li className="list-group-item">
					<div className="row charts">
						<div className="col-12">
							<div className={ 'container h-100 ' + (this.props.dataIsLoading ? '' : 'd-none') }>
								<div className="row h-100 d-flex justify-content-center align-items-center">
									<div className="col-12 text-center text-muted">
										<Icon name="fa fa-circle-o-notch fa-fw fa-2x fa-spin"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		);
	}

	renderDefaultPreloader() {
		return (
			<div className={ 'container h-100 ' + (this.props.dataIsLoading ? '' : 'd-none') }>
				<div className="row h-100 d-flex justify-content-center align-items-center">
					<div className="col-12 text-center text-muted">
						<Icon name="fa fa-circle-o-notch fa-fw fa-2x fa-spin"/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			this.props.isPreloaderForProductStats ? this.renderPreloaderForProductStats() : this.renderDefaultPreloader()
		);

	}
}

export default Preloader;
