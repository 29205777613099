export const LOADING_SUPPORT_TUNNELS_SUCCESS = 'LOADING_SUPPORT_TUNNELS_SUCCESS';
export const LOADING_SUPPORT_TUNNELS_FAILURE = 'LOADING_SUPPORT_TUNNELS_FAILURE';
export const READING_TOKEN_SUCCESS = 'READING_TOKEN_SUCCESS';
export const READING_TOKEN_FAILURE = 'READING_TOKEN_FAILURE';
export const GENERATING_TOKEN_SUCCESS = 'GENERATING_TOKEN_SUCCESS';
export const GENERATING_TOKEN_FAILURE = 'GENERATING_TOKEN_FAILURE';
export const CONSUMING_TOKEN_SUCCESS = 'CONSUMING_TOKEN_SUCCESS';
export const CONSUMING_TOKEN_FAILURE = 'CONSUMING_TOKEN_FAILURE';

export const GETTING_USER_PRODUCTS = 'GETTING_USER_PRODUCTS';
export const GET_USER_PRODUCTS_SUCCESS = 'GET_USER_PRODUCTS_SUCCESS';
export const GET_USER_PRODUCTS_ERROR = 'GET_USER_PRODUCTS_ERROR';

export const GETTING_NOTES_FOR_PRODUCT = 'GETTING_NOTES_FOR_PRODUCT';
export const GET_NOTE_DETAILS_SUCCESS = 'GET_NOTE_DETAILS_SUCCESS';
export const GET_NOTE_DETAILS_ERROR = 'GET_NOTE_DETAILS_ERROR';
export const GET_NOTES_FOR_PRODUCT_SUCCESS = 'GET_NOTES_FOR_PRODUCT_SUCCESS';
export const GET_NOTES_FOR_PRODUCT_ERROR = 'GET_NOTES_FOR_PRODUCT_ERROR';
export const UPDATE_NOTE_FOR_PRODUCT_SUCCESS = 'UPDATE_NOTE_FOR_PRODUCT_SUCCESS';
export const UPDATE_NOTE_FOR_PRODUCT_ERROR = 'UPDATE_NOTE_FOR_PRODUCT_ERROR';
export const DELETE_NOTE_FOR_PRODUCT_SUCCESS = 'DELETE_NOTE_FOR_PRODUCT_SUCCESS';
export const DELETE_NOTE_FOR_PRODUCT_ERROR = 'DELETE_NOTE_FOR_PRODUCT_ERROR';
export const SAVE_NOTE_FOR_PRODUCT_SUCCESS = 'SAVE_NOTE_FOR_PRODUCT_SUCCESS';
export const SAVE_NOTE_FOR_PRODUCT_ERROR = 'SAVE_NOTE_FOR_PRODUCT_ERROR';

export const UPLOADING_FILE = 'UPLOADING_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const UPDATE_PRODUCT_FAVORITE_STATE_SUCCESS = 'UPDATE_PRODUCT_FAVORITE_STATE_SUCCESS';
export const UPDATE_PRODUCT_FAVORITE_STATE_ERROR = 'UPDATE_PRODUCT_FAVORITE_STATE_ERROR';

export const GET_VM_ACTIVATION_CODES_SUCCESS = 'GET_VM_ACTIVATION_CODES_SUCCESS';
export const GET_VM_ACTIVATION_CODES_ERROR = 'GET_VM_ACTIVATION_CODES_ERROR';
export const START_INCREASE_ACTIVATIONS = 'START_INCREASE_ACTIVATIONS';
export const INCREASE_ACTIVATIONS_SUCCESS = 'INCREASE_ACTIVATIONS_SUCCESS';
export const INCREASE_ACTIVATIONS_ERROR = 'INCREASE_ACTIVATIONS_ERROR';



