import React, { useState } from 'react';
import { Button, Icon, Table } from '@syneto/compass-react';
import AdminMaintenanceTypesEditModal from './adminMaintenanceTypesEditModal';
import Preloader from '../../../../components/preloader';
import DeleteMaintenanceType from './adminMaintenanceTypesDeleteModal';

function AdminMaintenanceTypesTable(props) {
	let { loadingMaintenancePlansReports, maintenancePlansReport, fetchMaintenancePlansReports } = props;

	const [maintenancePlanDetails, setMaintenancePlanDetails] = useState(null);
	const [editModalOpened, setEditModalOpened] = useState(false);
	const [showDeleteModal, toggleDeleteModal] = useState(false);

	function openEditModal(maintenancePlan) {
		setEditModalOpened(true);
		setMaintenancePlanDetails(maintenancePlan);
	}

	function closeModal() {
		setEditModalOpened(false);
	}

	function openDeleteModal(maintenancePlan) {
		toggleDeleteModal(true);
		setMaintenancePlanDetails(maintenancePlan);
	}

	function renderCurrentlySelling(cell, row) {
		return (
			row.currentlySelling ? <span className="rounded-circle success"><Icon name="fa fa-check" /></span>
				: <span className="rounded-circle warning"><Icon name="fa fa-times" /></span>
		);
	}

	function renderActions(cell, row) {
		return (
			<>
				<Button role="tertiary" onClick={() => { return openEditModal(row); }}>
					Edit
				</Button>
				<Button role="tertiary" disabled={row.count > 0} onClick={(e) => {
					openDeleteModal(row);
					e.stopPropagation();
				}}>
					Delete
				</Button>
			</>
		);
	}

	return (
		<div>
			{loadingMaintenancePlansReports ? <Preloader dataIsLoading={loadingMaintenancePlansReports} /> :
				<Table data={maintenancePlansReport} rowEvents={{ onClick: (e, row) => { return openEditModal(row); } }}
					keyField="id">
					<Table.Col field="customerFacingName" align="left">
						External name
					</Table.Col>
					<Table.Col field="synetoInternalName" align="left">
						Internal name
					</Table.Col>
					<Table.Col field="currentlySelling" align="center" renderCell={renderCurrentlySelling}>
						Currently selling
					</Table.Col>
					<Table.Col field="count" align="left">
						Machines No
					</Table.Col>
					<Table.Col field="serenity" align="left">
						Serenity
					</Table.Col>
					<Table.Col align="center" renderCell={renderActions}>
						Actions
					</Table.Col>
				</Table>
			}

			<AdminMaintenanceTypesEditModal
				isOpen={editModalOpened}
				closeEditModal={closeModal}
				fetchMaintenancePlansReports={fetchMaintenancePlansReports}
				fetchMaintenancePlansOptions={props.fetchMaintenancePlansOptions}
				maintenancePlan={maintenancePlanDetails}
				toggleDeleteModal={toggleDeleteModal} />

			<DeleteMaintenanceType showDeleteModal={showDeleteModal}
				closeDeleteModal={() => { return toggleDeleteModal(false); }}
				maintenancePlan={maintenancePlanDetails}
				fetchMaintenancePlansReports={fetchMaintenancePlansReports}
				closeEditModal={closeModal} />
		</div>
	);
}

export default AdminMaintenanceTypesTable;
