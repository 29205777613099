export const actionTypes = {
	navigation: {
		admin: {
			clicked: 'ADMIN_MENU_ITEM_CLICKED'
		}
	},
	sidebar: {
		activeMenu: 'SIDEBAR_MENU_ITEM_CLICKED'
	}
};
