import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	error: null,
	loading: false,
	maintenanceServices: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.FETCHING_MARKETING_REPORTS:
			return {
				...state,
				loading: true
			};
		case actionTypes.FETCH_MARKETING_REPORTS_SUCCESS:
			return {
				...state,
				loading: false,
				maintenanceServices: action.payload.maintenanceServices
			};
		case actionTypes.FETCH_MARKETING_REPORTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.message
			};
		default:
			return state;
	}
};
