import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';

function SupportSerenityEventsDeleteModalFn(props) {
	const { isVisible, close, events, handleEventsDelete } = props;

	function handleDelete() {
		handleEventsDelete(events);
		close();
	}

	return events && (
		<Modal
			show={isVisible}
			onHide={close}
			title={events.length > 1 ? 'Bulk Delete Serenity Events' : 'Delete Serenity Event'}
			content={events.length > 1 ? 'Are you sure you want to permanently delete these serenity events?' : 'Are you sure you want to delete this event?'}
			actions={{
				primary: {
					label: 'Delete',
					onClick: handleDelete
				},
				secondary: {
					label: 'Cancel',
					onClick: close,
				},
			}}
		/>
	);
}

SupportSerenityEventsDeleteModalFn.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	events: PropTypes.array,
	handleEventsDelete: PropTypes.func.isRequired,
};

export const SupportSerenityEventsDeleteModal = SupportSerenityEventsDeleteModalFn;
