//TODO - make sure the values are the same on the backend as well - the keys can differ if we please, but it would be
// nice not to
const UserRoles = {
	CLIENT: 'user',
	SUPPORT_ENGINEER: 'support',
	OPERATIONS: 'operations',
	ADMINISTRATOR: 'administrator',
	MARKETING: 'marketing'
};

export default UserRoles;
