import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from '@syneto/compass-react';
import Preloader from '../../../../components/preloader';
import MFAConfirmationModal from '../../../../components/UserDetailModal/MfaConfirmationModal';
import EnableMfaSwitch from '../../../../components/EnableMfaSwitch';

function InternalUsersTable(props) {
	const { loading, users, role, openEditUserModal, openDeleteUserModal, refreshUserList, toggleMFAForCurrentRole, isMFAEnforcedForCurrentRole } = props;
	const [opelEnabledMfaModal, setOpelEnabledMfaModal] = useState(false);
	const [mfaStatus, setMfaStatus] = useState(null);

	useEffect(() => {
		let clearSearch = document.querySelector('.table-search__clear');
		clearSearch && clearSearch.click();
	}, [users]);

	useEffect(() => {
		setMfaStatus(isMFAEnforcedForCurrentRole);
	}, [isMFAEnforcedForCurrentRole]);

	const changeMfaStatus = () => {
		toggleMFAForCurrentRole({
			enable: mfaStatus,
			role
		}).then(() => {
			refreshUserList(role);
		});
		setOpelEnabledMfaModal(false);
	};

	const cancelEnabledMfaForUsers = () => {
		setMfaStatus(!mfaStatus);
		setOpelEnabledMfaModal(false);
	};

	const handleMfaStatusChange = (value) => {
		setMfaStatus(value);
		setOpelEnabledMfaModal(true);
	};

	const renderCustomerName = (cell, row) => {
		return (
			<>{`${row.first_name} ${row.last_name}`}</>
		);
	};

	const renderCellActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { return openEditUserModal(row); }}>
					Edit
				</Button>
				<Button role="tertiary" onClick={(e) => {
					openDeleteUserModal(row);
					e.stopPropagation();
				}}>
					Delete
				</Button>
			</>
		);
	};

	const renderCellMfaEnabled = (cell, row) => {
		return row.mfa_enabled ? 'Enabled': 'Disabled';
	};

	const renderMfaToggle = () => {
		return (
			<div className='d-flex gap-2'>
				<h5 className='me-3'>{`Enforce multi-factor authentication for ${role} users`}
					<Tooltip text="Multifactor authentication (MFA) is a authentication method that requires multiple authentication steps to verify a user's identity for login"
						placement="bottom">
						<i
							className="fa fa-question ms-1"
						/>
					</Tooltip>
				</h5>
				<EnableMfaSwitch 
					disabled={false}
					onChange={handleMfaStatusChange}
					value={mfaStatus}
				/>
				{opelEnabledMfaModal && <MFAConfirmationModal role={role} isAdmin={true} mfaStatus={mfaStatus} isMultiUserEnforcement={true} onHide={cancelEnabledMfaForUsers} onConfirmAndLogOut={changeMfaStatus} />}
			</div>
		);
	};

	return loading
		? <Preloader dataIsLoading={loading} />
		: users && users.length ?
			<Table
				data={users}
				keyField='id'
				rowEvents={{ onClick: (e, row) => { return openEditUserModal(row); } }}
				search
				pagination={{ sizePerPageList: [15, 30, users.length], showTotal: true }}
				actions={renderMfaToggle()}
			>
				<Table.Col renderCell={renderCustomerName} field="customerName" align="left">
					Name
				</Table.Col>
				<Table.Col field="email" align="left">
					Email
				</Table.Col>
				<Table.Col align="center" renderCell={renderCellMfaEnabled}>
					Multi-factor authentication
				</Table.Col>
				<Table.Col align="center" renderCell={renderCellActions}>
					Actions
				</Table.Col>
			</Table> : null;
}

export default InternalUsersTable;
