export const LOADING_MAINTENANCE_SERVICES = 'LOADING_MAINTENANCE_SERVICES';

export const LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS = 'LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS';
export const LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR = 'LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR';

export const CREATE_MAINTENANCE_SERVICE_DATA = 'CREATE_MAINTENANCE_SERVICE_DATA';
export const CREATE_MAINTENANCE_SERVICE_DATA_SUCCESS = 'CREATE_MAINTENANCE_SERVICE_DATA_SUCCESS';
export const CREATE_MAINTENANCE_SERVICE_DATA_ERROR = 'CREATE_MAINTENANCE_SERVICE_DATA_ERROR';
export const UPDATE_MAINTENANCE_SERVICE_DATA_SUCCESS = 'UPDATE_MAINTENANCE_SERVICE_DATA_SUCCESS';
export const UPDATE_MAINTENANCE_SERVICE_DATA_ERROR = 'UPDATE_MAINTENANCE_SERVICE_DATA_ERROR';
export const DELETE_MAINTENANCE_SERVICE_DATA_SUCCESS = 'DELETE_MAINTENANCE_SERVICE_DATA_SUCCESS';
export const DELETE_MAINTENANCE_SERVICE_DATA_ERROR = 'DELETE_MAINTENANCE_SERVICE_DATA_ERROR';
export const DELETE_MAINTENANCE_SERVICES_DATA_SUCCESS = 'DELETE_MAINTENANCE_SERVICES_DATA_SUCCESS';
export const DELETE_MAINTENANCE_SERVICES_DATA_ERROR = 'DELETE_MAINTENANCE_SERVICES_DATA_ERROR';

export const LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS = 'LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS';
export const LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS = 'LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS';
export const LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR = 'LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR';
export const RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS = 'RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS';

export const LOAD_OPERATIONS_MAINTENANCE_DETAILS_SUCCESS = 'LOAD_MAINTENANCE_DETAILS_OPERATIONS_SUCCESS';
export const LOAD_OPERATIONS_MAINTENANCE_DETAILS_ERROR = 'LOAD_MAINTENANCE_DETAILS_OPERATIONS_ERROR';
