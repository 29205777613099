import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@syneto/compass-react';
import { deleteProductModel, deletingProductModel } from '../../../../data/productModels/action';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

function AdminProductModelsDeleteModal(props) {

	//custom hook for useDidMount logic
	function useDidMount() {
		const [didMount, setDidMount] = useState(false);
		useEffect(() => { return setDidMount(true); }, []);
		return didMount;
	}

	const didMount = useDidMount();

	useEffect(() => {
		if ( didMount ) {
			props.deletedSuccessMessage && props.pushSuccessfulNotification(props.deletedSuccessMessage);
			props.deletedFailedMessage && props.pushDangerNotification(props.deletedFailedMessage);
		}
	}, [props.deletedSuccessMessage, props.deletedFailedMessage]);

	function deleteProductModel(productModelId) {
		return Promise.all([props.deletingProductModel(), props.deleteProductModel(productModelId)]).then(() => {
			props.closeDeleteModal();
			props.closeEditModal();
			props.loadProductModels();
		});
	}

	return (
		<Modal
			show={ props.showDeleteModal }
			onHide={ props.closeDeleteModal }
			content={ `You are about to delete the following HYPER model: ${ props.productModel && props.productModel.title }` }
			title="Warning"
			actions={ {
				primary:   {
					label:   'Delete',
					onClick: () => { return deleteProductModel(props.productModel.id); }
				},
				secondary: {
					label: 'Cancel'
				}
			} }
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		deletedSuccessMessage: state.productModels.deleteSuccessMessage,
		deletedFailedMessage:  state.productModels.deleteErrorMessage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deletingProductModel:       () => {
			return dispatch(deletingProductModel());
		},
		deleteProductModel:         (productId) => {
			return dispatch(deleteProductModel(productId));
		},
		pushSuccessfulNotification: (deletedSuccessMessage) => {
			return dispatch(pushSuccessfulNotification(deletedSuccessMessage));
		},
		pushDangerNotification:     (deletedFailedMessage) => {
			return dispatch(pushDangerNotification(deletedFailedMessage));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProductModelsDeleteModal);
