import React, { useEffect, useState } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { Button, Modal, Tooltip, Icon } from '@syneto/compass-react';

import { updateMaintenancePlan, updatingMaintenancePlan } from '../../../../data/maintenanceType/action';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';
const validate = (values) => {
	const validator = new Validator(values,
		{
			'customerFacingName': ['required'],
			'synetoInternalName': ['required'],
			'serenity': ['required'],
			'endUserDescription': ['required']
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const selector = formValueSelector('editMaintenancePlan');

let AdminMaintenanceTypesEditModal = (props) => {
	let { handleSubmit, submitting, isOpen, maintenancePlan, closeEditModal, reset, toggleDeleteModal } = props;

	//custom hook for useDidMount logic
	function useDidMount() {
		const [didMount, setDidMount] = useState(false);
		useEffect(() => { return setDidMount(true); }, []);
		return didMount;
	}

	const didMount = useDidMount();

	useEffect(() => {
		if (didMount) {
			props.updatedSuccessMessage && props.dispatch(pushSuccessfulNotification(props.updatedSuccessMessage));
			props.updatedFailedMessage && props.dispatch(pushDangerNotification(props.updatedFailedMessage));
		}
	}, [props.updatedSuccessMessage, props.updatedFailedMessage]);

	function submit(values) {
		updateMaintenanceType(values);
		cancelEditModal();
	}

	function cancelEditModal() {
		closeEditModal();
		reset('editMaintenancePlan');
	}

	function updateMaintenanceType(values) {
		return Promise.all([props.updatingMaintenancePlan(), props.updateMaintenancePlan(values)]).then((results) => {
			if (results.length) {
				props.closeEditModal();
				props.fetchMaintenancePlansReports('all');
				props.fetchMaintenancePlansOptions();
			}
		});
	}

	return (
		<div>
			{maintenancePlan &&
				<Modal show={isOpen} size="lg">
					<Modal.Header>
						<Modal.Title>Edit maintenance type</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form noValidate>
							<fieldset>
								<Form.text label="External name"
									id="customerFacingName" name="customerFacingName"
									value={maintenancePlan.customerFacingName} required />
								<Form.text label="Internal name"
									id="synetoInternalName" name="synetoInternalName"
									value={maintenancePlan.synetoInternalName} required />
								<div className="mb-3 row with-border">
									<label htmlFor="serenity" className="col-3 p-0">Serenity<span className="required">*</span></label>
									<div className="col-9">
										<Form.radio name="serenity" id="included" value="included" label="Included" inline />
										<Form.radio name="serenity" id="optional" value="optional" label="Optional" inline />
										<Form.radio name="serenity" id="not available" value="not available" label="Not available" inline />
									</div>
								</div>
								<div className="mb-3 row with-border">
									<label htmlFor="currentlySelling" className="col-3 p-0">Currently selling</label>
									<div className="col-9">
										<Form.checkbox id="currentlySelling" name="currentlySelling" label="Yes" />
									</div>
								</div>
								<Form.textarea label="Internal notes"
									id="internalNotes" name="internalNotes"
									value={maintenancePlan.internal_notes} />
								<Form.textarea label="End user description"
									id="endUserDescription" name="endUserDescription"
									value={maintenancePlan.end_user_description} required />
							</fieldset>
						</form>
					</Modal.Body>

					<Modal.Footer>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'space-between'
							}}>
							<div className="float-start">
								<Button
									disabled={maintenancePlan.count > 0}
									appearance="danger"
									role="secondary"
									onClick={() => { return toggleDeleteModal(true); }}>
									Delete
								</Button>

								{maintenancePlan.count > 0 &&
									<Tooltip text={` This maintenance plan cannot be deleted as it is attached to ${maintenancePlan.count} maintenance ${maintenancePlan.count > 1 ? 'services' : 'service'} `} placement="top">
										<span className='ms-1'>
											<Icon name="fa fa-question-circle fa-fw" />
										</span>
									</Tooltip>
								}
							</div>
							<div>
								<Button role="secondary" style={{ marginRight: '6px' }} onClick={() => { return cancelEditModal(); }}>Cancel</Button>
								<Button disabled={submitting} onClick={handleSubmit(submit)}>Save</Button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			}
		</div>
	);
};

AdminMaintenanceTypesEditModal = reduxForm({
	form: 'editMaintenancePlan',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate,
	shouldValidate: () => { return true; }
})(AdminMaintenanceTypesEditModal);

AdminMaintenanceTypesEditModal = connect((state, componentProps) => {
	let { maintenancePlan } = componentProps;
	let valuesSelector = selector(state, 'maintenancePlanId', 'customerFacingName', 'synetoInternalName', 'serenity', 'currentlySelling', 'internalNotes', 'endUserDescription');

	return {
		initialValues: {
			maintenancePlanId: maintenancePlan ? maintenancePlan['id'] : null,
			customerFacingName: maintenancePlan ? maintenancePlan['customerFacingName'] : '',
			synetoInternalName: maintenancePlan ? maintenancePlan['synetoInternalName'] : '',
			serenity: maintenancePlan ? maintenancePlan['serenity'] : '',
			currentlySelling: maintenancePlan ? maintenancePlan['currentlySelling'] : null,
			internalNotes: maintenancePlan ? maintenancePlan['internal_notes'] : '',
			endUserDescription: maintenancePlan ? maintenancePlan['end_user_description'] : ''
		},
		formValues: valuesSelector,
		loggedInUser: state.user ? state.user.user : null,
		updatedSuccessMessage: state.maintenancePlansOptions.maintenancePlanUpdateSuccessMessage,
		updatedFailedMessage: state.maintenancePlansOptions.maintenancePlanUpdateErrorMessage
	};
})(AdminMaintenanceTypesEditModal);

const mapDispatchToProps = (dispatch) => {
	return {
		updatingMaintenancePlan: () => {
			return dispatch(updatingMaintenancePlan());
		},
		updateMaintenancePlan: (values) => {
			return dispatch(updateMaintenancePlan(values));
		}
	};
};

export default connect(null, mapDispatchToProps)(AdminMaintenanceTypesEditModal);
