import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getSerenityMarketingSite = () => {
	return (dispatch) => {
		return axios.get('/settings/serenity/marketing-site')
			.then((response) => {
				dispatch(gettingSerenityMarketingSiteSuccess(response.data));
			})
			.catch((result) => {
				if (!result) {
					dispatch(gettingSerenityMarketingSiteFailure(result.response.data));
				}
			});
	};
};

export const gettingSerenityMarketingSiteSuccess = (data) => {
	return {
		type: actionTypes.GETTING_SERENITY_MARKETING_SITE_SUCCESS,
		payload: data
	};
};

export const gettingSerenityMarketingSiteFailure = (error) => {
	return {
		type: actionTypes.GETTING_SERENITY_MARKETING_SITE_FAILURE,
		payload: error
	};
};

export const updateSerenityEvent = (event) => {
	return (dispatch) => {
		return axios
			.put('/serenity/events', event)
			.then(() => { return dispatch(updateSerenityEventSuccess()); })
			.catch((error) => { return dispatch(updateSerenityEventFailure(error)); });
	};
};

export const updateSerenityEventSuccess = (data) => {
	return {
		type: actionTypes.SERENITY_EVENT_UPDATE_SUCCESS,
		payload: data
	};
};

export const updateSerenityEventFailure = (error) => {
	return {
		type: actionTypes.SERENITY_EVENT_UPDATE_FAILURE,
		payload: error
	};
};

export const loadSerenityEvents = (url = '/serenity/events') => {
	return (dispatch) => {
		dispatch(startLoadingProductSerenityEvents());
		return axios.get(url).then((response) => {
			dispatch(loadingSerenityEventsSuccess(response.data));
		}).catch((error) => {
			dispatch(loadingSerenityEventsFailure(error));
		});
	};
};

const startLoadingProductSerenityEvents = () => {
	return {
		type: actionTypes.START_LOADING_SERENITY_EVENTS
	};
};
const loadingSerenityEventsSuccess = (data) => {
	return {
		type: actionTypes.LOADING_SERENITY_EVENTS_SUCCESS,
		payload: data
	};
};

export const loadingSerenityEventsFailure = (error) => {
	return {
		type: actionTypes.LOADING_SERENITY_EVENTS_FAILURE,
		payload: error
	};
};

export const deleteSerenityEvent = (id) => {
	return (dispatch) => {
		return axios
			.delete(`/serenity/events?id=${id}`)
			.then(() => { return dispatch(deleteSerenityEventSuccess()); })
			.catch((error) => { return dispatch(deleteSerenityEventFailure(error)); });
	};
};

export const deleteSerenityEventSuccess = (data) => {
	return {
		type: actionTypes.SERENITY_EVENT_DELETE_SUCCESS,
		payload: data
	};
};

export const deleteSerenityEventFailure = (error) => {
	return {
		type: actionTypes.SERENITY_EVENT_DELETE_FAILURE,
		payload: error
	};
};
