import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS, GET_PRODUCTS_INACTIVE_QUERY_PARAMS } from '../../constants/queryParams';
import { API_V2, axios, backEndAxios, API_DEFAULT_VERSION } from '../../services/httpClient';
import * as productsService from '../../services/products';
import * as actionTypes from './actionTypes';
import { pushDangerNotification } from '../../data/notifications/actions';
import FileDownload from 'js-file-download';

export const getProducts = (queryParams = GET_PRODUCTS_DEFAULT_QUERY_PARAMS) => {
	return (dispatch) => {
		dispatch(startFetchingProducts());
		return productsService
			.getProducts(queryParams)
			.then(({ data }) => {
				dispatch(
					getProductsSuccess({
						products: data.data ?? [],
						pagination: data.pagination ?? {},
					})
				);

				return data.message;
			})
			.catch((error) => {
				dispatch(getProductsError(error));
			});
	};
};

export const getProductsSupport = (queryParams = GET_PRODUCTS_INACTIVE_QUERY_PARAMS) => {
	const searchParams = new URLSearchParams(queryParams).toString();
	return (dispatch) => {
		dispatch(startFetchingProducts());
		return backEndAxios
			.get(`/centralBackend/${API_V2}/product/getProducts?${searchParams}`)
			.then((result) => {
				return dispatch(
					getProductsSuccess({
						products: result.data.data ?? [],
						pagination: result.data.pagination ?? {},
					})
				);
			})
			.catch((error) => {
				return dispatch(getProductsError(error));
			});
	};
};

export const getProductsBySerialNumber = (serialNumbers = []) => {
	return (dispatch) => {
		dispatch(startFetchingProducts());
		return productsService
			.getProductsBySerialNumber(serialNumbers)
			.then(({ data }) => {
				dispatch(
					getProductsSuccess({
						products: data.data ?? [],
						pagination: data.pagination ?? {},
					})
				);

				return data;
			})
			.catch((error) => {
				dispatch(getProductsError(error));
			});
	};
};

const startFetchingProducts = () => {
	return {
		type: actionTypes.START_FETCHING_PRODUCTS,
	};
};

const getProductsSuccess = (payload) => {
	return {
		type: actionTypes.GET_PRODUCTS_SUCCESS,
		payload,
	};
};

const getProductsError = (error) => {
	return {
		type: actionTypes.GET_PRODUCTS_ERROR,
		payload: error,
	};
};

export const deleteProductForUser = (productId) => {
	return (dispatch) => {
		return axios
			.delete(`/administrator/users/products/${productId}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch(deleteProductForUserSuccess(response.data));
				}
			})
			.catch((error) => {
				dispatch(deleteProductForUserError(error?.response?.data?.message));
				throw new Error(error.message);
			});
	};
};

const deleteProductForUserSuccess = (data) => {
	return {
		type: actionTypes.DELETE_PRODUCT_FOR_USER_SUCCESS,
		payload: data,
	};
};

const deleteProductForUserError = (data) => {
	return {
		type: actionTypes.DELETE_PRODUCT_FOR_USER_ERROR,
		payload: data.message,
	};
};

export const onShowModalAddProduct = () => {
	return { type: actionTypes.SHOW_ADD_PRODUCT_MODAL };
};
export const onHideModalAddProduct = () => {
	return { type: actionTypes.HIDE_ADD_PRODUCT_MODAL };
};

export const getActivationCode = () => {
	return async (dispatch) => {
		return productsService
			.generateActivationCode()
			.then((res) => {
				dispatch(getActivationCodeSuccess(res.data.data.activationKey));
				return res.data.data.activationKey;
			})
			.catch((error) => {
				dispatch(getActivationCodeError(error));
			});
	};
};

const getActivationCodeSuccess = (activationKey) => {
	return {
		type: actionTypes.GET_ACTIVATION_CODE_SUCCESS,
		payload: activationKey,
	};
};

const getActivationCodeError = (error) => {
	return {
		type: actionTypes.GET_ACTIVATION_CODE_ERROR,
		payload: error,
	};
};

export const downloadProductsReport = async () => {
	try {
		const response = await backEndAxios.post(
			`/central-backend/${API_DEFAULT_VERSION}/internal-crud/generate-products-excel-report`,
			{},
			{
				responseType: 'blob',
			}
		);

		let fileName = 'file.xlsx';
		const contentDisposition = response.headers['content-disposition'];
		if (contentDisposition) {
			const fileNameMatch = contentDisposition.match(/filename=(.+)/);
			if (fileNameMatch.length === 2) {
				fileName = fileNameMatch[1];
			}
		}

		FileDownload(response.data, fileName);
	} catch (error) {
		console.error('Error downloading the products excel report file:', error);
		throw error;
	}
};
