import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from '@syneto/compass-react';
import OSVersionParser from '../../../helpers/OSVersionParser';
import { navigateToMenuItem } from '../../../data/actions/navigation';

const ResultItemCard = (props) => {
	const navigate = useNavigate();
	const self = useRef(null);
	let { product, selected, query, navigateToMenuItem } = props;

	useEffect(() => {
		if (selected && self.current) {
			self.current.scrollIntoView({ behavior: 'smooth', });
		}
	}, [selected, self]);

	const styleCard = () => {
		if (!product.activationDate) { return '#f1ecd6'; } //pending
		if (selected) { return '#EBF2FF'; } //selected
	};

	const putBorder = () => {
		if (query === product.serialNumber) { return '1px solid #359ce6'; }
	};

	const goToActivationCode = (e, activationKey) => {
		e.stopPropagation();
		navigate(`/support/vm-activation-codes?activationKey=${activationKey}`);
		// TODO: find better way than setTimeout; this is needed because of the huge data.. 1 solution might be to make the filtering on BE
		setTimeout(() => {
			navigateToMenuItem({
				menu: {
					headerTextIdentifier: 'VM Activation Codes',
					menuIdentifier: 'vmActivationCodes',
					menuItems: []
				}
			});
		}, 400);
	};

	return (
		<div
			ref={self}
		>
			<Card
			
				style={{
					border: putBorder(),
					background: styleCard()
				}}
				content={
					<>
						<div className="pull-left">
							<img src="/assets/img/host-32.svg" width="30" height="30" alt="Product Icon" />
						</div>
						<div className="pull-left ms-3">
							<small className="product-details">
								<ul className="list-group list-unstyled">
									<li className="list-item mb-2">
										<p className="mb-0 font-weight-500"
											style={{ fontSize: '15px' }}>S/N: {product.serialNumber}</p>
									</li>
									<li className="list-item">
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span
												className="font-weight-500 text-black">Model:</span> {product?.model?.title ?? product?.model?.title ?? 'Virtual appliance'} {product?.activatedModel ? `(${product.activatedModel})` : ''}
										</p>
									</li>
									<li className="list-item">
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span
												className="font-weight-500 text-black">SynetoOS version:</span> {product.osVersion ? OSVersionParser.parseOSVersion(product.osVersion) : 'N/A'}
											<span
												className="font-weight-500 text-black ms-3">ESXi version:</span> {product.esxiVersion ? OSVersionParser.parseOSVersion(product.esxiVersion) : 'N/A'}
										</p>
									</li>
									<li className="list-item">
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span
												className="font-weight-500 text-black">Hostname:</span> {product?.name ? product.name : 'N/A'}
										</p>
									</li>

									<li className={`list-item ${product?.activationKey?.productKey ? 'd-block' : 'd-none'}`}>
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span className="font-weight-500 text-black">Activation key: </span>
											<span
												className="text-primary"
												onClick={(e) => { goToActivationCode(e, product?.activationKey?.productKey ? product?.activationKey?.productKey : 'N/A'); }}
												style={{ cursor: 'pointer' }}
											>
												{product?.activationKey?.productKey ? product?.activationKey?.productKey : 'N/A'}
											</span>
										</p>
									</li>

									<li className="list-item">
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span
												className="font-weight-500 text-black">Hardware ID:</span> {product?.hardwareId ? product.hardwareId : 'N/A'}
										</p>
									</li>

									<li className="list-item">
										<p className="mb-0 text-muted" style={{ fontSize: '13px' }}>
											<span
												className="font-weight-500 text-black">RAS GW:</span> {product?.isRasGateway ? 'Yes' : 'No'}
										</p>
									</li>
								</ul>
							</small>
						</div>
					</>
				}
			/>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigateToMenuItem: (menuProperties) => {
			return dispatch(navigateToMenuItem(menuProperties));
		}
	};
};

ResultItemCard.propTypes = {
	product: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(ResultItemCard);
