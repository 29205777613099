import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from '../form';
import { Badge, Icon } from '@syneto/compass-react';

class SerenityField extends Component {
	componentDidUpdate(prevProps) {
		const { maintenanceType, changePropsInParent, name, value } = this.props;

		if (prevProps.maintenanceType !== maintenanceType) {
			const serenityAvailability = this.getSerenityAvailability(maintenanceType);

			switch (serenityAvailability) {
				case 'optional': {
					changePropsInParent(name, value);
					break;
				}
				case 'included': {
					changePropsInParent(name, true);
					break;
				}
				default: {
					changePropsInParent(name, false);
					break;
				}
			}
		}
	}

	getSerenityAvailability(maintenanceType) {
		const { maintenancePlansOptions } = this.props;

		const maintenancePlanOptions = (maintenancePlansOptions && maintenancePlansOptions.maintenancePlansOptions && maintenancePlansOptions.maintenancePlansOptions.allMaintenancePlansOptions);

		const maintenancePlanOption = maintenancePlanOptions.filter((v) => { return v.id === maintenanceType; }).pop();

		return maintenancePlanOption?.serenity ?? null;
	}

	render() {
		const maintenanceType = this.props.maintenanceType;
		let output;

		if (!maintenanceType) {
			return null;
		}

		const serenityAvailability = this.getSerenityAvailability(maintenanceType);

		switch (serenityAvailability) {
			case 'optional': {
				output = <div><Form.checkbox name={this.props.name} id={this.props.name}
					style={{ marginRight: 0 + 'px' }} /></div>;
				break;
			}
			case 'included': {
				output =
					<div>
						<Form.hidden name={this.props.name} value="true" />
						<Badge appearance="success">
							<Icon name="fa fa-check" />
						</Badge>
					</div>;
				break;
			}
			default: {
				output =
					<div>
						<Form.hidden name={this.props.name} value="false" />
						<Badge appearance="danger">
							<Icon name="fa fa-times" />
						</Badge>
					</div>;
				break;
			}
		}

		return output;
	}
}

const mapStateToProps = ((state) => {
	return {
		maintenancePlansOptions: state.maintenancePlansOptions
	};
});

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SerenityField);
