import { SubmissionError } from 'redux-form';
import * as internalUsersService from '../../services/internalUsers';
import { normalizeUserData, processUserData } from '../../helpers/normalize-data';
import ReqBodyValidator from '../../helpers/ReqBodyValidator';
import * as actionTypes from './actionTypes';

const handleUpdateUserSuccess = (dispatch, userData) => {
	const user = normalizeUserData(userData);
	dispatch(updateUserSuccess(user));
};

const handleUpdateUserFailed = (dispatch, userDataError) => {
	if (userDataError?.data?.validationErrors) {
		dispatch(updateUserFailure(new SubmissionError(ReqBodyValidator.parseReqBodyValidationErrorsUser(userDataError.data.validationErrors))));
	} else {
		dispatch(updateUserFailure(userDataError));
	}
};

export const getUserProfile = () => {
	return (dispatch) => {
		return internalUsersService.getInternalUser()
			.then((response) => {
				handleUpdateUserSuccess(dispatch, response.data);
			})
			.catch((result) => {
				handleUpdateUserFailed(dispatch, result.response);
			});
	};
};

export const updateUser = (formFields) => {
	const data = processUserData(formFields);

	return (dispatch) => {
		dispatch(startUserProfileUpdate());
		return internalUsersService.updateInternalUser(data)
			.then((response) => {
				handleUpdateUserSuccess(dispatch, response.data);
			})
			.catch((result) => {
				handleUpdateUserFailed(dispatch, result.response);
			});
	};
};

export const startUserProfileUpdate = () => {
	return {
		type: actionTypes.START_USER_PROFILE_UPDATE,
	};
};

export const updateUserSuccess = (payload) => {
	return {
		type: actionTypes.USER_PROFILE_UPDATE_SUCCESS,
		payload
	};
};

export const updateUserFailure = (payload) => {
	return {
		type: actionTypes.USER_PROFILE_UPDATE_FAILURE,
		payload
	};
};

export const showProfileModal = () => {
	return {
		type: actionTypes.SHOW_PROFILE_MODAL
	};
};

export const hideProfileModal = () => {
	return {
		type: actionTypes.HIDE_PROFILE_MODAL
	};
};

export const showNotificationPreferencesModal = () => {
	return {
		type: actionTypes.SHOW_NOTIFICATION_PREFERENCES_MODAL
	};
};

export const hideNotificationPreferencesModal = () => {
	return {
		type: actionTypes.HIDE_NOTIFICATION_PREFERENCES_MODAL
	};
};
