import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Tooltip, Badge } from '@syneto/compass-react';
import CustomersTableSearchResults from './CustomersTableSearchResults';
import {
	doImpersonate
} from '../../../data/actions/actions';
import { usePrevious } from '../../../../../hooks/usePrevious';

const CustomersTable = (props) => {
	const { query, users, doImpersonate, customersQueryParams, setCustomersQueryParams, pagination } = props;
	const [userId, setUserId] = useState(null);
	const [userData, setUserData] = useState(null);
	const prevQuery = usePrevious(query);

	useEffect(() => {
		if (users.length === 0 || prevQuery !== query) {
			setUserId(null);
			setUserData(null);
		}
	}, [users, query]);

	useEffect(() => {
		setUserId(null);
		setUserData(null);
	}, [customersQueryParams]);

	const handlePagination = (page, sizePerPage) => {
		const start = (page - 1) * sizePerPage;

		setCustomersQueryParams({
			...customersQueryParams,
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleTableChange = (type, { page, sizePerPage, searchText, sortOrder, sortField, data }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
		}
	};
	

	const renderAccountType = (cell, row) => {
		return (
			<>
				{row.isMasterAccount ? <Tooltip text="Master Account" placement="top">
					<Badge appearance="info">MA</Badge>
				</Tooltip> : <Tooltip text="Regular User" placement="top">
					<Badge appearance="info">RU</Badge>
				</Tooltip>}
				{row.isSupportContact && <Tooltip text="Support Contact" placement="top">
					<Badge appearance="info">SC</Badge>
				</Tooltip>}
			</>
		);
	};

	const renderName = (cell, row) => { return `${row.firstName} ${row.lastName}`; };

	const renderActions = (cell, row) => {
		return <Button onClick={() => { return doImpersonate(row.user.email); }}>Impersonate</Button>;
	};

	if (!customersQueryParams) { return null; }

	// if (!users?.length) { return null; }

	return (
		<>
			<Table
				data={users}
				keyField="id"
				remote
				scrollable
				selectable="single"
				onSelect={({ item }) => { setUserId(item.user.id); setUserData(item); }}
				pagination={{
					sizePerPageList: [15, 30, 60, 100, 200],
					sizePerPage: pagination?.itemsPerPage,
					showTotal: true,
					page: pagination?.currentPage,
					totalSize: pagination?.totalItems
				}}
				onTableChange={handleTableChange}
			>
				<Table.Col field="name" renderCell={renderName}>Name</Table.Col>
				<Table.Col field="accountType" renderCell={renderAccountType}>Type</Table.Col>
				<Table.Col field="user.email" sort>Email</Table.Col>
				<Table.Col field="company.email" sort>Company email</Table.Col>
				<Table.Col field="company.name" sort>Company</Table.Col>
				<Table.Col field="company.marketSector" sort>Market sector</Table.Col>
				<Table.Col field="actions" renderCell={renderActions}>Actions</Table.Col>
			</Table>

			{userId && <CustomersTableSearchResults userId={userId} userData={userData} query={customersQueryParams} />}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		pagination: state.customers.data.pagination
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		doImpersonate: (email) => {
			return dispatch(doImpersonate(email));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTable);

CustomersTable.propTypes = {
	users: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
};
