import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from '@syneto/compass-react';
import { deleteMaintenancePlan, deletingMaintenancePlan } from '../../../../data/maintenanceType/action';
import { pushSuccessfulNotification } from '../../../../data/notifications/actions';

function AdminMaintenanceTypeDeleteModal(props) {
	let { submitting, maintenancePlan, showDeleteModal, closeDeleteModal, closeEditModal } = props;

	//custom hook for useDidMount logic
	function useDidMount() {
		const [didMount, setDidMount] = useState(false);
		useEffect(() => { return setDidMount(true); }, []);
		return didMount;
	}

	const didMount = useDidMount();

	useEffect(() => {
		if ( didMount ) {
			props.successMessage && props.pushSuccessfulNotification(props.successMessage);
			props.failedMessage && props.pushDangerNotification(props.failedMessage);
		}
	}, [props.successMessage, props.failedMessage]);

	function deleteMaintenancePlan(maintenancePlanId) {
		return Promise.all([props.deletingMaintenancePlan(), props.deleteMaintenancePlan(maintenancePlanId)]).then(() => {
			closeDeleteModal();
			closeEditModal();
			props.fetchMaintenancePlansReports('all');
		});
	}

	return (
		<Modal show={ showDeleteModal }>
			<Modal.Header>
				<Modal.Title>Warning</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p className="text-value">You are about to delete the following maintenance type: </p>
				<dl className="row">
					<dt className="col-4">Internal name</dt>
					<dd className="col-8">{ maintenancePlan ? maintenancePlan.synetoInternalName : null }</dd>
					<dt className="col-4">External name</dt>
					<dd className="col-8">{ maintenancePlan ? maintenancePlan.customerFacingName : null }</dd>
				</dl>
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" style={ { marginRight: '6px' } } onClick={ closeDeleteModal }>Cancel</Button>
				<Button disabled={ submitting } onClick={ () => { return deleteMaintenancePlan(maintenancePlan.id); } }>Delete</Button>
			</Modal.Footer>
		</Modal>
	);
}

const mapStateToProps = (state) => {
	return {
		successMessage: state.maintenancePlansOptions.maintenancePlanDeleteSuccessMessage,
		failedMessage:  state.maintenancePlansOptions.maintenancePlanDeleteErrorMessage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deletingMaintenancePlan:    () => {
			return dispatch(deletingMaintenancePlan());
		},
		deleteMaintenancePlan:      (maintenancePlanId) => {
			return dispatch(deleteMaintenancePlan(maintenancePlanId));
		},
		pushSuccessfulNotification: (successMessage) => {
			return dispatch(pushSuccessfulNotification(successMessage));
		},
		pushDangerNotification:     (failedMessage) => {
			return dispatch(pushSuccessfulNotification(failedMessage));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMaintenanceTypeDeleteModal);
