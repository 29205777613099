import { API_V2, backEndAxios, axios } from '../../services/httpClient';
import * as customersService from '../../services/customers';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../constants/queryParams';
import * as actionTypes from './actionTypes';

const startGettingCustomers = () => { return { type: actionTypes.GET_CUSTOMERS_LOADING }; };

const getCustomersSuccess = (data) => {
	return {
		type: actionTypes.GET_CUSTOMERS_SUCCESS,
		payload: data
	};
};

const getCustomersError = (error) => {
	return {
		type: actionTypes.GET_CUSTOMERS_ERROR,
		payload: error
	};
};

export const getCustomers = (filterSynetoClients) => {
	return (dispatch) => {
		dispatch(startGettingCustomers());
		return axios.get(`/users${filterSynetoClients ? '?filterSynetoClients=true' : ''}`)
			.then((result) => { 
				return dispatch(getCustomersSuccess(result.data.users)); 
			})
			.catch((error) => { return dispatch(getCustomersError(error)); });
	};
};

export const getCustomersSupport = (queryParams) => {
	const searchParams = new URLSearchParams(queryParams).toString();
	return (dispatch) => {
		dispatch(startGettingCustomers());
		return backEndAxios.get(`/centralBackend/${API_V2}/iam/getCustomers?${searchParams}`)
			.then((result) => { 
				return dispatch(getCustomersSuccess({
					customers: result.data.data ?? [],
					pagination: result.data.pagination ?? {}
				})); 
			})
			.catch((error) => { return dispatch(getCustomersError(error)); });
	};
};
