module.exports = {
	manuals: {
		os4: [
			{
				osVersion:    'v4.0.0',
				title:        'User guide for Syneto OS v4.0.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.0.0.pdf'
			},
			{
				osVersion:    'v4.0.1',
				title:        'User guide for Syneto OS v4.0.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.0.1.pdf'
			},
			{
				osVersion:    'v4.1.0',
				title:        'User guide for Syneto OS v4.1.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.1.0.pdf'
			},
			{
				osVersion:    'v4.1.1',
				title:        'User guide for Syneto OS v4.1.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.1.1.pdf'
			},
			{
				osVersion:    'v4.1.3',
				title:        'User guide for Syneto OS v4.1.3',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.1.3.pdf'
			},
			{
				osVersion:    'v4.2.0',
				title:        'User guide for Syneto OS v4.2.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.2.0.pdf'
			},
			{
				osVersion:    'v4.2.1',
				title:        'User guide for Syneto OS v4.2.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.2.0.pdf'
			},
			{
				osVersion:    'v4.2.2',
				title:        'User guide for Syneto OS v4.2.2',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.2.0.pdf'
			},
			{
				osVersion:    'v4.2.3',
				title:        'User guide for Syneto OS v4.2.3',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.2.0.pdf'
			},
			{
				osVersion:    'v4.3.0',
				title:        'User guide for Syneto OS v4.3.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.3.0.pdf'
			},
			{
				osVersion:    'v4.4.0',
				title:        'User guide for Syneto OS v4.4.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.4.0.pdf'
			},
			{
				osVersion:    'v4.4.2',
				title:        'User guide for Syneto OS v4.4.2',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.4.2.pdf'
			},
			{
				osVersion:    'v4.4.3',
				title:        'User guide for Syneto OS v4.4.3',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.4.3.pdf'
			},
			{
				osVersion:    'v4.4.4',
				title:        'User guide for Syneto OS v4.4.4',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.4.4.pdf'
			},
			{
				osVersion:    'v4.4.6',
				title:        'User guide for Syneto OS v4.4.6',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.4.6.pdf'
			},
			{
				osVersion:    'v4.5.0',
				title:        'User guide for Syneto OS v4.5.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.5.0.pdf'
			},
			{
				osVersion:    'v4.5.1',
				title:        'User guide for Syneto OS v4.5.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.5.1.pdf'
			},
			{
				osVersion:    'v4.6.0',
				title:        'User guide for Syneto OS v4.6.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.0.pdf'
			},
			{
				osVersion:    'v4.6.1',
				title:        'User guide for Syneto OS v4.6.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.1.pdf'
			},
			{
				osVersion:    'v4.6.2',
				title:        'User guide for Syneto OS v4.6.2',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.2.pdf'
			},
			{
				osVersion:    'v4.6.3',
				title:        'User guide for Syneto OS v4.6.3',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.3.pdf'
			},
			{
				osVersion:    'v4.6.4',
				title:        'User guide for Syneto OS v4.6.4',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.4.pdf'
			},
			{
				osVersion:    'v4.6.5',
				title:        'User guide for Syneto OS v4.6.5',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.6.5.pdf'
			},
			{
				osVersion:    'v4.7.0',
				title:        'User guide for Syneto OS v4.7.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.0.pdf'
			},
			{
				osVersion:    'v4.7.1',
				title:        'User guide for Syneto OS v4.7.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.1.pdf'
			},
			{
				osVersion:    'v4.7.2',
				title:        'User guide for Syneto OS v4.7.2',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.2.pdf'
			},
			{
				osVersion:    'v4.7.3',
				title:        'User guide for Syneto OS v4.7.3',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.3.pdf'
			},
			{
				osVersion:    'v4.7.4',
				title:        'User guide for Syneto OS v4.7.4',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.4.pdf'
			},
			{
				osVersion:    'v4.7.5',
				title:        'User guide for Syneto OS v4.7.5',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.5.pdf'
			},
			{
				osVersion:    'v4.7.6',
				title:        'User guide for Syneto OS v4.7.6',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.6.pdf'
			},
			{
				osVersion:    'v4.7.7',
				title:        'User guide for Syneto OS v4.7.7',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.7.7.pdf'
			},
			{
				osVersion:    'v4.8.1',
				title:        'User guide for Syneto OS v4.8.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.8.1.pdf'
			},
			{
				osVersion:    'v4.8.4',
				title:        'User guide for Syneto OS v4.8.4',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-4.8.4.pdf'
			}
		],
		os5: [
			{
				osVersion:    'v5.0.0',
				title:        'User guide for Syneto OS v5.0.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-5.0.0.pdf'
			},
			{
				osVersion:    'v5.1.0',
				title:        'User guide for Syneto OS v5.1.0',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-5.1.0.pdf'
			},
			{
				osVersion:    'v5.1.1',
				title:        'User guide for Syneto OS v5.1.1',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-5.1.1.pdf'
			},
			{
				osVersion:    'v5.1.2',
				title:        'User guide for Syneto OS v5.1.2',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-5.1.2.pdf'
			},
			{
				osVersion:    'v5.1.4',
				title:        'User guide for Syneto OS v5.1.4',
				downloadLink: 'https://storage.googleapis.com/central-manuals/user-guide-syneto-os-5.1.4.pdf'
			}
		]
	}
};
