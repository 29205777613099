import React from 'react';
import { Alert, Icon } from '@syneto/compass-react';

class UserRenderer {
	static renderNoUsersFoundSupport() {
		return <Alert variant={'primary'}>No users found matching your search criteria.</Alert>;
	}

	static renderNoUsersFoundMarketing() {
		return <Alert variant={'primary'}>No users found.</Alert>;
	}

	static renderNoUsersFoundAdmin() {
		return (
			<div className="row w-50">
				<div className="col-auto"><Icon name="fa fa-question question-circle-icon"/></div>
				<div className="col">
					<h4>No users found.</h4>
					<dd>There are no users to match your search criteria, please have a look then try again.</dd>
				</div>
			</div>
		);
	}

	static renderUserDetailsModalSupport(user) {
		if ( !user ) {
			return <p>Please wait...</p>;
		}

		return <div className="mt-4 ms-3">
			<dl className="row">
				<dt className="col-3">Name</dt>
				<dd className="col-9">{ user.firstName } { user.lastName }</dd>

				<dt className="col-3">Email</dt>
				<dd className="col-9">{ user.email }</dd>

				<dt className="col-3">Company email</dt>
				<dd className="col-9">{ user.company.email }</dd>

				<dt className="col-3">Phone number</dt>
				<dd className="col-9">{ user.phoneNumber }</dd>

				<dt className="col-3">Company</dt>
				<dd className="col-9">{ user.company.name ? user.company.name : 'N/A' }</dd>

				<dt className="col-3">Market sector</dt>
				<dd className="col-9">{ user.company.marketSector ? user.company.marketSector : 'N/A' }</dd>

				<dt className="col-3">City</dt>
				<dd className="col-9">{ user.company.city ? user.company.city : 'N/A' }</dd>

				<dt className="col-3">Country</dt>
				<dd className="col-9">{ user.company.country ? user.company.country : 'N/A' }</dd>

				<dt className="col-3">Address</dt>
				<dd className="col-9">{ user.company.address ? user.company.address : 'N/A' }</dd>

				<dt className="col-3">Number of employees</dt>
				<dd className="col-9">{ user.company.numberOfEmployees ? user.company.numberOfEmployees : 'N/A' }</dd>
			</dl>
		</div>;
	}

	static renderCompanyDetailsModalMarketing(company) {
		if ( !company ) {
			return <p>Please wait...</p>;
		}

		return (
			<div>
				<div className="d-flex">
					<dt className="col-3">Company</dt>
					<dd className="col-9">{ company.name ? company.name : 'N/A' }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">Company email</dt>
					<dd className="col-9">{ company.email }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">Market sector</dt>
					<dd className="col-9">{ company.marketSector ? company.marketSector : 'N/A' }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">City</dt>
					<dd className="col-9">{ company.city ? company.city : 'N/A' }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">Country</dt>
					<dd className="col-9">{ company.country ? company.country : 'N/A' }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">Address</dt>
					<dd className="col-9">{ company.address ? company.address : 'N/A' }</dd>
				</div>

				<div className="d-flex">
					<dt className="col-3">Number of employees</dt>
					<dd className="col-9">{ company.numberOfEmployees ? company.numberOfEmployees : 'N/A' }</dd>
				</div>

				<hr />

				{company.users.map((user) => {
					return (
						<div key={user.id} style={{ marginBottom: '15px' }}>
							<div className="d-flex">
								<dt className="col-3">Name</dt>
								<dd className="col-9">{ user.firstName } { user.lastName }</dd>
							</div>
							<div className="d-flex">
								<dt className="col-3">Email</dt>
								<dd className="col-9">{ user.email }</dd>
							</div>
							<div className="d-flex">
								<dt className="col-3">Phone</dt>
								<dd className="col-9">{ user.phoneNumber }</dd>
							</div>
							<div className="d-flex">
								<dt className="col-3">Product model(s)</dt>
								<dd className="col-9">
									<dl className="dl-horizontal">
										<ul className="list-unstyled">
											{ UserRenderer.renderProductModels(user.products) }
										</ul>
									</dl>
								</dd>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	static renderUserDetailsModalMarketingReports(user) {
		if ( !user ) {
			return <p>Please wait...</p>;
		}

		return <div className="mt-2 ms-1">
			<dl className="row">
				<dt className="col-3">Name</dt>
				<dd className="col-9">{ user.firstName } { user.lastName }</dd>

				<dt className="col-3">Email</dt>
				<dd className="col-9">{ user.email }</dd>

				<dt className="col-3">Phone number</dt>
				<dd className="col-9">{ user.phoneNumber }</dd>

				<dt className="col-3">Company</dt>
				<dd className="col-9">{ user && user.company && user.company.name ? user.company.name : 'N/A' }</dd>

				<dt className="col-3">Market sector</dt>
				<dd
					className="col-9">{ user && user.company && user.company.marketSector ? user.company.marketSector : 'N/A' }</dd>

				<dt className="col-3">City</dt>
				<dd className="col-9">{ user && user.company && user.company.city ? user.company.city : 'N/A' }</dd>

				<dt className="col-3">Country</dt>
				<dd className="col-9">{ user && user.company && user.company.country ? user.company.country : 'N/A' }</dd>

				<dt className="col-3">Address</dt>
				<dd className="col-9">{ user && user.company && user.company.address ? user.company.address : 'N/A' }</dd>
			</dl>
		</div>;
	}

	static renderProductModels(products) {
		if ( !products || !products.length ) {
			return <p>User has no products activated yet.</p>;
		}

		let productCount = _.countBy(products, 'product_model[title]');

		return Object.keys(productCount).map((key) => {
			return (
				<dd key={ key }>{ productCount[ key ] } x { key }</dd>
			);
		});
	}
}

export default UserRenderer;
