import React from 'react';
import { Tabs } from '@syneto/compass-react';
import { manuals } from '../../../../constants/os-manuals-links';
import AdminManualsTable from './adminManualsTable';

const AdminManuals = () => {
	return (
		<div id="manuals" className="p-4 bg-white">
			<Tabs>
				<Tabs.Tab eventKey="os4" title="OS4 Manuals">
					<AdminManualsTable manuals={manuals.os4}/>
				</Tabs.Tab>
				<Tabs.Tab eventKey="os5" title="OS5 Manuals">
					<AdminManualsTable manuals={manuals.os5}/>
				</Tabs.Tab>
			</Tabs>
		</div>
	);
};

export default AdminManuals;
