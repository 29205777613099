import * as actionTypes from '../actionTypes';

export default (state = [], action) => {
	let key = state.length;
	switch (action.type) {
		case actionTypes.PUSH_SUCCESSFUL:
			return [
				...state,
				{
					key,
					[key]:
					{
						text: action.text,
						type: 'success',
					}
				}
			];
		case actionTypes.PUSH_WARNING:
			return [
				...state,
				{
					key,
					[key]:
					{
						text: action.text,
						type: 'warning',
					}
				}
			];
		case actionTypes.PUSH_DANGER:
			return [
				...state,
				{
					key,
					[key]:
					{
						text: action.text,
						type: 'danger',
					}
				}
			];
		case actionTypes.REMOVE_NOTIFICATION: {
			let removeIndex;
			state.map((item, index) => {
				if (item.key === action.key) {
					removeIndex = index;
				}
			});
			return [
				...state.slice(0, removeIndex), ...state.slice(removeIndex + 1)
			];
		}
		default:
			return state;
	}
};
