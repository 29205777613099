import React from 'react';
import { Icon, Tooltip } from '@syneto/compass-react';
import { maintenanceKeyPdfTemplate } from './MaintenancePlanPdfStyles';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

export const MaintenancePlanPdf = (props) => {
	const { expirationDate, maintenancePlan, serialNumber } = props;

	return (
		<a
			className="ms-2"
			onClick={ (e) => {
				const docDefinition = maintenanceKeyPdfTemplate(expirationDate, maintenancePlan, serialNumber);
				const { vfs } = vfsFonts.pdfMake;

				pdfMake.vfs = vfs;
				pdfMake.createPdf(docDefinition).download(`${ new Date().valueOf() }.pdf`);
				e.stopPropagation();
			} }
			style={ { cursor: 'pointer' } }
		>
			<Tooltip text="Maintenance Plan Extension" placement="top">
				<span>
					<Icon name="fa fa-file-pdf" aria-hidden="true"/>
				</span>
			</Tooltip>
		</a>
	);
};
