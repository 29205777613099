import { API_V2, axios, backEndAxios } from './httpClient';

export const createProduct = (body) => {
	const payload = {
		product: {
			...body,
			// activationDate: moment(body.activationDate, 'DD/MM/YYYY').toISOString()
			activationDate: moment(body.activationDate, 'DD/MM/YYYY').add(moment().utcOffset(), 'minutes').toISOString()
		}
	};
	return backEndAxios.post(`/centralBackend/${API_V2}/product/createProduct`, payload);
};

export const getProducts = (queryParams) => {
	const searchParams = new URLSearchParams(queryParams).toString();
	return backEndAxios.get(`/centralBackend/${API_V2}/product/getProducts?${searchParams}`);
};

export const getProductsBySerialNumber = (listOfSerialNumbers) => {
	const data = {
		serialNumbers: listOfSerialNumbers
	};
	return backEndAxios.post(`/centralBackend/${API_V2}/product/getProductsBySerialNumber`, data);
};

export const updateProduct = (body) => {
	const payload = {
		product: {
			...body,
			// activationDate: moment(body.activationDate, 'DD/MM/YYYY').toISOString()
			activationDate: moment(body.activationDate, 'DD/MM/YYYY').add(moment().utcOffset(), 'minutes').toISOString()
		}
	};
	return backEndAxios.put(`/centralBackend/${API_V2}/product/updateProduct`, payload);
};

export const deleteProducts = (products) => {
	return backEndAxios.delete(`/centralBackend/${API_V2}/product/deleteProducts`, { data: products });
};

export const generateActivationCode = () => {
	return axios.get('/products/activation-keys/generate');
};
