import React, { useEffect } from 'react';
import { Button, Modal } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { unflatten } from 'flat';
import Form from '../../forms/form';
import Validator from '../../forms/validate';
import formFields from '../../../constants/formFields';
import { updateUser } from '../../../data/profile/action';
import { hideResetPasswordModal } from '../../../modules/Password-Reset/data/actions/actions';
import {
	pushSuccessfulNotification,
	pushDangerNotification
} from '../../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'account.password': ['required', 'min: 6'],
			'account.passwordCheck': ['required', 'same:account.password']
		},
		{
			'same.account.passwordCheck': 'Passwords do not match'
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let NavProfilePasswordModal = (props) => {
	const { handleSubmit, submitting, showPasswordResetModal, user } = props;

	useEffect(() => {
		user.status === 'changed' && props.dispatch(pushSuccessfulNotification('Your password has been changed.'));
		user.status === 'failed' && props.dispatch(pushDangerNotification(user.error.message));
	}, [user]);

	function submit(values) {
		return props.updateUserPassword({ ...user.user, ...values }).then(() => {
			props.hideResetPasswordModal();
		}).catch((error) => {
			props.dispatch(pushDangerNotification(`Password could not be changed. Reason: ${error}`));
		});
	}

	function hideResetPasswordModal() {
		props.reset('password');
		return props.hideResetPasswordModal();
	}

	return (
		<Modal show={showPasswordResetModal}>
			<Modal.Header>
				<Modal.Title>Change password</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form.password name={formFields.account.password} label="New password" autoFocus />
				<Form.password name={formFields.account.passwordCheck} label="Re-type new password" />
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" style={{ marginRight: '6px' }}
					onClick={() => { return hideResetPasswordModal(); }}>Cancel</Button>
				<Button disabled={submitting} onClick={handleSubmit(submit)}>Change</Button>
			</Modal.Footer>
		</Modal>
	);
};

NavProfilePasswordModal = reduxForm({
	form: 'password',
	validate
})(NavProfilePasswordModal);

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserPassword: (userData) => {
			return dispatch(updateUser(userData));
		},
		hideResetPasswordModal: () => {
			return dispatch(hideResetPasswordModal());
		}
	};
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		showPasswordResetModal: state.passwordReset.showPasswordResetModal
	};
};

NavProfilePasswordModal.propTypes = {
	user: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func,
	submitting: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfilePasswordModal);
