import * as customersService from '../../services/customers';
import * as actionTypes from './actionTypes';
import { convertObjectKeysFromSnakeCaseToCamelCase } from '../../helpers/normalize-data';

export const getCustomerDetails = (userId) => {
	return (dispatch) => {
		dispatch(gettingUserDetails());
		return customersService.getCustomerDetails(userId)
			.then((response) => {
				if (response.status === 200) {
					const userData = convertObjectKeysFromSnakeCaseToCamelCase(response.data);
					dispatch(getUserDetailsSuccess(userData));
				}
			}).catch((error) => {
				dispatch(getUserDetailsError(error.response.data));
			});
	};
};

export const gettingUserDetails = () => {
	return {
		type: actionTypes.GETTING_USER_DETAILS
	};
};

export const getUserDetailsSuccess = (data) => {
	return {
		type: actionTypes.GET_USER_DETAILS_SUCCESS,
		payload: data.customerDetails
	};
};

export const getUserDetailsError = (error) => {
	return {
		type: actionTypes.GET_USER_DETAILS_ERROR,
		payload: error
	};
};

export const resetUserDetailsState = () => {
	return {
		type: actionTypes.RESET_USER_DETAILS_STATE,
		payload: null
	};
};

export const getCustomerMonitoringDetails = (companyId) => {
	return (dispatch) => {
		dispatch(getMonitoringDetails());
		return customersService.getCustomerGrafanaData(companyId)
			.then((response) => {
				dispatch(getMonitoringDetailsSuccess(response.data));
			})
			.catch((error) => {	
				dispatch(getMonitoringDetailsError(error));
			});
	};
};

export const getMonitoringDetails = () => {
	return {
		type: actionTypes.GETTING_MONITORING_DETAILS
	};
};

export const getMonitoringDetailsSuccess = (monitoringDetails) => {
	return {
		type: actionTypes.GET_MONITORING_SUCCESS,
		payload: monitoringDetails
	};
};

export const getMonitoringDetailsError = () => {
	return {
		type: actionTypes.GET_MONITORING_ERROR
	};
};
