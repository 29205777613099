import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Utils from '../../../../../helpers/Utils';
import Form from '../../../../../components/forms/form';

const STATE_STATUS_FILTER = [
	{ label: 'New', value: 'new' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Resolved', value: 'resolved' },
	{ label: 'Closed', value: 'closed' },
	{ label: 'Ignored', value: 'ignored' },
];

const TIME_FILTER = [
	{ label: 'Last 24 hours', value: 1 },
	{ label: 'Last 7 days', value: 7 },
	{ label: 'Last 30 days', value: 30 },
	{ label: 'Last 3 months', value: 60 },
	{ label: 'Last 6 months', value: 180 },
	{ label: 'Last year', value: 360 },
];

let SupportSerenityEventsTableFilters = ({ fetchSerenityEvents, minimal }) => {	
	const renderCheckboxFilter = () => {
		return (
			<Form.checkbox
				label="Show ignored events"
				name="showIgnoredEvents"
				onValueChange={(value) => { return fetchSerenityEvents({ showIgnored: value }); }}
			/>
		);
	};

	const renderSelectFilter = (filter, optionsArray) => {
		const options = [{ label: 'All', value: '' }, ...optionsArray];
		return (
			<Form.select
				label={Utils.toUppercase(filter)}
				name={filter}
				options={options}
				onValueChange={(value) => { return fetchSerenityEvents( { [filter]: value } ); }}
				noMarginBottom
			/>
		);
	};

	return minimal
		? (
			<>
				{renderCheckboxFilter()}
			</>
		)
		: (
			<div className='d-flex flex-grow-1 align-items-center gap-3'>
				{renderSelectFilter('status', STATE_STATUS_FILTER)}
				{renderSelectFilter('time', TIME_FILTER)}
			</div>
		);
};

SupportSerenityEventsTableFilters = reduxForm({ form: 'SupportSerenityEventsTableFilters', enableReinitialize: true })(SupportSerenityEventsTableFilters);

const mapStateToProps = (state, ownProps) => {
	return {
		initialValues: {
			showIgnoredEvents: ownProps?.queryParams?.showIgnoredEvents || true,
			status: ownProps?.queryParams?.status || '',
			time: ownProps?.queryParams?.time || ''
		}
	};
};

export default connect(mapStateToProps, null)(SupportSerenityEventsTableFilters);

SupportSerenityEventsTableFilters.propTypes = {
	minimal: PropTypes.bool.isRequired,
	fetchSerenityEvents: PropTypes.func.isRequired,
	queryParams: PropTypes.object.isRequired
};
