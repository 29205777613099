import { processErrorMessage } from '../../helpers/normalize-data';
import * as actionTypes from './actionTypes';

const MFA_CONFIG_INIT_DATA = {
	isConfigured:  false,
	isEnabled: false,
	isEnforced: false,
	secret: null,
	qrCode: null,
	recoveryCodes: []
};

const INITIAL_STATE = {
	email: null,
	status: 'unauthenticated',
	token: null,
	error: null,
	mfaData: MFA_CONFIG_INIT_DATA
};

export default (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case actionTypes.UNAUTHENTICATE:
			return {
				...INITIAL_STATE,
				status: 'unauthenticated',
			};
		case actionTypes.START_AUTHENTICATION:
			return {
				...state,
				status: 'pending',
				error: null,
				...( payload ? { email: payload } : {} )
			};
		case actionTypes.AUTHENTICATION_SUCCESS:
			return {
				...state,
				status:  'authenticated',
				token: payload ?? '',
				email: null
			};
		case actionTypes.AUTHENTICATION_FAILURE:
			return {
				...state,
				status: 'failed',
				error: processErrorMessage(payload)
			};
		case actionTypes.START_GET_MFA_CONFIG_DATA:
			return {
				...state,
				mfaData: MFA_CONFIG_INIT_DATA,
				error: null
			};
		case actionTypes.SET_MFA_CONFIG_DATA:
		case actionTypes.GET_MFA_CONFIG_DATA_SUCCESS:
			return {
				...state,
				mfaData: {
					...state.mfaData,
					...payload
				}
			};
		case actionTypes.GET_MFA_CONFIG_DATA_ERROR:
			return {
				...state,
				mfaData: MFA_CONFIG_INIT_DATA
			};
		default:
			return state;
	}
};
