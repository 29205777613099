import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Utils from '../../../../../helpers/Utils';
import Form from '../../../../../components/forms/form';
import { connect } from 'react-redux';

const SEVERITY_FILTERS = [
	{ label: 'Critical', value: 'critical' },
	{ label: 'Warning', value: 'warning' },
	{ label: 'Info', value: 'info' },
];

const TIME_FILTERS = [
	{ label: 'Last 24 hours', value: 1 },
	{ label: 'Last 7 days', value: 7 },
	{ label: 'Last 30 days', value: 30 },
	{ label: 'Last 3 months', value: 60 },
	{ label: 'Last 6 months', value: 180 },
	{ label: 'Last year', value: 360 },
];

let SupportSerenityNewEventsTableFilters = ({ fetchSerenityEvents }) => {
	const renderSelectFilter = (filter, filters) => {
		const options = [{ label: 'All', value: '' }, ...filters];
		return <Form.select
			label={Utils.toUppercase(filter)}
			onValueChange={(value) => { return fetchSerenityEvents({ [filter]: value }); }}
			options={options}
			noMarginBottom
		/>;
	};

	const renderCheckboxFilter = (filter) => {
		return <Form.checkbox
			id={filter}
			name={filter}
			label={Utils.toUppercase(filter)}
			onValueChange={(value) => { return fetchSerenityEvents({ [filter]: value }); }}
			noMarginBottom
		/>;
	};

	return (
		<div className='d-flex flex-grow-1 align-items-center gap-3'>
			{renderSelectFilter('severity', SEVERITY_FILTERS)}
			{renderSelectFilter('time', TIME_FILTERS)}
			{renderCheckboxFilter('favorite')}
		</div>		
	);
};

SupportSerenityNewEventsTableFilters = reduxForm({ form: 'SupportSerenityNewEventsTableFilters', enableReinitialize: true })(SupportSerenityNewEventsTableFilters);

const mapStateToProps = (state, ownProps) => {
	return {
		initialValues: {
			severity:  ownProps?.queryParams?.severity || '',
			time:  ownProps?.queryParams?.time || '',
			favorite:  ownProps?.queryParams?.favorite || false
		}
	};
};

export default connect(mapStateToProps, null)(SupportSerenityNewEventsTableFilters);

SupportSerenityNewEventsTableFilters.propTypes = {
	fetchSerenityEvents: PropTypes.func.isRequired,
	queryParams: PropTypes.object.isRequired
};
