import React, { useState } from 'react';
import { axios } from '../../services/httpClient';
import { Button, Icon, Modal } from '@syneto/compass-react';
import FileDownload from 'js-file-download';
import { pushDangerNotification } from '../../data/notifications/actions';

const PostInstallCheckLogsModal = ({ show, onHide, postInstallData, userRole }) => {
	const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

	const downloadPDF = () => {
		setIsDownloadingPDF(true);

		axios.get(`/${userRole}/product/assembly-test/${postInstallData.serialNumber}/output`, {
			responseType: 'blob'
		})
			.then((response) => {
				FileDownload(response.data, `${postInstallData.serialNumber}-logs.pdf`);
			}).catch((error) => {
				pushDangerNotification(`Could not download Assembly Test Results Logs PDF. Reason: ${error.message}`);
			}).finally(() => {
				setIsDownloadingPDF(false);
			});
	};

	return (
		<Modal
			enforceFocus={false}
			show={show}
			onHide={onHide}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>Assembly Test Results Logs</Modal.Title>
				<Button className="post-install-check-logs-download-btn" role="tertiary" type="submit" onClick={downloadPDF} disabled={isDownloadingPDF}>
					{isDownloadingPDF
						? (
							<Icon className="me-2" name="fa fa-circle-notch fa-spin" color="#4f5ed7" />
						)
						: (
							<>
								<Icon className="me-2" name="fa fa-file-pdf" color="#4f5ed7" /> Download PDF
							</>
						)
					}
				</Button>
			</Modal.Header>

			<Modal.Body
				className="post-install-check-logs"
			>
				<pre className='form-control scrollable-content'>
					{postInstallData.postInstallCheckOutput}
				</pre>
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PostInstallCheckLogsModal;
