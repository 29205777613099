export const languageVars = {
	en: {
		criteriaList: {
			1: [
				{ name: 'scores.shippingSpeed', label: 'Shipping Speed', checked: 0 },
				{ name: 'scores.packaging', label: 'Packaging', checked: 0 },
				{ name: 'scores.documentation', label: 'Documentation', checked: 0 },
				{ name: 'scores.easeOfInstallation', label: 'Ease of installation', checked: 0 },
				{ name: 'scores.setOfFeatures', label: 'Set of features', checked: 0 },
			],
			2: [
				{ name: 'scores.systemStability', label: 'System stability', checked: 0 },
				{ name: 'scores.setOfFeatures', label: 'Set of features', checked: 0 },
				{ name: 'scores.usability', label: 'Usability', checked: 0 },
				{ name: 'scores.synetoSupport', label: 'Syneto support', checked: 0 },
			],
		},
		initialValues: {
			scores: {
				1: {
					shippingSpeed: 0,
					packaging: 0,
					documentation: 0,
					easeOfInstallation: 0,
					setOfFeatures: 0,
				},
				2: {
					systemStability: 0,
					setOfFeatures: 0,
					usability: 0,
					synetoSupport: 0,
				},
			},
		},
	},
	it: {
		criteriaList: {
			1: [
				{ name: 'scores.shippingSpeed', label: 'Velocità nella consegna', checked: 0 },
				{ name: 'scores.packaging', label: 'Imballaggio', checked: 0 },
				{ name: 'scores.documentation', label: 'Documentazione', checked: 0 },
				{ name: 'scores.easeOfInstallation', label: 'Facilità di installazione', checked: 0 },
				{ name: 'scores.setOfFeatures', label: 'Funzionalità', checked: 0 },
			],
			2: [
				{ name: 'scores.systemStability', label: 'Stabilità del sistema', checked: 0 },
				{ name: 'scores.setOfFeatures', label: 'Funzionalità', checked: 0 },
				{ name: 'scores.usability', label: 'Usabilità', checked: 0 },
				{ name: 'scores.synetoSupport', label: 'Supporto Syneto', checked: 0 },
			],
		},
		initialValues: {
			scores: {
				1: {
					velocitaNellaConsegna: 0,
					imballaggio: 0,
					documentazione: 0,
					facilitaDiInstallazione: 0,
					facilitaDiUtilizzo: 0,
					funzionalita: 0,
				},
				2: {
					stabilitaDelSistema: 0,
					funzionalita: 0,
					usabilita: 0,
					supportoSyneto: 0,
				},
			},
		},
	},
};

export const translations = {
	'en': {
		'likelihoodToRecommend': {
			1: 'Based on your experience setting it up, how likely are you to recommend it to someone else?',
			2: 'After running Syneto in production, how likely are you to recommend it to someone else?',
		},
		'moreInsight': 'Thank you for sharing your opinion. If you have the time, we would appreciate a bit more insight in your rating.\n',
		'dissatisfied': 'Dissatisfied',
		'somewhatDissatisfied': 'Somewhat dissatisfied',
		'neutral': 'Neutral',
		'somewhatSatisfied': 'Somewhat satisfied',
		'satisfied': 'Satisfied',
		'otherComments': 'Any other comments',
		'submit': 'Submit',
		'thankyou': 'Thank you for your answer.',
		'alreadysubmitted': 'You have already submitted your answer.',
		'notLikely': 'Not likely',
		'veryLikely': 'Very likely',
	},
	'it': {
		'likelihoodToRecommend': {
			1: 'In base alla tua esperienza nel corso dell\'installazione, con quale probabilità consiglieresti il prodotto ad altri?',
			2: 'Siamo molto interessati della tua soddisfazione col prodotto. Con quale probabilità consiglieresti Syneto HYPERSeries ad altri?',
		},
		'moreInsight': 'Grazie per aver condiviso la tua opinione. Ci farebbe piacere qualcosa in più sulla tua valutazione al fine di poterti garantire un servizio sempre migliore.',
		'dissatisfied': 'Molto insodisfatto',
		'somewhatDissatisfied': 'Insodisfatto',
		'neutral': 'Poco Soddisfatto',
		'somewhatSatisfied': 'Soddisfatto',
		'satisfied': 'Molto Soddisfatto',
		'otherComments': 'Qualsiasi altro commento',
		'submit': 'Inviare',
		'thankyou': 'La ringraziamo per la sua risposta.',
		'alreadysubmitted': 'Hai già inviato la tua risposta.',
		'notLikely': 'Improbabile',
		'veryLikely': 'Molto probabile',
	},
};
