import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	error: null,
	loading: true,
	activationKeys: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.START_INCREASE_ACTIVATIONS:
			return {
				...state,
				error: null
			};
		case actionTypes.GET_VM_ACTIVATION_CODES_SUCCESS:
			return {
				...state,
				loading: false,
				activationKeys: action.payload
			};
		case actionTypes.GET_VM_ACTIVATION_CODES_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case actionTypes.INCREASE_ACTIVATIONS_SUCCESS:
			return {
				...state,
				loading: false
			};
		case actionTypes.INCREASE_ACTIVATIONS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

