class DateFormatterService {
	static humanizeMonths(noOfMonths) {
		const momentMonth = moment.duration(noOfMonths, 'months');
		const years = momentMonth.years();
		const months = momentMonth.months();

		return `${years > 0 ? `${years}yr` : ''}${months > 0 ? `${months}mo` : ''}`;
	}

	static formatDateToSpecificFormat(objectWithDateProp, dateProperty, dateFormat) {
		return objectWithDateProp[dateProperty] && dateFormat  
			? moment(objectWithDateProp[ dateProperty ]).format(dateFormat)
			: null;
	}
}

export default DateFormatterService;
