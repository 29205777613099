export const START_LOADING_SERENITY_EVENTS = 'START_LOADING_SERENITY_EVENTS';
export const LOADING_SERENITY_EVENTS_SUCCESS = 'LOADING_SERENITY_EVENTS_SUCCESS';
export const LOADING_SERENITY_EVENTS_FAILURE = 'LOADING_SERENITY_EVENTS_FAILURE';
export const GETTING_SERENITY_MARKETING_SITE_SUCCESS = 'GETTING_SERENITY_MARKETING_SITE_SUCCESS';
export const GETTING_SERENITY_MARKETING_SITE_FAILURE = 'GETTING_SERENITY_MARKETING_SITE_FAILURE';

export const SERENITY_EVENT_UPDATE_SUCCESS = 'SERENITY_EVENT_UPDATE_SUCCESS';
export const SERENITY_EVENT_UPDATE_FAILURE = 'SERENITY_EVENT_UPDATE_FAILURE';

export const SERENITY_EVENT_DELETE_SUCCESS = 'SERENITY_EVENT_DELETE_SUCCESS';
export const SERENITY_EVENT_DELETE_FAILURE = 'SERENITY_EVENT_DELETE_FAILURE';
