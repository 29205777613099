import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MenuItem = ({ item, activeMenu, navigateToMenuItem }) => {
	const [isActive, setIsActive] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		setIsActive(activeMenu === item.IDENTIFIER);
	}, [activeMenu]);

	useEffect(() => {
		if (isActive) {
			navigateToItem();
		}
	}, [isActive]);

	const navigateToItem = () => {
		navigate(item.PATH);
		navigateToMenuItem({
			menu: {
				headerTextIdentifier: item.LABEL,
				menuIdentifier: item.IDENTIFIER,
				menuItems: item.ITEMS
			}
		});
	};

	return (
		<li 
			className={ `list-group-item menu-item-product-models rounded-0 qa-${item.IDENTIFIER}  ${isActive ? 'active-menu-item' : ''}`}
			onClick={navigateToItem}
		>
			{item.LABEL}
		</li>
	);
};

export default MenuItem;
