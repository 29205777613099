import { actionTypes } from '../actionTypes';

export const navigateToMenuItem = (menuProperties) => {
	const { headerTextIdentifier, menuIdentifier, menuItems } = menuProperties.menu;

	return {
		type: actionTypes.navigation.admin.clicked,
		payload: {
			headerTextIdentifier,
			menuItems,
			menuIdentifier
		}
	};
};
