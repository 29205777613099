import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import Validator from 'validatorjs';
import { unflatten } from 'flat';
import { Button, Modal } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import { usePrevious } from '../../hooks/usePrevious';
import formFields from '../../constants/formFields';
import Form from '../forms/form';
import UserDetailsForm from './UserDetailsForm';
import MFAConfirmationModal from './MfaConfirmationModal';
import { changeMfaStatus, unauthenticateUser } from '../../data/authenticate/action';

const FORM_NAME = 'userEditModal';

const editUserValidationSchema = {
	'account.email': ['required', 'email'],
	'contact.firstName': ['required'],
	'contact.lastName': ['required'],
	'contact.roleInCompany': ['required'],
	'contact.phoneNumber': ['required', 'regex:/^\\+{0,1}\\d*$/'],
};

const addUserValidationSchema = {

	'account.email': ['required', 'email'],
	'account.password': ['required', 'min: 6'],
	'account.passwordCheck': ['required', 'same:account.password'],
	'contact.firstName': ['required'],
	'contact.lastName': ['required'],
	'contact.phoneNumber': ['required', 'regex:/^\\+{0,1}\\d*$/'],
};

const UserDetailModalForm = (props) => {
	const { isAdmin, modalTitle, isUserEdit, isEditLoggedUser, closeModal, modalAction, openDeleteUserModal, handleSubmit, initialValues, actionStatus, handleModalActionSuccess, handleModalActioError, changeFormValue, changeMfaStatus, unauthenticateUser } = props;
	const [isEditMode, setIsEditMode] = useState(!!isUserEdit);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [mfaStatus, setMfaStatus] = useState(initialValues.mfa.isEnabled);
	const userUpdatePrevStatus = usePrevious(actionStatus);

	useEffect(() => {
		if (!mfaStatus) {
			changeFormValue(formFields.mfaEnabled, false);
		}
	}, [mfaStatus]);

	useEffect(() => {
		if (initialValues.mfa.isEnabled !== mfaStatus) {
			setOpenConfirmationModal(!openConfirmationModal);
		}
	}, [initialValues.mfa.isEnabled, mfaStatus]);

	useEffect(() => {
		setIsEditMode(!!isUserEdit);
	}, [isUserEdit]);

	useEffect(() => {
		if (userUpdatePrevStatus === 'panding' && actionStatus === 'success') {
			handleModalActionSuccess();
			closeModal();
		}
		if (actionStatus === 'failed') {
			handleModalActioError();
		}
	}, [actionStatus, userUpdatePrevStatus]);

	const submitting = async (values) => {
		if (isSubmitting) {
			return;
		}

		setIsSubmitting(true);

		await modalAction(values);

		setIsSubmitting(false);
	};

	const submitButtonLable = () => {
		switch (true) {
			case !isEditMode && isSubmitting:
				return 'Adding...';
			case !isEditMode && !isSubmitting:
				return 'Add user';
			case isEditMode && isSubmitting:
				return 'Saving...';
			case isEditMode && !isSubmitting:
			default:
				return 'Save';
		}
	};

	const onConfirmAndLogOut = () => {
		changeMfaStatus({
			enable: mfaStatus,
			target_user_id: initialValues.userId,
			optional_token: ''
		})
			.then(() => {
				unauthenticateUser();
			});
	};

	const closeConfirmationModal = () => {
		setMfaStatus(!mfaStatus);
		setOpenConfirmationModal(false);
	};

	return (
		<>
			<Modal
				enforceFocus={false}
				show={true}
				size="lg"
				onHide={closeModal}
			>
				<Modal.Header>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form noValidate>
						<Form.hidden name={'form-type'} value={isEditMode ? 'edit' : 'add_user'} />
						<UserDetailsForm
							isEditMode={isEditMode}
							isEditLoggedUser={isEditLoggedUser}
							isEnforced={initialValues?.mfa?.isEnforced ?? false}
							shouldShowConsentCheckbox={initialValues?.account?.data_collection_consent === false}
							mfaStatus={mfaStatus}
							setMfaStatus={setMfaStatus}
						/>
					</form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-end">
					{(isEditMode && openDeleteUserModal) &&
						<span className="d-flex flex-grow-1">
							<Button appearance="danger" role="secondary" disabled={isSubmitting} onClick={openDeleteUserModal}>
								Delete
							</Button>
						</span>
					}
					<Button role="secondary" disabled={isSubmitting} onClick={closeModal}>
						Cancel
					</Button>
					<Button disabled={isSubmitting} onClick={handleSubmit(submitting)}>
						{submitButtonLable()}
					</Button>
				</Modal.Footer>
			</Modal>
			{openConfirmationModal && <MFAConfirmationModal isAdmin={isAdmin} mfaStatus={mfaStatus} onHide={closeConfirmationModal} onConfirmAndLogOut={onConfirmAndLogOut} />}
		</>
	);
};

const validate = (values) => {
	const validationSchema = values.userId ? editUserValidationSchema : addUserValidationSchema;
	const validator = new Validator(values, validationSchema, {
		'same.account.passwordCheck': 'Passwords do not match'
	});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const UserDetailModal = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate,
	shouldValidate: () => { return true; }
})(UserDetailModalForm);

const mapDispatchToProps = (dispatch) => {
	return {
		changeFormValue: (key, value) => { return dispatch(change(FORM_NAME, key, value)); },
		changeMfaStatus: (data) => { return dispatch(changeMfaStatus(data)); },
		unauthenticateUser: () => { dispatch(unauthenticateUser()); }
	};
};

export default connect(null, mapDispatchToProps)(UserDetailModal);

UserDetailModal.propTypes = {
	isAdmin: PropTypes.bool,
	modalTitle: PropTypes.string.isRequired,
	isUserEdit: PropTypes.bool,
	actionStatus: PropTypes.string,
	isEditLoggedUser: PropTypes.bool,
	initialValues: PropTypes.object.isRequired,
	modalAction: PropTypes.func.isRequired,
	openDeleteUserModal: PropTypes.func,
	closeModal: PropTypes.func.isRequired,
	handleModalActionSuccess: PropTypes.func.isRequired,
	handleModalActioError: PropTypes.func.isRequired
};
