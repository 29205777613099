import React from 'react';
import { Field } from 'redux-form';
import { Form } from '@syneto/compass-react';

export const Switch = (props) => {
	return (
		<Field
			component={renderSwitch}
			{...props}
		/>
	);
};

const renderSwitch = (props) => {
	const { input: { value, onChange }, disabled, label } = props;

	return (
		<Form.Group className={`switch ${disabled ? 'disabled' : ''} mb-3 d-flex gap-2`}>
			<Form.Label>{label.off}</Form.Label >
			<Form.Switch onChange={() => { onChange(!value); }} checked={value} disabled={disabled} />
			<Form.Label>{label.on}</Form.Label >
		</Form.Group>
	);
};
