import React from 'react';
import { Form } from '@syneto/compass-react';

const EnableMfaSwitch = (props) => {
	const { disabled, onChange, value } = props;
	return (
		<Form.Group className={`switch ${disabled ? 'disabled' : ''} mb-3 d-flex gap-2`}>
			<Form.Label>Disabled</Form.Label >
			<Form.Switch onChange={() => { onChange(!value); }} checked={value} disabled={disabled} />
			<Form.Label>Enabled</Form.Label >
		</Form.Group>
	);
};

export default EnableMfaSwitch;
