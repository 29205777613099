import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from '@syneto/compass-react';
import { getCompanies } from '../../../../data/companies/action';
import Preloader from '../../../../components/preloader';
import { pushDangerNotification } from '../../../../data/notifications/actions';
import { OperationsCompaniesViewModal } from './operationsCompaniesViewModal';

const CompaniesTable = (props) => {
	const [company, setCompany] = useState(null);

	let { loading, companies, getCompanies } = props;

	useEffect(() => {
		getCompanies();
	}, []);

	const openViewCompanyModal = (company) => {
		setCompany(company);
	};

	const closeViewCompanyModal = () => {
		setCompany(null);
	};

	const renderUsers = (cell, row) => {
		return (
			<ul style={{ padding: '0', margin: '0' }}>
				{row.profiles.map((user) => { return <li key={user.id}>{`${user.first_name} ${user.last_name}`}</li>; })}
			</ul>
		);
	};

	const renderCellActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { return openViewCompanyModal(row); }}>
					Edit
				</Button>
			</>
		);
	};

	return (
		<div id="companies" className="p-4 bg-white">
			{
				loading ? <Preloader dataIsLoading={loading} /> :
					companies && companies.length ?
						<Table
							data={companies}
							keyField='id'
							rowEvents={{ onClick: (e, row) => { return openViewCompanyModal(row); } }}
							search
							pagination={{ sizePerPageList: [15, 30, companies.length], showTotal: true }}
						>
							<Table.Col field="name" align="left" sort>
								Company
							</Table.Col>
							<Table.Col renderCell={renderUsers} field="users" align="left">
								Users
							</Table.Col>
							<Table.Col field="vat" align="left">
								VAT
							</Table.Col>
							<Table.Col field="email" align="left">
								Company Email
							</Table.Col>
							<Table.Col field="market_sector" align="left">
								Market Sector
							</Table.Col>
							<Table.Col align="center" renderCell={renderCellActions}>
								Actions
							</Table.Col>
						</Table> : null
			}

			{
				company && (
					<OperationsCompaniesViewModal
						closeModal={closeViewCompanyModal}
						companyDetails={company}
					/>
				)
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		companies: state.companies.data,
		loading: state.companies.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCompanies: () => { 
			try {
				dispatch(getCompanies());
			} catch (error) {
				dispatch(pushDangerNotification(`Could not retrieve users. Reason: ${error.message}`));
			}
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
