import React, { useState } from 'react';
import { Button } from '@syneto/compass-react';

function AdminMaintenancePlansFilterReport(props) {
	let { loadingMaintenancePlansReports, fetchMaintenancePlansReports } = props;

	const [filterValue, setFilterValue] = useState('all');

	function handleFilterValueChange(newFilterValue) {
		setFilterValue(newFilterValue);
	}

	return (
		<div className="d-flex mt-2 mb-4">
			<select defaultValue={ filterValue }
				className="form-select p-0 m-0 border-0 border-bottom rounded-0 bg-transparent w-50"
				onChange={ (event) => { return handleFilterValueChange(event.target.value); } }>
				<option value="all">All</option>
				<option value="active">Currently selling</option>
				<option value="inactive">Not selling</option>
			</select>

			<Button
				className="ms-3"
				type="submit"
				disabled={loadingMaintenancePlansReports}
				onClick={() => { fetchMaintenancePlansReports(filterValue); }}
			>
				Filter
			</Button>
		</div>
	);
}

export default AdminMaintenancePlansFilterReport;
