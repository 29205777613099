export const START_AUTHENTICATION = 'START_AUTHENTICATION';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';

export const START_TOGGLE_MFA = 'START_TOGGLE_MFA';
export const TOGGLE_MFA_SUCCESS = 'TOGGLE_MFA_SUCCESS';
export const TOGGLE_MFA_FAILURE = 'TOGGLE_MFA_FAILURE';

export const START_GET_MFA_CONFIG_DATA = 'START_GET_MFA_CONFIG_DATA';
export const SET_MFA_CONFIG_DATA = 'SET_MFA_CONFIG_DATA';
export const GET_MFA_CONFIG_DATA_SUCCESS = 'GET_MFA_CONFIG_DATA_SUCCESS';
export const GET_MFA_CONFIG_DATA_ERROR = 'GET_MFA_CONFIG_DATA_ERROR';

