import { axios } from '../../../../services/httpClient';
import * as actionTypes from '../actionTypes';

export const validateToken = (hash) => {
	return (dispatch) => {
		return axios.get(`/tokens/verify?hash=${hash}`)
			.then((response) => {
				dispatch(validateTokenSuccess(response.data));
			})
			.catch((error) => {
				dispatch(validateTokenFailure(error?.response?.data?.message));
			});
	};
};

export const validateTokenSuccess = (data) => {
	return {
		type:    actionTypes.VALIDATE_TOKEN_SUCCESS,
		payload: data
	};
};

export const validateTokenFailure = (error) => {
	return {
		type:    actionTypes.VALIDATE_TOKEN_FAILURE,
		payload: error
	};
};

export const createNps = (formFields) => {
	return (dispatch) => {
		return axios.post('/nps', formFields)
			.then((response) => {
				dispatch(createNpsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createNpsFailure(error?.response?.data?.message));
			});
	};
};

export const createNpsSuccess = (data) => {
	return {
		type:    actionTypes.CREATE_NPS_SUCCESS,
		payload: data
	};
};

export const createNpsFailure = (error) => {
	return {
		type:    actionTypes.CREATE_NPS_FAILURE,
		payload: error
	};
};

export const updateNps = (formFields) => {
	return (dispatch) => {
		return axios.put('/nps', formFields)
			.then((response) => {
				dispatch(updateNpsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(updateNpsFailure(error?.response?.data?.message));
			});
	};
};

export const updateNpsSuccess = (data) => {
	return {
		type:    actionTypes.UPDATE_NPS_SUCCESS,
		payload: data
	};
};

export const updateNpsFailure = (error) => {
	return {
		type:    actionTypes.UPDATE_NPS_FAILURE,
		payload: error
	};
};
