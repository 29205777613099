import React from 'react';
import { connect } from 'react-redux';
import ModalPassword from './NavProfilePasswordModal';
import { NavProfileNotificationsModal } from './NavProfileNotificationsModal';
import UserDetailModal from '../../UserDetailModal';
import { hideProfileModal, updateUser } from '../../../data/profile/action';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../data/notifications/actions';

const getEditUserModalInitValue = (user) => {
	return {
		userId: user.userId ?? null,
		account: {
			data_collection_consent: user?.account?.data_collection_consent ?? false,
			email: user?.account?.email ?? ''
		},
		contact: {
			firstName: user?.contact?.firstName ?? '',
			lastName: user?.contact?.lastName ?? '',
			roleInCompany: user?.contact?.roleInCompany ?? '',
			phoneNumber: user?.contact?.phoneNumber ?? ''
		},
		mfa: {
			isEnabled: user.mfa.isEnabled ?? false,
			isEnforced: user.mfa.isEnforced ?? false
		},
	};
};

const NavProfile = (props) => {
	const { showProfileModal, user, updateUser, hideProfileModal, pushSuccessfulNotification, pushDangerNotification } = props;

	const handleEditUserSubmit = async (values) => {
		await updateUser(values);
	};

	const handleModalActionSuccess = () => {
		pushSuccessfulNotification('Your profile has been updated.');
	};

	const handleModalActioError = () => {
		pushDangerNotification(user.error);
	};

	return (
		<>
			{
				showProfileModal && (
					<UserDetailModal
						modalTitle="Profile"
						isUserEdit={true}
						isEditLoggedUser={true}
						closeModal={hideProfileModal}
						initialValues={getEditUserModalInitValue(user.user)}
						modalAction={handleEditUserSubmit}
						actionStatus={user.status}
						handleModalActionSuccess={handleModalActionSuccess}
						handleModalActioError={handleModalActioError}
					/>
				)
			}
			<ModalPassword />
			<NavProfileNotificationsModal />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		showProfileModal: state.user.showProfileModal
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (userDetails) => { dispatch(updateUser(userDetails)); },
		hideProfileModal: () => { dispatch(hideProfileModal()); },
		pushSuccessfulNotification: (message) => { dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
