import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import UserRoles from '../../constants/user-roles';
import { pushDangerNotification } from '../../data/notifications/actions';
import { onClickSidebarMenu } from '../../data/actions/sidebar';
import { onShowModalAddProductModel } from '../../data/productModels/action';
import { showModalAddNewMaintenancePlan } from '../../data/maintenanceType/action';
import { showSelectedUserEditModal } from '../../data/selected-user/actions';
import Sidebar from '../../components/Sidebar';
import UserService from '../../helpers/User';
import Preloader from '../../components/preloader';

const Administrator = (props) => {
	const { loadUserData, userRole, onClickSidebarMenu, onShowAddProductModel, showSelectedUserEditModal, showModalAddNewMaintenancePlan } = props;
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const USER_ROLES_ROUTES = Object.values(UserRoles).map((role) => {
		return {
			PATH: role,
			LABEL: role === UserRoles.CLIENT ? 'Companies' : UserService.toUppercaseUserRole(role),
			IDENTIFIER: `add${role}`,
			ITEMS: role === UserRoles.CLIENT ? [] : [
				{
					menuTitle: 'Add user',
					fnToExecute: showSelectedUserEditModal
				}
			]
		}; 
	});
	
	const ADMIN_ROUTES = {
		...USER_ROLES_ROUTES,
		MANUALS: {
			LABEL: 'Manuals',
			IDENTIFIER: 'manuals',
			PATH: 'manuals',
			ITEMS: []
		},
		PRODUCTS: {
			LABEL: 'Products',
			IDENTIFIER: 'products',
			PATH: 'products',
			ITEMS: []
		},
		SETTINGS: {
			LABEL: 'Settings',
			IDENTIFIER: 'settings',
			PATH: 'settings',
			ITEMS: []
		},
		MAINTENANCE_TYPES: {
			LABEL: 'Maintenance Types',
			IDENTIFIER: 'maintenanceTypes',
			PATH: 'maintenance-types',
			ITEMS: [{
				menuTitle: 'Add maintenance type',
				fnToExecute: showModalAddNewMaintenancePlan
			}]
		},
		PRODUCT_MODALS : {
			LABEL: 'Product Models',
			IDENTIFIER: 'productModels',
			PATH: 'product-models',
			ITEMS: [{
				menuTitle: 'Add model',
				fnToExecute: onShowAddProductModel
			}]
		}
	};
	
	useEffect(() => {
		switch (location.pathname.split('/')[2]) {
			case ADMIN_ROUTES[1].PATH:
				onClickSidebarMenu(ADMIN_ROUTES[1].IDENTIFIER);
				break;
			case ADMIN_ROUTES[2].PATH:
				onClickSidebarMenu(ADMIN_ROUTES[2].IDENTIFIER);
				break;
			case ADMIN_ROUTES[3].PATH:
				onClickSidebarMenu(ADMIN_ROUTES[3].IDENTIFIER);
				break;
			case ADMIN_ROUTES[4].PATH:
				onClickSidebarMenu(ADMIN_ROUTES[4].IDENTIFIER);
				break;
			case ADMIN_ROUTES.MAINTENANCE_TYPES.PATH:
				onClickSidebarMenu(ADMIN_ROUTES.MAINTENANCE_TYPES.IDENTIFIER);
				break;
			case ADMIN_ROUTES.MANUALS.PATH:
				onClickSidebarMenu(ADMIN_ROUTES.MANUALS.IDENTIFIER);
				break;
			case ADMIN_ROUTES.PRODUCTS.PATH:
				onClickSidebarMenu(ADMIN_ROUTES.PRODUCTS.IDENTIFIER);
				break;
			case ADMIN_ROUTES.SETTINGS.PATH:
				onClickSidebarMenu(ADMIN_ROUTES.SETTINGS.IDENTIFIER);
				break;
			case ADMIN_ROUTES.PRODUCT_MODALS.PATH:
				onClickSidebarMenu(ADMIN_ROUTES.PRODUCT_MODALS.IDENTIFIER);
				break;
			case ADMIN_ROUTES[0].PATH:
			default:
				onClickSidebarMenu(ADMIN_ROUTES[0].IDENTIFIER);
				break;
		}		
	}, []);
	
	useEffect(() => {
		if (userRole && userRole !== 'administrator') {
			navigate('/');
		} 

		if (!loadUserData && userRole) {
			setLoading(false);
		}
	}, [loadUserData, userRole]);
	
	return (
		<div className="w-100 h-100 mt-4">
			<div className="container-fluid">
				<div className="row">
					<div className="side-menu">
						<Sidebar items={ADMIN_ROUTES}/>
					</div>

					<div className="page-content row">
						{
							!loading 
								? <Outlet />
								: <Preloader dataIsLoading={loading} />
						}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loadUserData: state?.user?.loading,
		userRole: state?.user?.user?.account?.role
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onShowAddProductModel: () => { return dispatch(onShowModalAddProductModel()); },
		onClickSidebarMenu: (path) => { dispatch(onClickSidebarMenu(path)); },
		showSelectedUserEditModal: () => { dispatch(showSelectedUserEditModal()); },
		showModalAddNewMaintenancePlan: () => { dispatch(showModalAddNewMaintenancePlan()); },
		pushDangerNotification: (message) => { dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Administrator);

