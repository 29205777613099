import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Button } from '@syneto/compass-react';
import Form from '../../../../components/forms/form';
import { editNpsSettings, getNpsSettings } from './data/actions';
import NPSFieldsValidation from './data/validation/nps-fields';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';

const selector = formValueSelector('npsScheduler');

let AdminSettingsNPSTab = (props) => {
	useEffect(() => {
		props.activeKey === 'nps' && props.getNpsSettings();
		props.reset('nps');
	}, [props.activeKey]);

	function submit(formValues) {
		return props.editNpsSettings(formValues).then(() => {
			props.dispatch(pushSuccessfulNotification('NPS settings updated'));
		}).catch((error) => {
			props.dispatch(pushDangerNotification('Could not update NPS settings.'));
		});
	}

	return (
		<form>
			<h1 className="mb-1">Net Promoter Score</h1>
			<p className="text-secondary mb-4 text-value">
				Measure the experience with the Net Promoter Score by sending email feedback form
			</p>
			<div>
				<Form.checkbox
					id="npsNotificationEnabled"
					name="npsNotificationEnabled"
					label="Enabled NPS surveys"
				/>
			</div>
			<div className="row">
				<div className="col-6">
					<Form.text
						id="daysToSendFirstNpsEmailAfter"
						name="daysToSendFirstNpsEmailAfter"
						label="Send first email after (days)"
						disabled={!props.npsNotificationEnabled}
					/>
				</div>
				<div className="col-6">
					<Form.text
						id="monthsToSendSecondNpsEmailAfter"
						name="monthsToSendSecondNpsEmailAfter"
						label="Send second email after (months)"
						disabled={!props.npsNotificationEnabled}
					/>
				</div>
			</div>

			<Button disabled={props.submitting} onClick={props.handleSubmit(submit)}>
				{!props.submitting ? 'Save' : 'Saving...'}
			</Button>
		</form>
	);
};

AdminSettingsNPSTab = reduxForm({
	form: 'npsScheduler',
	enableReinitialize: true,
	validate: NPSFieldsValidation.validate
})(AdminSettingsNPSTab);

const mapStateToProps = (state) => {
	const npsSettings = state.adminSettings.npsSettings;
	let valueSelector = selector(state, 'npsNotificationEnabled');

	return {
		initialValues: {
			npsNotificationEnabled: npsSettings && npsSettings.npsNotificationEnabled && npsSettings.npsNotificationEnabled === 'yes',
			daysToSendFirstNpsEmailAfter: npsSettings && npsSettings.daysToSendFirstNpsEmailAfter ? npsSettings.daysToSendFirstNpsEmailAfter : '',
			monthsToSendSecondNpsEmailAfter: npsSettings && npsSettings.monthsToSendSecondNpsEmailAfter ? npsSettings.monthsToSendSecondNpsEmailAfter : ''
		},
		npsNotificationEnabled: valueSelector
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNpsSettings: () => {
			return dispatch(getNpsSettings());
		},
		editNpsSettings: (formValues) => {
			return dispatch(editNpsSettings(formValues));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettingsNPSTab);
