import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import { Button, Card } from '@syneto/compass-react';
import formFields from '../../../../constants/formFields';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { authenticateUser } from '../../../../data/authenticate/action';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'account.email': ['required', 'email'],
			'account.password': ['required']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const LogInCard = (props) => {
	const { authenticateUser, authentication, handleSubmit, submitting } = props;

	const submit = (values) => { 
		authenticateUser(values.authentification);
	};
	
	return (
		<div className="col log-in">
			<Card
				className={`z-depth-1 ${authentication.status === 'failed' ? 'animated shake' : ''}`}
				title={'Authentication'}
				content={
					<form noValidate>
						<Form.email name={formFields.authentification.email} label="Email address" autoFocus />
						<Form.password name={formFields.authentification.password} label="Password" />

						<div className='d-flex justify-content-between'>
							<Link to="/password-recovery" className="mt-2">Forgot password?</Link>
					
							<Button
								type="submit"
								disabled={submitting}
								onClick={handleSubmit(submit)}
							>
								{!submitting ? 'Log in' : 'Authenticating...'}
							</Button>
						</div>
					</form>
				}
			/>
		</div>
	);
};

const LogInCardForm = reduxForm({
	form: 'authentication',
	validate
})(LogInCard);

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authenticateUser: (data) => { return dispatch(authenticateUser(data)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInCardForm);
