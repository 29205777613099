import * as actionTypes from './actionTypes';

// const DEFAULT_PAGEINATION = {
// 	currentPage: 1,
// 	itemsPerPage: 15,
// 	numberOfPages: 1,
// 	totalItems: 1
// };

export const INITIAL_STATE = {
	status: null,
	loading: false,
	error: null,
	products: [],
	pagination: {},
	showAddProductModal: false,
	activationKey: null
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.START_FETCHING_PRODUCTS:
			return {
				...INITIAL_STATE,
				status: 'loading',
				loading: true
			};
		case actionTypes.GET_PRODUCTS_SUCCESS: {
			return {
				...state,
				status: 'success',
				loading: false,
				products: action.payload.products,
				pagination: action.payload.pagination
			};
		}
		case actionTypes.GET_PRODUCTS_ERROR: {
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false
			};
		}
		case actionTypes.DELETE_PRODUCT_FOR_USER_SUCCESS:
			return {
				...state,
				status: action.payload
			};
		case actionTypes.DELETE_PRODUCT_FOR_USER_ERROR:
			return {
				...state,
				error: action.payload.message
			};
		case actionTypes.SHOW_ADD_PRODUCT_MODAL:
			return {
				...state,
				showAddProductModal: true
			};
		case actionTypes.HIDE_ADD_PRODUCT_MODAL:
			return {
				...state,
				showAddProductModal: false,
				activationKey: null
			};
		case actionTypes.GET_ACTIVATION_CODE_SUCCESS:
			return {
				...state,
				activationKey: action.payload
			};
		case actionTypes.GET_ACTIVATION_CODE_ERROR:
			return {
				...state,
				error: action.payload
			};
		default:
			return state;
	}
};
