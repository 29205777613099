import React from 'react';
import { connect } from 'react-redux';
import { navigateToMenuItem } from '../../data/actions/navigation';
import MenuItem from './components/MenuItem';


const Sidebar = (props) => {
	const { activeMenu, items, navigateToMenuItem } = props;

	return (
		<ul className="list-group" style={{ fontSize: '14px' }}>
			{
				Object.keys(items).map((item, index) => {
					return <MenuItem key={index} item={items[item]} activeMenu={activeMenu} navigateToMenuItem={navigateToMenuItem} />;
				})
			}
		</ul>
	);
};

const mapStateToProps = ({ sidebar }) => {
	return {
		activeMenu: sidebar.activeMenu
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigateToMenuItem: (menuProperties) => {
			return dispatch(navigateToMenuItem(menuProperties));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
