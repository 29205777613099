import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status: 'loading',
	error: null,
	loading: true,
	accessToken: null
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.READING_TOKEN_SUCCESS:
			return {
				...state,
				status: 'loaded',
				error: null,
				loading: false,
				accessToken: action.payload
			};
		case actionTypes.READING_TOKEN_FAILURE:// return error and make loading = false
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false,
				accessToken: null
			};
		case actionTypes.GENERATING_TOKEN_SUCCESS:
			return {
				...state,
				status: 'loaded',
				error: null,
				loading: false,
				accessToken: action.payload
			};
		case actionTypes.GENERATING_TOKEN_FAILURE:// return error and make loading = false
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false,
				accessToken: null
			};
		case actionTypes.CONSUMING_TOKEN_SUCCESS:
			return {
				...state,
				status: 'loaded',
				error: null,
				loading: false,
				accessToken: action.payload
			};
		case actionTypes.CONSUMING_TOKEN_FAILURE:// return error and make loading = false
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false,
				accessToken: null
			};
		default:
			return state;
	}
};
