import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	loading: false,
	status: 'loading',
	uploadMessageSuccess: null,
	uploadMessageError: null,
	error: null,
	productNote: null,
	notes: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.UPLOADING_FILE:
			return {
				...state,
				loading: true,
				uploadMessageSuccess: null,
				uploadMessageError: null

			};
		case actionTypes.UPLOAD_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				uploadMessageSuccess: action.payload.data.message,
				uploadMessageError: null
			};
		case actionTypes.UPLOAD_FILE_ERROR:
			return {
				...state,
				loading: false,
				uploadMessageSuccess: null,
				uploadMessageError: action.payload.message
			};
		case actionTypes.DELETE_FILE_SUCCESS:
			return {
				...state,
				status: action.payload.message
			};
		case actionTypes.DELETE_FILE_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		case actionTypes.SAVE_NOTE_FOR_PRODUCT_SUCCESS:
			return {
				...state,
				status: action.payload.message
			};
		case actionTypes.SAVE_NOTE_FOR_PRODUCT_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		case actionTypes.GET_NOTE_DETAILS_SUCCESS:
			return {
				...state,
				status: action.payload.message,
				productNote: action.payload.note
			};
		case actionTypes.GET_NOTE_DETAILS_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		case actionTypes.GET_NOTES_FOR_PRODUCT_SUCCESS:
			return {
				...state,
				status: action.payload.message,
				notes: action.payload.notes
			};
		case actionTypes.GET_NOTES_FOR_PRODUCT_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		case actionTypes.UPDATE_NOTE_FOR_PRODUCT_SUCCESS:
			return {
				...state,
				status: action.payload.message
			};
		case actionTypes.UPDATE_NOTE_FOR_PRODUCT_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		case actionTypes.DELETE_NOTE_FOR_PRODUCT_SUCCESS:
			return {
				...state,
				status: action.payload.message
			};
		case actionTypes.DELETE_NOTE_FOR_PRODUCT_ERROR:
			return {
				...state,
				status: action.payload,
				error: action.payload.message
			};
		default:
			return state;
	}
};
