import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import { Button } from '@syneto/compass-react';
import regexRules from '../../../../constants/regex-rules';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import {
	editContactDetailsForSerenityRequests,
	getContactDetailsForSerenityRequests
} from './data/actions';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';


const validate = (values) => {
	Validator.register('email', (originalValue) => {
		const emailAddressRegex = regexRules.validEmailRegex;
		
		return emailAddressRegex.test(originalValue);
	}, 'The value provided is not valid email addresses');

	const validator = new Validator(values,
		{
			'serenitySupportEmail': ['required', 'email'],
			'serenitySalesEmail': ['required', 'email'],
			'serenityMarketingSite': ['required', 'url']
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminSettingsSerenityTab = (props) => {
	const { handleSubmit, submitting, serenitySalesEmailError, serenitySupportEmailError } = props;

	useEffect(() => {
		props.activeKey === 'serenity' && props.getContactDetailsForSerenityRequests();
		props.reset('serenityRequests');
	}, [props.activeKey]);

	function submit(formValues) {
		props.dispatch(editContactDetailsForSerenityRequests(formValues)).then(() => {
			props.dispatch(pushSuccessfulNotification('Serenity settings updated.'));
		}).catch(() => {
			props.statusSetContactDetailsForSerenityRequests.map(error);
			props.dispatch(pushDangerNotification(props.statusSetContactDetailsForSerenityRequests));
		});
	}

	return (
		<form>
			<div className="mb-3" style={{ minHeight: '104px' }}>
				<label htmlFor="serenitySalesEmail">Serenity requests email</label>
				<Form.text name="serenitySalesEmail" />
				{!Object.prototype.hasOwnProperty.call(serenitySalesEmailError, 'serenitySalesEmail') &&
					<span
						className="text-secondary form-feedback">Email address to send end user requests for Serenity service</span>
				}
			</div>
			<div className="mb-3" style={{ minHeight: '104px' }}>
				<label htmlFor="serenityMarketingSite">Serenity Website</label>
				<Form.text name="serenityMarketingSite" />
			</div>
			<div className="mb-3" style={{ minHeight: '104px' }}>
				<label htmlFor="serenitySupportEmail">Serenity Support Email</label>
				<Form.text name="serenitySupportEmail" />
				{!Object.prototype.hasOwnProperty.call(serenitySalesEmailError, 'serenitySupportEmail') &&
					<span
						className="text-secondary form-feedback">Email address where the serenity events notifications are sent.</span>
				}
			</div>

			<Button disabled={submitting} onClick={handleSubmit(submit)} type="submit">
				{!submitting ? 'Save' : 'Saving...'}
			</Button>
		</form>
	);
};

AdminSettingsSerenityTab = reduxForm({
	form: 'serenityRequests',
	enableReinitialize: true,
	validate: validate
})(AdminSettingsSerenityTab);

const mapStateToProps = (state) => {
	const selector = getFormSyncErrors('serenityRequests');
	const serenitySalesEmailError = selector(state, 'serenitySalesEmail');
	const serenitySupportEmailError = selector(state, 'serenitySupportEmail');
	const { contactDetailsForSerenityRequests } = state.adminSettings;

	return {
		initialValues: {
			serenitySalesEmail: contactDetailsForSerenityRequests && contactDetailsForSerenityRequests.serenitySalesEmail ?
				contactDetailsForSerenityRequests.serenitySalesEmail.replace(/\s/g, '') : null,
			serenityMarketingSite: contactDetailsForSerenityRequests && contactDetailsForSerenityRequests.serenityMarketingSite ?
				contactDetailsForSerenityRequests.serenityMarketingSite : null,
			serenitySupportEmail: contactDetailsForSerenityRequests && contactDetailsForSerenityRequests.serenitySupportEmail ?
				contactDetailsForSerenityRequests.serenitySupportEmail.replace(/\s/g, '') : null
		},
		serenitySalesEmailError,
		serenitySupportEmailError
	};
};

export default connect(mapStateToProps, { getContactDetailsForSerenityRequests })(AdminSettingsSerenityTab);
