import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, Modal } from '@syneto/compass-react';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { getNotesForProduct, updateNoteForProduct } from '../../data/actions/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'title': ['required', 'min: 5', 'max: 255'],
			'content': ['required', 'min: 10', 'max: 255']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let SupportNotesUpdateModal = (props) => {
	let { productId, note, isOpen, toggleModal, noteForPendingProduct, handleSubmit, noteForProductStatusMessage, reset } = props;
	let { getNotesForProduct, updateNoteForProduct, pushSuccessfulNotification, pushDangerNotification } = props;

	function updateNote(formValues) {
		cancelModal();
		return updateNoteForProduct(note.id, formValues).then(() => {
			getNotesForProduct(productId);
			pushSuccessfulNotification(noteForProductStatusMessage);
		}).catch((error) => { return pushDangerNotification(`${noteForProductStatusMessage}\n\n Response error message: ${error.message}`); });
	}

	function updateNotePendingProduct(formValues) {
		cancelModal();
		return updateNoteForProduct(note.id, formValues).then(() => {
			getNotesForProduct(productId);
			pushSuccessfulNotification(noteForProductStatusMessage);
		}).catch((error) => {
			pushDangerNotification(`An error has occurred. Reason: ${error.message}`);
		});
	}

	function cancelModal() {
		toggleModal(false);
		reset('updateProductNotes');
	}

	return (
		<form id="product-notes" className="form modal fade">
			<Modal show={isOpen}>
				<Modal.Header>
					<Modal.Title>Update note</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<fieldset>
						<div className="mb-3">
							<label htmlFor="title">Title</label>
							<Form.text name="title" />
						</div>
						<div className="mb-3">
							<label htmlFor="content">Content</label>
							<Form.textarea name="content" />
						</div>
					</fieldset>
				</Modal.Body>

				<Modal.Footer>
					<Button role="secondary" onClick={cancelModal}>Cancel</Button>
					<Button onClick={noteForPendingProduct ? handleSubmit(updateNotePendingProduct) : handleSubmit(updateNote)}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
		</form>
	);
};

SupportNotesUpdateModal = reduxForm({
	form: 'updateProductNotes',
	enableReinitialize: true,
	validate
})(SupportNotesUpdateModal);

const mapStateToProps = (state) => {
	return {
		noteForProductStatusMessage: state.productNotes.status,
		initialValues: {
			title: state.productNotes.productNote ? state.productNotes.productNote.title : '',
			content: state.productNotes.productNote ? state.productNotes.productNote.content : ''
		}
	};
};

export default connect(mapStateToProps, {
	getNotesForProduct,
	updateNoteForProduct,
	pushDangerNotification,
	pushSuccessfulNotification
})(SupportNotesUpdateModal);
