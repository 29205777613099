import React from 'react';
import { reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import { Card, Button, Icon } from '@syneto/compass-react';
import Validator from '../../../../components/forms/validate';
import Form from '../../../../components/forms/form';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'mfa': ['required', 'min: 6']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const MFASetupCard = ({ authentication, verifyMfaCode, handleSubmit, submitting, pushSuccessfulNotification, pushDangerNotification }) => {
	const verifyTotpCode = (values) => {
		verifyMfaCode({
			email: authentication.email,
			code: values.mfa
		});
	};

	const renderTOTPSecret = () => {
		const copySecret = () => {
			navigator.clipboard.writeText(authentication.mfaData.secret);
			pushSuccessfulNotification('Your TOTP secret has been successfully copied to the clipboard. Keep it safe!');
		};

		return (
			<span role="button" onClick={copySecret}>
				<b>{authentication.mfaData.secret}&nbsp;
					<Icon name="fa fa-clipboard" />
				</b>
			</span>			
		);
	};

	return (
		<Card
			className={`z-depth-1 ${authentication.status === 'failed' ? 'animated shake' : ''}`}
			title={'Setup multiple factor authentication'}
			content={
				<form noValidate>

					<div className='row'>
						<h6>Use your two-factor authentication app to scan the image bellow.</h6>
					</div>

					<div className="row">
						<div className="col-5">
							<img src={`data:image/png;base64, ${authentication.mfaData.qrCode}`} className="img-fluid" alt="" />
						</div>
						<div className="col-7">
							<p>
								<small>
									In order to log in, you will need to download the Google Authenticator App on your phone, scan the QR
									code from this page or enter the TOTP secret {renderTOTPSecret()} manually and fill in the generated
									security code.
								</small>
							</p>
						</div>
					</div>

					<Form.mfa label="After scanning the barcode, enter the 6-digit code generated from the app." name={'mfa'} autoFocus />

					<div className='d-flex justify-content-end'>
						<Button
							type="submit"
							disabled={submitting}
							onClick={handleSubmit(verifyTotpCode)}
						>
							{submitting ? 'Verifying...' : 'Verify & Log In'}
						</Button>
					</div>
				</form>
			}
		/>
	);
};

export default reduxForm({
	form: 'mfaConfig',
	validate
})(MFASetupCard);

