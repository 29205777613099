import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';
import _ from 'lodash';

export const loadingProductUpdateEventsSuccess = (data) => {
	return {
		type: actionTypes.LOADING_PRODUCT_UPDATE_EVENTS_SUCCESS,
		payload: data
	};
};

export const loadingProductUpdateEventsError = (error) => {
	return {
		type: actionTypes.LOADING_PRODUCT_UPDATE_EVENTS_ERROR,
		payload: error
	};
};

export const loadProductUpdateEvents = (serialNumber = '') => {
	if (serialNumber && serialNumber.length) {
		return (dispatch) => {
			return axios.get('/products/update-events' + (serialNumber && '?serialNumber=' + serialNumber)).then((response) => {
				if (response.status === 200) {
					const updateEvents = response.data.data;
					let reactVisGraphLast12MonthsPerWeek = [];
					let finishedUpdates = [];
	
					if (updateEvents.length) {
						updateEvents.forEach(function(updateEvent) {
							const currentDate = moment();
							const currentWeek = currentDate.week();
							const currentYear = currentDate.year();
							const createdAtDate = moment(updateEvent.createdAt);
							const createdAtWeek = createdAtDate.week();
							const createdAtYear = createdAtDate.year();
							const noOfWeeksInYear = 52;
							const week12MonthsAgo = noOfWeeksInYear - currentWeek;
							const previousYear = currentYear - 1;
	
							switch (updateEvent.type) {
								case 'UpdateChecked': {
									if (
										(createdAtYear === previousYear && createdAtWeek >= week12MonthsAgo)
					||
					(createdAtYear === currentYear && createdAtWeek <= currentWeek)
									) {
										reactVisGraphLast12MonthsPerWeek.push({
											x: createdAtDate.startOf('week').format('DD/MM/YYYY'),
											y: 1,
										});
									}
	
									break;
								}
								case 'UpdateFinished': {
									finishedUpdates.push({
										timestamp: createdAtDate.valueOf(),
										date: createdAtDate.format('DD/MM/YYYY'),
										currentVersion: updateEvent.currentVersion,
										toVersion: updateEvent.toVersion,
										status: updateEvent.status,
									});
	
									break;
								}
							}
						});
					}
	
					response.data.reactVisGraphLast12MonthsPerWeek = reactVisGraphLast12MonthsPerWeek;
					response.data.finishedUpdates = _.orderBy(finishedUpdates, ['timestamp'], ['desc']);
	
					dispatch(loadingProductUpdateEventsSuccess(response.data));
				}
			}, (error) => {
				dispatch(loadingProductUpdateEventsError(error?.response?.data?.message));
			});
		};
	}
	
};
