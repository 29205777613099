import moment from 'moment';

const CurrentlyActiveMaintenanceService = (maintenanceService) => {
	if (!maintenanceService) {
		return null;
	}

	const renewals = maintenanceService?.maintenance_service_renewals ?? maintenanceService?.renewals ?? [];
	const expiresOn = maintenanceService.expiresOn;
	let maintenance = maintenanceService;

	if ( renewals && renewals.length > 0 ) {
		renewals.forEach((renewal) => {
			if ( moment().isBetween(moment(renewal.startDate), moment(renewal.endDate)) ) {
				maintenance = renewal;
			}
		});

		if ( moment() > moment(expiresOn) ) {
			const indexOfMostRecentMaintenanceRenewal = renewals.length - 1;
			maintenance = renewals[ indexOfMostRecentMaintenanceRenewal ];
		}
	}

	return maintenance;
};

export default CurrentlyActiveMaintenanceService;
