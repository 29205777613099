import React from 'react';
import { Tooltip } from '@syneto/compass-react';
import Form from '../forms/form';
import formFields from '../../constants/formFields';
import EnableMfaSwitch from '../EnableMfaSwitch';

const UserDetailsForm = ({ isEditMode, isEditLoggedUser, isEnforced, shouldShowConsentCheckbox, mfaStatus, setMfaStatus }) => {
	return (
		<>
			<fieldset>
				<div className="row">
					<div className="col-4">
						<h5>1. Account</h5>
					</div>
					<div className="col-8">
						<div className="row">
							<div className="col-12">
								<Form.email
									name={formFields.account.email}
									label="Email address"
									autoComplete="off"
									required
									icon={
										isEditLoggedUser 
											? (
												<Tooltip text="This will be used for your account name" placement="bottom">
													<i
														className="fa fa-question"
													/>
												</Tooltip>
											)
											: null
										
									}
				
								/>
							</div>
						</div>
						{
							!isEditMode && (
								<div className="row">
									<div className="col-6">
										<Form.password
											name={formFields.account.password}
											label="Password"
											required
											autoComplete="off"
										/>
									</div>
									<div className="col-6">
										<Form.password
											name={formFields.account.passwordCheck}
											label="Re-type password"
											autoComplete="off"
											required
										/>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</fieldset>
			<fieldset>
				<div className="row">
					<div className="col-4">
						<h5>2. Contact details&nbsp;
							{
								isEditLoggedUser && (
									<Tooltip text="Used for important communications regarding your account" placement="top">
										<i className="fa fa-question" />
									</Tooltip>
								)
							}
						</h5>
					</div>
					<div className="col-8">
						<div className="row">
							<div className="col-6">
								<Form.text
									name={formFields.contact.firstName}
									label="First name"
									autoComplete="off"
									required
								/>
							</div>
							<div className="col-6">
								<Form.text
									name={formFields.contact.lastName}
									label="Last name"
									autoComplete="off"
									required
								/>
							</div>
						</div>

						{isEditLoggedUser && (
							<div className="row">
								<div className="col-6">
									<Form.text
										name={formFields.contact.roleInCompany}
										label="Role"
										autoComplete="off"
										required
									/>
								</div>
								<div className="col-6">
									<Form.text
										name={formFields.contact.phoneNumber}
										label="Phone number"
										autoComplete="off"
										required
									/>
								</div>
							</div>
						)}

					</div>
				</div>
			</fieldset>
			<fieldset>
				<div className="row">
					<div className="col-4">
						<h5>3. Multi-factor authentication&nbsp;
							<Tooltip text="Multifactor authentication (MFA) is an authentication method that requires multiple authentication steps to verify an user's identity for login"
								placement="top">
								<i
									className="fa fa-question ms-1"
								/>
							</Tooltip>
						</h5>
					</div>
					<div className="col-8">
						{
							isEditLoggedUser 
								? (
									<span className='d-flex'>
										<EnableMfaSwitch 
											id={formFields.mfa.isEnabled}
											disabled={isEnforced}
											onChange={() => { return setMfaStatus(!mfaStatus); }} 
											value={mfaStatus}
										/>
										{isEnforced && (
											<span>
												<Tooltip 
													text="Multi-factor authentication has been enforced by your admin. For disabling it, talk with your administrator."
													placement="top"
												>
													<i
														className="fa fa-question ms-1"
													/>
												</Tooltip>
											</span>
										)}
									</span>
									
								)
								: (
									<Form.switch
										id={formFields.mfa.isEnabled}
										name={formFields.mfa.isEnabled}
										label={{
											off: 'Disabled',
											on: 'Enabled'
										}}
									/>
								)
						}						
					</div>
				</div>
			</fieldset>
			{isEditLoggedUser && shouldShowConsentCheckbox && (
				<fieldset>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<Form.checkbox
									id={formFields.account.data_collection_consent}
									name={formFields.account.data_collection_consent}
									label="I consent to my submitted data being collected and stored."
									icon={
										<a href="https://syneto.eu/privacy-policy/" target="_blank"
											className="color-type-default font-weight-bold ms-1" rel="noreferrer">Privacy policy</a>
									}
								/>
							</div>
						</div>
					</div>
				</fieldset>
			)}
		</>
	);
};

export default UserDetailsForm;
