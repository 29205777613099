import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import moment from 'moment';
import { Badge, Button, DatePicker, Icon, Tooltip } from '@syneto/compass-react';
import Form from '../../../../../components/forms/form';
import SelectOptionsRenderer from '../../../../../helpers/SelectOptionsRenderer';
import OperationsProductsModalMaintenancePlans from '../OperationsProductsMaintenancePlansModal';
import OperationsProductsEditModalMaintenanceExtension from './OperationsProductsEditModalMaintenanceExtension';
import ServiceField from '../../../../../components/forms/common/service-field';
import PostInstallCheckLogsModal from '../../../../../components/PostInstallCheckLogsModal';

export const OperationsProductsEditModalBody = (props) => {
	const {
		user,
		change,
		fields,
		productModels,
		maintenancePlansOptions,
		loadMaintenanceDetailsOperations,
		getActivationCode,
		activationKey,
		selectedProduct,
		companiesWithEndUsers,
		formName,
	} = props;

	const [modelId, setModelId] = useState(selectedProduct?.model);
	const [virtualAppliance, toggleVirtualAppliance] = useState(!!selectedProduct.activationKey);
	const [chronos, setChronos] = useState(selectedProduct?.model?.chronos);
	const [failoverAndFailback, setFailoverAndFailback] = useState(selectedProduct?.model?.failoverAndFailback);
	const [showPostInstallChecksModal, setShowPostInstallChecksModal] = useState(false);

	// useEffect(() => { virtualAppliance ? getActivationCode() : change('activationKey', ''); }, [virtualAppliance]);

	const changeProps = (field, value) => {
		return change(field, value);
	};

	const setNewActivationKey = async () => {
		try {
			const data = await getActivationCode();
			if (data) {
				change('activationKey', data);
			}
		} catch (error) {
			change('activationKey', '');
		}
	};

	useEffect(() => {
		if (modelId && modelId.title === 'Cloud Gate Appliance') {
			change('hasCloudGate', false);
		}
	}, [modelId]);

	useEffect(() => {
		if (virtualAppliance) {
			change('serialNumber', null);
			change('virtualAppliance', true);
			getActivationCode();
		} else {
			change('activationKey', '');
			change('virtualAppliance', false);
		}
	}, [virtualAppliance]);

	const changeVirtualAppliance = (selectedProductModel) => {
		if (selectedProductModel) {
			change('virtualAppliance', selectedProductModel.virtualAppliance);
			toggleVirtualAppliance(selectedProductModel.virtualAppliance);
			if (selectedProductModel.virtualAppliance) {
				if (selectedProductModel?.id === selectedProduct.model.id) {
					change('activationKey', selectedProduct?.activationKey?.productKey);
					return;
				} else {
					setNewActivationKey();
				}
			} else {
				change('activationKey', '');
			}
		}
	};

	const changeProductModel = (value) => {
		const selectedProductModel = productModels.find((productModel) => {
			return productModel.id === value;
		});

		if (selectedProductModel) {
			setChronos(selectedProductModel.chronos);
			setFailoverAndFailback(selectedProductModel.failoverAndFailback);
			changeVirtualAppliance(selectedProductModel);
			setModelId(selectedProductModel);
		}
	};

	useEffect(() => {
		change('activationKey', activationKey);
	}, [activationKey]);

	const renderPostInstallChecksSectionStatusPill = (status) => {
		switch (status) {
			case 'failed':
				return (
					<Tooltip text="Tests have failed" placement="top">
						<Badge className="me-2" appearance="danger">
							<Icon name="fa fa-times" />
						</Badge>
					</Tooltip>
				);
			case 'success':
				return (
					<Tooltip text="Tests are successful" placement="top">
						<Badge className="me-2" appearance="success">
							<Icon name="fa fa-check" />
						</Badge>
					</Tooltip>
				);
			case 'pending':
			default:
				return (
					<Tooltip text="Tests are pending" placement="top">
						<Badge className="me-2" appearance="warning">
							<Icon name="fa fa-clock-o" />
						</Badge>
					</Tooltip>
				);
		}
	};

	const renderPostInstallChecksSection = (postInstallChecks) => {
		const hasPostInstallChecks = postInstallChecks && Object.keys(postInstallChecks).length > 0;

		return hasPostInstallChecks ? (
			<span className="align-items-center">
				{postInstallChecks[0].status && renderPostInstallChecksSectionStatusPill(postInstallChecks[0].status)}
				{!postInstallChecks[0]?.status?.isPending && (
					<Button
						className="pt-0 pb-0 ps-1 pe-1"
						role="tertiary"
						type="submit"
						onClick={() => {
							return setShowPostInstallChecksModal(true);
						}}
					>
						<Icon className="me-2" name="fa fa-eye" color="#4f5ed7" /> View Logs
					</Button>
				)}
			</span>
		) : (
			<span>Not performed for this machine</span>
		);
	};

	function copyToClipboard(inputId) {
		const copyText = document.getElementById(inputId);
		copyText.select();
		document.execCommand('copy');
	}

	return (
		<form
			onSubmit={(e) => {
				return e.preventDefault();
			}}
		>
			<fieldset className="border-0 mb-3">
				<div className="row">
					<div className="col-8">
						<h4 className="with-border">Appliance</h4>
						<div className="row">
							<div className="col-5">
								<Form.select
									label="Model"
									name="modelId"
									options={SelectOptionsRenderer.renderProductModel(productModels)}
									onValueChange={changeProductModel}
									emptyOption="Please select"
									required
								/>
								<ServiceField name="hasChronos" label="Chronos" stateValue={chronos} changePropsInParent={change} />
								<ServiceField
									name="hasFailoverAndFailback"
									label="Failover & Failback"
									stateValue={failoverAndFailback}
									changePropsInParent={change}
								/>
							</div>
							<div className="col-4">
								<Form.text
									name="serialNumber"
									label="Serial number"
									disabled={virtualAppliance}
									placeholder={virtualAppliance ? 'No serial attached' : ''}
									required={!virtualAppliance}
								/>
							</div>
							<div className="col-3">
								<Form.text name="saleValue" label="Sale value" />
							</div>
						</div>
						<div className="row">
							<div className="d-flex gap-1">
								<label className="form-label pf-2">Continuous Authentication:</label>
								<Form.checkbox noMarginBottom name="hasContinuousAuthentication" />
							</div>
							<div className="d-flex gap-1">
								<label className="form-label pf-2">Multi-factor Authentication:</label>
								<Form.checkbox noMarginBottom name="hasMultiFactorAuthentication" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Has legal hold:</label>
								<Form.checkbox noMarginBottom name="hasLegalHold" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Is upgradable to OS5:</label>
								<Form.checkbox noMarginBottom name="isUpgradableToOs5" />
							</div>
							<div className="col-12 d-flex gap-1">
								<label className="form-label pf-2">Has Cloud Gate: </label>
								<Form.checkbox
									name="hasCloudGate"
									disabled={modelId && modelId.title === 'Cloud Gate Appliance' ? true : false}
									style={{ cursor: modelId && modelId.title === 'Cloud Gate Appliance' ? 'not-allowed' : 'default' }}
								/>
							</div>
							{selectedProduct.assemblyTests && (
								<div className="d-flex gap-1">
									<label className="form-label pf-2">Assembly test results:</label>
									{renderPostInstallChecksSection(selectedProduct.assemblyTests)}
								</div>
							)}
							<div className="col-12">
								<Form.textarea
									name="maintenanceService.notes"
									label="Notes"
									style={{ height: 85 + 'px' }}
									required={virtualAppliance}
								/>
							</div>
						</div>
					</div>
					<div className="col-4">
						<h4 className="with-border border-0 p-0">Specify activation details</h4>
						<div className="activation-details mb-2 border-radius-5">
							<div className="row">
								<div className="col-12">
									<Form.select
										label="End-user"
										name="userId"
										options={SelectOptionsRenderer.renderCompaniesWithUsers(companiesWithEndUsers)}
										emptyOption="Not set"
										disabled={virtualAppliance}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="mb-3">
										<Form.datepicker
											formName={formName}
											calendarClassName="activation-details__date-picker"
											id="activationDate"
											name="activationDate"
											label="Activation date"
											placeholderText="Not set"
											placement="left"
											initValue={
												selectedProduct.activationDate
													? moment(new Date(selectedProduct.activationDate), 'DD/MM/YYYY').toDate()
													: null
											}
											autoComplete="off"
										/>
									</div>
									<div className={virtualAppliance ? 'd-block' : 'd-none'}>
										<label className="font-weight-bold">Activation Code</label>
										<div className="float-end">
											<Tooltip text="Copy activation key" placement="top" trigger="click">
												<Button
													className="p-0 me-2"
													role="tertiary"
													onClick={() => {
														return copyToClipboard('activationKey');
													}}
												>
													<Icon name="fa fa-copy" color="black" />
												</Button>
											</Tooltip>
										</div>
										<Form.text id="activationKey" name="activationKey" readOnly placeholder="Not set" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<OperationsProductsModalMaintenancePlans
				maintenancePlansOptions={maintenancePlansOptions}
				initialMaintenanceType={selectedProduct?.maintenanceService?.maintenancePlanId ?? null}
				serenityValue={selectedProduct?.maintenanceService?.hasSerenity ?? false}
				change={change}
			/>

			{selectedProduct.activationDate && (
				<FieldArray
					name="maintenanceService.renewals"
					props={fields}
					maintenancePlansOptions={maintenancePlansOptions}
					maintenanceRenewals={selectedProduct?.maintenanceService?.renewals ?? []}
					refreshMaintenanceDetails={() => {
						return loadMaintenanceDetailsOperations(selectedProduct.maintenanceService.id);
					}}
					changeProps={changeProps}
					component={OperationsProductsEditModalMaintenanceExtension}
				/>
			)}

			{showPostInstallChecksModal && (
				<PostInstallCheckLogsModal
					show={true}
					onHide={() => {
						return setShowPostInstallChecksModal(false);
					}}
					postInstallData={selectedProduct.assemblyTests[0]}
					userRole={user?.account?.role}
				/>
			)}
		</form>
	);
};

OperationsProductsEditModalBody.propTypes = {
	initialValues: PropTypes.object,
	user: PropTypes.object.isRequired,
	companiesWithEndUsers: PropTypes.array.isRequired,
	change: PropTypes.func.isRequired,
	productModels: PropTypes.array.isRequired,
	maintenancePlansOptions: PropTypes.array.isRequired,
	loadMaintenanceDetailsOperations: PropTypes.func.isRequired,
	getActivationCode: PropTypes.func.isRequired,
};
