import React from 'react';
import { Modal } from '@syneto/compass-react';

const ActionModal = ({ showModal, onHide, callback, primaryLabel, title, content }) => {
	return (
		<Modal
			show={showModal}
			onHide={onHide}
			title={title}
			content={content}
			actions={{
				primary: {
					label: primaryLabel,
					onClick: callback
				},
				secondary: {
					label: 'Cancel'
				}
			}}
		/>
	);
};

export default ActionModal;

