import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Modal, Button, Icon } from '@syneto/compass-react';
import Form from '../../../../../components/forms/form';
import SelectOptionsRenderer from '../../../../../helpers/SelectOptionsRenderer';
import SerenityField from '../../../../../components/forms/common/serenity-field';

const OperationsProductsModalMaintenancePlans = (props) => {
	const { maintenancePlansOptions, initialMaintenanceType, serenityValue, change, extension, extensionName, deleteCallBack } = props;
	const [isRenewal, setIsRenewal] = useState(!!extension && !!extensionName && !!deleteCallBack);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [maintenanceType, setMaintenanceType] = useState(initialMaintenanceType);
	const changeMaintenanceType = (maintenanceType) => { return setMaintenanceType(maintenanceType); };

	useEffect(() => {
		setIsRenewal(!!extension && !!extensionName && !!deleteCallBack);
	},[extension, extensionName, deleteCallBack]);

	const renderType = () => {
		return (<>
			{!isRenewal && (
				<Row className="mb-2">
					<b>Type</b>
				</Row>
			)}
			<Row>
				<Col className="d-flex center">
					{isRenewal ? (<Badge appearance="success">R</Badge>) : (<Badge appearance="info">I</Badge>)}
				</Col>
			</Row>
		</>);
	};

	const renderSupportLevel = () => {
		return <Form.select
			{... !isRenewal ? { label: 'Support level' } : {}}
			name={isRenewal ? `${extensionName}.maintenancePlanId` : 'maintenanceService.maintenancePlanId'}
			options={SelectOptionsRenderer.renderMaintenanceTypesOptions(maintenancePlansOptions)}
			onValueChange={(value) => { return changeMaintenanceType(value); }}
			emptyOption="Please select"
		/>;
	};

	const renderSerenity = () => {
		return (<>
			{!isRenewal && (
				<Row className="mb-2">
					<b>Serenity</b>
				</Row>
			)}
			<Row>
				<Col className="d-flex center">
					<SerenityField
						name={isRenewal ? `${extensionName}.hasSerenity` : 'maintenanceService.hasSerenity'}
						value={serenityValue}
						maintenanceType={maintenanceType}
						changePropsInParent={change} />
				</Col>
			</Row>
		</>);
	};

	const renderDuration = () => {
		return <Form.select
			{... !isRenewal ? { label: 'Duration' } : {}}
			name={isRenewal ? `${extensionName}.duration` : 'maintenanceService.duration'}
			options={SelectOptionsRenderer.renderMaintenanceDurationsOptions()}
			emptyOption="Please select"
		/>;
	};

	const renderQuoteNumber = () => {
		return (<Form.text
			{... !isRenewal ? { label: 'Quote number' } : {}}
			className="text-value"
			id={isRenewal ? `${extensionName}.quoteNumber` : 'maintenanceService.quoteNumber'}
			name={isRenewal ? `${extensionName}.quoteNumber` : 'maintenanceService.quoteNumber'}
			required
		/>);
	};

	const renderSaleValue = () => {
		return (<Form.text
			{... !isRenewal ? { label: 'Sale value' } : {}}
			className="text-value"
			id={isRenewal ? `${extensionName}.saleValue` : 'maintenanceService.saleValue'}
			name={isRenewal ? `${extensionName}.saleValue` : 'maintenanceService.saleValue'}
		/>);
	};

	return (
		<form>
			<Row>
				<Col xs={1}>
					{renderType()}
				</Col>

				<Col xs={3}>
					{renderSupportLevel()}
				</Col>

				<Col xs={1}>
					{renderSerenity()}
				</Col>

				<Col xs={2}>
					{renderDuration()}
				</Col>

				<Col xs={2}>
					{renderQuoteNumber()}
				</Col>

				<Col xs={2}>
					{renderSaleValue()}
				</Col>

				{isRenewal && (
					<Col xs={1}>
						<Button
							role="tertiary"
							onClick={() => { return setShowModalDelete(true); }}
						>
							<Icon name="fa fa-trash" className="text-muted" />
						</Button>
					</Col>
				)}
			</Row>
			{isRenewal && (
				<Modal
					show={showModalDelete}
					onHide={() => { return setShowModalDelete(false); }}
					title="Delete extension plan"
					content={
						<>
							{
								extension && extension.quoteNumber
									?
									<p>Are you sure you want to delete the extension plan with the following quote
							number: {extension.quoteNumber}?</p>
									: <p>Are you sure you want to delete this extension plan?</p>
							}
						</>
					}
					actions={{
						primary: {
							label: 'Delete',
							onClick: () => {
								deleteCallBack();
								setShowModalDelete(false);
							}
						},
						secondary: {
							label: 'Cancel',
							onClick: () => { 
								setShowModalDelete(false); 
							}
						}
					}}
				/>
			)}
		</form>
	);
};

export default OperationsProductsModalMaintenancePlans;
