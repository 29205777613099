import moment from 'moment';
import Utils from '../../../../client/helpers/Utils';

class ValidationService {
	static isSerialNumberValid = (serialNumber) => {
		const regExSerialNumberEndUser = /SY[0-9]{10}$/;
		const regExSerialNumberVMWare = /\VMware-/;
		if (serialNumber === null || serialNumber === undefined) {
			return false;
		} else {
			return regExSerialNumberEndUser.test(serialNumber) || regExSerialNumberVMWare.test(serialNumber);
		}
	};
	static isDurationValid = (maxMaintenanceExtension, totalDuration) => {
		return totalDuration <= maxMaintenanceExtension;

	};
	static validateSelectOptionsModel = (values) => {
		let hasErrors = false;

		if ( values ) {
			if ( !values.modelId || values.modelId === 'Please select' ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	};

	static validateSelectOptionsRenewal = (value) => {
		let hasErrors = false;
		if (!value || !value.length) {
			hasErrors = true;
		}

		return hasErrors;
	};

	static validateSelectOptionsDuration = (values) => {
		let hasErrors = false;

		if ( values && values.maintenance ) {
			if ( !values.maintenance.duration || values.maintenance.duration === 'Select a duration' ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	};
	static validateSelectMaintenanceType = (values) => {
		let hasErrors = false;

		if ( values && values.maintenance ) {
			if ( !values.maintenance.type || values.maintenance.type === 'Select a maintenance type' ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	};

	static validateSelectEndUser = (values) => {
		let hasErrors = false;
		let message = 'Please select activation date.';
		let regExp = new RegExp(/^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/);

		const activationDate = values && values.activationDate;

		if (!values.virtualAppliance) {
			if ( values.userId !== '' && values.userId !== null ) {
				if ( activationDate == null || activationDate === '' ) {
					hasErrors = true;
				} else if ( !regExp.test(activationDate) ) {
					hasErrors = true;
					message = 'Invalid activation date.';
				}
			}
		}

		return { hasErrors: hasErrors, message: message };
	};

	static validateSelectActivationDate = (values) => {
		let hasErrors = false;

		const activationDate = values && values.activationDate;

		if (!values.virtualAppliance) {
			if ( activationDate != null && activationDate !== '' && (values.userId === '' || values.userId === null) ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	};

	static isSelectActivationDateInFuture = (values) => {
		let hasWarnings = false;
		let today = moment();
		let activationDate = values && moment(values.activationDate, 'DD/MM/YYYY');

		if ( activationDate > today ) {
			hasWarnings = true;
		}

		return hasWarnings;
	};

	static warn = (values) => {
		let warnings = {
			product: {}
		};

		if ( ValidationService.isSelectActivationDateInFuture(values) ) {
			warnings.activationDate = 'Selected date is in the future';
		}

		return warnings;
	};

	static validate = (values) => {
		let errors = {
			product:     {},
			maintenance: {},
			maintenanceService: { renewals: [] }
		};

		let hasErrorsValidateQuoteNumberField = ValidationService.validateQuoteNumberField(values && values.maintenanceService && values.maintenanceService.quoteNumber);
		let hasErrorsValidateSerialNumberField = ValidationService.validateSerialNumberField(values);
		let hasErrorsValidateNotesField = ValidationService.validateNotesField(values);
		let hasErrorsValidateSelectOptionsModel = ValidationService.validateSelectOptionsModel(values);
		let hasErrorsValidateSelectOptionsDuration = ValidationService.validateSelectOptionsDuration(values);
		let hasErrorsValidateSelectMaintenanceType = ValidationService.validateSelectMaintenanceType(values);
		let hasErrorsValidateSelectEndUser = ValidationService.validateSelectEndUser(values);
		let hasErrorsValidateSelectActivationDate = ValidationService.validateSelectActivationDate(values);
		let hasErrorsValidateSaleValueProduct = ValidationService.validateSaleValue(values && values.saleValue);
		let hasErrorsValidateSaleValueMaintenance = ValidationService.validateSaleValue(values && values.maintenanceService && values.maintenanceService.saleValue);

		if ( hasErrorsValidateQuoteNumberField ) {
			errors.maintenanceService.quoteNumber = 'Cannot be empty';
		}

		if ( hasErrorsValidateSerialNumberField.missingSerialNumber ) {
			errors.serialNumber = 'Cannot be empty';
		}

		if ( hasErrorsValidateNotesField.missingNotes ) {
			errors.maintenanceService.notes = 'Cannot be empty';
		}

		// if (hasErrorsValidateSerialNumberField.invalidFormatSerialNumber) {
		//   errors.serialNumber = 'Invalid format for serial number.'
		// }

		if ( hasErrorsValidateSelectOptionsModel ) {
			errors.modelId = 'Cannot be empty';
		}

		if ( hasErrorsValidateSelectOptionsDuration ) {
			errors.maintenance.duration = 'Cannot be empty';
		}

		if ( hasErrorsValidateSelectMaintenanceType ) {
			errors.maintenance.type = 'Cannot be empty';
		}

		if ( hasErrorsValidateSelectActivationDate ) {
			errors.userId = 'Please select end-user.';
		}

		if ( hasErrorsValidateSelectEndUser.hasErrors ) {
			errors.activationDate = hasErrorsValidateSelectEndUser.message;
		}

		if ( hasErrorsValidateSaleValueProduct ) {
			errors.saleValue = 'Should look like this:\n' +
                                 '4000  1456.43  1456,43 or 1.456,43';
		}

		if ( hasErrorsValidateSaleValueMaintenance ) {
			errors.maintenanceService.saleValue = 'Should look like this:\n' +
                                     '4000  1456.43  1456,43 or 1.456,43';
		}
		ValidationService.validateMaintenanceExtensions(errors, values);
		
		return errors;
	};

	static validateMaintenanceExtensions = (errors, values) => {

		if ( values.maintenanceService && values.maintenanceService.renewals && values.maintenanceService.renewals.length > 0 ) {
			for (let i = 0; i <= values.maintenanceService.renewals.length - 1; i++) {
				errors.maintenanceService.renewals[ i ] = {};

				let hasErrorsValidateSelectOptionsRenewal = ValidationService.validateSelectOptionsRenewal(values.maintenanceService.renewals[ i ].maintenancePlanId);
				let hasErrorsValidateQuoteNumberField = ValidationService.validateQuoteNumberField(values.maintenanceService.renewals[ i ].quoteNumber);
				let hasErrorsValidateSaleValue = ValidationService.validateSaleValue(values.maintenanceService.renewals[ i ].saleValue);

				if ( hasErrorsValidateSelectOptionsRenewal ) {
					errors.maintenanceService.renewals[ i ].maintenancePlanId = 'Cannot be empty';
				}

				if ( hasErrorsValidateQuoteNumberField ) {
					errors.maintenanceService.renewals[ i ].quoteNumber = 'Cannot be empty';
				}

				if ( hasErrorsValidateSaleValue ) {
					errors.maintenanceService.renewals[ i ].saleValue = 'Should be float.';
				}
			}
		}

		return errors;
	};

	static validateQuoteNumberField(quoteNumber) {
		let hasErrors = false;
		if (!quoteNumber || (quoteNumber && quoteNumber.length === 0)) {
			hasErrors = true;
		}

		return hasErrors;
	}

	static validateSerialNumberField(values) {
		let hasErrors = {
			missingSerialNumber: false
		};
		if ( !values.activationKey && !values.activationKey && !values.serialNumber ) {
			hasErrors.missingSerialNumber = true;
		}

		return hasErrors;
	}

	static validateNotesField(values) {
		let hasErrors = {
			missingNotes: false
		};
		if ( values.activationKey && values.maintenanceService && !values.maintenanceService.notes ) {
			hasErrors.missingNotes = true;
		}

		return hasErrors;
	}

	static validateSaleValue = (saleValue) => {
		let hasErrors = false;
		let normSaleValue = Utils.normalizeSaleValue(saleValue);

		let regExp = new RegExp(/^(\d+)(\.\d{1,2})?$/);

		if ( saleValue && !regExp.test(normSaleValue) ) {
			hasErrors = true;
		}

		return hasErrors;
	};

	static validateSearchField(values) {
		let errors = {};
		if ( !values.serialNumber ) {
			errors.serialNumber = 'A serial number is required when building the query!';
		}

		return errors;
	}
}

export default ValidationService;
