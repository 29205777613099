import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';

const AdminProductDeleteModal = ({ show, onHide, onDelete }) => {
	return (
		<Modal
			show={ show }
			onHide={ onHide }
			content="Are your sure you want to delete this product?"
			title="Delete product"
			actions={ {
				primary:   {
					label:   'Delete',
					onClick: onDelete
				},
				secondary: {
					label: 'Cancel'
				}
			} }
		/>
	);
};

export default AdminProductDeleteModal;

AdminProductDeleteModal.propTypes = {
	show:     PropTypes.bool.isRequired,
	onHide:   PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};
