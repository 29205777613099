import React from 'react';
import moment from 'moment';
import { Table } from '@syneto/compass-react';
import _ from 'lodash';
import Preloader from '../../../../components/preloader';
import CurrentlyActiveMaintenanceService from '../../../../helpers/CurrentlyActiveMaintenanceService';

const MarketingReportsTable = (props) => {
	const { maintenanceServices, loading } = props;

	const renderCompanyName = (cell, row) => { return row.companyName ?? 'N/A'; };
	const renderUserName = (cell, row) => { return (row.user.firstName !== '' && row.user.lastName !== '') ? `${row.user.firstName} ${row.user.lastName}` : ''; };
	const renderModel = (cell, row) => { return _.get(row, 'product.product_model.title', null); };
	const renderMaintenance = (cell, row) => { return CurrentlyActiveMaintenanceService(row).maintenance_plan.customerFacingName; };
	const renderMachineSN = (cell, row) => { return _.get(row, 'product.serialNumber', ''); };
	const renderLastActiveDay = (cell, row) => { return moment(row.expiresOn).format('DD/MM/YYYY'); };

	const emptyTableState = () => {
		return loading 
			? <Preloader dataIsLoading={true} />
			: 'There are no results to match the filter criteria. Please change the filter to give the required results.';
	};

	return (
		<Table
			data={!loading ? maintenanceServices : []}
			keyField="id"
			noDataIndication={emptyTableState()}
		>
			<Table.Col field="company" renderCell={renderCompanyName}>Company name</Table.Col>
			<Table.Col field="userName" renderCell={renderUserName}>User name</Table.Col>
			<Table.Col field="model" renderCell={renderModel}>Model</Table.Col>
			<Table.Col field="maintenance" renderCell={renderMaintenance}>Maintenance</Table.Col>
			<Table.Col field="product.serialNumber" renderCell={renderMachineSN}>Machine SN</Table.Col>
			<Table.Col field="lastActiveDay" renderCell={renderLastActiveDay}>Last day active</Table.Col>
		</Table>
	);
};

export default MarketingReportsTable;
