import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { Button } from '@syneto/compass-react';
import {
	updateRasSettings,
	getRasSettings
} from './data/actions';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'rasSalesEmail': ['required', 'email'],
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminSettingsRASTab = (props) => {
	const { handleSubmit, submitting, rasSalesEmailError } = props;

	useEffect(() => {
		props.activeKey === 'ras' && props.getRasSettings();
		props.reset('ras');
	}, [props.activeKey]);

	function submit(formValues) {
		props.dispatch(updateRasSettings(formValues)).then(() => {
			props.dispatch(pushSuccessfulNotification('RAS settings updated.'));
		}).catch(() => {
			props.statusRasSettings?.map(error);
			props.dispatch(pushDangerNotification(props.statusRasSettings));
		});
	}

	return (
		<form>
			<div className="mb-3" style={{ minHeight: '104px' }}>
				<label htmlFor="rasSalesEmail">RAS requests email</label>
				<Form.text name="rasSalesEmail" />
				{!Object.prototype.hasOwnProperty.call(rasSalesEmailError, 'rasSalesEmail') &&
					<span
						className="text-secondary form-feedback">Email address to send end user requests for RAS service</span>
				}
			</div>

			<Button disabled={submitting} onClick={handleSubmit(submit)} type="submit">
				{!submitting ? 'Save' : 'Saving...'}
			</Button>
		</form>
	);
};

AdminSettingsRASTab = reduxForm({
	form: 'rasSettings',
	enableReinitialize: true,
	validate: validate
})(AdminSettingsRASTab);

const mapStateToProps = (state) => {
	const selector = getFormSyncErrors('rasSettings');
	const rasSalesEmailError = selector(state, 'rasSalesEmail');
	const { rasSettings } = state.adminSettings;

	return {
		initialValues: {
			rasSalesEmail: rasSettings && rasSettings.rasSalesEmail ? rasSettings.rasSalesEmail.replace(/\s/g, '') : null,
		},
		rasSalesEmailError,
	};
};

export default connect(mapStateToProps, { getRasSettings })(AdminSettingsRASTab);
