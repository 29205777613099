import { actionTypes } from '../actionTypes';

const INITIAL_STATE = {
	section: {
		isActive: false,
		headerTextIdentifier: '',
		headerMenuOptions: [],
		menuIdentifier: ''
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.navigation.admin.clicked:
			return {
				...state,
				section: {
					isActive: true,
					headerTextIdentifier: action.payload.headerTextIdentifier,
					headerMenuOptions: action.payload.menuItems,
					menuIdentifier: action.payload.menuIdentifier
				}
			};
		default:
			return state;
	}
};
