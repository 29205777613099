import Validator from 'validatorjs';

let messages = Validator.getMessages('en');
messages.email = 'Format is invalid';
messages.alpha = 'Only letters allowed';
messages.min.string = 'Must be at least :min characters';
messages.max.string = 'May not be greater than :max characters';
messages.numeric = 'Must be a number';
messages.integer = 'Must be an integer number';
messages.required = 'Cannot be empty';
messages.same = 'Must match value from :same';
messages.regex = 'Format is invalid';
Validator.setMessages('en', messages);
Validator.stopOnError(true);

export default Validator;
