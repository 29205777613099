import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Table } from '@syneto/compass-react';
import DeleteProductModels from './adminProductModelsDeleteModal';
import Preloader from '../../../../components/preloader';
import EditHyperModels from './adminProductModelsEditModal';
import AddHyperModel from './adminProductModelsAddModal';
import { loadProductModels } from '../../../../data/productModels/action';

function ProductModels(props) {
	let { loading, productModels, loadProductModels } = props;
	const [productModel, setProductModel] = useState(null);
	const [showEditModal, toggleEditModal] = useState(false);
	const [showDeleteModal, toggleDeleteModal] = useState(false);

	useEffect(() => {
		loadProductModels();
	}, []);

	const openEditModal = (productModel) => {
		setProductModel(productModel);
		toggleEditModal(true);
	};

	const closeEditModal = () => {
		toggleEditModal(false);
	};

	const openDeleteModal = (productModel) => {
		setProductModel(productModel);
		toggleDeleteModal(true);
	};

	const renderCurrentlySelling = (cell, row) => {
		return (
			row.currentlySelling ? <span className="rounded-circle success"><Icon name="fa fa-check" /></span>
				: <span className="rounded-circle warning"><Icon name="fa fa-times" /></span>
		);
	};

	const renderRenewableField = (cell, row) => {
		return (
			row.isRenewable ? <span className="rounded-circle success"><Icon name="fa fa-check" /></span>
				: <span className="rounded-circle warning"><Icon name="fa fa-times" /></span>
		);
	};

	const renderActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { return openEditModal(row); }}>
					Edit
				</Button>
				<Button role="tertiary" disabled={row.count > 0} onClick={(e) => {
					openDeleteModal(row);
					e.stopPropagation();
				}}>
					Delete
				</Button>
			</>
		);
	};

	return (
		<div id="productsModals" className="p-4 bg-white">
			{loading ? <Preloader dataIsLoading={loading} /> :
				<Table data={productModels} keyField="id" rowEvents={{ onClick: (e, row) => { return openEditModal(row); } }}>
					<Table.Col field="title">
						Model Name
					</Table.Col>
					<Table.Col field="currentlySelling" align="center" renderCell={renderCurrentlySelling}>
						Currently Selling
					</Table.Col>
					<Table.Col field="isRenewable" align="center" renderCell={renderRenewableField}>
						Send renewal email
					</Table.Col>
					<Table.Col align="center" renderCell={renderActions}>
						Actions
					</Table.Col>
				</Table>
			}
			<EditHyperModels isOpen={showEditModal}
				closeModal={closeEditModal}
				productModel={productModel}
				className="modal-dialog modal-lg"
				classNameModalHeader="modal-header"
				toggleDeleteModal={toggleDeleteModal}
				loadProductModels={loadProductModels} />
			<DeleteProductModels showDeleteModal={showDeleteModal}
				closeDeleteModal={() => { return toggleDeleteModal(false); }}
				closeEditModal={closeEditModal}
				productModel={productModel}
				loadProductModels={loadProductModels} />
			<AddHyperModel
				fetchProductModels={loadProductModels}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		productModels: state.productModels.models,
		loadingProductModels: state.productModels.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadProductModels: () => {
			return dispatch(loadProductModels());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModels);
