import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status:                 null,
	error:                  null,
	loading:                false,
	showPasswordResetModal: false
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.PASSWORD_RESET_SUCCESS:
			return {
				...state,
				status:  'resetted',
				error:   false,
				loading: false
			};
		case actionTypes.PASSWORD_RESET_FAILURE:
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status:  'failed',
				error:   error,
				loading: false
			};
		case actionTypes.SHOW_PASSWORD_RESET_MODAL:
			return {
				...state,
				showPasswordResetModal: true
			};
		case actionTypes.HIDE_PASSWORD_RESET_MODAL:
			return {
				...state,
				showPasswordResetModal: false
			};
		default:
			return state;
	}
};
