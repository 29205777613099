import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';
import moment from 'moment';
import _ from 'lodash';
import Utils from '../../helpers/Utils';
import Currency from '../../helpers/money';

export const loadProductMaintenanceServicesForSupport = () => {
	return (dispatch) => {
		dispatch(loadingMaintenanceServices());
		return axios.get('/support/products/maintenance')
			.then((response) => {
				dispatch(loadingProductMaintenanceServicesSuccess(response.data));
			}).catch((error) => {
				dispatch(loadingProductMaintenanceServicesError(error));
			});
	};
};

const loadingMaintenanceServices = () => {
	return {
		type: actionTypes.LOADING_MAINTENANCE_SERVICES
	};
};

const loadingProductMaintenanceServicesSuccess = (data) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS,
		payload: {
			maintenanceServices: data.maintenanceServices,
			maxMaintenanceExtension: data.maxMaintenanceExtension
		}
	};
};

const loadingProductMaintenanceServicesError = (error) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR,
		payload: error
	};
};

export const loadProductMaintenanceServiceDetails = (maintenanceServiceId) => {
	return (dispatch) => {
		dispatch(loadingProductMaintenanceServiceDetails());
		return axios.get(`/support/maintenance/${maintenanceServiceId}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch(loadProductMaintenanceServiceDetailsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(loadProductMaintenanceServiceDetailsError(error?.response?.data?.message));
			});
	};
};

const loadingProductMaintenanceServiceDetails = () => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS
	};
};

const loadProductMaintenanceServiceDetailsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS,
		payload: data.maintenanceService
	};
};

const loadProductMaintenanceServiceDetailsError = (error) => {
	return {
		type: actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR,
		payload: error
	};
};

export const loadMaintenanceDetailsOperations = (maintenanceId) => {
	return (dispatch) => {
		return axios.get(`/operations/maintenance/${maintenanceId}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch(loadMaintenanceDetailsOperationsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(loadMaintenanceDetailsOperationsError(error?.response?.data?.message));
			});
	};
};

const loadMaintenanceDetailsOperationsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_SUCCESS,
		payload: data.maintenanceService
	};
};

const loadMaintenanceDetailsOperationsError = (error) => {
	return {
		type: actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_ERROR,
		payload: error.message
	};
};

export const deleteMaintenanceServiceData = (id, product_id) => {
	return (dispatch) => {
		return axios.delete(`/operations/maintenance/service/${id}/${product_id}`)
			.then((response) => {
				dispatch(deleteMaintenanceServiceSuccess(response.data));
			}).catch((error) => {
				dispatch(deleteMaintenanceServiceError(error.response.data));
			});
	};
};

const deleteMaintenanceServiceSuccess = (data) => {
	return {
		type: actionTypes.DELETE_MAINTENANCE_SERVICE_DATA_SUCCESS,
		payload: data
	};
};

const deleteMaintenanceServiceError = (error) => {
	return {
		type: actionTypes.DELETE_MAINTENANCE_SERVICE_DATA_ERROR,
		payload: error
	};
};


export const bulkDeleteMaintenanceServices = (values) => {
	let maintenanceServicesIds = values.map((value) => { return value.id; });
	let maintenanceServicesProductsIds = values.map((value) => { return value.productId; });

	return (dispatch) => {
		return axios.delete('/operations/maintenance/services', {
			params: {
				maintenanceServicesIds,
				maintenanceServicesProductsIds
			}
		}).then((response) => {
			dispatch(deleteMaintenanceServicesSuccess(response.data));
		}).catch((error) => {
			dispatch(deleteMaintenanceServicesError(error.response.data));
		});
	};
};


const deleteMaintenanceServicesSuccess = (data) => {
	return {
		type: actionTypes.DELETE_MAINTENANCE_SERVICES_DATA_SUCCESS,
		payload: data
	};
};

const deleteMaintenanceServicesError = (data) => {
	return {
		type: actionTypes.DELETE_MAINTENANCE_SERVICES_DATA_ERROR,
		payload: data
	};
};

export const updateMaintenanceServiceSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_MAINTENANCE_SERVICE_DATA_SUCCESS,
		payload: data
	};
};

export const updateMaintenanceServiceError = (error) => {
	return {
		type: actionTypes.UPDATE_MAINTENANCE_SERVICE_DATA_ERROR,
		payload: error
	};
};
 
export const resetMaintenanceServiceDetails = () => {
	return {
		type: actionTypes.RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS
	};
};

export const createMaintenanceServiceData = (formFields) => {
	let _plan = _.cloneDeep(formFields);
	_plan.product.activationDate = _plan.product.activationDate ? moment(_plan.product.activationDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

	const currency = Currency.EUR();
	_plan.product.saleValue = Utils.normalizeSaleValue(_plan.product.saleValue) * currency.getCentFactor();
	_plan.maintenance.saleValue = Utils.normalizeSaleValue(_plan.maintenance.saleValue) * currency.getCentFactor();

	return (dispatch) => {
		return axios.post('/operations/maintenance/services', _plan)
			.then((response) => {
				dispatch(createMaintenanceServiceSucces(response.data));
			}).catch((error) => {
				dispatch(createMaintenanceServiceError(error.response.data));
			});
	};
};

const createMaintenanceServiceSucces = (data) => {
	return {
		type: actionTypes.CREATE_MAINTENANCE_SERVICE_DATA_SUCCESS,
		payload: data
	};
};

const createMaintenanceServiceError = (error) => {
	return {
		type: actionTypes.CREATE_MAINTENANCE_SERVICE_DATA_ERROR,
		payload: error
	};
};
