import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { Button, Modal } from '@syneto/compass-react';
import { loadProductModels, updateProductModel, updatingProductModel } from '../../../../data/productModels/action';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values, { 'title': ['required'] });
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminProductModelsEditModal = (props) => {

	let { submitting, handleSubmit, productModel, isOpen, closeModal, reset, toggleDeleteModal } = props;

	//custom hook for useDidMount logic
	function useDidMount() {
		const [didMount, setDidMount] = useState(false);
		useEffect(() => { return setDidMount(true); }, []);
		return didMount;
	}

	const didMount = useDidMount();

	useEffect(() => {
		if (didMount) {
			props.updatedSuccessMessage && props.dispatch(pushSuccessfulNotification(props.updatedSuccessMessage));
			props.updatedFailedMessage && props.dispatch(pushDangerNotification(props.updatedFailedMessage));
		}
	}, [props.updatedSuccessMessage, props.updatedFailedMessage]);

	function cancelEditModal() {
		closeModal();
		reset('editHyperModel');
	}

	function submit(values) {
		return Promise.all([props.updatingProductModel(), props.updateProductModel(values)]).then(() => {
			cancelEditModal();
			props.dispatch(loadProductModels());
		}).catch((error) => {
			props.updatedFailedMessage && props.dispatch(pushDangerNotification(`Product model could not be updated. Reason: ${error}`));
		});
	}

	return (
		<div>
			{productModel &&
				<div>
					<Modal show={isOpen}>
						<Modal.Header>
							<Modal.Title>Edit HYPER Model</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form>
								<dl className="mb-3">
									<dt>Model name</dt>
									<dd>
										<Form.text name="title" />
									</dd>
								</dl>
								<div className="mb-3  ps-0">
									{/*redux-form has an issue with updating checkbox values, reason of why we use "normalize"*/}
									<Field name="currentlySelling" id="currentlySelling" component="input" type="checkbox"
										normalize={(v) => { return !!v; }} />
									<label className="ps-2">Currently selling</label>
								</div>
								<div className="mb-3  ps-0">
									<Field name="isRenewable" id="isRenewable" component="input" type="checkbox"
										normalize={(v) => { return !!v; }} />
									<label className="ps-2">Renewable</label>
								</div>
								<div className="mb-3  ps-0">
									<Field name="virtualAppliance" id="virtualAppliance" component="input" type="checkbox"
										normalize={(v) => { return !!v; }} />
									<label className="ps-2">Virtual Appliance</label>
								</div>
								<div className="mb-3  ps-0">
									<Field name="chronos" id="chronos" component="input" type="checkbox"
										normalize={(v) => { return !!v; }} />
									<label className="ps-2">Has Chronos</label>
								</div>
								<div className="mb-3  ps-0">
									<Field name="failoverAndFailback" id="failoverAndFailback" component="input" type="checkbox"
										normalize={(v) => { return !!v; }} disabled={true} />
									<label className="ps-2">Has Failover & Failback</label>
								</div>
							</form>
						</Modal.Body>
						<Modal.Footer>
							<div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
								<Button appearance="danger"
									role="secondary"
									disabled={submitting || productModel.count > 0}
									onClick={() => { return toggleDeleteModal(true); }}>Delete</Button>
								<div>
									<Button role="secondary" style={{ marginRight: '6px' }}
										onClick={() => { return cancelEditModal(); }}>Cancel</Button>
									<Button disabled={submitting} onClick={handleSubmit(submit)}>Save</Button>
								</div>
							</div>
						</Modal.Footer>
					</Modal>
				</div>}
		</div>
	);
};

AdminProductModelsEditModal = reduxForm({
	form: 'editHyperModel',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate,
	shouldValidate: () => { return true; }
})(AdminProductModelsEditModal);

const mapStateToProps = (state) => {
	return {
		updatedSuccessMessage: state.productModels.updateSuccessMessage,
		updatedFailedMessage: state.productModels.updateErrorMessage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatingProductModel: () => {
			return dispatch(updatingProductModel());
		},
		updateProductModel: (values) => {
			return dispatch(updateProductModel(values));
		}

	};
};

AdminProductModelsEditModal = connect((state, componentProps) => {
	return {
		initialValues: {
			id: componentProps.productModel ? componentProps.productModel.id : null,
			title: componentProps.productModel ? componentProps.productModel.title : null,
			currentlySelling: componentProps.productModel ? componentProps.productModel.currentlySelling : null,
			isRenewable: componentProps.productModel ? componentProps.productModel.isRenewable : null,
			virtualAppliance: componentProps.productModel ? componentProps.productModel.virtualAppliance : null,
			chronos: componentProps.productModel && componentProps.productModel.chronos === 'included',
			failoverAndFailback: componentProps.productModel && componentProps.productModel.failoverAndFailback === 'included'
		}
	};
})(AdminProductModelsEditModal);

AdminProductModelsEditModal = connect(mapStateToProps, mapDispatchToProps)(AdminProductModelsEditModal);

export default AdminProductModelsEditModal;
