import * as actionTypes from './actionTypes';
import * as authActionTypes from '../authenticate/actionTypes';

const INITIAL_STATE = {
	loading: false,
	status: null,
	user: null,
	error: null,
	showProfileModal: false,
	showNotificationPreferencesModal: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.START_USER_PROFILE_UPDATE:
			return {
				...state,
				status: 'panding',
				loading: true,
				error: null
			};
		case authActionTypes.UNAUTHENTICATE:
			return INITIAL_STATE;
		case actionTypes.USER_PROFILE_UPDATE_SUCCESS:
			return {
				...state,
				status: 'success',
				loading: false,
				user: action.payload
			};
		case actionTypes.USER_PROFILE_UPDATE_FAILURE:
			return {
				...state,
				status: 'failed',
				loading: false,
				error: action.payload?.data?.message || action.payload?.message
			};
		case actionTypes.SHOW_PROFILE_MODAL:
			return {
				...state,
				showNotificationPreferencesModal: false,
				showProfileModal: true
			};
		case actionTypes.HIDE_PROFILE_MODAL:
			return {
				...state,
				showProfileModal: false
			};
		case actionTypes.SHOW_NOTIFICATION_PREFERENCES_MODAL:
			return {
				...state,
				showProfileModal: false,
				showNotificationPreferencesModal: true
			};
		case actionTypes.HIDE_NOTIFICATION_PREFERENCES_MODAL:
			return {
				...state,
				showNotificationPreferencesModal: false
			};
		default:
			return state;
	}
};
