import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { Button } from '@syneto/compass-react';
import { reduxForm } from 'redux-form';
import { getVirtualApplianceSettings, updateVirtualApplianceSettings } from './data/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';
import AdminVirtualApplianceDistributionLinks from './adminVirtualApplianceDistributionLinks';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'virtualApplianceOs4GuideUrl': ['url'],
			'virtualApplianceOs4IsoUrl': ['url'],
			'virtualApplianceOs4MD5Url': ['url'],
			'virtualApplianceOs5GuideUrl': ['url'],
			'virtualApplianceOs5IsoUrl': ['url'],
			'virtualApplianceOs5MD5Url': ['url'],
			'maximumNumberOfActivationsPerProductKey': ['required', 'integer']
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminSettingsVirtualApplianceTab = (props) => {
	useEffect(() => {
		props.activeKey === 'virtualAppliance' && props.getVirtualApplianceSettings();
		props.reset('virtualAppliance');
	}, [props.activeKey]);

	function submit(formValues) {
		return props.updateVirtualApplianceSettings(formValues).then(() => {
			props.dispatch(pushSuccessfulNotification('Virtual appliance settings updated'));
		}).catch((error) => {
			props.dispatch(pushDangerNotification('Could not update virtual appliance settings.'));
		});
	}

	return (
		<form className="mb-5">
			<h1 className="mb-1">Maximum Activations</h1>
			<p className="text-secondary mb-4 text-value">
				Configure how many times a Virtual Appliance Key can be used to re-install an appliance.
			</p>
			<Form.text name="maximumNumberOfActivationsPerProductKey" label="Activation key max number of uses"/>
			<AdminVirtualApplianceDistributionLinks />
			<Button onClick={props.handleSubmit(submit)} type="submit">Save</Button>
		</form>
	);
};

AdminSettingsVirtualApplianceTab = reduxForm({
	form: 'virtualAppliance',
	enableReinitialize: true,
	validate: validate
})(AdminSettingsVirtualApplianceTab);

const mapStateToProps = (state) => {
	const { virtualApplianceSettings } = state.adminSettings;

	return {
		initialValues: {
			maximumNumberOfActivationsPerProductKey: virtualApplianceSettings?.maximumNumberOfActivationsPerProductKey ?? '3',
			virtualApplianceOs4GuideUrl: virtualApplianceSettings?.virtualApplianceOs4GuideUrl ?? null,
			virtualApplianceOs4IsoUrl: virtualApplianceSettings?.virtualApplianceOs4IsoUrl ?? null,
			virtualApplianceOs4MD5Url: virtualApplianceSettings?.virtualApplianceOs4MD5Url ?? null,
			virtualApplianceOs5GuideUrl: virtualApplianceSettings?.virtualApplianceOs5GuideUrl ?? null,
			virtualApplianceOs5IsoUrl: virtualApplianceSettings?.virtualApplianceOs5IsoUrl ?? null,
			virtualApplianceOs5MD5Url: virtualApplianceSettings?.virtualApplianceOs5MD5Url ?? null
		}
	};
};

export default connect(mapStateToProps, {
	getVirtualApplianceSettings,
	updateVirtualApplianceSettings
})(AdminSettingsVirtualApplianceTab);
