import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from '@syneto/compass-react';
import { deleteNoteForProduct, getNotesForProduct } from '../../data/actions/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

function SupportNotesDeleteModal(props) {
	let { productId, note, isOpen, toggleModal, noteForPendingProduct } = props;
	let { getNotesForProduct, deleteNoteForProduct, pushSuccessfulNotification, pushDangerNotification } = props;

	function deleteNote() {
		return deleteNoteForProduct(note.id).then(() => {
			getNotesForProduct(productId);
			toggleModal(false);
			pushSuccessfulNotification('Note deleted successfully.');
		}).catch((error) => {
			pushDangerNotification(`An error has occurred. Reason: ${ error.message }`);
		});
	}

	function deleteNotePendingProduct() {
		return deleteNoteForProduct(note.id).then(() => {
			getNotesForProduct(productId);
			toggleModal(false);
			pushSuccessfulNotification('Note deleted successfully.');
		}).catch((error) => {
			pushDangerNotification(`An error has occurred. Reason: ${ error.message }`);
		});
	}

	return (
		<form id="product-notes" className="form modal fade">
			<Modal show={ isOpen }>
				<Modal.Header>
					<Modal.Title>Delete note</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>Are you sure you want to delete this note?</p>
				</Modal.Body>

				<Modal.Footer>
					<Button role="secondary" onClick={ () => { return toggleModal(false); } }>Cancel</Button>
					<Button onClick={ noteForPendingProduct ? deleteNotePendingProduct : deleteNote }>
            Delete
					</Button>
				</Modal.Footer>
			</Modal>
		</form>
	);
}

export default connect(null, {
	deleteNoteForProduct,
	getNotesForProduct,
	pushDangerNotification,
	pushSuccessfulNotification
})(SupportNotesDeleteModal);
