import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, Modal } from '@syneto/compass-react';
import { unflatten } from 'flat';
import { getVMActivationKeys, increaseActivations } from '../../data/actions/actions';
import { pushSuccessfulNotification, pushDangerNotification } from '../../../../data/notifications/actions';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';

const validate = (values) => {
	const validator = new Validator(values, { 'reason': ['required'] });
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let SupportVMActivaionCodesIncrementModal = (props) => {
	const { isOpen, toggleModal, productKey, handleSubmit, reset, getVMActivationKeys, increaseActivations, pushSuccessfulNotification, pushDangerNotification } = props;
	
	const incrementActivations = (formValue) => {
		closeModal();
		increaseActivations(productKey, formValue)
			.then((message) => {
				pushSuccessfulNotification(message);
				getVMActivationKeys();
			})
			.catch((error) => {
				pushDangerNotification(error);
			});
	};

	const closeModal = () => {
		reset('incrementActivations');
		toggleModal(false);
	};

	return (
		<Modal show={isOpen}>
			<Modal.Header>
				<Modal.Title>Increase Activations by 1</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<form noValidate>
					<label>Reason for extension</label>
					<Form.textarea rows="3" name="reason" />
				</form>
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" onClick={closeModal}>Cancel</Button>
				<Button onClick={handleSubmit(incrementActivations)}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
};

SupportVMActivaionCodesIncrementModal = reduxForm({
	form: 'incrementActivations',
	enableReinitialize: true,
	validate,
	shouldValidate: () => { return true; }
})(SupportVMActivaionCodesIncrementModal);

const mapDispatchToProps = (dispatch) => {
	return { 
		getVMActivationKeys: () => { return dispatch(getVMActivationKeys()); }, 
		increaseActivations: (productKey, formValue) => { return dispatch(increaseActivations(productKey, formValue)); }, 
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); } 
	};
};
export default connect(null, mapDispatchToProps)(SupportVMActivaionCodesIncrementModal);
