export const SerenityEventsStatusFormatter = (status) => {
	switch (status) {
		case 'new':
			return 'info';
		case 'pending':
			return 'warning';
		case 'resolved':
			return 'success';
		case 'closed':
			return 'secondary';
	}
};
