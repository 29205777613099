import React, { useEffect, useState } from 'react';
import { Row, Col, Badge } from '@syneto/compass-react';
import _ from 'lodash';
import Preloader from '../../../../../../components/preloader';
import moment from 'moment';
import { getCompanyServices } from '../../../../../../services/companies';

const TabServices = (props) => {
	const { companyId, pushDangerNotification } = props;

	const [loadingCompanyServices, setLoadingCompanyServices] = useState(false);
	const [rasServiceDetails, setRasServiceDetails] = useState(null);

	useEffect(() => {
		loadCompanyServices();
	}, []);

	const loadCompanyServices = async () => {
		try {
			setLoadingCompanyServices(true);

			const companyServices = await getCompanyServices(companyId);
			const rasService = companyServices.find((service) => { return service.name === 'Remote Access Service'; });

			if (rasService) {
				setRasServiceDetails({
					rasAvailableSeats: _.find(rasService.service_properties, { 'name': 'availableSeats' })?.value,
					rasStartingPeriod: _.find(rasService.service_properties, { 'name': 'startingPeriod' })?.value,
					rasEndingPeriod: _.find(rasService.service_properties, { 'name': 'endingPeriod' })?.value
				});
			} else {
				setRasServiceDetails(null);
			}

			setLoadingCompanyServices(false);
		} catch (e) {
			pushDangerNotification('Company services cannot be loaded');
		}
	};

	const renderAvailability = () => {
		const currentDate = moment().format('YYYY-MM-DD');

		if ( rasServiceDetails?.rasEndingPeriod ) {
			return moment(rasServiceDetails.rasEndingPeriod).diff(currentDate, 'days') > 0
				? <Badge appearance="success">Active</Badge>
				: <Badge appearance="danger">Expired</Badge>;
		}

		return null;
	};

	if ( loadingCompanyServices ) { return <Preloader dataIsLoading={ loadingCompanyServices }/>; }

	return rasServiceDetails ? (
		<div className="container mt-3 ps-0 pf-0">
			<div className="row">
				<div className="col-6">
					<h5>RAS Licensing</h5>

					<Row className="mt-3">
						<Col><b>Status</b></Col>
						<Col>{ renderAvailability() }</Col>
					</Row>

					<Row className="mt-2">
						<Col><b>Number of Licensed Seats</b></Col>
						<Col>{ rasServiceDetails?.rasAvailableSeats }</Col>
					</Row>

					<Row className="mt-2">
						<Col><b>Activation date</b></Col>
						<Col>{ rasServiceDetails?.rasStartingPeriod ?
							moment(rasServiceDetails.rasStartingPeriod).format('DD/MM/YYYY') : null }</Col>
					</Row>

					<Row className="mt-2">
						<Col><b>Expiration date</b></Col>
						<Col>{ rasServiceDetails?.rasEndingPeriod ?
							moment(rasServiceDetails.rasEndingPeriod).format('DD/MM/YYYY') : null }</Col>
					</Row>
				</div>
			</div>
		</div>
	)
		: (<p className="text-muted">Currently, the remote access service for this account is not active.</p>);
};

export default TabServices;
