import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { Modal } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import ValidationService from '../../../validation/ValidationService';
import { OperationsProductsWarningModal } from './OperationsProductsWarningModal';
import { OperationsProductsAddModalBody } from './OperationsProductsAddModalBody';
import { createMaintenanceServiceData } from '../../../../../data/productMaintenanceService/actions';
import { getActivationCode, onHideModalAddProduct } from '../../../../../data/product/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../../data/notifications/actions';
import { createProduct } from '../../../../../services/products';
import Currency from '../../../../../helpers/money';
import Utils from '../../../../../helpers/Utils';

const FORM_NAME = 'maintenanceServices';

const OperationsProductsAddModal = (props) => {
	const [showWarningModal, setShowWarningModal] = useState(false);
	const { handleSubmit, submitting, initialValues, change, reset } = props;
	const { companiesWithEndUsers, productModels, currentlySellingMaintenancePlansOptions, activationKey } = props;
	const {
		onHideModalAddProduct,
		getActivationCode,
		createMaintenanceServiceData,
		afterUpdateCallback,
		pushSuccessfulNotification,
		pushDangerNotification,
	} = props;
	const [currentlySellingMaintenancePlansOptionsOrdered, setCurrentlySellingMaintenancePlansOptionsOrdered] = useState(
		[]
	);

	useEffect(() => {
		const orderedCurrentlySellingMaintenancePlans = _.orderBy(
			currentlySellingMaintenancePlansOptions,
			[
				(maintenanceTypeOption) => {
					return maintenanceTypeOption.synetoInternalName.toUpperCase().trim();
				},
			],
			['asc']
		);

		setCurrentlySellingMaintenancePlansOptionsOrdered(orderedCurrentlySellingMaintenancePlans);
	}, [currentlySellingMaintenancePlansOptions]);

	const submit = (values) => {
		if (!ValidationService.isSerialNumberValid(values.serialNumber) && !values.virtualAppliance) {
			return setShowWarningModal(true);
		} else {
			return createMaintenancePlan(values);
		}
	};

	const submitWarningModal = (values) => {
		createMaintenancePlan(values);
		setShowWarningModal(false);
	};

	const createMaintenancePlan = async (values) => {
		const currency = Currency.EUR();
		values.saleValue = parseFloat((Utils.normalizeSaleValue(values.saleValue) * currency.getCentFactor()).toFixed(2));
		values.maintenanceService.saleValue = parseFloat(
			(Utils.normalizeSaleValue(values.maintenanceService.saleValue) * currency.getCentFactor()).toFixed(2)
		);
		if (
			values &&
      values.maintenanceService &&
      values.maintenanceService.renewals &&
      values.maintenanceService.renewals.length > 0
		) {
			values.maintenanceService.renewals.forEach((renewal) => {
				renewal.saleValue = parseFloat(
					(Utils.normalizeSaleValue(renewal.saleValue) * currency.getCentFactor()).toFixed(2)
				);
			});
		}
		createProduct(values)
			.then((response) => {
				onHideModalAddProduct();
				afterUpdateCallback();
				pushSuccessfulNotification(response.data.message);
			})
			.catch((error) => {
				pushDangerNotification(error.response.data.message);
			});
	};

	const cancelAddModal = () => {
		onHideModalAddProduct();
		reset(FORM_NAME);
	};

	return (
		<form id="maintenance-register" className="form modal fade">
			<Modal
				enforceFocus={false}
				show={true}
				onHide={onHideModalAddProduct}
				content={
					<OperationsProductsAddModalBody
						formName={FORM_NAME}
						productModels={productModels}
						users={companiesWithEndUsers}
						maintenancePlansOptions={currentlySellingMaintenancePlansOptionsOrdered}
						initialMaintenanceType={initialValues?.maintenanceService?.maintenancePlanId}
						initialChronos={initialValues?.hasChronos}
						initialFailoverAndFailback={initialValues?.failoverAndFailback}
						change={change}
						getActivationCode={getActivationCode}
						activationKey={activationKey}
					/>
				}
				title="New Product"
				size="lg"
				actions={{
					primary: {
						label: !submitting ? 'Save' : 'Saving...',
						onClick: handleSubmit(submit),
						disabled: submitting,
					},
					secondary: {
						label: 'Cancel',
						onClick: cancelAddModal,
						disabled: submitting,
					},
				}}
			/>

			<OperationsProductsWarningModal
				showWarningModal={showWarningModal}
				closeWarningModal={() => {
					setShowWarningModal(false);
				}}
				onSubmit={handleSubmit(submitWarningModal)}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	const {
		maintenancePlansOptions: { maintenancePlansOptions },
	} = state;

	let firstPlan = (maintenancePlansOptions && maintenancePlansOptions.currentlySelling[0]) || '';

	return {
		productMaintenanceService: state.productMaintenanceService,
		activationKey: state.products.activationKey,
		initialValues: {
			id: '',
			modelId: '',
			serialNumber: null,
			activationDate: null,
			activationKey: null,
			saleValue: '',
			hasChronos: false,
			postInstallChecks: [],
			hasFailoverAndFailback: false,
			userId: '',
			hasContinuousAuthentication: false,
			hasMultiFactorAuthentication: false,
			hasLegalHold: false,
			hasCloudGate: false,
			isUpgradableToOs5: false,
			maintenanceService: {
				plan: null,
				maintenancePlanId: firstPlan ? firstPlan.id : '',
				// type: firstPlan ? firstPlan.synetoInternalName : '',
				hasSerenity: firstPlan ? firstPlan.serenity === 'included' : false,
				duration: 36,
				quoteNumber: '',
				saleValue: '',
				notes: '',
			},
		},
		productModels: state.productModels.models,
		currentlySellingMaintenancePlansOptions: state.maintenancePlansOptions.maintenancePlansOptions.currentlySelling,
		customers: state.customers.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createMaintenanceServiceData: (values) => {
			return dispatch(createMaintenanceServiceData(values));
		},
		onHideModalAddProduct: () => {
			return dispatch(onHideModalAddProduct());
		},
		getActivationCode: () => {
			return dispatch(getActivationCode());
		},
		pushSuccessfulNotification: (message) => {
			return dispatch(pushSuccessfulNotification(message));
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		},
	};
};

const OperationsProductsAddModalForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	validate: ValidationService.validate,
	warn: ValidationService.warn,
	onSubmit: submit,
	shouldValidate: () => {
		return true;
	},
})(OperationsProductsAddModal);

export default connect(mapStateToProps, mapDispatchToProps)(OperationsProductsAddModalForm);

OperationsProductsAddModal.propTypes = {
	afterUpdateCallback: PropTypes.func.isRequired,
	companiesWithEndUsers: PropTypes.array.isRequired,
};
