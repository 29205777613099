import React, { Component } from 'react';
import {
	FlexibleWidthXYPlot,
	VerticalBarSeries,
	XAxis,
	YAxis
} from 'react-vis';
import { connect } from 'react-redux';
import { loadProductUpdateEvents } from '../../data/update-events/actions';
import _ from 'lodash';

class PulseGraph extends Component {
	componentDidMount () {
		this.props.loadProductUpdateEvents(this.props.serialNumber);
	}

	componentDidUpdate (prevProps) {
		if ( prevProps.serialNumber !== this.props.serialNumber ) {
			this.props.loadProductUpdateEvents(this.props.serialNumber);
		}

		if ( prevProps.tabsActiveKey !== this.props.tabsActiveKey ) {
			this.props.tabsActiveKey === 'pulse' && this.triggerWindowResizeEvent();
		}
	}

	triggerWindowResizeEvent() {
		if (typeof(Event) === 'function') {
			// modern browsers
			window.dispatchEvent(new Event('resize'));
		} else {
			// for IE and other old browsers
			// causes deprecation warning on modern browsers
			let evt = window.document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		}
	}

	render() {
		if (!this.props.productUpdateEvents.reactVisGraphLast12MonthsPerWeek) { return null; } // loading here..

		const finishedUpdates = this.props.productUpdateEvents.finishedUpdates;
		const XAxisLabels = (_.uniq(this.props.productUpdateEvents.reactVisGraphLast12MonthsPerWeek.map((element) => {
			return element.x;
		}))).filter((element, index) => {
			return index % 2 === 0;
		});

		return (
			<div>
				{this.props.productUpdateEvents.reactVisGraphLast12MonthsPerWeek.length > 0
					?
					<FlexibleWidthXYPlot height={ 300 } margin={{ bottom: 80, left: 50, right: 10, top: 20 }} xType="ordinal" stackBy="y">
						<XAxis
							tickValues={XAxisLabels}
							tickLabelAngle={ -40 }
							style={ {
								line:  { stroke: '#460390' },
								ticks: { stroke: '#460390' },
								text:  { stroke: 'none', fill: '#6b6b76', fontWeight: 600, fontSize: 10 }
							} }/>
						<YAxis
							tickFormat={ (v) => { return parseInt(v); } }
							style={ {
								line:  { stroke: '#460390' },
								ticks: { stroke: '#460390' },
								text:  { stroke: 'none', fill: '#6b6b76', fontWeight: 600, fontSize: 10 }
							} }/>
						<VerticalBarSeries data={ this.props.productUpdateEvents.reactVisGraphLast12MonthsPerWeek } color={ '#aebdf0' }/>
					</FlexibleWidthXYPlot>
					: <div className="mb-4">No data available to generate the pulse graph.</div>
				}
				<div className="list-wrapper">
					<header className="list_header">
						<h4 className="list_title">Update log</h4>
					</header>
					<ul className="list">
						{finishedUpdates.length
							? finishedUpdates.map((finishedUpdate, index) => {
								return (
									<li key={index} className="list_item">
										{finishedUpdate.status === 'finished'
											? <><div className="list_label">{finishedUpdate.date}</div><div className="list_value">{`Successfully updated from ${finishedUpdate.currentVersion} to ${finishedUpdate.toVersion}`}</div></>
											: <><div className="list_label">{finishedUpdate.date}</div><div className="list_value">{`Failed to update from ${finishedUpdate.currentVersion} to ${finishedUpdate.toVersion}`}</div></>
										}
									</li>
								);
							})
							: <li className="mt-4">No updates data available.</li>
						}
					</ul>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		productUpdateEvents: state.productUpdateEvents.updateEvents
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadProductUpdateEvents: (serialNumber) => {
			dispatch(loadProductUpdateEvents(serialNumber));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PulseGraph);
