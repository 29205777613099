import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';
import { getUsersBasedOnRole, toggleMFABasedOnRole } from '../../../../data/users/action';
import DateFormatterService from '../../../../helpers/DateFormatter';
import InternalUsersTable from './adminInternalUsersTable';
import AdminInternalUsersDeleteModal from './adminInternalUsersDeleteModal';
import UserDetailModal from '../../../../components/UserDetailModal';
import { deleteSelectedUser, hideSelectedUserDeleteModal, hideSelectedUserEditModal, registerUser, showSelectedUserDeleteModal, showSelectedUserEditModal, updateSelectedUser } from '../../../../data/selected-user/actions';
import { usePrevious } from '../../../../hooks/usePrevious';


const getEditUserModalInitValue = (selectedUserDetails) => {
	return {
		userId: selectedUserDetails ? selectedUserDetails.id : 0,
		account: {
			email: selectedUserDetails && selectedUserDetails.email ? selectedUserDetails.email : 'N/A',
			role: selectedUserDetails && selectedUserDetails.role ? selectedUserDetails.role : 'Other',
			data_collection_consent: selectedUserDetails ? selectedUserDetails.data_collection_consent : false,
			subscribe_news: selectedUserDetails ? selectedUserDetails.subscribe_news : false,
			subscribe_product: selectedUserDetails ? selectedUserDetails.subscribe_product : false,
			subscribe_satisfaction_surveys: selectedUserDetails ? selectedUserDetails.subscribe_satisfaction_surveys : false,
			businessRelationship: selectedUserDetails && selectedUserDetails.businessRelationship ? selectedUserDetails.businessRelationship : 'committed',
			customerSince: selectedUserDetails && selectedUserDetails.created_at ? DateFormatterService.formatDateToSpecificFormat(selectedUserDetails, 'created_at', 'DD/MM/YYYY') : ''
		},
		contact: {
			firstName: selectedUserDetails && selectedUserDetails.first_name ? selectedUserDetails.first_name : 'N/A',
			lastName: selectedUserDetails && selectedUserDetails.last_name ? selectedUserDetails.last_name : 'N/A',
			phoneNumber: selectedUserDetails && selectedUserDetails.phone_number ? selectedUserDetails.phone_number : 'N/A'
		},
		mfa: {
			isEnabled: selectedUserDetails && selectedUserDetails.mfa_enabled ? selectedUserDetails.mfa_enabled : false,
			isEnforced: selectedUserDetails && selectedUserDetails.mfa_enforced ? selectedUserDetails.mfa_enforced : false
		}
	};
};

const getAddUserModalInitValute = (role) => {
	return {
		account: {
			email: '',
			password: '',
			passwordCheck: '',
			data_collection_consent: false,
			subscribe_news: false,
			subscribe_product: false,
			subscribe_satisfaction_surveys: false,
			businessRelationship: 'committed',
			customerSince: ''
		},
		contact: {
			firstName: '',
			lastName: '',
			roleInCompany: 'Other',
			phoneNumber: '0000'
		},
		mfa: {
			isEnabled: false,
			isEnforced: false
		},
		userRole: role
	};
};


const AdminInternalUsers = (props) => {
	const {
		getUsersBasedOnRole,
		hideSelectedUserDeleteModal,
		hideSelectedUserEditModal,
		isDeleteUserModalVisible,
		isEditUserModalVisible,
		isMFAEnforcedForCurrentRole,
		loadingEndUser,
		loggedInUser,
		pushDangerNotification,
		pushSuccessfulNotification,
		role,
		selectedUser,
		showSelectedUserDeleteModal,
		showSelectedUserEditModal,
		toggleMFAForCurrentRole,
		addUser,
		deleteUser,
		updateUser,
		users,
		error
	} = props;
	const userUpdatePrevStatus = usePrevious(selectedUser.status);

	useEffect(() => {
		getUsersBasedOnRole(role);
	}, [role]);

	useEffect(() => {
		if (userUpdatePrevStatus === 'panding' && selectedUser.status === 'success') {
			getUsersBasedOnRole(role);
		}
	}, [selectedUser.status, userUpdatePrevStatus]);

	const openEditUserModal = (user) => {
		showSelectedUserEditModal(user);
	};

	const openDeleteUserModal = (user) => {
		showSelectedUserDeleteModal(user);
	};


	const onDeleteUser = async (user) => {
		deleteUser(user.id);
	};

	const handleAddUser = (values) => {
		addUser(values);
	};

	const handleEditUser = (values) => {
		updateUser(values);
	};

	const handleAddUserSuccess = () => {
		pushSuccessfulNotification('User created successfully');
	};

	const handleAddUserError = () => {
		pushDangerNotification(`Could not register user: ${error}`);
	};

	const handleEditUserSuccess = () => {
		pushSuccessfulNotification('User edited successfully');
	};

	const handleEditUserError = () => {
		pushDangerNotification(`Could not edit user: ${error}`);
	};

	const handleDeleteSuccess = () => {
		pushSuccessfulNotification('User deleted successfully');
	};

	const handleDeleteError = () => {
		pushDangerNotification(`Could not delete user: ${error}`);
	};

	const filterUserList = (users) => {
		if (users.length <= 0) {
			return [];
		}

		return users.filter((user) => { return user.id !== loggedInUser.userId; });
	};

	return (
		<div id={role} className="p-4 bg-white">
			<InternalUsersTable
				users={filterUserList(users)}
				role={role}
				loading={loadingEndUser}
				openEditUserModal={openEditUserModal}
				openDeleteUserModal={openDeleteUserModal}
				refreshUserList={getUsersBasedOnRole}
				toggleMFAForCurrentRole={toggleMFAForCurrentRole}
				isMFAEnforcedForCurrentRole={isMFAEnforcedForCurrentRole}
			/>
			{isEditUserModalVisible && (
				<UserDetailModal
					isAdmin={true}
					modalTitle={selectedUser.user ? 'User Details' : `Add ${role}`}
					isUserEdit={!!selectedUser.user}
					closeModal={hideSelectedUserEditModal}
					handleModalActionSuccess={selectedUser.user ? handleEditUserSuccess : handleAddUserSuccess}
					handleModalActioError={selectedUser.user ? handleEditUserError : handleAddUserError}
					initialValues={selectedUser.user ? getEditUserModalInitValue(selectedUser.user) : getAddUserModalInitValute(role)}
					modalAction={selectedUser.user ? handleEditUser : handleAddUser}
					actionStatus={selectedUser.status}
					{...(selectedUser?.user && loggedInUser?.account?.email !== selectedUser.user.email
						? { openDeleteUserModal: () => { return openDeleteUserModal(selectedUser.user); } }
						: {}
					)}
				/>
			)}
			{isDeleteUserModalVisible && (
				<AdminInternalUsersDeleteModal
					userDetails={selectedUser.user}
					closeModal={hideSelectedUserDeleteModal}
					onDelete={onDeleteUser}
					actionStatus={selectedUser.status}
					handleModalActionSuccess={handleDeleteSuccess}
					handleModalActioError={handleDeleteError}
				/>
			)}

		</div>

	);
};

function mapStateToProps(state) {
	return {
		loggedInUser: state.user?.user || null,
		loadingEndUser: state.users.loading,
		isMFAEnforcedForCurrentRole: state.users.isMFAEnforcedForCurrentRole,
		users: state.users.data,
		selectedUser: state.selectedUser,
		error: state.selectedUser.error,
		isEditUserModalVisible: state.selectedUser.showSelectedUserEditModal,
		isDeleteUserModalVisible: state.selectedUser.showSelectedUserDeletetModal
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersBasedOnRole: (role) => {
			dispatch(getUsersBasedOnRole(role))
				.catch(() => {
					dispatch(pushDangerNotification(`Fetch users with ${role} role failed...`));
				});
		},
		pushSuccessfulNotification: (message) => { dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { dispatch(pushDangerNotification(message)); },
		addUser: (userDetails) => { dispatch(registerUser(userDetails, true)); },
		updateUser: (userDetails) => { dispatch(updateSelectedUser(userDetails, true)); },
		deleteUser: (userId) => { dispatch(deleteSelectedUser(userId)); },
		showSelectedUserDeleteModal: (user) => { dispatch(showSelectedUserDeleteModal(user)); },
		hideSelectedUserDeleteModal: () => { dispatch(hideSelectedUserDeleteModal(),); },
		showSelectedUserEditModal: (user) => { dispatch(showSelectedUserEditModal(user)); },
		hideSelectedUserEditModal: () => { dispatch(hideSelectedUserEditModal()); },
		toggleMFAForCurrentRole: (data) => { return dispatch(toggleMFABasedOnRole(data)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInternalUsers);
