import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	error:                               null,
	loading:                             true,
	tokenValid:          				 null,
	formSubmitted:						 null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.VALIDATE_TOKEN_SUCCESS:
			return {
				...state,
				error:                               false,
				loading:                             false,
				tokenValid:          				 action.payload.data.valid,
			};
		case actionTypes.VALIDATE_TOKEN_FAILURE:
			return {
				...state,
				error:                               true,
				loading:                             false,
				tokenValid:          				 null,
			};
		case actionTypes.CREATE_NPS_SUCCESS:
			return {
				...state,
				error:                               false,
				loading:                             false,
			};
		case actionTypes.CREATE_NPS_FAILURE:
			return {
				...state,
				error:                               true,
				loading:                             false,
			};
		case actionTypes.UPDATE_NPS_SUCCESS:
			return {
				...state,
				error:                               false,
				loading:                             false,
				tokenValid:							 null,
				formSubmitted:						 true,
			};
		case actionTypes.UPDATE_NPS_FAILURE:
			return {
				...state,
				error:                               true,
				loading:                             false,
				formSubmitted:						 null,
			};
		default:
			return state;
	}
};
