import React from 'react';
import ActionModal from '../ActionModal';

const MFAConfirmationModal = ({ onHide, mfaStatus, onConfirmAndLogOut, isAdmin = false, isMultiUserEnforcement = false, role }) => {
	const getMfaStatusText = (mfaStatus) => { return mfaStatus ? 'enabled' : 'disabled'; };
	const getModalContentText = () => {
		switch (true) {
			case isAdmin && !isMultiUserEnforcement:
				return `You have ${getMfaStatusText(mfaStatus)} multi factor authentication for this user.${ mfaStatus ? ' On next login he will configure multi factor authentication.' : ''}`;
			case isAdmin && isMultiUserEnforcement:
				return `You have ${getMfaStatusText(mfaStatus)} multi factor authentication for all ${role} users.${ mfaStatus ? ' On next login they will configure multi factor authentication.' : ''}`;
			default:
				return `You have ${getMfaStatusText(mfaStatus)} multi factor authentication.${ mfaStatus ? ' On your next login you will configure your multi factor authentication.' : ''}`;
		}
	};

	return (
		<ActionModal
			showModal={true}
			title='Confirm change to multifactor authentication'
			content={<span>{getModalContentText()}</span>}
			primaryLabel={isAdmin ? 'Confirm' : 'Confirm and Log Out'}
			callback={onConfirmAndLogOut}
			onHide={onHide}
		/>
	);
};

export default MFAConfirmationModal;

