import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';

const PrivateRoute = ({ authenticationStatus }) => {
	const authenticationToken = localStorage.getItem('authenticationToken');
	const navigate = useNavigate();

	useEffect(() => {
		if ((authenticationStatus === 'unauthenticated' || authenticationStatus === 'pending') && !authenticationToken) {
			navigate('/sign-in');
		}
	}, []);

	return <Outlet/>;
};

const mapStateToProps = (state) => {
	return {
		authenticationStatus: state.authentication.status
	};
};

export default connect(mapStateToProps, null)(PrivateRoute);
