import React from 'react';
import { Toast } from '@syneto/compass-react';
import { connect } from 'react-redux';
import { removeNotification } from '../data/notifications/actions';

const Notification = (props) => {
	return (
		<div style={ {
			position: 'fixed',
			bottom:   20,
			right:    0,
			margin:   30,
			width:    350,
			height:   'auto',
			zIndex:   99999
		} }>
			{ props.notifications.map((notification) => {
				const item = notification[ notification.key ];
				return (
					item.text !== '' ?
						<Toast
							color={ item.type }
							show={ true }
							onClose={ () => { props.dispatch(removeNotification(notification.key)); } }
							delay={ 9000 }
							autohide
							key={ notification.key }
						>
							<Toast.Header
								style={ {
									display:        'flex',
									justifyContent: 'space-between'
								} }
							>
								{ item.type === 'danger' ? 'Error' : item.type.toUpperCase() }
							</Toast.Header>
							<Toast.Body>{ item.text }</Toast.Body>
						</Toast> : ''
				);
			}) }
		</div>
	);
};

const mapStateToProps = ((state) => {
	return {
		notifications: state.notifications
	};
});

export default connect(mapStateToProps)(Notification);
