import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	loading: false,
	error: '',
	data: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.GET_SETTINGS_LOADING:
			return {
				...INITIAL_STATE,
				loading: true
			};
		case actionTypes.GET_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case actionTypes.GET_SETTINGS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};
