import React, { useEffect, useState } from 'react';
import { Card } from '@syneto/compass-react';
import Search from './Search';
import CustomersTable from './CustomersTable';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';

const SupportCustomers = () => {
	const [userAccountsFromSearch, setUserAccountsFromSearch] = useState([]);
	const [maintenanceServicesFromSearch, setMaintenanceServicesFromSearch] = useState([]);
	const [searchStarted, setSearchStarted] = useState(false);
	const [query, setQuery] = useState('');
	const [customersQueryParams, setCustomersQueryParams] = useState(GET_PRODUCTS_DEFAULT_QUERY_PARAMS);
	const [shouldRenderUserTabelAndSupportCustomerTable, setShouldRenderUserTabelAndSupportCustomerTable] = useState(false);

	useEffect(() => {
		setShouldRenderUserTabelAndSupportCustomerTable(userAccountsFromSearch.length > 0);
	}, [userAccountsFromSearch, maintenanceServicesFromSearch]);

	const updateUserAccountsFromSearch = (userAccountsFromSearch, trimmedQuery = query) => {
		setUserAccountsFromSearch(userAccountsFromSearch);
		setSearchStarted(true);
		setQuery(trimmedQuery);
	};

	const renderUserAccountsTable = () => {
		return (userAccountsFromSearch?.length > 0) && (
			<CustomersTable
				users={userAccountsFromSearch}
				query={query}
				customersQueryParams={customersQueryParams}
				setCustomersQueryParams={setCustomersQueryParams}
			/>
		);
	};

	const renderEmptyState = () => {
		return (
			<p style={{ color: '#b1b7bd' }}>
				There are no customers, machines or maintenance plans to match the search criteria.
			</p>
		);
	};

	return (
		<>
			<Card
				style={{ marginBottom: '10px' }}
				content={
					<>
						<Search
							setUserAccountsFromSearch={updateUserAccountsFromSearch}
							customersQueryParams={customersQueryParams}
							setCustomersQueryParams={setCustomersQueryParams}
						/>
						{!shouldRenderUserTabelAndSupportCustomerTable && renderUserAccountsTable()}
						{searchStarted && !userAccountsFromSearch?.length && !maintenanceServicesFromSearch?.length && renderEmptyState()}
					</>
				}
			/>
			{
				shouldRenderUserTabelAndSupportCustomerTable && (
					<>
						<Card
							style={{ marginBottom: '10px' }}
							content={renderUserAccountsTable()}
						/>
					</>
				)
			}
		</>
	);
};

export default SupportCustomers;
