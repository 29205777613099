import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';

export const loadingProductModels = () => { return { type: actionTypes.LOADING_PRODUCT_MODELS }; };

export const loadingProductModelsSuccess = (data) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MODELS_SUCCESS,
		payload: data.productModels.sort((a, b) => {
			return a.currentlySelling > b.currentlySelling ? -1 : a.currentlySelling < b.currentlySelling ? 1
				: a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1;
		})
	};
};

export const loadingProductModelsError = (error) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MODELS_ERROR,
		payload: error
	};
};

export const loadProductModels = () => {
	return (dispatch) => {
		dispatch(loadingProductModels());

		return axios.get('/products/models').then((response) => {
			if (response.status === 200) {
				dispatch(loadingProductModelsSuccess(response.data));
			}
		}, (error) => {
			dispatch(loadingProductModelsError(error?.response?.data?.message));
		});
	};
};

export const creatingProductModel = () => {
	return {
		type: actionTypes.CREATING_PRODUCT_MODEL
	};
};

export const createProductModelSuccess = (data) => {
	return {
		type: actionTypes.CREATE_PRODUCT_MODEL_SUCCESS,
		payload: data
	};
};

export const createProductModelError = (error) => {
	return {
		type: actionTypes.CREATE_PRODUCT_MODEL_ERROR,
		payload: error
	};
};

export const createProductModel = (formFields) => {
	let _productModel = { ...formFields, chronos: formFields.chronos ? 'included' : 'optional', failoverAndFailback: 'included' };
	return (dispatch) => {
		dispatch(creatingProductModel());
		return axios.post('/products/models', _productModel).then((response) => {
			const message = response.data.message;
			dispatch(createProductModelSuccess(message));
			return message;
		}).catch((error) => {
			const message = error?.response?.data?.data?.message ?? error?.response?.data?.message ?? '';
			dispatch(createProductModelError(message));
			throw message;
		});
	};
};

export const updatingProductModel = () => {
	return {
		type: actionTypes.UPDATING_PRODUCT_MODEL,
		payload: null
	};
};

export const updateProductModelSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_PRODUCT_MODEL_SUCCESS,
		payload: data
	};
};

export const updateProductModelError = (error) => {
	return {
		type: actionTypes.UPDATE_PRODUCT_MODEL_ERROR,
		payload: error
	};
};

export const updateProductModel = (formFields) => {
	let _productModel = { ...formFields, chronos: formFields.chronos ? 'included' : 'optional', failoverAndFailback: 'included' };
	return (dispatch) => {
		return axios.put('/products/models/', _productModel).then((response) => {
			dispatch(updateProductModelSuccess(response.data));
		}).catch((error) => {
			dispatch(updateProductModelError(error.response.data));
		});
	};
};

export const deletingProductModel = () => {
	return {
		type: actionTypes.DELETING_PRODUCT_MODEL,
		payload: null
	};
};

export const deleteProductModelSuccess = (data) => {
	return {
		type: actionTypes.DELETE_PRODUCT_MODEL_SUCCESS,
		payload: data
	};
};

export const deleteProductModelError = (error) => {
	return {
		type: actionTypes.DELETE_PRODUCT_MODEL_ERROR,
		payload: error
	};
};

export const deleteProductModel = (productModelId, formFields) => {
	return (dispatch) => {
		return axios.delete(`/products/models/${productModelId}`, formFields).then((response) => {
			dispatch(deleteProductModelSuccess(response.data));
		}).catch((error) => {
			dispatch(deleteProductModelError(error.response.data));
		});
	};
};

export const onShowModalAddProductModel = () => { return { type: actionTypes.SHOW_MODAL_ADD_PRODUCT_MODEL }; };
export const onHideModalAddProductModel = () => { return { type: actionTypes.HIDE_MODAL_ADD_PRODUCT_MODEL }; };
