import React, { useState } from 'react';
import { Tabs } from '@syneto/compass-react';
import SupportSerenityAllEventsTable from './SupportSerenityAllEvents/supportSerenityAllEventsTable';
import SupportSerenityNewEventsTable from './SupportSerenityNewEvents/supportSerenityNewEventsTable';

const SupportSerenityEvents = () => {
	const [activeKey, setActiveKey] = useState('newEvents');

	return (
		<div className="p-4 bg-white">
			<Tabs activeKey={ activeKey } onSelect={ (tab) => { return setActiveKey(tab); } }>
				<Tabs.Tab eventKey="newEvents" title="New Events" className="qa-new-events">
					{ activeKey === 'newEvents' ? <SupportSerenityNewEventsTable activeKey={ activeKey }/> : null }
				</Tabs.Tab>

				<Tabs.Tab eventKey="allEvents" title="All Events" className="qa-all-events">
					{ activeKey === 'allEvents' ? <SupportSerenityAllEventsTable activeKey={ activeKey }/> : null }
				</Tabs.Tab>
			</Tabs>
		</div>
	);
};

export default SupportSerenityEvents;
