import * as internalUsersService from '../../services/internalUsers';
import _ from 'lodash';
import { SubmissionError } from 'redux-form';
import * as actionTypes from './actionTypes';
import { processUserData } from '../../helpers/normalize-data';
import ReqBodyValidator from '../../helpers/ReqBodyValidator';

export const registerUser = (formFields, sendMfaEnforcement) => {
	const data = processUserData(formFields, sendMfaEnforcement);

	return (dispatch) => {
		dispatch(startUserRegistration());
		return internalUsersService.createInternalUser(data)
			.then((result) => {
				dispatch(registrationSuccess());
			})
			.catch((result) => {
				if (result.response.data.validationErrors) {
					dispatch(registrationFailure(new SubmissionError(ReqBodyValidator.parseReqBodyValidationErrorsUser(result.response.data.validationErrors))));
				} else {
					dispatch(registrationFailure(result.response.data));
				}
			});
	};
};

export const startUserRegistration = () => {
	return {
		type: actionTypes.START_USER_REGISTRATION
	};
};

export const registrationSuccess = () => {
	return {
		type: actionTypes.REGISTRATION_SUCCESS
	};
};

export const registrationFailure = (error) => {
	return {
		type: actionTypes.REGISTRATION_FAILURE,
		payload: error
	};
};

export const updateSelectedUser = (formFields, sendMfaEnforcement = false) => {
	const data = processUserData(formFields, sendMfaEnforcement);

	return (dispatch) => {
		dispatch(startUpdatingSelectedUser());
		return internalUsersService.updateInternalUser(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(updateSelectedUserSuccess());
				}
			})
			.catch((result) => {
				if (result.response.data.validationErrors) {
					dispatch(updateSelectedUserFailure(new SubmissionError(ReqBodyValidator.parseReqBodyValidationErrorsUser(result.response.data.validationErrors))));
				} else {
					dispatch(updateSelectedUserFailure(result.response.data));
				}
			});
	};
};

export const startUpdatingSelectedUser = () => {
	return {
		type: actionTypes.START_UPDATING_SELECTED_USER
	};
};

export const updateSelectedUserSuccess = () => {
	return {
		type: actionTypes.SELECTED_USER_UPDATE_SUCCESS
	};
};

export const updateSelectedUserFailure = (error) => {
	return {
		type: actionTypes.SELECTED_USER_UPDATE_ERROR,
		payload: error
	};
};

export const deleteSelectedUser = (userId) => {
	return (dispatch) => {
		dispatch(startDeletingSelectedUser());
		return internalUsersService.deleteInternalUser(userId)
			.then((result) => {
				dispatch(deleteSelectedUserSuccess());
			})
			.catch((error) => {
				dispatch(deleteSelectedUserFailed(error.message));
			});
	};
};

export const startDeletingSelectedUser = () => {
	return {
		type: actionTypes.START_DELETING_SELECTED_USER
	};
};

export const deleteSelectedUserSuccess = () => {
	return {
		type: actionTypes.SELECTED_USER_DELETE_SUCCESS
	};
};

export const deleteSelectedUserFailed = (error) => {
	return {
		type: actionTypes.SELECTED_USER_DELETE_FAILED,
		payload: error
	};
};

export const showSelectedUserDeleteModal = (user) => {
	return {
		type: actionTypes.SHOW_DELETE_USER_DELETE_MODAL,
		payload: user
	};
};

export const hideSelectedUserDeleteModal = () => {
	return {
		type: actionTypes.HIDE_DELETE_USER_DELETE_MODAL
	};
};

export const showSelectedUserEditModal = (user) => {
	return {
		type: actionTypes.SHOW_SELECTED_USER_EDIT_MODAL,
		payload: user
	};
};

export const hideSelectedUserEditModal = () => {
	return {
		type: actionTypes.HIDE_SELECTED_USER_EDIT_MODAL
	};
};

