import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import { connect } from 'react-redux';
import { Card, Button } from '@syneto/compass-react';
import { verifyMfaCode } from '../../../../data/authenticate/action';
import Validator from '../../../../components/forms/validate';
import Form from '../../../../components/forms/form';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'mfa': ['required', 'min: 6']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const MFALogin = ({ authentication, verifyMfaCode, handleSubmit, submitting, setShowRecoveryModal }) => {
	const verifyTotpCode = (values) => {
		verifyMfaCode({
			email: authentication.email,
			code: values.mfa
		});
	};

	return (
		<div className="col log-in">
			<Card
				className={`z-depth-1 ${authentication.status === 'failed' ? 'animated shake' : ''}`}
				title={'Please enter your 2FA code'}
				content={
					<form noValidate>
						<div className='row'>
							<p>
								<small>
								Two-factor authentication (2FA) is enabled for your account. Please enter a code to log in.
								</small>
							</p>
						</div>

						<div className='row'>
							<Form.mfa label="Enter security code" name={'mfa'} autoFocus />
						</div>

						<div className='d-flex justify-content-between'>
							<a href="#" onClick={setShowRecoveryModal} className="text-primary mt-2">Use a recovery code</a>

							<Button
								type="submit"
								disabled={submitting}
								onClick={handleSubmit(verifyTotpCode)}
							>
								{submitting ? 'Verifying...' : 'Verify & Log In'}
							</Button>
						</div>
					</form>
				}
			/>
		</div>
	);
};

const MFALoginForm = reduxForm({
	form: 'mfaForm',
	validate
})(MFALogin);

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyMfaCode: (data) => { return dispatch(verifyMfaCode(data)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MFALoginForm);
