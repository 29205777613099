import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { onClickSidebarMenu } from '../../data/actions/sidebar';
import Sidebar from '../../components/Sidebar';
import Preloader from '../../components/preloader';


const SUPPORT_ROUTES = {
	CUSTOMERS: {
		LABEL: 'Customers',
		PATH: 'customers',
		IDENTIFIER: 'customers',
		ITEMS: []
	},
	PRODUCTS: {
		LABEL: 'Products',
		PATH: 'products',
		IDENTIFIER: 'products',
		ITEMS: []
	},
	SERENITY_EVENTS: {
		LABEL: 'Serenity Events',
		PATH: 'serenity-events',
		IDENTIFIER: 'serenityEvents',
		ITEMS: []
	},
	VM_ACTIVATION_CODE: {
		LABEL: 'VM Activation Codes',
		PATH: 'vm-activation-codes',
		IDENTIFIER: 'vmActivationCodes',
		ITEMS: []
	}
};

const Support = ({ loadUserData, userRole, onClickSidebarMenu }) => {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		switch (location.pathname.split('/')[2]) {
			case SUPPORT_ROUTES.SERENITY_EVENTS.PATH:
				onClickSidebarMenu(SUPPORT_ROUTES.SERENITY_EVENTS.IDENTIFIER);
				break;
			case SUPPORT_ROUTES.VM_ACTIVATION_CODE.PATH:
				onClickSidebarMenu(SUPPORT_ROUTES.VM_ACTIVATION_CODE.IDENTIFIER);
				break;
			case SUPPORT_ROUTES.CUSTOMERS.PATH:
			default:
				onClickSidebarMenu(SUPPORT_ROUTES.CUSTOMERS.IDENTIFIER);
				break;
		}		
	}, []);
	
	useEffect(() => {
		if (userRole && userRole !== 'support') {
			navigate('/');
		} 

		if (!loadUserData && userRole) {
			setLoading(false);
		}
	}, [loadUserData, userRole]);

	return (
		<div className="w-100 h-100 mt-4">
			<div className="container-fluid">
				<div className="row">
					<div className="side-menu">
						<Sidebar items={SUPPORT_ROUTES} />
					</div>

					<div className="page-content row">
						{
							!loading 
								? <Outlet />
								: <Preloader dataIsLoading={loading} />
						}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loadUserData: state?.user?.loading,
		userRole: state?.user?.user?.account?.role
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClickSidebarMenu: (path) => {
			dispatch(onClickSidebarMenu(path)); 
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
