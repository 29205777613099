module.exports = {
	collection: [
		{
			'abbreviation': 'AG',
			'name':         'Agrigento',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'AL',
			'name':         'Alessandria',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'AN',
			'name':         'Ancona',
			'region':       'Marche'
		},

		{
			'abbreviation': 'AO',
			'name':         'Aosta',
			'region':       'Valle d\'Aosta'
		},

		{
			'abbreviation': 'AQ',
			'name':         'L\'Aquila',
			'region':       'Abruzzo'
		},

		{
			'abbreviation': 'AR',
			'name':         'Arezzo',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'AP',
			'name':         'Ascoli-Piceno',
			'region':       'Marche'
		},

		{
			'abbreviation': 'AT',
			'name':         'Asti',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'AV',
			'name':         'Avellino',
			'region':       'Campania'
		},

		{
			'abbreviation': 'BA',
			'name':         'Bari',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'BT',
			'name':         'Barletta-Andria-Trani',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'BL',
			'name':         'Belluno',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'BN',
			'name':         'Benevento',
			'region':       'Campania'
		},

		{
			'abbreviation': 'BG',
			'name':         'Bergamo',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'BI',
			'name':         'Biella',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'BO',
			'name':         'Bologna',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'BZ',
			'name':         'Bolzano',
			'region':       'Trentino Alto Adige'
		},

		{
			'abbreviation': 'BS',
			'name':         'Brescia',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'BR',
			'name':         'Brindisi',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'CA',
			'name':         'Cagliari',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'CL',
			'name':         'Caltanissetta',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'CB',
			'name':         'Campobasso',
			'region':       'Molise'
		},

		{
			'abbreviation': 'CI',
			'name':         'Carbonia Iglesias',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'CE',
			'name':         'Caserta',
			'region':       'Campania'
		},

		{
			'abbreviation': 'CT',
			'name':         'Catania',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'CZ',
			'name':         'Catanzaro',
			'region':       'Calabria'
		},

		{
			'abbreviation': 'CH',
			'name':         'Chieti',
			'region':       'Abruzzo'
		},

		{
			'abbreviation': 'CO',
			'name':         'Como',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'CS',
			'name':         'Cosenza',
			'region':       'Calabria'
		},

		{
			'abbreviation': 'CR',
			'name':         'Cremona',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'KR',
			'name':         'Crotone',
			'region':       'Calabria'
		},

		{
			'abbreviation': 'CN',
			'name':         'Cuneo',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'EN',
			'name':         'Enna',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'FM',
			'name':         'Fermo',
			'region':       'Marche'
		},

		{
			'abbreviation': 'FE',
			'name':         'Ferrara',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'FI',
			'name':         'Firenze',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'FG',
			'name':         'Foggia',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'FC',
			'name':         'Forli-Cesena',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'FR',
			'name':         'Frosinone',
			'region':       'Lazio'
		},

		{
			'abbreviation': 'GE',
			'name':         'Genova',
			'region':       'Liguria'
		},

		{
			'abbreviation': 'GO',
			'name':         'Gorizia',
			'region':       'Friuli Venezia Giulia'
		},

		{
			'abbreviation': 'GR',
			'name':         'Grosseto',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'IM',
			'name':         'Imperia',
			'region':       'Liguria'
		},

		{
			'abbreviation': 'IS',
			'name':         'Isernia',
			'region':       'Molise'
		},

		{
			'abbreviation': 'SP',
			'name':         'La-Spezia',
			'region':       'Liguria'
		},

		{
			'abbreviation': 'LT',
			'name':         'Latina',
			'region':       'Lazio'
		},

		{
			'abbreviation': 'LE',
			'name':         'Lecce',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'LC',
			'name':         'Lecco',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'LI',
			'name':         'Livorno',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'LO',
			'name':         'Lodi',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'LU',
			'name':         'Lucca',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'MC',
			'name':         'Macerata',
			'region':       'Marche'
		},

		{
			'abbreviation': 'MN',
			'name':         'Mantova',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'MS',
			'name':         'Massa-Carrara',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'MT',
			'name':         'Matera',
			'region':       'Basilicata'
		},

		{
			'abbreviation': 'VS',
			'name':         'Medio Campidano',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'ME',
			'name':         'Messina',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'MI',
			'name':         'Milano',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'MO',
			'name':         'Modena',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'MB',
			'name':         'Monza-Brianza',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'NA',
			'name':         'Napoli',
			'region':       'Campania'
		},

		{
			'abbreviation': 'NO',
			'name':         'Novara',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'NU',
			'name':         'Nuoro',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'OG',
			'name':         'Ogliastra',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'OT',
			'name':         'Olbia Tempio',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'OR',
			'name':         'Oristano',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'PD',
			'name':         'Padova',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'PA',
			'name':         'Palermo',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'PR',
			'name':         'Parma',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'PV',
			'name':         'Pavia',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'PG',
			'name':         'Perugia',
			'region':       'Umbria'
		},

		{
			'abbreviation': 'PU',
			'name':         'Pesaro-Urbino',
			'region':       'Marche'
		},

		{
			'abbreviation': 'PE',
			'name':         'Pescara',
			'region':       'Abruzzo'
		},

		{
			'abbreviation': 'PC',
			'name':         'Piacenza',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'PI',
			'name':         'Pisa',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'PT',
			'name':         'Pistoia',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'PN',
			'name':         'Pordenone',
			'region':       'Friuli Venezia Giulia'
		},

		{
			'abbreviation': 'PZ',
			'name':         'Potenza',
			'region':       'Basilicata'
		},

		{
			'abbreviation': 'PO',
			'name':         'Prato',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'RG',
			'name':         'Ragusa',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'RA',
			'name':         'Ravenna',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'RC',
			'name':         'Reggio-Calabria',
			'region':       'Calabria'
		},

		{
			'abbreviation': 'RE',
			'name':         'Reggio-Emilia',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'RI',
			'name':         'Rieti',
			'region':       'Lazio'
		},

		{
			'abbreviation': 'RN',
			'name':         'Rimini',
			'region':       'Emilia Romagna'
		},

		{
			'abbreviation': 'ROMA',
			'name':         'Roma',
			'region':       'Lazio'
		},

		{
			'abbreviation': 'RO',
			'name':         'Rovigo',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'SA',
			'name':         'Salerno',
			'region':       'Campania'
		},

		{
			'abbreviation': 'SS',
			'name':         'Sassari',
			'region':       'Sardegna'
		},

		{
			'abbreviation': 'SV',
			'name':         'Savona',
			'region':       'Liguria'
		},

		{
			'abbreviation': 'SI',
			'name':         'Siena',
			'region':       'Toscana'
		},

		{
			'abbreviation': 'SR',
			'name':         'Siracusa',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'SO',
			'name':         'Sondrio',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'TA',
			'name':         'Taranto',
			'region':       'Puglia'
		},

		{
			'abbreviation': 'TE',
			'name':         'Teramo',
			'region':       'Abruzzo'
		},

		{
			'abbreviation': 'TR',
			'name':         'Terni',
			'region':       'Umbria'
		},

		{
			'abbreviation': 'TO',
			'name':         'Torino',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'TP',
			'name':         'Trapani',
			'region':       'Sicilia'
		},

		{
			'abbreviation': 'TN',
			'name':         'Trento',
			'region':       'Trentino Alto Adige'
		},

		{
			'abbreviation': 'TV',
			'name':         'Treviso',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'TS',
			'name':         'Trieste',
			'region':       'Friuli Venezia Giulia'
		},

		{
			'abbreviation': 'UD',
			'name':         'Udine',
			'region':       'Friuli Venezia Giulia'
		},

		{
			'abbreviation': 'VA',
			'name':         'Varese',
			'region':       'Lombardia'
		},

		{
			'abbreviation': 'VE',
			'name':         'Venezia',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'VB',
			'name':         'Verbania',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'VC',
			'name':         'Vercelli',
			'region':       'Piemonte'
		},

		{
			'abbreviation': 'VR',
			'name':         'Verona',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'VV',
			'name':         'Vibo-Valentia',
			'region':       'Calabria'
		},

		{
			'abbreviation': 'VI',
			'name':         'Vicenza',
			'region':       'Veneto'
		},

		{
			'abbreviation': 'VT',
			'name':         'Viterbo',
			'region':       'Lazio'
		}
	]
};
