import React, { Component, useState } from 'react';
import { Button } from '@syneto/compass-react';
import { maintenanceExpirationDefaultFilter } from '../../../../constants/maintenance-durations';

const MarketingReportsFilter = (props) => {
	const { updateMaintenanceExpiration, fetchMarketingReports } = props;
	const [filterValue, setFilterdValue] = useState(maintenanceExpirationDefaultFilter);

	return (
		<div className="d-flex mt-2 mb-4">
			<select defaultValue={maintenanceExpirationDefaultFilter}
				className="form-select me-2 p-0 m-0 border-0 border-bottom rounded-0 bg-transparent w-50"
				onChange={(event) => { return setFilterdValue(event.target.value); }}>
				<option value="max-12-min-6-months-fromNow">next 7-12 months</option>
				<option value="6-months-fromNow">next 6 months</option>
				<option value="12-months-fromNow">next 12 months</option>
				<option value="18-months-fromNow">next 18 months</option>
				<option disabled>--------------------</option>
				<option value="6-months-ago">last 6 months</option>
				<option value="max-12-min-6-months-ago">last 7-12 months</option>
				<option value="max-9999-min-12-months-ago">older than 12 months</option>
			</select>

			<Button
				type="submit"
				onClick={() => {
					updateMaintenanceExpiration(filterValue);
					fetchMarketingReports(filterValue);
				}}
			>
				Filter
			</Button>
		</div>
	);
};

export default MarketingReportsFilter;
