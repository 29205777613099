import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import {
	createMaintenancePlan,
	creatingMaintenancePlan,
	hideModalAddNewMaintenancePlan
} from '../../../../data/maintenanceType/action';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';
import { Button, Modal } from '@syneto/compass-react';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'customerFacingName': ['required'],
			'synetoInternalName': ['required'],
			'serenity': ['required'],
			'endUserDescription': ['required']
		});

	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminMaintenanceTypesAddModal = (props) => {
	let { handleSubmit, submitting } = props;

	useEffect(() => {
		props.successMessage && props.dispatch(pushSuccessfulNotification(props.successMessage));
		props.failedMessage && props.dispatch(pushDangerNotification(props.failedMessage));
	}, [props.successMessage, props.failedMessage]);

	function submit(values) {
		return Promise.all([props.creatingMaintenancePlan(), props.createMaintenancePlan(values)]).then(() => {
			cancelAddModal();
			props.fetchMaintenancePlansReports('all');
		}).catch(() => {
			props.dispatch(pushDangerNotification(`Maintenance type could not be created. Reason: ${error}`));
		});
	}

	function cancelAddModal() {
		props.dispatch(hideModalAddNewMaintenancePlan());
		props.reset('addMaintenancePlan');
	}

	return (
		<div>
			{props.showRegisterNewMaintenancePlanModal &&
				<Modal show={props.showRegisterNewMaintenancePlanModal} size="lg">
					<Modal.Header>
						<Modal.Title>Add maintenance type</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form noValidate>
							<fieldset>
								<Form.text label="External name"
									id="customerFacingName" name="customerFacingName" required />
								<Form.text label="Internal name"
									id="synetoInternalName" name="synetoInternalName" required />

								<div className="mb-3 row with-border mx-1">
									<label htmlFor="serenity" className="col-3 p-0">Serenity<span className="required">*</span></label>
									<div className="col-9">
										<Form.radio name="serenity" id="included" value="included" label="Included" inline />
										<Form.radio name="serenity" id="optional" value="optional" label="Optional" inline />
										<Form.radio name="serenity" id="not available" value="not available" label="Not available" inline />
									</div>
								</div>

								<div className="mb-3 row with-border mx-1">
									<label htmlFor="currentlySelling" className="col-3 p-0">Currently selling</label>
									<div className="col-9">
										<Form.checkbox id="currentlySelling" name="currentlySelling" label="Yes" />
									</div>
								</div>

								<Form.textarea label="Internal notes"
									id="internalNotes" name="internalNotes" />
								<Form.textarea label="End user description"
									id="endUserDescription" name="endUserDescription" required />
							</fieldset>
						</form>
					</Modal.Body>

					<Modal.Footer>
						<Button role="secondary" style={{ marginRight: '6px' }} onClick={() => { return cancelAddModal(); }}>Cancel</Button>
						<Button disabled={submitting} onClick={handleSubmit(submit)}>Save</Button>
					</Modal.Footer>
				</Modal>
			}
		</div>
	);
};

AdminMaintenanceTypesAddModal = reduxForm({
	form: 'addMaintenancePlan',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate,
	shouldValidate: () => { return true; }
})(AdminMaintenanceTypesAddModal);

const mapStateToProps = (state) => {
	return {
		showRegisterNewMaintenancePlanModal: state.maintenancePlansOptions.showRegisterNewMaintenancePlanModal,
		successMessage: state.maintenancePlansOptions.maintenancePlanCreatedSuccessMessage,
		failedMessage: state.maintenancePlansOptions.maintenancePlanCreatedErrorMessage,
		initialValues: {
			customerFacingName: '',
			synetoInternalName: '',
			serenity: 'not available',
			currentlySelling: true,
			internalNotes: '',
			endUserDescription: ''
		}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		creatingMaintenancePlan: () => {
			return dispatch(creatingMaintenancePlan());
		},
		createMaintenancePlan: (values) => {
			return dispatch(createMaintenancePlan(values));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMaintenanceTypesAddModal);
