import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Navbar } from '@syneto/compass-react';
import { NavLinks } from './NavLinks';
import NavProfile from './NavProfile';
import { unauthenticateUser } from '../../data/authenticate/action';
import { showResetPasswordModal } from '../../modules/Password-Reset/data/actions/actions';
import {
	showProfileModal,
	showNotificationPreferencesModal
} from '../../data/profile/action';


const Nav = (props) => {
	const { user: { user }, showProfileModal, showResetPasswordModal, showNotificationPreferencesModal, unauthenticateUser } = props;
	const firstName = user && user.contact ? user.contact.firstName : '';
	const userRole = user && user.account ? user.account.role : '';
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const hash = location.hash || '';

		switch (hash) {
			case '#notificationPreferences': {
				this.showNotificationPreferencesModal();
				location.hash = '';
				break;
			}
			default:
				break;
		}
	}, [location, location.hash]);

	return (
		<Navbar bg="white" expand="lg" className='fixed-top flex-wrap'>
			<Navbar.Brand href="/">| Central</Navbar.Brand>

			{user && (
				<>
					<div className="d-flex flex-fill justify-content-between p-2 pe-3">
						<NavLinks userRole={userRole} />

						<Dropdown align="end" label={firstName}>
							<Dropdown.Menu>
								<Dropdown.Item onClick={showProfileModal}>Profile</Dropdown.Item>
								<Dropdown.Item onClick={showResetPasswordModal}>Change password</Dropdown.Item>
								<Dropdown.Item onClick={showNotificationPreferencesModal}>
									Notification preferences
								</Dropdown.Item>

								<Dropdown.Divider />
								<Dropdown.Item onClick={() => { return unauthenticateUser(navigate); }}>Log out</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<NavProfile />
				</>
			)}
		</Navbar>
	);
};

const mapStateToProps = ({ user }) => { return { user }; };

export default connect(mapStateToProps, {
	showProfileModal,
	showResetPasswordModal,
	showNotificationPreferencesModal,
	unauthenticateUser
})(Nav);
