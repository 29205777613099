import { setAxiosAuthorizationHeader } from '../../services/httpClient';
import * as authActionTypes from './actionTypes';
import { normalizeMfaData } from '../../helpers/normalize-data';
import * as authenticateService from '../../services/authenticate';

export const userLogedIn = (dispatch, token) => {
	setAuthToken(token);
	dispatch(authenticationSuccess(token));
};

export const userLoggedOut = (dispatch) => {
	setAuthToken(null);
	dispatch(unauthenticateUser());
};

export const checkToken = (authenticationToken) => {
	return (dispatch) => {
		dispatch(startAuthentication());
		return authenticateService.validateJwtToken(authenticationToken)
			.then((response) => {
				const user = response?.data?.entity;

				if (user && user.role !== '' && user.id !== '') {
					userLogedIn(dispatch, authenticationToken);
				} else {
					userLoggedOut(dispatch);
				}
			})
			.catch((error) => {
				userLoggedOut(dispatch);
				throw error;
			});
	};
};

export const authenticateUser = (formFields) => {
	return (dispatch) => {
		dispatch(startAuthentication(formFields.email));
		return authenticateService.authenticateInternalUser(formFields)
			.then((response) => {
				const { jwt, ...mfa } = response.data;
				if (jwt !== '') {
					userLogedIn(dispatch, jwt);
				} else if (jwt == '' && mfa.mfa_configured === true) {
					dispatch(setMfaData(normalizeMfaData(mfa)));
				} else if (jwt == '' && mfa.mfa_configured === false) {
					return dispatch(configurateMfa(formFields)).catch((error) => {
						throw error;
					});
				} else {
					throw new Error({ response: { message: 'Ooops, something went wrong...' } });
				}
			})
			.catch((error) => {
				dispatch(authenticationFailure(error.response));
			});
	};
};

const setAuthToken = (token) => {
	localStorage.setItem('authenticationToken', token);
	setAxiosAuthorizationHeader(token);
};

export const startAuthentication = (email) => {
	return {
		type: authActionTypes.START_AUTHENTICATION,
		...(email ? { payload: email } : {})
	};
};

export const authenticationSuccess = (token) => {
	return {
		type: authActionTypes.AUTHENTICATION_SUCCESS,
		payload: token
	};
};

export const authenticationFailure = (error) => {
	return {
		type: authActionTypes.AUTHENTICATION_FAILURE,
		payload: error
	};
};

export const setMfaData = (payload) => {
	return {
		type: authActionTypes.SET_MFA_CONFIG_DATA,
		payload
	};
};

export const configurateMfa = (data) => {
	return (dispatch) => {
		dispatch(startGetMfaConfigFata());
		return authenticateService.configureMfa(data)
			.then((response) => {
				dispatch(getMfaConfigDataSuccess(normalizeMfaData(response.data)));
			})
			.catch((error) => {
				dispatch(getMfaConfigDataError());
				throw new Error(error);
			});
	};
};

const startGetMfaConfigFata = () => {
	return {
		type: authActionTypes.START_GET_MFA_CONFIG_DATA,
	};
};

const getMfaConfigDataSuccess = (payload) => {
	return {
		type: authActionTypes.GET_MFA_CONFIG_DATA_SUCCESS,
		payload
	};
};

const getMfaConfigDataError = () => {
	return {
		type: authActionTypes.GET_MFA_CONFIG_DATA_ERROR
	};
};

export const verifyMfaCode = (payload) => {
	return (dispatch) => {
		dispatch(startAuthentication());
		return authenticateService.verifyTotpCode(payload)
			.then((response) => {
				if (response?.data?.jwt !== '') {
					userLogedIn(dispatch, response.data.jwt);
				} else {
					throw new Error({ response: { message: 'Ooops, something went wrong...' } });
				}
			})
			.catch((error) => {
				dispatch(authenticationFailure(error.response));
			});
	};
};

export const loginRecoveryCode = (payload) => {
	return (dispatch) => {
		dispatch(startAuthentication());
		return authenticateService.verifyRecoveryCode(payload)
			.then((response) => {
				if (response?.data?.jwt !== '') {
					userLogedIn(dispatch, response.data.jwt);
				} else {
					throw new Error({ response: { message: 'Ooops, something went wrong...' } });
				}
			})
			.catch((error) => {
				dispatch(authenticationFailure(error.response));
			});
	};
};

export const unauthenticateUser = () => {
	localStorage.removeItem('authenticationToken');
	setAxiosAuthorizationHeader(null);

	return {
		type: authActionTypes.UNAUTHENTICATE,
		payload: null
	};
};

export const changeMfaStatus = (data) => {
	return (dispatch) => {
		dispatch(startToggleMfaStatus());
		return authenticateService.toggleMfaStatus(data)
			.then(() => {
				dispatch(toggleMfaStatusSuccess());
			})
			.catch((error) => {
				dispatch(toggleMfaStatusFailure(error));
			});

	};
};

const startToggleMfaStatus = () => {
	return {
		type: authActionTypes.START_TOGGLE_MFA
	};
};

const toggleMfaStatusSuccess = () => {
	return {
		type: authActionTypes.TOGGLE_MFA_SUCCESS
	};
};

const toggleMfaStatusFailure = () => {
	return {
		type: authActionTypes.TOGGLE_MFA_FAILURE
	};
};
