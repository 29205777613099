export const SHOW_SELECTED_USER_EDIT_MODAL = 'SHOW_SELECTED_USER_EDIT_MODAL';
export const HIDE_SELECTED_USER_EDIT_MODAL = 'HIDE_SELECTED_USER_EDIT_MODAL';

export const SHOW_DELETE_USER_DELETE_MODAL = 'SHOW_DELETE_USER_DELETE_MODAL';
export const HIDE_DELETE_USER_DELETE_MODAL = 'HIDE_DELETE_USER_DELETE_MODAL';

export const START_USER_REGISTRATION = 'START_USER_REGISTRATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const START_UPDATING_SELECTED_USER = 'START_UPDATING_SELECTED_USER';
export const SELECTED_USER_UPDATE_SUCCESS = 'SELECTED_USER_UPDATE_SUCCESS';
export const SELECTED_USER_UPDATE_ERROR = 'SELECTED_USER_UPDATE_ERROR';

export const START_DELETING_SELECTED_USER = 'START_DELETING_SELECTED_USER';
export const SELECTED_USER_DELETE_SUCCESS = 'SELECTED_USER_DELETE_SUCCESS';
export const SELECTED_USER_DELETE_FAILED = 'SELECTED_USER_DELETE_FAILED';
