import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	status: null,
	loading: false,
	error: null,
	user: {},
	products: [],
	profile: null,
	company: null,
	monitoringDetails: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.GETTING_USER_DETAILS:
			return {
				...INITIAL_STATE,
				loading: true
			};
		case actionTypes.GET_USER_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				...action.payload
			};
		case actionTypes.GET_USER_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case actionTypes.RESET_USER_DETAILS_STATE:
			return INITIAL_STATE;
		case actionTypes.GETTING_MONITORING_DETAILS:
			return {
				...state,
				monitoringDetails: null
			};
		case actionTypes.GET_MONITORING_SUCCESS:
			return {
				...state,
				monitoringDetails: action.payload
			};
		case actionTypes.GET_MONITORING_ERROR:
			return {
				...state,
				monitoringDetails: null,
				error: action.payload
			};
		default:
			return state;
	}
};
