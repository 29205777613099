import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Button } from '@syneto/compass-react';
import _ from 'lodash';
import { groupUsersPerCompany } from '../../../../helpers/normalize-data';
import Currency from '../../../../helpers/money';
import CSVExportFields from '../../../../constants/csv-export-fields';
import UserRenderer from '../UserRenderer';
import Preloader from '../../../../components/preloader';
import { getCustomers } from '../../../../data/customers/action';
import { exportToCSV } from '../../../../data/export-csv/actions';
import { OperationsCompaniesViewModal } from '../../../Operations/components/OperationsCompanies/operationsCompaniesViewModal';

const UsersTableMarketing = (props) => {
	const currency = Currency.EUR();
	const { customers, loading, loggedInUser, exportToCSV, getCustomers } = props;
	const [companyDetails, setCompanyDetails] = useState(null);
	const [sortedUsers, setSortedUsers] = useState(customers);

	useEffect(() => {
		getCustomers();
	}, []);

	useEffect(() => {
		setSortedUsers(groupUsersPerCompany(customers));
	}, [customers]);

	const openModalWithUser = (companyDetails) => {
		setCompanyDetails(companyDetails);
	};

	const closeModal = () => { 
		setCompanyDetails(null);
	};

	const renderNrCrt = (cell, row, rowIndex) => { return rowIndex + 1; };

	const renderUsers = (cell, row) => {
		if (!row.users) {
			return;
		}

		return (
			<ul style={{ padding: '0', margin: '0' }}>
				{row.users && row.users.map((user) => { return <li key={user.id}>{`${user.firstName} ${user.lastName}`}</li>; })}
			</ul>
		);
	};

	const renderRegistrationDate = (cell, row) => { 
		const createdAt = moment(row.createdAt).isValid() ? row.createdAt : parseInt(row.createdAt);
		return moment(createdAt).format('DD/MM/YYYY'); 
	};

	if (customers.length < 0) {
		return UserRenderer.renderNoUsersFoundMarketing();
	}
	
	const renderCltv = (row) => {
		return `${row } ${currency.getStringRepresentation()}`;
	};

	return loading || loggedInUser.loading
		? <Preloader dataIsLoading={loading || loggedInUser.loading} />
		: (
			<div>
				<Table
					data={sortedUsers}
					keyField="id"
					search
					selectable="single"
					onSelect={({ item }) => { return openModalWithUser(item); }}
					extra={
						<div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
							<Button onClick={() => { return exportToCSV(CSVExportFields.fields, sortedUsers, loggedInUser?.user?.account?.email ?? ''); }}>
							Export client list
							</Button>
						</div>
					}
				>
					<Table.Col field="#" width="100px" renderCell={renderNrCrt}>#</Table.Col>
					<Table.Col field="name" sort>Company</Table.Col>
					<Table.Col field="users" renderCell={renderUsers} sort>Users</Table.Col>
					<Table.Col field="registrationDate" renderCell={renderRegistrationDate}>Registration date</Table.Col>
					<Table.Col field="cltv" renderCell={renderCltv} sort>CLTV</Table.Col>
				</Table>

				{companyDetails && (
					<OperationsCompaniesViewModal
						isOperations
						closeModal={closeModal}
						companyDetails={companyDetails}
						renderCompanyDetails={UserRenderer.renderCompanyDetailsModalMarketing}
						className="modal-dialog modal-lg"
						hasDeleteButton={false}
					/>
				)}
			</div>
		);
};

const mapStateToProps = (state) => {
	return {
		loading: state.customers.loading,
		customers: state.customers.data ?? [],
		exportedFile: state.exportToCSV,
		loggedInUser: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		exportToCSV: (fields, data, marketingUserEmail) => { return dispatch(exportToCSV(fields, data, marketingUserEmail)); },
		getCustomers: () => { return dispatch(getCustomers(true)); }
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(UsersTableMarketing);
