import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<div id="error" className="w-100 not-found">
			<div className="container mt-2">
				<h2>Page not found</h2>
				Go <Link to="/">home</Link>
			</div>
		</div>
	);
};

export default NotFound;
