import React from 'react';
import { Field } from 'redux-form';
import { Form } from '@syneto/compass-react';

export const Textarea = (props) => { return <Field { ...props } component={ renderField }/>; };

const renderField = (props) => {
	const { label, required, icon, id, name, rows, style, placeholder } = props;
	const { autoFocus, disabled, input, meta: { touched, error, warning } } = props;

	return (
		<Form.Group className='mb-3' controlId={ id }>
			{ label ? <Form.Label>{ label }{ required ? '*' : null } { icon ? icon : null }</Form.Label> : null }

			<Form.Control
				as="textarea"
				name={ name }
				rows={ rows }
				style={ style }
				placeholder={ placeholder }
				autoFocus={ autoFocus }
				disabled={ disabled }
				isInvalid={ touched && error }
				{ ...input }
			/>

			{ touched && warning && <span>{ warning }</span> }

			<Form.Control.Feedback type="invalid">
				{ error }
			</Form.Control.Feedback>
		</Form.Group>
	);
};
