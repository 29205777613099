import React from 'react';
import { Table } from '@syneto/compass-react';

const AdminManualsTable = ({ manuals }) => {
	const renderDownloadLink = (cell, row) => {
		return (
			<a target="_blank" href={row.downloadLink} rel="noreferrer"> Download user guide for Syneto OS {row.osVersion}</a>
		);
	};

	return (
		<Table data={manuals} keyField="osVersion">
			<Table.Col field="osVersion">OS Version</Table.Col>
			<Table.Col field="title">Title</Table.Col>
			<Table.Col field="downloadLink" renderCell={renderDownloadLink}>Download Link</Table.Col>
		</Table>
	);
};

export default AdminManualsTable;
