import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Form from '../form';
import { Badge, Icon } from '@syneto/compass-react';

function ServiceField(props) {
	const { stateValue, label, changePropsInParent, name, value, disabled } = props;
	let output;

	useEffect(() => {
		switch (stateValue) {
			case 'optional': {
				changePropsInParent(name, value);
				break;
			}
			case 'included': {
				changePropsInParent(name, true);
				break;
			}
			default: {
				changePropsInParent(name, false);
				break;
			}
		}
	}, [stateValue]);

	switch (stateValue) {
		case 'optional': {
			output = <div className="d-flex gap-1">
				<label className="form-label pf-2">{label}:</label>
				<Form.checkbox name={name} id={name} style={{ marginRight: 0 + 'px' }} disabled={disabled} />
			</div>;
			break;
		}
		case 'included': {
			output =
				<div className='d-flex gap-1'>
					<Form.hidden name={name} value="true" />
					<label className="form-label pf-2">{label}:</label>
					<Badge appearance="success">
						<Icon name="fa fa-check" />
					</Badge>
				</div>;
			break;
		}
		default: {
			output =
				<div className='d-flex gap-1'>
					<Form.hidden name={name} value="false" />
					<label className="form-label pf-2">{label}:</label>
					<Badge appearance="danger">
						<Icon name="fa fa-times" />
					</Badge>
				</div>;
			break;
		}
	}
	return output;
}

export default connect()(ServiceField);
