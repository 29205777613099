import React from 'react';
import { reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import { connect } from 'react-redux';
import { Card, Button } from '@syneto/compass-react';
import { loginRecoveryCode } from '../../../../data/authenticate/action';
import Validator from '../../../../components/forms/validate';
import Form from '../../../../components/forms/form';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'recoveryCode': ['required', 'min: 6']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

const MFARecovery = ({ authentication, loginRecoveryCode, handleSubmit, submitting, onHide }) => {
	const verifyCode = (values) => {
		loginRecoveryCode({
			email: authentication.email,
			code: values.recoveryCode
		});
	};

	return (
		<div className="col log-in">
			<Card
				className={`z-depth-1 ${authentication.status === 'failed' ? 'animated shake' : ''}`}
				title={'Please enter your recovery code'}
				content={
					<form noValidate>
						<div className='row'>
							<p>
								<small>
								Regain access to your account securely using the provided MFA Recovery Code. Contact the admin user for assistance if needed.
								</small>
							</p>
						</div>

						<div className='row'>
							<Form.mfa label="Enter recovery code" name={'recoveryCode'} autoFocus />
						</div>

						<div className='d-flex justify-content-between'>
							<a href="#" onClick={onHide} className="text-primary mt-2">Cancel</a>

							<Button
								type="submit"
								disabled={submitting}
								onClick={handleSubmit(verifyCode)}
							>
								{submitting ? 'Verifying...' : 'Verify & Log In'}
							</Button>
						</div>
					</form>
				}
			/>
		</div>
	);
};

const MFARecoveryForm = reduxForm({
	form: 'mfaRecoveryForm',
	validate
})(MFARecovery);

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginRecoveryCode: (data) => { return dispatch(loginRecoveryCode(data)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MFARecoveryForm);
