import React from 'react';
import GenerateDownloadLinkForOSManual from '../../../../helpers/GenerateDownloadLinkForOSManual';
import OSVersionParserService from '../../../../helpers/OSVersionParser';
import AdminProductMaintenancePlansTable from './AdminProductMaintenancePlansTable';

const ProductDetails = (props) => {
	const { product } = props;
	const user = product?.company?.profiles[0] ?? null;

	return (
		<div className="row">
			<div className="col-6">
				<ul className="list-unstyled">
					<li className="pt-2 pb-1"><h5>Appliance</h5></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Serial number:</label><span
						className="text-value">{product.serialNumber ? product.serialNumber : 'N/A'}</span></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Model:</label><span
						className="text-value">{product?.model?.title ?? 'N/A'} {product.activatedModel ? ` (${product.activatedModel})` : null}</span>
					</li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">SynetoOS Version:</label><span
						className="text-value">{product.osVersion ? GenerateDownloadLinkForOSManual.generateDownloadLink(OSVersionParserService.parseOSVersion(product.osVersion)) : 'N/A'}</span>
					</li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">ESXi Version:</label><span
						className="text-value">{OSVersionParserService.parseOSVersion(product.esxiVersion)}</span>
					</li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Name:</label><span
						className="text-value">{product.name ? product.name : 'N/A'}</span></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Hardware ID:</label><span
						className="text-value">{product.hardwareId ? product.hardwareId : 'N/A'}</span></li>
				</ul>
			</div>
			<div className="col-6">
				<ul className="list-unstyled">
					<li className="pt-2 pb-1"><h5>Contact</h5></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Client:</label><span
						className="text-value">{user ? `${user.firstName} ${user.lastName}` : 'N/A'}</span>
					</li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Email:</label><span
						className="text-value">{product?.company?.email ?? 'N/A'}</span></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Phone Number:</label><span
						className="text-value">{user?.phoneNumber ?? 'N/A'}</span></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Company:</label><span
						className="text-value">{product?.company?.name ?? 'N/A'}</span>
					</li>
				</ul>
			</div>

			{
				product.activationDate && (
					<div className="col">
						{product.maintenanceService.startDate ? <h5>Support</h5> : null}
						<AdminProductMaintenancePlansTable product={product} />
					</div>
				)
			}

		</div>
	);
};

export default ProductDetails;
