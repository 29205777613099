import React from 'react';
import { Button, Col, Modal, Row, Table } from '@syneto/compass-react';
import DateFormatter from '../../../../helpers/DateFormatter';

const SupportVMActivaionCodesHistoryModal = (props) => {
	const { isOpen, toggleModal, activationHistory, extraActivations } = props;

	const formatDate = (cell, row) => {
		return DateFormatter.formatDateToSpecificFormat(row, 'createdAt', 'DD/MM/YYYY hh:mm:ss a');
	};

	return (
		<Modal show={ isOpen } size="lg" scrollable>
			<Modal.Header>
				<Modal.Title>Activation History</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Table data={ activationHistory } keyField="id">
					<Table.Col field="createdAt" renderCell={ formatDate }>Activation Date</Table.Col>
					<Table.Col field="ip">Activation IP</Table.Col>
					<Table.Col field="serialNumber">Serial Number</Table.Col>
				</Table>
				<h6 className="mt-4 ps-2">Reason for extension</h6>
				<ul className="list-group">
					{ extraActivations && extraActivations.map((extraActivation) => {
						return (
							<li className="list-group-item ps-2" key={extraActivation.id}>
								<Row>
									<Col lg={2}>
										<p className="text-disabled">
											{ DateFormatter.formatDateToSpecificFormat(extraActivation, 'createdAt', 'DD/MM/YYYY') }
										</p>
									</Col>
									<Col lg={10}>
										<p>{ extraActivation.reason }</p>
									</Col>
								</Row>
							</li>
						);
					}) }
				</ul>
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" onClick={() => { return toggleModal(false); } }>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SupportVMActivaionCodesHistoryModal;
