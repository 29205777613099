import React from 'react';
import UserRoles from '../constants/user-roles';

class UserService {
	static redirectToInitialRouteBasedOnUserRole(userRole, browserHistory) {
		switch (userRole) {
			case UserRoles.OPERATIONS:
				browserHistory.push('/maintenance-service/maintenance-plans');
				break;
			default:
				browserHistory.push('/products');
				break;
		}
	}

	static buildAccountObjectForAuthRequest(formValues) {
		return {
			email:      formValues.account.email,
			password:   formValues.account.password,
			rememberMe: false
		};
	}

	static toUppercaseUserRole(userRole) {
		let firstLetter = userRole.substr(0, 1);
		return firstLetter.toUpperCase().concat(userRole.substr(1, userRole.length - 1));
	}
}

export default UserService;
