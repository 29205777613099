import React from 'react';
import { Badge, Button, Table } from '@syneto/compass-react';
import Preloader from '../../../../components/preloader';
import DateFormatter from '../../../../helpers/DateFormatter';

export const dataTable = (activationKeys) => {
	if (activationKeys && activationKeys.length) {
		return activationKeys.map((activationKey) => {
			return {
				id: activationKey.id,
				productKey: activationKey.productKey,
				createdAt: DateFormatter.formatDateToSpecificFormat(activationKey, 'createdAt', 'DD/MM/YYYY hh:mm:ss a'),
				status: activationKey.product_activation_histories.filter((activation) => { return activation.status === 'successful'; }).length ? 'successful' : 'pending',
				product_activation_histories: activationKey.product_activation_histories.filter((activation) => { return activation.status === 'successful'; }),
				extra_activations: activationKey.product_activation_key_extra_activations,
				total_activations: activationKey.product_activation_key_total_activations
			};
		});
	}

	return [];
};

const SupportVMActivationCodesTable = (props) => {
	let { loading, activationKeys, ActivationCodeQueryString, openIncrementModal, openActivationsHistoryModal } = props;

	const renderStatus = (cell) => {
		return (
			<Badge appearance={cell === 'successful' ? 'success' : 'warning'}>{cell}</Badge>
		);
	};

	const renderActivations = (cell, row) => {
		let totalNumberOfActivations = row.total_activations;
		let usedActivations = row.product_activation_histories.length;
		let extraActivations = row.extra_activations.length;
		let initialActivationNumber = totalNumberOfActivations - extraActivations;

		return (
			<Button role="tertiary" appearance={usedActivations >= initialActivationNumber ? 'danger' : null}
				onClick={() => { openActivationsHistoryModal(cell, row); }}
				disabled={!cell.length}>
				{`${usedActivations} of ${totalNumberOfActivations} Activation(s)`}
			</Button>
		);
	};

	const renderIncrementations = (cell, row) => {
		return (	
			<Button role="tertiary" onClick={() => { openIncrementModal(row.productKey); }}>
				Increase Activations
			</Button>
		);
	};

	return (
		<div className="p-4 bg-white">
			{loading ? <Preloader dataIsLoading={loading} /> :
				<Table
					classes="vmActivationCodesTable"
					data={dataTable(activationKeys)}
					keyField="id"
					search={{ defaultSearch: ActivationCodeQueryString }}
					noDataIndication="No activation codes"
				>
					<Table.Col field="productKey">Activation Code</Table.Col>
					<Table.Col field="createdAt">Date Issued</Table.Col>
					<Table.Col field="status" renderCell={renderStatus}>Status</Table.Col>
					<Table.Col field="product_activation_histories" renderCell={renderActivations}>Activations</Table.Col>
					<Table.Col align="center" renderCell={renderIncrementations}>Action</Table.Col>
				</Table>
			}
		</div>
	);
};

export default SupportVMActivationCodesTable;
