import React from 'react';
import { Button, Modal } from '@syneto/compass-react';
import { Field, reduxForm } from 'redux-form';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { connect } from 'react-redux';
import { createProductModel } from '../../../../data/productModels/action';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';
import { onHideModalAddProductModel } from '../../../../data/productModels/action';

const validate = (values) => {
	const validator = new Validator(values, { 'title': ['required'] });
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let AdminProductModelsAddModal = (props) => {
	const { submitting, handleSubmit, reset, createProductModel, showModalAddProductModel, onHideModalAddProductModel, pushSuccessfulNotification, pushDangerNotification } = props;
	let textInput = null;

	let setTextInputRef = (element) => { return textInput = element; };

	function submit(formFields) {
		createProductModel(formFields)
			.then((successMessage) => {
				cancelAddModal();
				props.fetchProductModels();
				pushSuccessfulNotification(successMessage);
			})
			.catch((errorMessage) => {
				pushDangerNotification(`Product model could not be created.${errorMessage !== '' ? ` Reason:${errorMessage}` : ''}`);
			});
	}

	function cancelAddModal() {
		onHideModalAddProductModel();
		reset('addHyperModelForm');
	}

	return (
		<Modal show={showModalAddProductModel}>
			<Modal.Header>
				<Modal.Title>Add HYPER Model</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<form onSubmit={handleSubmit}>
					<dl className="mb-3">
						<dt>Model name</dt>
						<dd>
							<Form.text name="title" inputRef={setTextInputRef} />
						</dd>
					</dl>
					<div className="mb-3  ps-0">
						<Field name="currentlySelling" id="currentlySelling" component="input" type="checkbox"
							checked={true} disabled={true} />
						<label className="ps-2">Currently selling</label>
					</div>
					<div className="mb-3  ps-0">
						<Field name="isRenewable" id="isRenewable" component="input" type="checkbox"
							checked={true} disabled={true} />
						<label className="ps-2">Renewable</label>
					</div>
					<div className="mb-3  ps-0">
						<Field name="virtualAppliance" id="virtualAppliance" component="input" type="checkbox" />
						<label className="ps-2">Virtual Appliance</label>
					</div>
					<div className="mb-3  ps-0">
						<Field name="chronos" id="chronos" component="input" type="checkbox" />
						<label className="ps-2">Has Chronos</label>
					</div>
					<div className="mb-3  ps-0">
						<Field name="failoverAndFailback" id="failoverAndFailback" component="input" type="checkbox" checked={true} disabled={true} />
						<label className="ps-2">Has Failover & Failback</label>
					</div>
				</form>
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" onClick={cancelAddModal}>Cancel</Button>
				<Button disabled={submitting} onClick={handleSubmit(submit)}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
};

AdminProductModelsAddModal = reduxForm({
	form: 'addHyperModelForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate,
	shouldValidate: () => { return true; }
})(AdminProductModelsAddModal);

const mapStateToProps = ({ productModels }) => {
	return {
		showModalAddProductModel: productModels.showModalAddProductModel
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createProductModel: (formData) => { return dispatch(createProductModel(formData)); },
		onHideModalAddProductModel: () => { return dispatch(onHideModalAddProductModel()); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProductModelsAddModal);
