import * as internalUsersService from '../../services/internalUsers';
import * as actionTypes from './actionTypes';

const isAdminEnforcingMFASuccess = (payload) => {
	return {
		type: actionTypes.IS_ADMIN_ENFORCING_MFA_SUCCESS,
		payload
	};
};

const isAdminEnforcingMFAFailed = () => {
	return {
		type: actionTypes.IS_ADMIN_ENFORCING_MFA_FAILED
	};
};

export const toggleMFABasedOnRole = (data) => {
	return (dispatch) => {
		return internalUsersService.updateMfaStatusByRole(data)
			.then((response) => {
				return dispatch(isAdminEnforcingMFASuccess(response?.data?.result ?? false));
			})
			.catch(() => {
				return dispatch(isAdminEnforcingMFAFailed());
			});
	};
	
};

const isAdminEnforcingMFA = (role) => {
	return (dispatch) => {
		return internalUsersService.allInternalUsersHaveEnforcedMfa(role)
			.then((response) => {
				dispatch(isAdminEnforcingMFASuccess(response?.data?.result ?? false));
			})
			.catch((error) => {
				dispatch(isAdminEnforcingMFAFailed());
			});
	};
};

const getUsersLoading = () => { return { type: actionTypes.GET_USERS_LOADING }; };

const getUsersSuccess = (data) => {
	return {
		type: actionTypes.GET_USERS_SUCCESS,
		payload: data
	};
};

const getUsersError = () => {
	return {
		type: actionTypes.GET_USERS_ERROR
	};
};

export const getUsersBasedOnRole = (role) => {
	return (dispatch) => {
		dispatch(getUsersLoading());

		return internalUsersService.getInternalUsers(role)
			.then((result) => {
				dispatch(isAdminEnforcingMFA(role));
				return dispatch(getUsersSuccess(result.data.users));
			})
			.catch((error) => {
				dispatch(getUsersError()); 
				throw error;
			});
	};
};
