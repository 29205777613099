import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Modal } from '@syneto/compass-react';
import MaintenanceStatusFormatter from '../../../../../helpers/MaintenanceStatusFormatter';
import DateFormatterService from '../../../../../helpers/DateFormatter';

export const OperationsProductsEditModalHeader = ({ selectedProduct }) => {
	const activationDate = DateFormatterService.formatDateToSpecificFormat(selectedProduct, 'activationDate', 'DD/MM/YYYY');
	const expirationDate = (activationDate && selectedProduct.maintenanceService) ? DateFormatterService.formatDateToSpecificFormat(selectedProduct?.maintenanceService, 'expiresOn', 'DD/MM/YYYY') : null;
	const status = MaintenanceStatusFormatter.maintenanceStatus(selectedProduct.maintenanceService.expiresOn);

	return (
		<>
			<Modal.Title>Edit product</Modal.Title>

			<div>
				{
					activationDate ?
						<Badge appearance={MaintenanceStatusFormatter.setExpiresOnBadge(status)}>
							{`Expires on: ${expirationDate}`}
						</Badge>
						: null
				}

				<Badge
					appearance={MaintenanceStatusFormatter.setStyle(status)}
					className="ms-2"
				>
					{}
				</Badge>
			</div>
		</>
	);
};

OperationsProductsEditModalHeader.propTypes = {
	selectedProduct: PropTypes.object.isRequired
};
