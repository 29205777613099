import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OperationsProductsTable } from './OperationsProductsTable';
import OperationsProductsAddModal from './OperationsProductsAddModal';
import { getProducts } from '../../../../data/product/actions';
import OperationsProductsEditModal from './OperationsProductsEditModal';
import { OperationsProductsDeleteModal } from './OperationsProductsDeleteModal';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';
import { getCompaniesWithEndUsers } from '../../../../data/companies/action';
import { loadProductModels } from '../../../../data/productModels/action';
import { getMaintenancePlansOptions } from '../../../../data/maintenanceType/action';

const OperationProductsPage = (props) => {
	const { products, pagination, loading, getProducts, showAddProductModal, getCompaniesWithEndUsers, companiesWithEndUsers, getProductModels, getMaintenancePlansOptions } = props;
	const [queryParams, setQueryParams] = useState(GET_PRODUCTS_DEFAULT_QUERY_PARAMS);
	const [itemToRemove, setItemToRemove] = useState([]);
	const [editedProduct, setEditedProduct] = useState(null);
	
	useEffect(() => {
		getProducts(queryParams);
	}, [queryParams]);

	useEffect(() => {
		getCompaniesWithEndUsers();
		getProductModels();
		getMaintenancePlansOptions();
	}, []);

	const closeEditModal = () => { return setEditedProduct(null); };
	const closeDeleteModal = () => { return setItemToRemove([]); };

	return (
		<>
			<OperationsProductsTable
				products={products}
				pagination={pagination}
				queryParams={queryParams}
				setQueryParams={setQueryParams}
				setEditedProduct={setEditedProduct}
				setItemToRemove={setItemToRemove}
				loading={loading}
			/>
			{showAddProductModal && (
				<OperationsProductsAddModal 
					companiesWithEndUsers={companiesWithEndUsers}
					afterUpdateCallback={() => { getProducts(queryParams); }}
				/>
			)}

			{editedProduct && (
				<OperationsProductsEditModal
					onHide={closeEditModal}
					products={products}
					companiesWithEndUsers={companiesWithEndUsers}
					selectedProduct={editedProduct}
					afterUpdateCallback={() => { getProducts(queryParams); }}
				/>
			)}

			{itemToRemove.length > 0 && (
				<OperationsProductsDeleteModal
					onHide={closeDeleteModal}
					maintenanceServices={itemToRemove}
					afterUpdateCallback={() => { getProducts(queryParams); }}
				/>
			)}

		</>
	);
};

const mapStateToProps = (state) => {
	return {
		showAddProductModal: state.products.showAddProductModal,
		products: state.products.products,
		pagination: state.products.pagination,
		loading: state.products.loading,
		companiesWithEndUsers: state.companies.companiesWithEndUsers
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProducts: (queryParams) => { return dispatch(getProducts(queryParams)); },
		getCompaniesWithEndUsers: () => { return dispatch(getCompaniesWithEndUsers()); },
		getProductModels: () => { return dispatch(loadProductModels()); },
		getMaintenancePlansOptions: () => { return dispatch(getMaintenancePlansOptions()); },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationProductsPage);
