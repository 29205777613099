import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { onShowModalAddProduct, downloadProductsReport } from '../../data/product/actions';
import { onClickSidebarMenu } from '../../data/actions/sidebar';
import { showModalAddNewMaintenancePlan } from '../../data/maintenanceType/action';
import { onShowModalAddProductModel } from '../../data/productModels/action';
import { pushDangerNotification } from '../../data/notifications/actions';
import Sidebar from '../../components/Sidebar';
import Preloader from '../../components/preloader';

const MaintenanceServicesMaster = (props) => {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const {
		loadUserData,
		userRole,
		onShowModalAddProduct,
		onShowModalAddProductModel,
		showModalAddNewMaintenancePlan,
		onClickSidebarMenu,
		downloadProductsReport,
		pushDangerNotification,
	} = props;

	const downloadExcel = () => {
		downloadProductsReport()
			.then()
			.catch((error) => {
				pushDangerNotification(`Could not download operations products excel report. Reason: ${error.message}`);
			});
	};

	const MAINTENANCE_ROUTES = {
		PRODUCTS: {
			LABEL: 'Products',
			PATH: 'maintenance-plans',
			IDENTIFIER: 'products',
			ITEMS: [
				{
					menuTitle: 'Add product',
					fnToExecute: onShowModalAddProduct,
				},
				{
					menuTitle: 'Download products report',
					fnToExecute: downloadExcel,
				},
			],
		},
		PRODUCT_MODELS: {
			LABEL: 'Product Models',
			PATH: 'hyper-models',
			IDENTIFIER: 'models',
			ITEMS: [
				{
					menuTitle: 'Add model',
					fnToExecute: onShowModalAddProductModel,
				},
			],
		},
		COMPANIES: {
			LABEL: 'Companies',
			PATH: 'companies',
			IDENTIFIER: 'companies',
			ITEMS: [],
		},
		MAINTENANCE_TYPES: {
			LABEL: 'Maintenance Types',
			PATH: 'maintenance-types',
			IDENTIFIER: 'maintenanceTypes',
			ITEMS: [
				{
					menuTitle: 'Add maintenance type',
					fnToExecute: showModalAddNewMaintenancePlan,
				},
			],
		},
	};

	useEffect(() => {
		switch (location.pathname.split('/')[2]) {
			case MAINTENANCE_ROUTES.PRODUCT_MODELS.PATH:
				onClickSidebarMenu(MAINTENANCE_ROUTES.PRODUCT_MODELS.IDENTIFIER);
				break;
			case MAINTENANCE_ROUTES.COMPANIES.PATH:
				onClickSidebarMenu(MAINTENANCE_ROUTES.COMPANIES.IDENTIFIER);
				break;
			case MAINTENANCE_ROUTES.MAINTENANCE_TYPES.PATH:
				onClickSidebarMenu(MAINTENANCE_ROUTES.MAINTENANCE_TYPES.IDENTIFIER);
				break;
			case MAINTENANCE_ROUTES.PRODUCTS.PATH:
			default:
				onClickSidebarMenu(MAINTENANCE_ROUTES.PRODUCTS.IDENTIFIER);
				break;
		}
	}, []);

	useEffect(() => {
		if (userRole && userRole !== 'operations') {
			navigate('/');
		}

		if (!loadUserData && userRole) {
			setLoading(false);
		}
	}, [loadUserData, userRole]);

	return (
		<div id="maintenance-services" className="w-100 mt-4">
			<div className="container-fluid">
				<div className="row">
					<div className="side-menu">
						<Sidebar items={MAINTENANCE_ROUTES} />
					</div>

					<div className="page-content row">
						{!loading ? <Outlet /> : <Preloader dataIsLoading={loading} />}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loadUserData: state?.user?.loading,
		userRole: state?.user?.user?.account?.role,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onShowModalAddProduct: () => {
			return dispatch(onShowModalAddProduct());
		},
		onShowModalAddProductModel: () => {
			return dispatch(onShowModalAddProductModel());
		},
		showModalAddNewMaintenancePlan: () => {
			return dispatch(showModalAddNewMaintenancePlan());
		},
		onClickSidebarMenu: (path) => {
			dispatch(onClickSidebarMenu(path));
		},
		downloadProductsReport: () => {
			return dispatch(downloadProductsReport);
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceServicesMaster);
