import { API_DEFAULT_VERSION, API_V2, backEndAxios } from './httpClient';

export const getCustomerGrafanaData = (companyId) => {
	return backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/get-support-grafana-config?id=${companyId}`);
};

export const getCustomerDetails = (userId) => {
	return backEndAxios.get(`/central-backend/v1/internal-crud/get-customer-details?id=${userId}`);
};

export const getCustomers = (queryParams) => {
	const searchParams = new URLSearchParams(queryParams).toString();
	return backEndAxios.get(`/centralBackend/${API_V2}/iam/getCustomers?${searchParams}`);
};
