import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Icon } from '@syneto/compass-react';
import Form from '../../../../../components/forms/form';
import {
	loadProductMaintenanceServicesForSupport,
	resetMaintenanceServiceDetails
} from '../../../../../data/productMaintenanceService/actions';
import {
	pushDangerNotification
} from '../../../../../data/notifications/actions';
import Preloader from '../../../../../components/preloader';
import { getProductsSupport } from '../../../../../data/product/actions';

const Search = (props) => {
	let { handleSubmit, submitting, getProductsSupport, pushDangerNotification, setMaintenanceServicesFromSearch, resetMaintenanceServiceDetails, productsQueryParams, setProductsQueryParams } = props;
	const [dataLoaded, setDataLoaded] = useState(true);
	const [isSearchDisabled, setIsSearchDisabled] = useState(false);

	useEffect(() => {
		if (!productsQueryParams.searchFor.length) {
			return;
		}
		setDataLoaded(false);
		getProductsSupport(productsQueryParams).then((response) => {
			setMaintenanceServicesFromSearch(response.payload.products);
			setDataLoaded(true);
		});
	}, [productsQueryParams]);

	const submit = (values) => {
		setDataLoaded(false);
		resetMaintenanceServiceDetails();

		setProductsQueryParams({
			searchFor: values.query, 
			start: 0,
			limit: 30
		});
	};

	return !dataLoaded
		? <Preloader dataIsLoading={true} />
		: (
			<form className="row">
				<div className="col-11">
					<Form.text name="query"
						placeholder="Search serial numbers, activation codes or maintenance notes"
						onChange={(event) => {
							if (event.target.value === '') {
								setIsSearchDisabled(true);
								setMaintenanceServicesFromSearch([]);
							} else {
								setIsSearchDisabled(false);
							}
						}}
					/>
				</div>

				<div className="col-1">
					<Button
						disabled={submitting || isSearchDisabled}
						onClick={handleSubmit(submit)}
						block
						type="submit"
						className="w-100"
					>
						<Icon name='fa fa-search' />
					</Button>
				</div>
			</form>
		);
};

const mapStateToProps = (state) => {
	return {
		productMaintenanceService: state.productMaintenanceService
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsSupport: (queryParams) => { return dispatch(getProductsSupport(queryParams)); },
		loadProductMaintenanceServicesForSupport: () => { return dispatch(loadProductMaintenanceServicesForSupport()); },
		resetMaintenanceServiceDetails: () => { return dispatch(resetMaintenanceServiceDetails()); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

Search.propTypes = {
	setMaintenanceServicesFromSearch: PropTypes.func.isRequired
};

const SearchForm = reduxForm({
	form: 'searchForUserAccounts',
	initialValues: {
		query: ''
	}
})(Search);

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
