import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status:  null,
	error:   null,
	loading: false
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.PASSWORD_RECOVER_SUCCESS:
			return {
				...state,
				status:  'instructionsSent',
				error:   false,
				loading: false
			};
		case actionTypes.PASSWORD_RECOVER_FAILURE:
			error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
			return {
				...state,
				status:  'failed',
				error:   error,
				loading: false
			};
		default:
			return state;
	}
};
