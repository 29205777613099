import React, { useEffect, useState } from 'react';
import { Card } from '@syneto/compass-react';
import Search from './Search';
import ProductsTable from './ProductsTable';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';

const SupportCustomers = () => {
	const [searchStarted, setSearchStarted] = useState(false);
	const [maintenanceServicesFromSearch, setMaintenanceServicesFromSearch] = useState([]);
	const [productsQueryParams, setProductsQueryParams] = useState(GET_PRODUCTS_DEFAULT_QUERY_PARAMS);
	const [shouldRenderUserTabelAndSupportCustomerTable, setShouldRenderUserTabelAndSupportCustomerTable] = useState(false);

	useEffect(() => {
		setShouldRenderUserTabelAndSupportCustomerTable(maintenanceServicesFromSearch.length > 0);
	}, [maintenanceServicesFromSearch]);

	const updateMaintenanceServicesFromSearch = (maintenanceServicesFromSearch, trimmedQuery) => {
		setMaintenanceServicesFromSearch(maintenanceServicesFromSearch);
		setSearchStarted(true);
	};

	const renderMaintenanceServicesTable = () => {
		return (maintenanceServicesFromSearch?.length > 0) && (
			<ProductsTable
				maintenanceServices={maintenanceServicesFromSearch}
				productsQueryParams={productsQueryParams}
				setProductsQueryParams={setProductsQueryParams}
			/>
		);
	};

	const renderEmptyState = () => {
		return (
			<p style={{ color: '#b1b7bd' }}>
				There are no customers, machines or maintenance plans to match the search criteria.
			</p>
		);
	};

	return (
		<>
			<Card
				style={{ marginBottom: '10px' }}
				content={
					<>
						<Search
							setMaintenanceServicesFromSearch={updateMaintenanceServicesFromSearch}
							setProductsQueryParams={setProductsQueryParams}
							productsQueryParams={productsQueryParams}
						/>
						{!shouldRenderUserTabelAndSupportCustomerTable && renderMaintenanceServicesTable()}
						{searchStarted && !maintenanceServicesFromSearch?.length && renderEmptyState()}
					</>
				}
			/>
			{
				shouldRenderUserTabelAndSupportCustomerTable && (
					<>
						<Card
							style={{ marginBottom: '10px' }}
							content={renderMaintenanceServicesTable()}
						/>
					</>
				)
			}
		</>
	);
};

export default SupportCustomers;
