import { processErrorMessage } from '../../helpers/normalize-data';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	user: null,
	status: null,
	loading: false,
	error: null,
	showSelectedUserEditModal: false,
	showSelectedUserDeletetModal: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.START_USER_REGISTRATION:
		case actionTypes.START_DELETING_SELECTED_USER:
		case actionTypes.START_UPDATING_SELECTED_USER:
			return {
				...state,
				status: 'panding'
			};
		case actionTypes.SELECTED_USER_UPDATE_SUCCESS:
		case actionTypes.SELECTED_USER_DELETE_SUCCESS:
		case actionTypes.REGISTRATION_SUCCESS:
			return {
				...state,
				status: 'success'
			};
		case actionTypes.REGISTRATION_FAILURE:
		case actionTypes.SELECTED_USER_DELETE_FAILED:			
		case actionTypes.SELECTED_USER_UPDATE_ERROR:
			return {
				...state,
				error: processErrorMessage(action.payload),
				status: 'failed'
			};
		case actionTypes.SHOW_SELECTED_USER_EDIT_MODAL:
			return {
				...state,
				user: action.payload,
				showSelectedUserEditModal: true,
				showSelectedUserDeletetModal: false
			};
		case actionTypes.SHOW_DELETE_USER_DELETE_MODAL:
			return {
				...state,
				user: action.payload,
				showSelectedUserEditModal: false,
				showSelectedUserDeletetModal: true
			};
		case actionTypes.HIDE_DELETE_USER_DELETE_MODAL:
		case actionTypes.HIDE_SELECTED_USER_EDIT_MODAL:
			return INITIAL_STATE;
		default:
			return state;
	}
};
