import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	status: null,
	error: null,
	loading: false,
	data: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.GET_CUSTOMERS_LOADING:
			return {
				...state,
				status: 'panding',
				loading: true
			};
		case actionTypes.GET_CUSTOMERS_SUCCESS:
			return {
				...state,
				status: 'loaded',
				loading: false,
				data: action.payload
			};
		case actionTypes.GET_CUSTOMERS_ERROR:
			return {
				...state,
				status: 'failed',
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};
