import * as actions from '../actionTypes';

const INITIAL_STATE = {
	exporting: false,
	exported: false,
	error: null,
	file: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.EXPORTING_TO_CSV:
			return {
				...state,
				exporting: true
			};
		case actions.EXPORT_TO_CSV_SUCCESS:
			return {
				...state,
				exporting: false,
				exported: true,
				file: action.payload
			};
		case actions.EXPORT_TO_CSV_ERROR:
			return {
				...state,
				exporting: false,
				exported: false,
				error: action.payload
			};
		default:
			return state;
	}
};
