import { unflatten } from 'flat';
import Validator from '../../../../../../components/forms/validate';

class ValidationService {
	static validate = (values) => {
		let validationRules = {};

		if (values.npsNotificationEnabled === true) {
			validationRules['daysToSendFirstNpsEmailAfter'] = ['required', 'integer', 'min:1', 'max:31'];
			validationRules['monthsToSendSecondNpsEmailAfter'] = ['required', 'integer', 'min:1', 'max:12'];
		}

		const validator = new Validator(values, validationRules);
		return (validator.fails() ? unflatten(validator.errors.all()) : {});
	};
}

export default ValidationService;

