import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import UserRoles from '../../constants/user-roles';
import Nav from '../Nav';
import Notification from '../notification';
import { usePrevious } from '../../hooks/usePrevious';
import { checkToken } from '../../data/authenticate/action';
import { getUserProfile } from '../../data/profile/action';
import { pushDangerNotification } from '../../data/notifications/actions';
import { getSettings } from '../../data/settings/actions';

const Layout = (props) => {
	const { userRole, getUserProfile, checkToken, authentication, authenticationStatus, pushDangerNotification, getSettings } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = queryString.parse(location.search);
	const prevRole = usePrevious(userRole);
	const prevAuthenticationStatus = usePrevious(authenticationStatus);

	useEffect(() => {
		const authenticationToken = localStorage.getItem('authenticationToken');
		const redirectTo = queryParams.redirect_to || location.hash || '';

		if (authenticationToken) {
			checkToken(authenticationToken)
				.then(() => {
					getSettings();
				})
				.catch((e) => {
					pushDangerNotification('Token validation has failed...');
					navigate('/sign-in');
				});
		} else if ( !location.pathname.includes( 'password-reset')) {
			navigate('/sign-in');
		}

		if (redirectTo) {
			if (redirectTo.indexOf('#') !== -1) {
				navigate(`${location.pathname}${redirectTo}`);
			} else {
				navigate(`/${redirectTo}`);
			}
		}
	}, []);

	useEffect(() => {
		const sholdShowMFARecoveryCodes = authentication.mfaData.recoveryCodes.length > 0;

		if (authenticationStatus === 'authenticated' && !sholdShowMFARecoveryCodes) { 
			getUserProfile(); 
			getSettings();
		}

		if (prevAuthenticationStatus === 'authenticated' && authenticationStatus === 'unauthenticated') {
			navigate('/sign-in');
		}
	}, [authentication, authenticationStatus, authentication.mfaData, authentication.mfaData.recoveryCodes]);

	useEffect(() => {
		if (!prevRole && userRole) {
			redirectUserBasedOnRole();
		}
	}, [prevRole, userRole]);

	useEffect(() => {
		redirectUserBasedOnRole();
	}, [location, location.pathname]);

	const redirectUserBasedOnRole = () => {
		if (userRole && (location.pathname === '/' || location.pathname === '/sign-in' || location.pathname.includes( 'password-reset'))) {
			switch (userRole) {
				case UserRoles.SUPPORT_ENGINEER:
					navigate('/support');
					break;
				case UserRoles.MARKETING:
					navigate('/marketing');
					break;
				case UserRoles.OPERATIONS:
					navigate('/maintenance-service');
					break;
				case UserRoles.ADMINISTRATOR:
					navigate('/user-management');
					break;
				default:
					navigate('/sign-in');
					break;
			}
		}
	};

	return (
		<div className="w-100 h-100">
			<Nav {...props} />
			<Outlet />
			<Notification />
		</div>
	);
};

const mapStateToProps = ({ user, authentication }) => {
	return {
		userRole: user?.user?.account?.role,
		authentication: authentication,
		authenticationStatus: authentication.status
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserProfile: () => { return dispatch(getUserProfile()); },
		getSettings: () => { return dispatch(getSettings()); },
		checkToken: (token) => { return dispatch(checkToken(token)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
