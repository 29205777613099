import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import Form from '../../components/forms/form';
import { connect } from 'react-redux';
import { Button, Card } from '@syneto/compass-react';
import { validateToken, createNps, updateNps } from './data/actions/actions';
import { languageVars, translations } from './language';
import Preloader from '../../components/preloader';

let NPS = (props) => {
	const [componentDidMount, setDidComponentDidMount] = useState(false);
	const [surveyCriteria, setSurveyCriteria] = useState(props.surveyCriteria);
	const [likelihoodToRecommend, setLikelihoodToRecommend] = useState(0);

	function submit(formFields) {
		props.updateNps(formFields);
	}

	function onChange(e) {
		const modifiedSurveyCriteria = surveyCriteria.map((surveyCriteria) => {
			if (surveyCriteria.name === e.target.name) {
				surveyCriteria.checked = parseInt(e.target.value);
			}

			return surveyCriteria;
		});

		setSurveyCriteria(modifiedSurveyCriteria);
	}

	useEffect(() => {
		if (!componentDidMount) {
			const formFields = props.initialValues;

			props.validateToken(formFields.hash);
			props.createNps(formFields);

			setDidComponentDidMount(true);
		}
	}, [componentDidMount]);

	return (
		props.npsSurvey.loading
			?
			<div className="row h-100 justify-content-center align-items-center">
				<Preloader dataIsLoading={true} />
			</div>
			:
			<div className="container w-100 h-100">
				<Card
					content={props.npsSurvey.tokenValid
						?
						<form noValidate>
							<Form.hidden name="hash" />
							<h1
								className="mb-5">{translations[props.language]['likelihoodToRecommend'][props.criteriaList]}</h1>

							<div className="row">
								<div className="col-12">
									<div className="d-flex w-100">
										{[...Array(11).keys()].map((value) => {
											return (
												<div key={value} className={`nps flex-fill`}>
													<Form.radio
														name="scores.likelihoodToRecommend"
														id={`value_${Math.random()}`}
														value={value}
														label={value}
														checked={likelihoodToRecommend === value}
														onChange={(e) => { return setLikelihoodToRecommend(parseInt(e.target.value)); }}
													/>
													<span>{value === 0 && translations[props.language]['notLikely'] || value === 10 && translations[props.language]['veryLikely']}</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<h5 className="mt-5 mb-5">{translations[props.language]['moreInsight']}</h5>

							<div className="row p-3">
								<div className="col-2">&nbsp;</div>
								<div
									className="col-2 font-weight-semi-bold text-center">{translations[props.language]['dissatisfied']}</div>
								<div
									className="col-2 font-weight-semi-bold text-center">{translations[props.language]['somewhatDissatisfied']}</div>
								<div
									className="col-2 font-weight-semi-bold text-center">{translations[props.language]['neutral']}</div>
								<div
									className="col-2 font-weight-semi-bold text-center">{translations[props.language]['somewhatSatisfied']}</div>
								<div
									className="col-2 font-weight-semi-bold text-center">{translations[props.language]['satisfied']}</div>
							</div>
							{
								surveyCriteria.map((criteria) => {
									return (
										<div key={criteria.name} className="row p-3">
											<div className="col-2 font-weight-bold">{criteria.label}</div>
											{[...Array(5).keys()].map((value) => {
												value++;
												return (
													<div key={`${criteria.name}_${value}`} className="col-2 text-center">
														<Form.radio
															name={criteria.name}
															id={`value_${Math.random()}`}
															value={value}
															checked={criteria.checked === value}
															onChange={onChange}
														/>
													</div>
												);
											})}
										</div>
									);
								})
							}
							<div className="row p-3">
								<div className="col-2 font-weight-bold">{translations[props.language]['otherComments']}</div>
								<div className="col-10">
									<Form.textarea rows="5" name="otherComments" />
								</div>
							</div>
							<Button onClick={props.handleSubmit(submit)}>{translations[props.language]['submit']}</Button>
						</form>
						:
						<div>
							{props.npsSurvey.formSubmitted
								? translations[props.language]['thankyou']
								: translations[props.language]['alreadysubmitted']
							}
						</div>
					}
				/>
			</div>
	);
};

NPS = reduxForm({
	form: 'nps',
	enableReinitialize: true
})(NPS);

const mapStateToProps = (state, ownProps) => {
	let surveyCriteria, scores;
	let { hash, likelihoodToRecommend, criteriaList, language } = ownProps.match.params;

	criteriaList = parseInt(criteriaList);
	surveyCriteria = languageVars[language]['criteriaList'][criteriaList];
	scores = {
		likelihoodToRecommend: parseInt(likelihoodToRecommend),
		...languageVars['en']['initialValues']['scores'][criteriaList]
	};

	return {
		language: language,
		npsSurvey: state.npsSurvey,
		surveyCriteria: surveyCriteria,
		criteriaList: criteriaList,
		initialValues: {
			hash: hash,
			type: criteriaList,
			scores: scores,
			otherComments: ''
		}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		validateToken: (hash) => {
			return dispatch(validateToken(hash));
		},
		createNps: (formFields) => {
			return dispatch(createNps(formFields));
		},
		updateNps: (formFields) => {
			return dispatch(updateNps(formFields));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NPS);
