import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, List, Modal, Tooltip } from '@syneto/compass-react';
import moment from 'moment';
import { consumeToken, generateToken, loadSupportTunnels, readToken } from '../../../data/actions/actions';

function SupportSerenityEventsEditModalFn(props) {
	const { isVisible, close, event, renderStatus, handleEventStatusChange, openDeleteModal, supportTunnels, accessToken } = props;
	const [accessTokenTooltipText, setAccessTokenTooltipText] = useState('Copy token');
	const [sshPortTooltipText, setSshPortTooltipText] = useState('Copy port');

	useEffect(() => {
		if (event?.emitter?.serialNumber) {
			props.loadSupportTunnels(event.emitter.serialNumber);
			props.readToken(event.emitter.serialNumber);
		}
	}, [event]);

	function generateToken(serialNumber) {
		if (hasPinValue(accessToken)) {
			props.consumeToken(serialNumber);
			props.readToken(serialNumber);
		} else {
			props.generateToken(serialNumber);
		}
	}

	function hasPinValue(accessToken) {
		return accessToken && accessToken.pin && accessToken.pin.value;
	}

	function arrowState(state) {
		return state === 'lost' && 'fa fa-arrow-down' || state === 'up' && 'fa fa-arrow-up' || null;
	}

	function setStyle(state) {
		return state === 'lost' && '#E04C59' || state === 'up' && '#00A865' || null;
	}

	function copyToClipboard(inputId, changeTooltipText) {
		const copyText = document.getElementById(inputId);
		copyText.select();
		document.execCommand('copy');

		changeTooltipText('Copied: ' + copyText.value);
	}

	function handleStatusChange(status) {
		handleEventStatusChange(event, status.toLowerCase());
	}

	function handleDelete() {
		openDeleteModal([event]);
	}

	function renderStatusDropdown() {
		return <Dropdown
			label={renderStatus(null, event)}
			onSelect={handleStatusChange}
			items={[
				{ label: 'New' },
				{ label: 'Pending' },
				{ label: 'Resolved' },
				{ label: 'Closed' },
				{ label: 'Ignored' }
			]}
		/>;
	}

	function renderWebPort(supportPorts) {
		return (
			<>
				{supportPorts && supportPorts.web && supportPorts.web.state !== 'down'
					? <div>
						<div>
							<Icon name={`${arrowState(supportPorts.web.state)} pf-2`} />
							<input
								style={{ color: setStyle(supportPorts.web.state) }}
								className="portInputElement"
								id="webPort" type="text" readOnly
								value={supportPorts.web.port}
							/>
							<a
								style={{ color: '#70758B' }}
								className="btn btn-default p-0"
								href={`https://${supportPorts.host}:${supportPorts.web.port}${hasPinValue(accessToken) ?
									'/?pin=' + accessToken.pin.value : ''}`}
								target="_blank" rel="noreferrer"
							>
								<Icon name="fa fa-external-link ps-2" />
							</a>
						</div>
						<div>
							{`https://${supportPorts.host}:${supportPorts.web.port}${hasPinValue(accessToken) ?
								'/?pin=' + accessToken.pin.value :
								''}`}
						</div>
					</div>
					: <div>Web port is not opened</div>
				}
			</>
		);
	}

	function renderSshPort(supportPorts) {
		return (
			<>
				{supportPorts && supportPorts.cli && supportPorts.cli.state !== 'down'
					? <div>
						<Icon name={`${arrowState(supportPorts.cli.state)} pf-2`} />
						<input
							id="inputSSHPortModal" className="portInputElement"
							style={{ color: setStyle(supportPorts.cli.state) }}
							type="text" readOnly
							value={supportPorts.cli.port}
						/>
						<Tooltip text={sshPortTooltipText} id='tooltipSSHPortModal' placement="top">
							<a
								style={{ color: '#70758B' }}
								onClick={() => {
									copyToClipboard('inputSSHPortModal', setSshPortTooltipText);
								}}
							>
								<Icon name="fa fa-copy ps-2" />
							</a>
						</Tooltip>
					</div>
					: <div>SSH port is not opened</div>
				}
			</>
		);
	}

	function renderLoginToken() {
		const osVersion = event && event.emitter.osVersion ? parseInt(event.emitter.osVersion.replace('.', '')) : null;
		return (
			<>
				{osVersion < 44
					? <div className="text-value">Login tokens are not supported</div>
					: <div className="row">
						<div className="col-8 text-value">
							{hasPinValue(accessToken)
								? <div>
									<Icon name='fa fa-check pf-2' />
									<input id="inputAccessTokenModal" className="portInputElement" type="text" readOnly
										value={accessToken.pin.value} />
									<Tooltip id='tooltipAccessTokenModal' placement="top" text={accessTokenTooltipText}>
										<a
											style={{ color: '#70758B' }}
											onClick={() => {
												copyToClipboard('inputAccessTokenModal', setAccessTokenTooltipText);
											}}
										>
											<Icon name="fa fa-copy ps-2" />
										</a>
									</Tooltip>
								</div>
								: <div>No access token</div>
							}
						</div>
						<div className="col-4">
							<a href="javascript:;" onClick={() => {
								generateToken(event.emitter.serialNumber);
							}}>
								{hasPinValue(accessToken) ? 'Cancel' : 'Generate'}
							</a>
						</div>
					</div>
				}
			</>
		);
	}

	return event && (
		<Modal
			size="lg"
			show={isVisible}
			scrollable
		>
			<Modal.Header>
				<Modal.Title>Edit Serenity Event</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="row">
					<div className="col-5">
						<List
							title="Contact / Appliance"
							tabbed
							items={[
								{ label: 'Contact', value: event.emitter.contactName },
								{ label: 'Contact email', value: event.emitter.email },
								{ label: 'Company', value: event.emitter.companyName },
								{ label: 'Company email', value: event.emitter.companyEmail },
								{ label: 'Machine S/N', value: event.emitter.serialNumber },
								{ label: 'Machine model', value: event.emitter.productModel },
								{ label: 'SynetoOS version', value: event.emitter.osVersion || 'N/A' },
								{ label: 'ESXi version', value: event.emitter.esxiVersion || 'N/A' }
							]}
						/>
					</div>
					<div className="col-6 offset-1">
						<List
							title="Event details"
							tabbed
							items={[
								{ label: 'Status', value: renderStatusDropdown() },
								{ label: 'Date - time', value: moment(event.recordTimestamp, 'x').format('DD/MM/YYYY - HH:mm') },
								{ label: 'Web port', value: renderWebPort(supportTunnels) },
								{ label: 'SSH port', value: renderSshPort(supportTunnels) },
								{ label: 'Login token', value: renderLoginToken() },
								{ label: 'Event title', value: event.content.subject },
								{ label: 'Event details', value: event.content.body }
							]}
						/>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<div style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'space-between'
				}}
				>
					<Button appearance="danger" role="secondary" onClick={handleDelete}>
						Delete
					</Button>
					<Button onClick={close}>
						Close
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

SupportSerenityEventsEditModalFn.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	event: PropTypes.object,
	renderStatus: PropTypes.func.isRequired,
	handleEventStatusChange: PropTypes.func.isRequired,
	openDeleteModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		supportTunnels: state.supportTunnels.supportTunnels,
		accessToken: state.accessToken.accessToken
	};
};

export const SupportSerenityEventsEditModal = connect(mapStateToProps, {
	loadSupportTunnels,
	readToken,
	generateToken,
	consumeToken
})(SupportSerenityEventsEditModalFn);
