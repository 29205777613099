import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EnforcementDisclamer from './EnforcementDisclamer';
import MFASetupCard from './MFASetupCard';
import RecoveryCodes from './RecoveryCodes';
import { getUserProfile } from '../../../../data/profile/action';
import { verifyMfaCode } from '../../../../data/authenticate/action';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

const MFAConfigTunnel = (props) => {
	const { isEnforced, authentication, verifyMfaCode, getUserProfile, pushSuccessfulNotification, pushDangerNotification } = props;
	const [showEnforcementDisclamer, setShowEnforcementDisclamer] = useState(isEnforced);
	const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);

	useEffect(() => {
		const sholdShowMFARecoveryCodes = authentication.mfaData.recoveryCodes.length > 0;

		if (authentication.status === 'authenticated' && sholdShowMFARecoveryCodes) { 
			setShowRecoveryCodes(true); 
		}
	}, [authentication, authentication.status, authentication.mfaData, authentication.mfaData.recoveryCodes]);

	const renderMFATunnelStep = () => {
		switch (true) {
			case showEnforcementDisclamer:
				return <EnforcementDisclamer close={() => { return setShowEnforcementDisclamer(false); }}/>;
			case showRecoveryCodes:
				return <RecoveryCodes authentication={authentication} getUserProfile={getUserProfile} pushSuccessfulNotification={pushSuccessfulNotification}/>;
			default:
				return <MFASetupCard authentication={authentication} verifyMfaCode={verifyMfaCode} pushSuccessfulNotification={pushSuccessfulNotification} pushDangerNotification={pushDangerNotification} />;
		}
	};

	return (
		<div className="col log-in mfa-config">
			{renderMFATunnelStep()}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyMfaCode: (data) => { return dispatch(verifyMfaCode(data)); },
		getUserProfile: () => { return dispatch(getUserProfile()); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MFAConfigTunnel);
