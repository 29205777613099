import React from 'react';
import moment from 'moment';
import { Table } from '@syneto/compass-react';
import DateFormatter from '../../../../helpers/DateFormatter';

const AdminProductMaintenancePlansTable = ({ product }) => {
	const { maintenanceService: { renewals, ...initialService } } = product;
	const maintenanceService = [initialService, ...renewals].map((service, key) => {
		return {
			...service,
			type: key === 0 ? 'Initial' : 'Renewal' 
		}; 
	});

	const renderSupportLevel = (cell, row) => { return row?.plan?.customerFacingName ?? 'N/A'; };

	const renderDuration = (cell, row) => {
		const duration = row && row.duration;
		return DateFormatter.humanizeMonths(duration);
	};

	const renderStartDate = (cell, row) => {
		const startDate = row && row.startDate;
		return moment(startDate).format('YYYY-MM-DD');
	};

	const renderLastDayActive = (cell, row) => {
		const endDate = row && row.endDate;
		return moment(endDate).format('YYYY-MM-DD');
	};

	return (
		<Table
			data={ maintenanceService }
			keyField="id"
		>
			<Table.Col field="type">Type</Table.Col>
			<Table.Col field="supportLevel" renderCell={ renderSupportLevel }>Support level</Table.Col>
			<Table.Col field="duration" renderCell={ renderDuration }>Duration</Table.Col>
			<Table.Col field="startDate" renderCell={ renderStartDate }>Start date</Table.Col>
			<Table.Col field="lastDayActive" renderCell={ renderLastDayActive }>Last day active</Table.Col>
		</Table>
	);
};

export default AdminProductMaintenancePlansTable;
