import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	status: null,
	error: null,
	loading: false,
	products: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.GETTING_USER_PRODUCTS:
			return {
				...INITIAL_STATE,
				status: 'pending',
				loading: true
			};
		case actionTypes.GET_USER_PRODUCTS_SUCCESS:
			return {
				...state,
				status: 'loaded',
				loading: false,
				products: action.payload.products
			};
		case actionTypes.GET_USER_PRODUCTS_ERROR:
			return {
				...state,
				status: 'failed',
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

