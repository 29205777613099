import React, { useEffect, useState } from 'react';
import { Field, change } from 'redux-form';
import { Form } from '@syneto/compass-react';
import { connect } from 'react-redux';

const MfaField = (props) => {
	const renderField = (props) => {
		const { label, icon, required, id, readOnly, className, setValue } = props;
		const { placeholder, autoFocus, disabled } = props;
		const { meta: { touched, error, warning, form }, input, input: { name } } = props;
		const [visibleValue, setVisibleValue] = useState(null);

		const handleMfaChange = (value) => {
			const trimedValue = value.replace('-','');
			if (trimedValue.length > 3) {
				setVisibleValue(trimedValue.slice(0, 3) + '-' + trimedValue.slice(3));
			} else {
				setVisibleValue(trimedValue);
			}
	
			setValue(form, name, trimedValue);
		};

		return (
			<Form.Group className='mb-3' controlId={ id }>
				{ label ? <Form.Label>{ label }{ required ? '*' : null } { icon ? icon : null }</Form.Label> : null }
	
				<Form.Control type="hidden" isInvalid={ touched && error } { ...input } />
				<Form.Control
					placeholder={ placeholder }
					autoFocus={ autoFocus }
					disabled={ disabled }
					isInvalid={ touched && error }
					readOnly={ readOnly }
					className={ className }
					value={ visibleValue }
					maxLength={7}
					onChange={(e) => {
						return handleMfaChange(e.target.value); 
					}}
				/>
	
				{ touched && warning && <span>{ warning }</span> }
	
				<Form.Control.Feedback type="invalid">
					{ error }
				</Form.Control.Feedback>
			</Form.Group>
		);
	};
	
	return <Field { ...props } component={ renderField }/>; 
};

const mapDispatchToProps = (dispatch) => {
	return {
		setValue: (formName, name, value) => {
			return dispatch(change(formName, name, value)); 
		}
	};
};

export default connect(null, mapDispatchToProps)(MfaField);
