import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';

export const gettingMaintenancePlansReport = () => { return { type: actionTypes.GETTING_MAINTENANCE_TYPES_REPORT }; };

export const createMaintenancePlan = (values) => {
	return (dispatch) => {
		return axios.post('/maintenance/plans', values).then((response) => {
			if ( response.status === 200 ) {
				dispatch(createMaintenancePlanSuccess(response.data));
			}
		}).catch((error) => {
			dispatch(createMaintenancePlanError(error?.response?.data?.message));
			throw new Error(error?.response?.data?.message);
		});
	};
};

export const creatingMaintenancePlan = () => {
	return {
		type:    actionTypes.CREATING_MAINTENANCE_TYPE,
		payload: null
	};
};

export const createMaintenancePlanSuccess = (data) => {
	return {
		type:    actionTypes.CREATE_MAINTENANCE_TYPE_SUCCESS,
		payload: data
	};
};

export const createMaintenancePlanError = (data) => {
	return {
		type:    actionTypes.CREATE_MAINTENANCE_TYPE_ERROR,
		payload: data
	};
};

export const updateMaintenancePlan = (values) => {
	return (dispatch) => {
		return axios.put('/maintenance/plans/update', values).then((response) => {
			if ( response.status === 200 ) {
				dispatch(updateMaintenancePlanSuccess(response.data));
			}
		}).catch((error) => {
			dispatch(updateMaintenancePlanError(error?.response?.data?.message));
		});
	};
};

export const updatingMaintenancePlan = () => {
	return {
		type:    actionTypes.UPDATING_MAINTENANCE_TYPE,
		payload: null
	};
};

export const updateMaintenancePlanSuccess = (data) => {
	return {
		type:    actionTypes.UPDATE_MAINTENANCE_TYPE_SUCCESS,
		payload: data
	};
};

export const updateMaintenancePlanError = (data) => {
	return {
		type:    actionTypes.UPDATE_MAINTENANCE_TYPE_ERROR,
		payload: data
	};
};

export const getMaintenancePlansAdminReport = (maintenancePlansStatus) => {
	return (dispatch) => {
		return axios.get('/maintenance/plans', { params: { maintenancePlansStatus } })
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(getMaintenancePlansReportSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(getMaintenancePlansReportError(error?.response?.data?.message));
			});
	};
};

export const getMaintenancePlansReportSuccess = (data) => {
	return {
		type:    actionTypes.GET_MAINTENANCE_TYPES_REPORT_SUCCESS,
		payload: data
	};
};

export const getMaintenancePlansReportError = (data) => {
	return {
		type:    actionTypes.GET_MAINTENANCE_TYPES_REPORT_ERROR,
		payload: data
	};
};

export const showModalAddNewMaintenancePlan = () => {
	return {
		type:    actionTypes.SHOW_MODAL_ADD_NEW_MAINTENANCE_TYPE,
		payload: null
	};
};

export const hideModalAddNewMaintenancePlan = () => {
	return {
		type:    actionTypes.HIDE_MODAL_ADD_NEW_MAINTENANCE_TYPE,
		payload: null
	};
};

export const deleteMaintenancePlan = (maintenancePlanId, formFields) => {
	return (dispatch) => {
		return axios.delete(`/maintenance/plans/${ maintenancePlanId }`, formFields)
			.then((response) => {
				dispatch(deleteMaintenancePlanSuccess(response.data));
			}).catch((error) => {
				dispatch(deleteMaintenancePlanError(error.response.data));
			});
	};
};

export const deletingMaintenancePlan = () => {
	return {
		type:    actionTypes.DELETING_MAINTENANCE_TYPE,
		payload: null
	};
};

const deleteMaintenancePlanSuccess = (data) => {
	return {
		type:    actionTypes.DELETE_MAINTENANCE_TYPE_SUCCESS,
		payload: data
	};
};

const deleteMaintenancePlanError = (error) => {
	return {
		type:    actionTypes.DELETE_MAINTENANCE_TYPE_ERROR,
		payload: error
	};
};


export const gettingMaintenancePlansOptions = () => {
	return {
		type:    actionTypes.GETTING_MAINTENANCE_PLANS_OPTIONS,
		payload: null
	};
};

export const getMaintenancePlansOptions = () => {
	return (dispatch) => {
		dispatch(gettingMaintenancePlansOptions());
		return axios.get('/maintenance/plans/grouped').then((response) => {
			if ( response.status === 200 ) {
				dispatch(getMaintenancePlansSuccess(response.data));
			}
		}, (error) => {
			dispatch(getMaintenancePlansError(error?.response?.data?.message));
		});
	};
};

export const getMaintenancePlansSuccess = (data) => {
	return {
		type:    actionTypes.GET_MAINTENANCE_PLANS_OPTIONS_SUCCESS,
		payload: data
	};
};

export const getMaintenancePlansError = (error) => {
	return {
		type:    actionTypes.GET_MAINTENANCE_PLANS_OPTIONS_ERROR,
		payload: error
	};
};
