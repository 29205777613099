import * as customersService from '../../../../services/customers';
import { axios } from '../../../../services/httpClient';
import * as actionTypes from '../actionTypes';

export const readToken = (serialNumber) => {
	return (dispatch) => {
		return axios.get(`authorization/pin?serialNumber=${serialNumber}`).then((response) => {
			dispatch(readingTokenSuccess(response));
		})
			.catch((result) => {
				dispatch(readingTokenFailure(result));
			});
	};
};

export const readingTokenSuccess = (accessToken) => {
	return {
		type: actionTypes.READING_TOKEN_SUCCESS,
		payload: accessToken.data
	};
};

export const readingTokenFailure = (error) => {
	return {
		type: actionTypes.READING_TOKEN_FAILURE,
		payload: error
	};
};

export const generateToken = (serialNumber) => {
	return (dispatch) => {
		return axios.post('authorization/pin', { serialNumber: serialNumber }).then((response) => {
			dispatch(generatingTokenSuccess(response));
		})
			.catch((result) => {
				dispatch(generatingTokenFailure(result));
			});
	};
};

export const generatingTokenSuccess = (accessToken) => {
	return {
		type: actionTypes.GENERATING_TOKEN_SUCCESS,
		payload: accessToken.data
	};
};

export const generatingTokenFailure = (error) => {
	return {
		type: actionTypes.GENERATING_TOKEN_FAILURE,
		payload: error
	};
};

export const consumeToken = (serialNumber) => {
	return (dispatch) => {
		return axios.delete(`authorization/pin/${serialNumber}`).then(() => {
			dispatch(readToken(serialNumber));
		})
			.catch((result) => {
				dispatch(consumingTokenFailure(result));
			});
	};
};

export const consumingTokenSuccess = (accessToken) => {
	return {
		type: actionTypes.CONSUMING_TOKEN_SUCCESS,
		payload: accessToken.data
	};
};

export const consumingTokenFailure = (error) => {
	return {
		type: actionTypes.CONSUMING_TOKEN_FAILURE,
		payload: error
	};
};

export const loadSupportTunnels = (serialNumber = '') => {
	return (dispatch) => {
		return axios.get('/port-states' + (serialNumber && '/' + serialNumber))
			.then((response) => {
				dispatch(loadingSupportTunnelsSuccess(response.data));
			})
			.catch((result) => {
				dispatch(loadingSupportTunnelsFailure(result));
			});
	};
};

export const loadingSupportTunnelsSuccess = (data) => {
	return {
		type: actionTypes.LOADING_SUPPORT_TUNNELS_SUCCESS,
		payload: data.data
	};
};

export const loadingSupportTunnelsFailure = (error) => {
	return {
		type: actionTypes.LOADING_SUPPORT_TUNNELS_FAILURE,
		payload: error
	};
};

export const getUserProductsSupport = (user_id) => {
	return (dispatch) => {
		dispatch(gettingUserProducts());
		return axios.get(`/support/user/products/${user_id}`)
			.then((result) => {
				dispatch(getUserProductsSuccess(result.data));
			})
			.catch((error) => {
				dispatch(getUserProductsError(error.message));
			});
	};
};

export const gettingUserProducts = () => {
	return {
		type: actionTypes.GETTING_USER_PRODUCTS,
		payload: null
	};
};

export const getUserProductsSuccess = (data) => {
	return {
		type: actionTypes.GET_USER_PRODUCTS_SUCCESS,
		payload: data
	};
};

export const getUserProductsError = (error) => {
	return {
		type: actionTypes.GET_USER_PRODUCTS_ERROR,
		payload: error
	};
};

export const getNoteDetails = (note_id) => {
	return (dispatch) => {
		return axios.get(`/support/product/notes/${note_id}`).then((response) => {
			if (response.status === 200) {
				return dispatch(getNoteDetailsSuccess(response.data));
			}
		}).catch((responseWithError) => {
			dispatch(getNoteDetailsError(responseWithError.response.data));
			throw new Error(responseWithError.response.data.message);
		});
	};
};

export const getNoteDetailsSuccess = (data) => {
	return {
		type: actionTypes.GET_NOTE_DETAILS_SUCCESS,
		payload: data
	};
};

export const getNoteDetailsError = (error) => {
	return {
		type: actionTypes.GET_NOTE_DETAILS_ERROR,
		payload: error
	};
};

export const getNotesForProduct = (product_id) => {
	return (dispatch) => {
		return axios.get(`/support/product/${product_id}/notes`).then((response) => {
			if (response.status === 200) {
				return dispatch(getNotesForProductSuccess(response.data));
			}
		}).catch((responseWithError) => {
			dispatch(getNotesForProductError(responseWithError.response.data));
			throw new Error(responseWithError.response.data.message);
		});
	};
};

export const getNotesForProductSuccess = (data) => {
	return {
		type: actionTypes.GET_NOTES_FOR_PRODUCT_SUCCESS,
		payload: data
	};
};

export const getNotesForProductError = (error) => {
	return {
		type: actionTypes.GET_NOTES_FOR_PRODUCT_ERROR,
		payload: error
	};
};

export const saveNoteForProduct = (productId, { title, content, noteAttachments }) => {
	const formData = new FormData();
	formData.append('productId', productId);
	formData.append('title', title);
	formData.append('content', content);

	let i = 0;
	for (const noteAttachment of noteAttachments) {
		formData.append(`noteAttachment_${i}`, noteAttachment);
		i++;
	}

	return (dispatch) => {
		return axios.post('/support/product/notes', formData,
			{ headers: { 'Content-Type': 'multipart/form-data' } }
		).then((response) => {
			if (response.status === 200) {
				return dispatch(saveNoteForProductSuccess(response.data));
			}
		}).catch((responseWithError) => {
			dispatch(saveNoteForProductError(responseWithError.response.data));
			throw new Error(responseWithError.response.data.message);
		});
	};
};

export const saveNoteForProductSuccess = (data) => {
	return {
		type: actionTypes.SAVE_NOTE_FOR_PRODUCT_SUCCESS,
		payload: data
	};
};

export const saveNoteForProductError = (error) => {
	return {
		type: actionTypes.SAVE_NOTE_FOR_PRODUCT_ERROR,
		payload: error
	};
};

export const updateNoteForProduct = (note_id, { title, content }) => {
	return (dispatch) => {
		return axios.put('/support/product/notes', { note_id, title, content }).then((response) => {
			if (response.status === 200) {
				return dispatch(updateNoteForProductSuccess(response.data));
			}
		}).catch((responseWithError) => {
			dispatch(updateNoteForProductError(responseWithError.response.data));
			throw new Error(responseWithError.response.data.message);
		});
	};
};

export const updateNoteForProductSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_NOTE_FOR_PRODUCT_SUCCESS,
		payload: data
	};
};

export const updateNoteForProductError = (error) => {
	return {
		type: actionTypes.UPDATE_NOTE_FOR_PRODUCT_ERROR,
		payload: error
	};
};

export const deleteNoteForProduct = (note_id) => {
	return (dispatch) => {
		return axios.delete(`/support/product/notes/${note_id}`).then((response) => {
			if (response.status === 200) {
				return dispatch(deleteNoteForProductSuccess(response.data));
			}
		}).catch((responseWithError) => {
			dispatch(deleteNoteForProductError(responseWithError.response.data));
			throw new Error(responseWithError.response.data.message);
		});
	};
};

export const deleteNoteForProductSuccess = (data) => {
	return {
		type: actionTypes.DELETE_NOTE_FOR_PRODUCT_SUCCESS,
		payload: data
	};
};

export const deleteNoteForProductError = (error) => {
	return {
		type: actionTypes.DELETE_NOTE_FOR_PRODUCT_ERROR,
		payload: error
	};
};

export const uploadFile = (noteId, noteAttachment, productId) => {
	const formData = new FormData();
	formData.append('noteAttachment', noteAttachment);

	return (dispatch) => {
		return axios.post(`/support/product/notes/${noteId}/attachments`, formData,
			{ headers: { 'Content-Type': 'multipart/form-data' } })
			.then((result) => {
				dispatch(uploadFileSuccess(result));
				dispatch(getNotesForProduct(productId));
			}).catch((error) => {
				dispatch(uploadFileError(error.response.data));
			});
	};
};

export const uploadingFile = () => {
	return {
		type: actionTypes.UPLOADING_FILE,
		payload: null
	};
};

export const uploadFileSuccess = (data) => {
	return {
		type: actionTypes.UPLOAD_FILE_SUCCESS,
		payload: data
	};
};

export const uploadFileError = (error) => {
	return {
		type: actionTypes.UPLOAD_FILE_ERROR,
		payload: error
	};
};

export const deleteFile = (noteId, attachmentId, productId) => {
	return (dispatch) => {
		return axios.delete(`/support/product/notes/${noteId}/attachments/${attachmentId}`)
			.then((result) => {
				dispatch(deleteFileSuccess(result.data));
				dispatch(getNotesForProduct(productId));
			})
			.catch((error) => { dispatch(deleteFileError(error.response.data)); });
	};
};

export const deleteFileSuccess = (data) => {
	return {
		type: actionTypes.DELETE_FILE_SUCCESS,
		payload: data
	};
};

export const deleteFileError = (error) => {
	return {
		type: actionTypes.DELETE_FILE_ERROR,
		payload: error
	};
};

export const updateFavorite = (serialNumber, state) => {
	return (dispatch) => {
		return axios.put('/products/favorite', { serialNumber, state }).then((response) => {
			dispatch(updateFavoriteSuccess());
		}).catch((error) => {
			dispatch(updateFavoriteError(error));
		});
	};
};

export const updateFavoriteSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_PRODUCT_FAVORITE_STATE_SUCCESS,
		payload: data
	};
};

export const updateFavoriteError = (error) => {
	return {
		type: actionTypes.UPDATE_PRODUCT_FAVORITE_STATE_ERROR,
		payload: error
	};
};

export const getVMActivationKeys = () => {
	return (dispatch) => {
		return axios.get('/products/activation-keys').then((result) => {
			dispatch(getVMActionCodesSuccess(result.data.data));
		}).catch((error) => {
			dispatch(getVMActionCodesError(error.response.data));
		}
		);
	};
};

export const getVMActionCodesSuccess = (data) => {
	return {
		type: actionTypes.GET_VM_ACTIVATION_CODES_SUCCESS,
		payload: data
	};
};

export const getVMActionCodesError = (error) => {
	return {
		type: actionTypes.GET_VM_ACTIVATION_CODES_ERROR,
		payload: error
	};
};

export const increaseActivations = (productKey, formValue) => {
	return (dispatch) => {
		dispatch(startIncreaseActivations());
		return axios.put(`/products/activation-keys/${productKey}/increase`, formValue)
			.then((result) => {
				dispatch(increaseActivationsSuccess());
				return result.data.message;
			})
			.catch((error) => {
				const message = error?.response?.data?.message ?? error?.message ?? 'Failed to increase the number of activation...';
				dispatch(increaseActivationsError(message));
				throw message;
			});
	};
};

export const startIncreaseActivations = () => {
	return {
		type: actionTypes.START_INCREASE_ACTIVATIONS
	};
};

export const increaseActivationsSuccess = () => {
	return {
		type: actionTypes.INCREASE_ACTIVATIONS_SUCCESS
	};
};

export const increaseActivationsError = (error) => {
	return {
		type: actionTypes.INCREASE_ACTIVATIONS_ERROR,
		payload: error
	};
};

export const doImpersonate = (email) => {
	return (dispatch) => {
		return axios.post(`/authorization/impersonate`, { email: email }).then((result) => {
			const url = window.REACT_APP_HUB_URL;
			window.open(`${url}/impersonate?email=${encodeURIComponent(result.data.email)}&token=${encodeURIComponent(result.data.token)}`);
		}).catch((error) => {
		}
		);
	};
};
