import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AdminMaintenancePlansFilterReport from './adminMaintenanceTypesFilter';
import AdminMaintenanceTypesTable from './adminMaintenanceTypesTable';
import AddMaintenancePlanModal from './adminMaintenanceTypesAddModal';
import { getMaintenancePlansAdminReport, getMaintenancePlansOptions } from '../../../../data/maintenanceType/action';

const AdminMaintenanceTypes = ({ loadingMaintenancePlansReports, maintenancePlansReport, fetchMaintenancePlansOptions, fetchMaintenancePlansReports }) => {
	useEffect(() => {
		try {
			fetchMaintenancePlansReports('all');
		} catch (error) {
			pushDangerNotification(`Could not retrieve maintenance plans report. Reason: ${error.message}`);
		}
	}, []);

	return (
		<div id="maintenance" className="p-4 bg-white">
			<AdminMaintenancePlansFilterReport
				loadingMaintenancePlansReports={loadingMaintenancePlansReports}
				fetchMaintenancePlansReports={fetchMaintenancePlansReports}
			/>

			<AdminMaintenanceTypesTable
				loadingMaintenancePlansReports={loadingMaintenancePlansReports}
				maintenancePlansReport={maintenancePlansReport}
				fetchMaintenancePlansReports={fetchMaintenancePlansReports}
				fetchMaintenancePlansOptions={fetchMaintenancePlansOptions}
			/>

			<AddMaintenancePlanModal
				fetchMaintenancePlansReports={fetchMaintenancePlansReports}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		maintenancePlansReport: state.maintenancePlansOptions.maintenancePlansReport,
		loadingMaintenancePlansReports: state.maintenancePlansOptions.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMaintenancePlansOptions: () => {
			dispatch(getMaintenancePlansOptions());
		},
		fetchMaintenancePlansReports: (maintenancePlanStatus) => {
			dispatch(getMaintenancePlansAdminReport(maintenancePlanStatus));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMaintenanceTypes);
