import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from '@syneto/compass-react';
import { deleteMaintenanceServiceRenewal } from '../../../../Administrator/components/AdminSettings/data/actions';
import OperationsProductsModalMaintenancePlans from '../OperationsProductsMaintenancePlansModal';

const OperationsProductsEditModalMaintenanceExtension = (props) => {
	const { maintenancePlansOptions, maintenanceRenewals, changeProps, fields, deleteMaintenanceServiceRenewal, refreshMaintenanceDetails } = props;
	const [newRenewalAdded, setNewRenewalAdded] = useState(false);
	const maintenanceType = maintenancePlansOptions.length > 0 ? maintenancePlansOptions[0].synetoInternalName : '';
	const defaultExtension = {
		// maintenanceType: maintenanceType,
		// maintenance_plan: { synetoInternalName: maintenanceType },
		duration: 12,
		quoteNumber: '',
		saleValue: ''
	};

	useEffect(() => {
		const newRenewals = fields.getAll().filter((field) => { return field.id === undefined; });

		setNewRenewalAdded(newRenewals.length > 0);
	}, [fields]);

	const addRenewal = () => {
		fields.push(defaultExtension);
	};
	
	return (
		<>
			{fields.map((extensionName, index) => {
				const renewal = maintenanceRenewals[index];
				const renewalExtension = fields.get(index);
				const deleteRenewal = () => {
					const renewalId = renewalExtension?.id ?? false;
					fields.remove(index);

					if (renewalId) {
						deleteMaintenanceServiceRenewal(renewalId);
						refreshMaintenanceDetails();
					}
				};

				return (
					<OperationsProductsModalMaintenancePlans
						key={index}
						extensionName={extensionName}
						extension={renewalExtension}
						deleteCallBack={deleteRenewal}
						maintenancePlansOptions={maintenancePlansOptions}
						initialMaintenanceType={renewalExtension.maintenancePlanId}
						serenityValue={(renewal?.maintenancePlanId === renewalExtension?.maintenancePlanId && renewalExtension?.serenity === 'optional' && renewal?.hasSerenity) || false}
						change={changeProps}
					/>
				);
			})}
			<Row>
				<Col colSpan="8" className="ps-0">
					<Button role="tertiary" type="submit" disabled={newRenewalAdded} onClick={addRenewal}>Extend maintenance</Button>
				</Col>
			</Row>
		</>
	);
};


export default connect(null, { deleteMaintenanceServiceRenewal })(OperationsProductsEditModalMaintenanceExtension);
