import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Form } from '@syneto/compass-react';
import { usePrevious } from '../../hooks/usePrevious';

export const Checkbox = (props) => {
	return (
		<Field
			type="checkbox"
			component={ renderField }
			{ ...props }
		/>
	); 
};

const renderField = (props) => {
	const { label, input, id, name, icon, required, disabled, feedback, style, noMarginBottom, meta, onValueChange } = props;
	const { invalid } = meta;
	const { value } = input;
	const prevValue = usePrevious(value);

	useEffect(() => {
		if (prevValue !== undefined && prevValue !== value && !!onValueChange) {
			onValueChange(value);
		}		
	}, [prevValue, value, onValueChange]);

	return (
		<Form.Group className={!noMarginBottom && 'mb-3'} controlId={ id }>
			<Form.Check
				type="checkbox"
				label={ <span>{ label } { icon ? icon : '' }</span> }
				name={ name }
				feedback={ feedback }
				disabled={ disabled }
				required={ required }
				isInvalid={ invalid }
				style={ style }
				{ ...input }
			/>
		</Form.Group>
	);
};
