import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Badge, Button, Dropdown, Icon, Table } from '@syneto/compass-react';
import { attachProductsToEvents, extractSerialNumbersFromSerenityEvents, groupSerenityEventsByMachinSerialNumber } from '../../../../../helpers/normalize-data';
import { updateFavorite } from '../../../data/actions/actions';
import { deleteSerenityEvent, updateSerenityEvent, loadSerenityEvents } from '../../../../../data/serenity-events/actions';
import { getProductsBySerialNumber } from '../../../../../data/product/actions';
import { SupportSerenityEventsDeleteModal } from '../SupportSerenityAllEvents/supportSerenityEventsDeleteModal';
import { SupportSerenityEventsEditModal } from '../SupportSerenityAllEvents/supportSerenityEventsEditModal';
import SupportSerenityNewEventsTableFilters from './supportSerenityNewEventsTableFilters';
import Preloader from '../../../../../components/preloader';
import './style.scss';

const SupportSerenityNewEventsTable = (props) => {
	const { loadSerenityEvents, getProductsBySerialNumber, updateFavorite, events, products, pagination, loading } = props;
	const [queryParams, setQueryParams] = useState({});
	const [isEditModalVisible, setIsEditModalVisible] = useState(false);
	const [eventBeingEdited, setEventBeingEdited] = useState(null);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [eventsToBeDeleted, setEventsToBeDeleted] = useState([]);
	const [eventsData, setEventsData] = useState([]);

	const defaultPagination = {
		limit: 60,
		start: 0
	};

	const defaultQueryParams = {
		...defaultPagination,
		... { status: 'new' },
	};

	const getPaginationOptions = () => {
		return {
			sizePerPageList: [15, 30, 60, 100, 200],
			sizePerPage: pagination.itemsPerPage,
			showTotal: true,
			page: pagination.currentPage,
			totalSize: pagination.totalItems
		};
	};

	const expandRow = {
		renderer: (row) => {
			return (
				<Table classes="collapsedSerenityEventsTable" data={row.events} keyField='id'>
					<Table.Col field="occurrences" align="left" width="60px" renderCell={renderOccurrences} />
					<Table.Col field="date" renderCell={renderDateTime}>Date - Time</Table.Col>
					<Table.Col field="content.subject">Event Title</Table.Col>
					<Table.Col field="status" renderCell={renderStatus}>Status</Table.Col>
					<Table.Col field="severity">Severity</Table.Col>
					<Table.Col renderCell={renderActions}>Actions</Table.Col>
				</Table>
			);
		},
		showExpandColumn: true,
		expandColumnPosition: 'right',
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? <Icon name="fas fa-caret-up" color='#4f5ed7' size="lg" /> :
				<Icon name="fas fa-caret-down" size="lg" />;
		},
		expandColumnRenderer: ({ expanded, rowKey }) => {
			const badge = document.getElementById(rowKey);

			if (expanded) {
				if (badge) {
					badge.classList.remove('badge-secondary');
					badge.classList.add('strong');
					badge.classList.add('badge-info');
				}

				return <Icon name="fas fa-caret-up" color='#4f5ed7' size="lg" />;
			} else {
				if (badge) {
					badge.classList.add('badge-secondary');
					badge.classList.remove('strong');
					badge.classList.remove('badge-info');
				}

				return <Icon name="fas fa-caret-down" size="lg" />;
			}
		}
	};

	useEffect(() => {
		fetchSerenityEvents(defaultQueryParams);
	}, []);

	useEffect(() => {
		const listOfSerialNumbers = extractSerialNumbersFromSerenityEvents(events);

		if (listOfSerialNumbers.length > 0) {
			getProductsBySerialNumber(listOfSerialNumbers);
		}
	}, [events]);

	useEffect(() => {
		if (products.length > 0 && events.length > 0) {
			setEventsData(attachProductsToEvents(products, events, !!queryParams.favorite));
		} else {
			setEventsData([]);
		}
	}, [events, products]);

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
			case 'search': {
				handleSearch(searchText);
				return;
			}
		}
	};

	const handlePagination = (page, sizePerPage) => {
		const start = String((page - 1) * sizePerPage);

		fetchSerenityEvents({
			start: start >= 0 ? start : 0,
			limit: sizePerPage,
		});
	};

	const handleSearch = (value) => {
		fetchSerenityEvents({
			searchFor: value,
			start: 0,
		});
	};

	const fetchSerenityEvents = (newParams) => {
		const { favorite: oldFavorite, ...oldUrlParams } = queryParams;
		const { favorite = oldFavorite, ...newUrlParams } = newParams;

		const urlSearchParams = { ...oldUrlParams, ...newUrlParams, ...{ status: 'new' } };
		let url = '/serenity/events';

		if (!_.isEmpty(urlSearchParams)) {
			url = url + `?${new URLSearchParams(urlSearchParams)}`;
		}

		setQueryParams({ ...queryParams, ...newParams });
		return loadSerenityEvents(url, favorite);
	};

	const renderSerialNumber = (cell, row) => {
		return (
			<div>
				<a onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					updateFavorite(row.serialNumber, !row.isFavorite);
					setTimeout(() => {
						fetchSerenityEvents(queryParams);
					}, 700);
					// fetchSerenityEvents(queryParams);
				}}>
					{renderFavoriteIcon(row.isFavorite)}
				</a> {row.serialNumber}
			</div>
		);
	};
	const renderFavoriteIcon = (isFavorite) => { return isFavorite ? <Icon name="fa fa-star" color="#FAD250" size="lg" /> : <Icon name="fa fa-star-o" color="#000000" size="lg" />; };
	const renderNoOfEvents = (cell, row) => { return <Badge id={row.serialNumber}>{row.events.length} Unresolved Event Groups</Badge>; };
	const renderOccurrences = (cell, row) => { return <Badge appearance="info">{row.occurrences}</Badge>; };
	const renderDateTime = (cell, row) => { return moment(row.recordTimestamp, 'x').format('DD/MM/YYYY - HH:mm'); };

	const renderStatus = (cell, row) => {
		return (
			<Dropdown
				label={row.state[row.state.length - 1].value}
				onSelect={(state) => { return handleEventsStatusChange([row], state.toLowerCase()); }}
				items={[
					{ label: 'New' },
					{ label: 'Pending' },
					{ label: 'Resolved' },
					{ label: 'Closed' },
					{ label: 'Ignored' }
				]}
			/>
		);
	};

	const renderActions = (cell, row) => {
		return (
			<Button role="tertiary" onClick={() => { return openEditModal(row); }}>
				Edit
			</Button>
		);
	};

	const handleEventsStatusChange = (events, status) => {
		Promise.all(events.map((event) => {
			event.state.push({
				value: status,
				timeStamp: moment().valueOf().toString()
			});

			return props.updateSerenityEvent({ event: event });
		})).then(() => {
			fetchSerenityEvents(queryParams);
		});
	};

	const handleEventStatusChange = (event, status) => {
		handleEventsStatusChange([event], status.toLowerCase());
	};


	const handleEventsDelete = (events) => {
		Promise.all(events.map((event) => {
			return props.deleteSerenityEvent(event.id);
		})).then(() => {
			fetchSerenityEvents(queryParams);
		});
	};

	const openEditModal = (event) => {
		setEventBeingEdited({ ...event });
		setIsEditModalVisible(true);
	};

	const closeEditModal = () => {
		setIsEditModalVisible(false);
		setEventBeingEdited(null);
	};

	const openDeleteModal = (events) => {
		setEventsToBeDeleted(events);
		setIsDeleteModalVisible(true);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setEventsToBeDeleted([]);
		closeEditModal();
	};

	const emptyTableState = () => {
		return loading 
			? <Preloader dataIsLoading={true} />
			: 'No events found';
	};

	return (
		<>
			<Table
				classes="serenityNewEventsTable"
				data={!loading && eventsData.length > 0 ? groupSerenityEventsByMachinSerialNumber(eventsData) : []}
				expandRow={expandRow}
				pagination={getPaginationOptions()}
				keyField='serialNumber'
				remote
				noDataIndication={emptyTableState()}
				search={{ defaultSearch: pagination.searchFor }}
				onTableChange={handleTableChange}
				extra={
					<SupportSerenityNewEventsTableFilters
						fetchSerenityEvents={fetchSerenityEvents}
						queryParams={queryParams}
					/>
				}
			>
				<Table.Col field="serialNumber" renderCell={renderSerialNumber}>Machine SN</Table.Col>
				<Table.Col field="productModel">Machine Model</Table.Col>
				<Table.Col field="contactName">End User</Table.Col>
				<Table.Col field="email">E-mail</Table.Col>
				<Table.Col field="maintenanceType">Machine Type</Table.Col>
				<Table.Col field="noOfEvents" renderCell={renderNoOfEvents}>No. of events</Table.Col>
			</Table>
						
			<SupportSerenityEventsEditModal
				isVisible={isEditModalVisible}
				close={closeEditModal}
				event={eventBeingEdited}
				renderStatus={renderStatus}
				handleEventStatusChange={handleEventStatusChange}
				openDeleteModal={openDeleteModal}
			/>

			<SupportSerenityEventsDeleteModal
				isVisible={isDeleteModalVisible}
				close={closeDeleteModal}
				events={eventsToBeDeleted}
				handleEventsDelete={handleEventsDelete}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const { serenityEvents: { serenityEvents, loading: serenityEventsLoading, pagination }, products: { products, loading: productsLoading } } = state;

	return {
		events: serenityEvents,
		products,
		loading: serenityEventsLoading || productsLoading,
		pagination
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadSerenityEvents: (url, showOnlyFavorite = false) => { return dispatch(loadSerenityEvents(url, showOnlyFavorite)); },
		getProductsBySerialNumber: (listOfSerialNumbers) => { return dispatch(getProductsBySerialNumber(listOfSerialNumbers)); },
		updateSerenityEvent: (event) => { return dispatch(updateSerenityEvent(event)); },
		deleteSerenityEvent: (event) => { return dispatch(deleteSerenityEvent(event.id)); },
		updateFavorite: (serialNumber, isFavorite) => { return dispatch(updateFavorite(serialNumber, isFavorite)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportSerenityNewEventsTable);
