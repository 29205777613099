import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	loading:                              true,
	maintenancePlanCreatedSuccessMessage: null,
	maintenancePlanCreatedErrorMessage:   null,
	maintenancePlanUpdateSuccessMessage:  null,
	maintenancePlanUpdateErrorMessage:    null,
	maintenancePlanDeleteSuccessMessage:  null,
	maintenancePlanDeleteErrorMessage:    null,
	maintenancePlansReport:               [],
	showRegisterNewMaintenancePlanModal:  false,
	maintenancePlansOptions:              {
		currentlySelling:           [],
		allMaintenancePlansOptions: []
	}
};

export default (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case actionTypes.CREATING_MAINTENANCE_TYPE:
			return {
				...state,
				maintenancePlanCreatedSuccessMessage: null,
				maintenancePlanCreatedErrorMessage:   null
			};
		case actionTypes.CREATE_MAINTENANCE_TYPE_SUCCESS:
			return {
				...state,
				maintenancePlanCreatedSuccessMessage: payload.message,
				maintenancePlanCreatedErrorMessage:   null
			};
		case actionTypes.CREATE_MAINTENANCE_TYPE_ERROR:
			return {
				...state,
				maintenancePlanCreatedSuccessMessage: null,
				maintenancePlanCreatedErrorMessage:   payload
			};
		case actionTypes.UPDATING_MAINTENANCE_TYPE:
			return {
				...state,
				maintenancePlanUpdateSuccessMessage: null,
				maintenancePlanUpdateErrorMessage:   null
			};
		case actionTypes.UPDATE_MAINTENANCE_TYPE_SUCCESS:
			return {
				...state,
				maintenancePlanUpdateSuccessMessage: payload.message,
				maintenancePlanUpdateErrorMessage:   null
			};
		case actionTypes.UPDATE_MAINTENANCE_TYPE_ERROR:
			return {
				...state,
				maintenancePlanUpdateSuccessMessage: null,
				maintenancePlanUpdateErrorMessage:   payload
			};
		case actionTypes.DELETING_MAINTENANCE_TYPE:
			return {
				...state,
				maintenancePlanDeleteSuccessMessage: null,
				maintenancePlanDeleteErrorMessage:   null
			};
		case actionTypes.DELETE_MAINTENANCE_TYPE_SUCCESS:
			return {
				...state,
				maintenancePlanDeleteSuccessMessage: payload.message,
				maintenancePlanDeleteErrorMessage:   null
			};
		case actionTypes.DELETE_MAINTENANCE_TYPE_ERROR:
			return {
				...state,
				maintenancePlanDeleteSuccessMessage: null,
				maintenancePlanDeleteErrorMessage:   payload
			};
		case actionTypes.GETTING_MAINTENANCE_TYPES_REPORT:
			return {
				...state,
				loading: true
			};
		case actionTypes.GET_MAINTENANCE_TYPES_REPORT_SUCCESS:
			return {
				...state,
				loading:                false,
				// success:                'Maintenance plans report fetched successfully.',
				maintenancePlansReport: payload.maintenancePlans
			};
		case actionTypes.GET_MAINTENANCE_TYPES_REPORT_ERROR:
			return {
				...state,
				loading:                false,
				// success:                `Could not fetch maintenance plans report. Reason: ${ payload }`,
				maintenancePlansReport: []
			};
		case actionTypes.SHOW_MODAL_ADD_NEW_MAINTENANCE_TYPE:
			return {
				...state,
				showRegisterNewMaintenancePlanModal: true
			};
		case actionTypes.HIDE_MODAL_ADD_NEW_MAINTENANCE_TYPE:
			return {
				...state,
				showRegisterNewMaintenancePlanModal: false
			};
		case actionTypes.GETTING_MAINTENANCE_PLANS_OPTIONS:
			return {
				...state,
				loading: false
			};
		case actionTypes.GET_MAINTENANCE_PLANS_OPTIONS_SUCCESS:
			return {
				...state,
				loading:                 false,
				// success:                 'Maintenance plans options fetched successfully.',
				maintenancePlansOptions: {
					currentlySelling:           payload.currentlySellingMaintenancePlansOptions,
					allMaintenancePlansOptions: payload.allMaintenancePlansOptions
				}
			};
		case actionTypes.GET_MAINTENANCE_PLANS_OPTIONS_ERROR:
			return {
				...state,
				loading: false
				// error:   `Could not fetch maintenance plans options. Reason: ${ payload.error.message }`
			};
		default:
			return state;
	}
};
