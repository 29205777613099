import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { usePrevious } from '../../hooks/usePrevious';
import { pushDangerNotification } from '../../data/notifications/actions';
import LogInCard from './components/LoginForm';
import MFAConfigTunnel from './components/MFAConfigTunnel';
import MFALogin from './components/MFALogin';
import MFARecovery from './components/MFARecoveryForm';

const Authentication = (props) => {
	const { authentication, pushDangerNotification } = props;
	const location = useLocation();
	const prevLocationPath = usePrevious(location.pathname);
	const prevStatus = usePrevious(authentication.status);
	const [showConfigurationTunel, setShowConfigurationTunel] = useState(false);
	const [showMFAModal, setShowMFAModal] = useState(false);
	const [showRecoveryModal, setShowRecoveryModal] = useState(false);

	useEffect(() => {
		if (prevLocationPath === '/sign-in' && (authentication.status === 'unauthenticated' || authentication.status === 'pending')) {
			setShowMFAModal(false);
			setShowConfigurationTunel(false);
			setShowRecoveryModal(false);
		}
	}, [prevLocationPath]);

	useEffect(() => {
		if (prevStatus === 'pending' && authentication.status === 'failed') {
			pushDangerNotification(`Authentication has failed...`);
		}
	}, [authentication.status, prevStatus]);

	useEffect(() => {		
		if (authentication?.mfaData?.isEnabled && !authentication?.mfaData?.isConfigured) {
			setShowConfigurationTunel(true);
		}

		if (authentication?.mfaData?.isEnabled && authentication?.mfaData?.isConfigured) {
			setShowMFAModal(true);
		}
	}, [
		authentication.mfaData,
		authentication.mfaData.isConfigured,
		authentication.mfaData.isEnabled,
		authentication.mfaData.isEnforced
	]);

	const renderAuthentication = () => {
		switch (true) {
			case showConfigurationTunel:
				return <MFAConfigTunnel isEnforced={authentication?.mfaData?.isEnforced ?? false} />;
			case showMFAModal:
				return <MFALogin setShowRecoveryModal={openRecoveryForm}/>;
			case showRecoveryModal:
				return <MFARecovery onHide={closeRecoveryForm}/>;
			default:
				return <LogInCard />;
		}
	};

	const openRecoveryForm = () => {
		if (showMFAModal) {
			setShowMFAModal(false);
		}

		setShowRecoveryModal(true);
	};

	const closeRecoveryForm = () => {
		if (showRecoveryModal) {
			setShowRecoveryModal(false);
		}

		setShowMFAModal(true);
	};

	return (
		<div id="authentication" className="w-100 h-100">
			<div className="container h-100">
				<div className="row h-100 d-flex justify-content-center align-items-center">					
					{renderAuthentication()}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
