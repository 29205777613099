import * as actionTypes from './actionTypes';

export const pushSuccessfulNotification = (text) => {
	return {
		type: actionTypes.PUSH_SUCCESSFUL,
		text: text
	};
};

export const pushDangerNotification = (text) => {
	return {
		type: actionTypes.PUSH_DANGER,
		text: text
	};
};

export const pushWarningNotification = (text) => {
	return {
		type: actionTypes.PUSH_WARNING,
		text: text
	};
};

export const removeNotification = (key) => {
	return {
		type: actionTypes.REMOVE_NOTIFICATION,
		key
	};
};
