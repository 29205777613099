import React, { useEffect } from 'react';
import { Field, change } from 'redux-form';
import { Form } from '@syneto/compass-react';
import { connect } from 'react-redux';

const Input = ({ type, ...props }) => {
	if ( type === 'hidden' ) { return <Field type={ type } { ...props } component="input"/>; }

	return (
		<Field type={ type } { ...props } component={ renderField }/>
	);
};

const renderField = (props) => {
	const { label, icon, required, type, id, name, readOnly, autoComplete, className, style, changeProp, initValue } = props;
	const { placeholder, inputRef, autoFocus, disabled, input } = props;
	const { meta: { touched, error, warning } } = props;

	useEffect(() => {
		if (initValue) {
			changeProp(initValue);
		}
	}, [initValue]);

	return (
		<Form.Group className='mb-3' controlId={ id }>
			{ label ? <Form.Label>{ label }{ required ? '*' : null } { icon ? icon : null }</Form.Label> : null }

			<Form.Control
				type={ type }
				name={ name }
				placeholder={ placeholder }
				ref={ inputRef }
				autoFocus={ autoFocus }
				disabled={ disabled }
				isInvalid={ touched && error }
				readOnly={ readOnly }
				style={ style }
				className={ className }
				{ ...input }
			/>

			{ touched && warning && <span>{ warning }</span> }

			<Form.Control.Feedback type="invalid">
				{ error }
			</Form.Control.Feedback>
		</Form.Group>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	if (!ownProps.formName || !ownProps.name || !ownProps.initValue) {
		return {};
	}

	return {
		changeProp: (value) => { dispatch(change(ownProps.formName, ownProps.name, value)); },
	};
};

export default connect(null, mapDispatchToProps)(Input);
