export const handleSort = (sortOrder, sortField, data) => {
	const type = typeof data[0][sortField];
	const compareString = (a, b) => { 
		return sortOrder === 'asc' ? a[sortField].localeCompare(b[sortField]) : b[sortField].localeCompare(a[sortField]); 
	};
	const compareNumber = (a, b) => { 
		return sortOrder === 'asc' ? a[sortField] - b[sortField] : b[sortField] - a[sortField]; 
	};

	return data.sort(type === 'string' ? compareString : compareNumber);
};
