// MAINTENANCE TYPES
export const GETTING_MAINTENANCE_TYPES_REPORT = 'GETTING_MAINTENANCE_TYPES_REPORT';

export const GET_MAINTENANCE_TYPES_REPORT_SUCCESS = 'GET_MAINTENANCE_TYPES_REPORT_SUCCESS';
export const GET_MAINTENANCE_TYPES_REPORT_ERROR = 'GET_MAINTENANCE_TYPES_REPORT_ERROR';

export const SHOW_MODAL_ADD_NEW_MAINTENANCE_TYPE = 'SHOW_MODAL_ADD_NEW_MAINTENANCE_TYPE';
export const HIDE_MODAL_ADD_NEW_MAINTENANCE_TYPE = 'HIDE_MODAL_ADD_NEW_MAINTENANCE_TYPE';

export const CREATING_MAINTENANCE_TYPE = 'CREATING_MAINTENANCE_TYPE';
export const CREATE_MAINTENANCE_TYPE_SUCCESS = 'CREATE_MAINTENANCE_TYPE_SUCCESS';
export const CREATE_MAINTENANCE_TYPE_ERROR = 'CREATE_MAINTENANCE_TYPE_ERROR';

export const UPDATING_MAINTENANCE_TYPE = 'UPDATING_MAINTENANCE_TYPE';
export const UPDATE_MAINTENANCE_TYPE_SUCCESS = 'UPDATE_MAINTENANCE_TYPE_SUCCESS';
export const UPDATE_MAINTENANCE_TYPE_ERROR = 'UPDATE_MAINTENANCE_TYPE_ERROR';

export const DELETING_MAINTENANCE_TYPE = 'DELETING_MAINTENANCE_TYPE';
export const DELETE_MAINTENANCE_TYPE_SUCCESS = 'DELETE_MAINTENANCE_TYPE_SUCCESS';
export const DELETE_MAINTENANCE_TYPE_ERROR = 'DELETE_MAINTENANCE_TYPE_ERROR';
// MAINTENANCE TYPES

export const GETTING_MAINTENANCE_PLANS_OPTIONS = 'GETTING_MAINTENANCE_PLANS_OPTIONS';
export const GET_MAINTENANCE_PLANS_OPTIONS_SUCCESS = 'GET_MAINTENANCE_PLANS_OPTIONS_SUCCESS';
export const GET_MAINTENANCE_PLANS_OPTIONS_ERROR = 'GET_MAINTENANCE_PLANS_OPTIONS_ERROR';

export const DELETING_USER = 'DELETING_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
