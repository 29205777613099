import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from '@syneto/compass-react';

const DESCRIPTION = 'These are the MFA recovery keys that you can use in case you lose access to your primary MFA method. These recovery keys will grant you access to your account when needed. Please keep them safe and only use them as a last resort.';
const DISCLAMER = 'You have 5 recovery codes, each usable once.';

const RecoveryCodes = ({ authentication, getUserProfile, pushSuccessfulNotification }) => {
	const formatRecoveryCodsForDocument = () => {
		const recoveryCodes = authentication.mfaData.recoveryCodes.toString().replaceAll(',',', ');

		return `${DESCRIPTION} \n\n\n ${recoveryCodes} \n\n\n ${DISCLAMER}`;
	};

	const print = () => {
		window.print();
	};

	const download = () => {
		// Create element with <a> tag
		const link = document.createElement('a');
		// Create a blog object with the file content which you want to add to the file
		const file = new Blob([formatRecoveryCodsForDocument()], { type: 'text/plain' });
		// Add file content in the object URL
		link.href = URL.createObjectURL(file);
		// Add file name
		link.download = 'centralRecoveryCode.txt';
		// Add click event to <a> tag to save file.
		link.click();
		URL.revokeObjectURL(link.href);
	};

	const copy = () => {
		navigator.clipboard.writeText(formatRecoveryCodsForDocument());
		pushSuccessfulNotification('Your Recovery Codes have been copied to the clipboard. Keep them safe!');
	};

	return (
		<Card
			className={`z-depth-1 ${authentication.status === 'failed' ? 'animated shake' : ''}`}
			title={'Setup multiple factor authentication'}
			content={
				<>
					<div className='row'>
						<h6>Multi-Factor Authentication (MFA) Recovery Keys</h6>
						<p><small>{DESCRIPTION}</small></p>
					</div>

					<div className='col-5 d-flex flex-wrap gap-3 border border-secondary-subtle p-2 mb-3'>
						{authentication.mfaData.recoveryCodes.map((code, index) => {
							return (<span key={index}>{code}</span>);
						})}
					</div>

					<div className='row'>
						<p><small>{`${DISCLAMER} Download, print or copy these recovery keys!`}</small></p>
					</div>

					<div className='d-flex gap-3'>
						<a href="#" onClick={download} className="text-primary mt-2">Download</a>
						<a href="#" onClick={print} className="text-primary mt-2">Print</a>
						<a href="#" onClick={copy} className="text-primary mt-2">Copy</a>
					</div>

					<div className='d-flex justify-content-end'>
						<Button onClick={getUserProfile}>Go To Dashboard</Button>
					</div>
				</>
			}
		/>
	);
};

export default RecoveryCodes;

RecoveryCodes.propTypes = {
	authentication: PropTypes.object.isRequired,
	getUserProfile: PropTypes.func.isRequired
};
