import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
	status: null,
	loading: false,
	isMFAEnforcedForCurrentRole: false,
	data: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.IS_ADMIN_ENFORCING_MFA_SUCCESS:
			return {
				...state,
				isMFAEnforcedForCurrentRole: action.payload
			};
		case actionTypes.IS_ADMIN_ENFORCING_MFA_FAILED:
			return {
				...state,
				isMFAEnforcedForCurrentRole: false
			};
		case actionTypes.GET_USERS_LOADING:
			return {
				...state,
				loading: true
			};
		case actionTypes.GET_USERS_SUCCESS:
			return {
				...state,
				status: 'loaded',
				loading: false,
				data: action.payload
			};
		case actionTypes.GET_USERS_ERROR:
			return {
				...state,
				status: 'loaded',
				loading: false
			};
		default:
			return state;
	}
};
