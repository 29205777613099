import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Icon } from '@syneto/compass-react';
import Form from '../../../../../components/forms/form';
import {
	loadProductMaintenanceServicesForSupport,
	resetMaintenanceServiceDetails
} from '../../../../../data/productMaintenanceService/actions';
import {
	pushDangerNotification
} from '../../../../../data/notifications/actions';
import { getCustomersSupport } from '../../../../../data/customers/action';
import { resetUserDetailsState } from '../../../../../data/selected-customer/actions';
import Preloader from '../../../../../components/preloader';

const Search = (props) => {
	let { customers, productMaintenanceService, handleSubmit, submitting, getCustomersSupport, setUserAccountsFromSearch, loadProductMaintenanceServicesForSupport, pushDangerNotification, setMaintenanceServicesFromSearch, resetUserDetailsState, resetMaintenanceServiceDetails, customersQueryParams, setCustomersQueryParams } = props;
	const [dataLoaded, setDataLoaded] = useState(true);
	const [isSearchDisabled, setIsSearchDisabled] = useState(false);

	useEffect(() => {
		if (!customersQueryParams.searchFor.length) {
			return;
		}
		setDataLoaded(false);
		getCustomersSupport(customersQueryParams).then((response) => {
			setUserAccountsFromSearch(response.payload.customers);
			setDataLoaded(true);
		});
	}, [customersQueryParams]);

	const submit = (values) => {
		resetUserDetailsState();
		resetMaintenanceServiceDetails();
		setDataLoaded(false);

		setCustomersQueryParams({
			searchFor: values.query, 
			start: 0,
			limit: 30
		});
	};

	return !dataLoaded
		? <Preloader dataIsLoading={true} />
		: (
			<form className="row">
				<div className="col-11">
					<Form.text name="query"
						placeholder="Search customers, machines or phone number"
						onChange={(event) => {
							if (event.target.value === '') {
								setIsSearchDisabled(true);
								setUserAccountsFromSearch([]);
							} else {
								setIsSearchDisabled(false);
							}
						}}
					/>
				</div>

				<div className="col-1">
					<Button
						disabled={submitting || isSearchDisabled}
						onClick={handleSubmit(submit)}
						block
						type="submit"
						className="w-100"
					>
						<Icon name='fa fa-search' />
					</Button>
				</div>
			</form>
		);
};

const mapStateToProps = (state) => {
	return {
		customers: state.customers.data ?? [],
		productMaintenanceService: state.productMaintenanceService
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCustomersSupport: (queryParams) => { return dispatch(getCustomersSupport(queryParams)); },
		loadProductMaintenanceServicesForSupport: () => { return dispatch(loadProductMaintenanceServicesForSupport()); },
		resetUserDetailsState: () => { return dispatch(resetUserDetailsState()); },
		resetMaintenanceServiceDetails: () => { return dispatch(resetMaintenanceServiceDetails()); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

Search.propTypes = {
	setUserAccountsFromSearch: PropTypes.func.isRequired,
};

const SearchForm = reduxForm({
	form: 'searchForUserAccounts',
	initialValues: {
		query: ''
	}
})(Search);

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
