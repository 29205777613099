import { API_DEFAULT_VERSION, backEndAxios } from './httpClient';

export const getInternalUser = () => {
	return backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/get-internal-user`);
};

export const createInternalUser = (data) => {
	return backEndAxios.post(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/create-internal-user`, data);
};

export const updateInternalUser = (data) => {
	return backEndAxios.put(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/update-internal-user`, data);
};

export const deleteInternalUser = (userId) => {
	return backEndAxios.delete(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/delete-internal-user?id=${userId}`);
};

export const getInternalUsers = (role) => {
	return backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/get-internal-users?role=${role}`);
};

export const updateMfaStatusByRole = (data) => {
	return backEndAxios.put(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/update-mfa-status-by-role`, data);
};

export const allInternalUsersHaveEnforcedMfa = (role) => {
	return backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/all-internal-users-have-enforced-mfa?role=${role}`);
};
