import '@syneto/compass-react/lib/styles.css';
import '../assets/css/vendor/animate.min.css';
import '../assets/css/vendor/font-awesome.min.css';
import '../assets/css/theme.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './data';
import Router from './routes';

const persistedState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));
const container = document.getElementById('app');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	</Provider>
);
