class Currency {
	constructor(centFactor, stringRepresentation) {
		this.centFactor = centFactor;
		this.stringRepresentation = stringRepresentation;
	}

	static EUR() {
		return new this(100, 'EUR');
	}

	getCentFactor() {
		return this.centFactor;
	}

	getStringRepresentation() {
		return this.stringRepresentation;
	}
}

export default Currency;
