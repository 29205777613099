export const START_FETCHING_PRODUCTS = 'START_FETCHING_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const SHOW_ADD_PRODUCT_MODAL = 'SHOW_ADD_PRODUCT_MODAL';
export const HIDE_ADD_PRODUCT_MODAL = 'HIDE_ADD_PRODUCT_MODAL';

export const DELETE_PRODUCT_FOR_USER_SUCCESS = 'DELETE_PRODUCT_FOR_USER_SUCCESS';
export const DELETE_PRODUCT_FOR_USER_ERROR = 'DELETE_PRODUCT_FOR_USER_ERROR';

export const GET_ACTIVATION_CODE_SUCCESS = 'GET_ACTIVATION_CODE_SUCCESS';
export const GET_ACTIVATION_CODE_ERROR = 'GET_ACTIVATION_CODE_ERROR';
