import React from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Icon, Modal } from '@syneto/compass-react';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { FieldArray, reduxForm } from 'redux-form';
import { getNotesForProduct, saveNoteForProduct } from '../../data/actions/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'title': ['required', 'min: 5', 'max: 255'],
			'content': ['required', 'min: 10', 'max: 255']
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let SupportNotesAddModal = (props) => {
	let { productId, isOpen, toggleModal, noteForPendingProduct, handleSubmit, noteForProductStatusMessage, reset } = props;
	let { getNotesForProduct, saveNoteForProduct, pushDangerNotification, pushSuccessfulNotification } = props;

	const renderAttachments = ({ fields }) => {
		return (
			<div>
				<label style={{ display: 'block', color: '#7a6be8', cursor: 'pointer' }}
					htmlFor={`noteAttachment_${fields.length}`}>Add attachment</label>
				{
					fields.map((field, index) => {
						const attachment = fields.get(index);
						return (
							<Badge key={index} className="my-1 me-2" role="outline">
								<a onClick={() => { return fields.remove(index); }}>
									<span>{attachment.name}</span>
									<Icon name="fas fa-times ms-2" size="xs" color="#9C353E" />
								</a>
							</Badge>
						);
					})
				}
				<input id={`noteAttachment_${fields.getAll().length}`} type="file"
					name={`noteAttachment_${fields.getAll().length}`} hidden
					onChange={(e) => {
						fields.push(e.target.files[0]);
					}}
				/>
			</div>
		);
	};

	function addNote(formValues) {
		cancelModal();
		return saveNoteForProduct(productId, formValues).then(() => {
			getNotesForProduct(productId);
			pushSuccessfulNotification(noteForProductStatusMessage);
		}).catch((error) => {
			pushDangerNotification(`An error has occurred. Reason: ${error.message}`);
		});
	}

	function addNotePendingProduct(formValues) {
		cancelModal();
		return saveNoteForProduct(productId, formValues).then(() => {
			getNotesForProduct(productId);
			pushSuccessfulNotification(noteForProductStatusMessage);
		}).catch((error) => {
			pushDangerNotification(`An error has occurred. Reason: ${error.message}`);
		});
	}

	function cancelModal() {
		toggleModal(false);
		reset('addProductNotes');
	}

	return (
		<form id="product-notes" className="form modal fade">
			<Modal show={isOpen}>
				<Modal.Header>
					<Modal.Title>Add note</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<fieldset>
						<div className="mb-3">
							<label htmlFor="title">Title</label>
							<Form.text name="title" />
						</div>
						<div className="mb-3">
							<label htmlFor="content">Content</label>
							<Form.textarea name="content" />
						</div>
						<div className="mb-3">
							<FieldArray name="noteAttachments" component={renderAttachments} />
						</div>
					</fieldset>
				</Modal.Body>

				<Modal.Footer>
					<Button role="secondary" onClick={cancelModal}>Cancel</Button>
					<Button onClick={noteForPendingProduct ? handleSubmit(addNotePendingProduct) : handleSubmit(addNote)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</form>
	);
};

SupportNotesAddModal = reduxForm({
	form: 'addProductNotes',
	enableReinitialize: true,
	validate
})(SupportNotesAddModal);

const mapStateToProps = (state) => {
	return {
		noteForProductStatusMessage: state.productNotes.status,
		initialValues: {
			title: '',
			content: '',
			noteAttachments: []
		}
	};
};

export default connect(mapStateToProps, {
	getNotesForProduct,
	saveNoteForProduct,
	pushDangerNotification,
	pushSuccessfulNotification
})(SupportNotesAddModal);
