import CurrentlyActiveMaintenanceService from './CurrentlyActiveMaintenanceService';
import Currency from './money';

const normalizeUserData = (data) => {
	return {
		userId: data.user.id,
		account: {
			email: data.user.email,
			role: data.user.role,
			data_collection_consent: data.user.data_collection_consent,
			subscribe_news: data.user.subscribe_news,
			subscribe_product: data.user.subscribe_product,
			subscribe_satisfaction_surveys: data.user.subscribe_satisfaction_surveys
		},
		contact: {
			firstName: data.user.first_name ?? '', 
			lastName: data.user.last_name ?? '',
			roleInCompany: data.user.role_in_company ?? '',
			phoneNumber: data.user.phone_number ?? data.user.phoneNumber ?? ''
		},
		mfa: {
			isEnabled: data.user.mfa_enabled ?? false,
			isEnforced: data.user.mfa_enforced ?? false,
		}
	};
};

const processUserData = (user, sendMfaEnforcement = false) => {
	return {
		user: {
			id: user.userId ?? '',
			email: user.account.email ?? '',
			first_name: user.contact.firstName ?? '',
			last_name: user.contact.lastName ?? '',
			phone_number: user.contact.phoneNumber ?? '',
			role_in_company: user.contact.roleInCompany ?? '',
			subscribe_news: user.account.subscribe_news ?? false,
			subscribe_product: user.account.subscribe_product ?? false,
			subscribe_satisfaction_surveys: user.account.subscribe_satisfaction_surveys ?? false,
			data_collection_consent: user.account.data_collection_consent ?? false,
			mfa_enabled: user.mfa.isEnabled ?? false,
			...(
				user.userRole
					? {
						role: user.userRole
					}
					: {}
			),
			...(
				user.account.password
					? { 
						password: user.account.password
					} 
					: {}
			),
			...( sendMfaEnforcement
				? { mfa_enforced: user.mfa.isEnabled ?? false } 
				: {} 
			)
		}
	};
};

const normalizeMfaData = (data) => {
	return {
		isConfigured: data.mfa_configured ?? false,
		isEnabled: data.mfa_enabled ?? false,
		isEnforced: data.mfa_enforced ?? false,
		secret: data.mfa_secret ?? '',
		qrCode: data.qr_code ?? '',
		recoveryCodes: data.recovery_codes ?? []
	};
};

const processErrorMessage = (payload) => {
	const apiError = payload?.data?.message ?? payload?.message ?? 'Oops something went wrong...';
	const error = apiError.split('Details: ')[1];

	return error ?? apiError;
};

const getCurrentStatusForSerenityEvents = (statusList) => {
	return statusList.reduce((latestStatus, status) => {
		if (latestStatus.timeStamp <= status.timeStamp) {
			return status;
		} else {
			return latestStatus;
		}
	}, statusList[0]);
};

const groupSerenityEventsByMachinSerialNumber = (events) => {
	if (!events || events.length <= 0) {
		return [];
	}

	return events.reduce((emitters, event) => {
		const currentStatus = getCurrentStatusForSerenityEvents(event.state).value;
		const isNewOrPendingEvent = currentStatus === 'new' || currentStatus === 'pending';
		const addedEmmitter = emitters.find((emitter) => { return emitter.serialNumber === event.emitter.serialNumber; });
		const hasMachineInfo = event.emitter.productModel !== 'N/A' && event.emitter.maintenanceType !== 'N/A';
		const shouldBeAdded = isNewOrPendingEvent && hasMachineInfo;

		if (addedEmmitter && shouldBeAdded) {
			addedEmmitter.events.push(event);
			return emitters;
		} else if (shouldBeAdded) {
			return [
				...emitters,
				{
					...event.emitter,
					events: [event]
				}
			];
		} else {
			return emitters;
		}
	}, []);
};

const extractSerialNumbersFromSerenityEvents = (events) => {
	if (!events || events.length <= 0) {
		return [];
	}

	const serialNumbers = events.map((event) => { return event.emitter.serialNumber; });

	return [...new Set(serialNumbers)];
};

const attachProductsToEvents = (products, events, showOnlyFavorite = false) => {
	if (!events || !events.length ||events.length <= 0) {
		return [];
	}

	let product;
	const processedEvenets = events.map((event) => {
		const result = products.find((product) => { return product.serialNumber === event.emitter.serialNumber; });
		if (result !== undefined) {
			product = result;
		}

		return {
			...event,
			emitter: {
				...event.emitter,
				contactName: product?.company?.profiles[0] ? `${product?.company?.profiles[0].firstName} ${product?.company?.profiles[0].lastName}` : 'N/A',
				email: product?.company?.profiles[0]?.user ? product?.company?.profiles[0]?.user.email : 'N/A',
				companyName: product?.company?.name ?? 'N/A',
				companyEmail: product?.company?.email ?? 'N/A',
				productModel: product && product.model ? product.model.title : 'N/A',
				osVersion: product && product.osVersion ? product.osVersion : null,
				esxiVersion: product && product.esxiVersion ? product.esxiVersion : null,
				isFavorite: product && product.isFavorite ? product.isFavorite : false,
				maintenanceType: CurrentlyActiveMaintenanceService(product?.maintenanceService)?.plan?.synetoInternalName ?? 'N/A'
			}
		};
	});

	return showOnlyFavorite 
		? processedEvenets.filter((event) => { return event.emitter.isFavorite === true; })
		: processedEvenets;
};

const groupUsersPerCompany = (users) => {
	const currency = Currency.EUR();

	return users.reduce((companies, user) => {
		const { company, internal_company_detail, cltv, ...userToAdd } = user;
		if (!company) {
			return companies;
		}

		const companyAdded = companies.find((addedCompany) => { return addedCompany.id === company.id; });

		if (!companyAdded) {
			return [...companies, {
				...company,
				...internal_company_detail,
				cltv: cltv / currency.getCentFactor(),
				users: [userToAdd]
			}];
		} else {
			companyAdded.users.push(userToAdd);
			companyAdded.cltv += cltv / currency.getCentFactor();

			return companies;
		}
	}, []);
};

const flatAutoCompleteOptions = (options) => {
	return options.reduce((childOptions, option) => { 
		return [...childOptions, ...(option?.options || [option])]; 
	}, []);
};

const convertObjectKeysFromSnakeCaseToCamelCase = (obj) => {
	return Object.keys(obj).reduce((newObj, key) => {
		const newKey = _.camelCase(key);
		var value = obj[key];

		if (!_.isEmpty(value) && _.isArray(value)) {
			newObj[newKey] = [...Object.values(convertObjectKeysFromSnakeCaseToCamelCase(value))];
		} else if (!_.isEmpty(value) && _.isObject(value)) {
			newObj[newKey] = convertObjectKeysFromSnakeCaseToCamelCase(value);
		} else {
			newObj[newKey] = value;
		}

		return newObj;
	}, {});
};

export {
	normalizeUserData,
	processUserData,
	normalizeMfaData,
	processErrorMessage,
	getCurrentStatusForSerenityEvents,
	groupSerenityEventsByMachinSerialNumber,
	extractSerialNumbersFromSerenityEvents,
	attachProductsToEvents,
	groupUsersPerCompany,
	flatAutoCompleteOptions,
	convertObjectKeysFromSnakeCaseToCamelCase
};
