import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Card } from '@syneto/compass-react';
import { unflatten } from 'flat';
import Form from '../../components/forms/form';
import Validator from '../../components/forms/validate';
import formFields from '../../constants/formFields';
import { resetPassword } from './data/actions/actions';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../data/notifications/actions';
import { useNavigate, useParams } from 'react-router';

const FORM_NAME = 'password-reset';

const validate = (values) => {
	const validator = new Validator(values,
		{
			'account.password': ['required', 'min: 6'],
			'account.passwordCheck': ['required', 'same:account.password']
		},
		{
			'same.account.passwordCheck': 'Passwords do not match'
		});
	return (validator.fails() ? unflatten(validator.errors.all()) : {});
};

let PasswordReset = (props) => {
	const { passwordResetToken } = useParams();
	const navigate = useNavigate();
	const { handleSubmit, submitting, passwordReset, changeProp, resetPassword, pushSuccessfulNotification, pushDangerNotification } = props;

	useEffect(() => {
		if (passwordResetToken) {
			changeProp(formFields.passwordResetToken, passwordResetToken);
		} else {
			navigate('/sign-in');
		}
	}, []);

	useEffect(() => {
		if (passwordReset.status == 'resetted') {
			pushSuccessfulNotification('Your password has been changed.');
			navigate('/sign-in');
		}
		if (passwordReset.status == 'failed') {
			pushDangerNotification(passwordReset.error.message);
		}
	}, [passwordReset.status]);

	const submit = (values) => {
		return resetPassword(values);
	};

	return (
		<div id="password-recovery" className="w-100 h-100">
			<div className="container h-100">
				<div className="row h-100 d-flex justify-content-center align-items-center">
					<div className="col log-in">
						<Card
							title={'Password reset'}
							content={
								<form noValidate>
									<Form.hidden name={formFields.passwordResetToken} />
									<Form.password name={formFields.account.password} label="New password"
										autoFocus />
									<Form.password name={formFields.account.passwordCheck}
										label="Re-type new password" />

									<Button disabled={submitting} onClick={handleSubmit(submit)} type="submit">
										{!submitting ? 'Change' : 'Changing...'}
									</Button>
								</form>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		initialValues: {
			account: { 'password': '', 'passwordCheck': '' }
		},
		passwordReset: state.passwordReset
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetPassword: (value) => { return dispatch(resetPassword(value)); },
		changeProp: (fieldName, value) => { return dispatch(change(FORM_NAME, fieldName, value)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); }
	};
};

PasswordReset.propTypes = {
	initialValues: PropTypes.object.isRequired,
	passwordReset: PropTypes.object.isRequired
};

PasswordReset = reduxForm({
	form: FORM_NAME,
	validate
})(PasswordReset);

PasswordReset = connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

export default PasswordReset;
