import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs } from '@syneto/compass-react';
import PulseGraph from '../../../../../components/graphs/pulse-graph';
import MaintenanceDetails from '../../MaintenanceDetails';
import SupportCustomersSupportNotes from '../../supportCustomersSupportNotes/supportCustomersSupportNotes';
import NotesIcon from './NoteIcon';
import SupportSerenityAllEventsTable from '../../SupportSerenityEvents/SupportSerenityAllEvents/supportSerenityAllEventsTable';
import { getNotesForProduct } from '../../../data/actions/actions';
import RenderOnlyActiveTab from '../../../../../components/RenderOnlyActiveTab';

const PendingCard = (props) => {
	const { userId, product, productNotes, getNotesForProduct, maintenanceServices, selectedProduct } = props;
	const [tabsActiveKey, setTabsActiveKey] = useState('maintenance');
	const m = maintenanceServices.length && maintenanceServices.find((m) => { return m.product_id === product.id; });
	const maintenanceService = product.maintenance_services.length ? product.maintenance_services[ 0 ] : null;
	const internalNotes = maintenanceService?.plan?.internalNotes ?? maintenanceService?.maintenance_plan?.internal_notes; 

	useEffect(() => {
		getNotesForProduct(product.id);
	}, [product]);

	return (
		<Tabs activeKey={ tabsActiveKey } onSelect={ (tabsActiveKey) => { return setTabsActiveKey(tabsActiveKey); } }>
			<Tabs.Tab eventKey="maintenance" title="Maintenance">
				<RenderOnlyActiveTab tabKey="maintenance" tabsActiveKey={tabsActiveKey}>
					<MaintenanceDetails
						serialNumber={ product.serialNumber }
						product={selectedProduct}
						maintenanceService={ m }
						maintenanceServiceDetails={ maintenanceService }
						currentlyActiveMaintenanceService={ maintenanceService }
						maintenancePlanInternalNotes={ internalNotes }
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab eventKey="pulse" title="Pulse">
				<RenderOnlyActiveTab tabKey="pulse" tabsActiveKey={tabsActiveKey}>

					{product.serialNumber ?
						<PulseGraph
							serialNumber={ product.serialNumber }
							tabsActiveKey={ tabsActiveKey }
						/> : <div className="mb-4">No data available to generate the pulse graph.</div> }
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab eventKey="serenity" title="Serenity events">
				<RenderOnlyActiveTab tabKey="serenity" tabsActiveKey={tabsActiveKey}>
					<SupportSerenityAllEventsTable
						minimal={ true }
						serialNumber={ product.serialNumber }
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab
				eventKey="notes"
				title={ <NotesIcon productNotes={ productNotes.length }/> }
			>
				<RenderOnlyActiveTab tabKey="notes" tabsActiveKey={tabsActiveKey}>
					<SupportCustomersSupportNotes
						maintenanceNotes={ selectedProduct.maintenanceService.notes }
						userId={ userId }
						productId={ product.id }
						noteForPendingProduct={ true }
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>
		</Tabs>
	);
};

const mapStateToProps = ({ productNotes, productMaintenanceService }) => {
	return { 
		productNotes: productNotes.notes,
		maintenanceServices: productMaintenanceService.maintenanceServices,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotesForProduct: (productId) => { return dispatch(getNotesForProduct(productId)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingCard);
