module.exports = {
	collection: [
		'<1 Mil',
		'1-5 Mil',
		'5-10 Mil',
		'10-25 Mil',
		'25-50 Mil',
		'50-75 Mil',
		'75-100 Mil',
		'>100 Mil'
	]
};
